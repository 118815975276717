import { LIST_OF_ITP_PROJECT, UPDATE_ITP_PROJECT, ADD_ITP_PROJECT, DELETE_ITP_PROJECT, DELETE_ITP_PROJECT_DETAILS, LIST_OF_ASSIGN_ITP_PROJECTS, FIND_ITP_PROJECT} from "./apiRoutes"
import { apiService2 } from "./baseURL"

export const listOfProject = async (data) => {
    const response = await apiService2.post(`${LIST_OF_ITP_PROJECT}`, data);
    return response.data;
};

export const addProject = async (data) => {
    const response = await apiService2.post(`${ADD_ITP_PROJECT}`, data);
    return response;
};

export const deleteProject = async (data) => {
    const response = await apiService2.put(`${DELETE_ITP_PROJECT}`, data);
    return response;
};

export const updateProject = async (data) => {
    const response = await apiService2.put(`${UPDATE_ITP_PROJECT}`, data);
    return response;
};

export const deleteProjectDetails = async (data) => {
    const response  = await apiService2.put(`${DELETE_ITP_PROJECT_DETAILS}`, data);
    return response;
} 

export const listOfAssignProject = async (data) => {
    const response = await apiService2.post(`${LIST_OF_ASSIGN_ITP_PROJECTS}`, data);
    return response.data;
};

export const listOfAssignProjectModule = async (id) => {
    const response = await apiService2.get(`${FIND_ITP_PROJECT}/${id}`);
    return response;
};