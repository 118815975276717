import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Dragula from "react-dragula";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../components/common/deleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import { getId, getRole, getToken } from "../../utils/helpers/custom";
import TableContainer from "../../components/common/tableContainer";
import { approvetasksheet, viewJunior } from "../../api/reviewApi";
import { dateFormatChange, timeFormat } from "../../utils/helpers/common";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { viewTasksheet } from "../../api/tasksheetApi";
import { useParams } from "react-router-dom";
import Loader from "../../components/common/loader";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import NoResultsMessage from "../../components/noResultMessage";

const Status = ({ status }) => {
  switch (status) {
    case "Pending":
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
    case "Rejected":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Approved":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
  }
};

const ReviewTaskSheet = () => {
  const { idJuniour } = useParams();
  document.title = "ReviewTaskSheet";
  const id = getId();
  const profileId = getId();
  const [juniorList, setJuniorList] = useState([]);
  const [juniorListName, setJuniorNameList] = useState([]);
  const [juniorTaskList, setJuniorTaskList] = useState([]);

  useEffect(() => {
    setLoading(true);
    viewTasksheet(idJuniour)
      .then((response) => {
        setJuniorList(response.data.data);
        setJuniorNameList(response.data.data[0]);
        setJuniorTaskList(response.data.data[0].taskSheet);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);
  const [modalProject, setModalProject] = useState(false);
  const role = getRole();

  const columns = useMemo(() => [
    {
      Header: "Date",
      Cell: (items) => {
        return <div>{items.row.original.date}</div>;
      },
    },
    {
      Header: "Name Of Task",
      accessor: "nameOfTask",
      filterable: false,
    },
    {
      Header: "Details Of Task",
      accessor: "detailsOfTask",
      filterable: false,
    },
    {
      Header: "Estimated Time",
      accessor: "estimateTime",
      filterable: false,
    },
    {
      Header: "Taken Time",
      accessor: "takenTime",
      filterable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell.value} />;
      },
    },
    {
      Header: "Action",
      Cell: (item) => {
        return (
          <div className="hstack gap-2">
            {/* <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleTodoClick(item)}>
                                <i className="ri-check-line align-bottom" />
                            </button>
                            <button className="btn btn-sm btn-soft-danger edit-list" onClick={() => handleTodoClick(item)}>
                                <i className="ri-close-line align-bottom" />
                            </button> */}
            <button
              className="btn btn-sm btn-soft-info edit-list"
              onClick={() => {
                handleTodoClick(item.row.original);
              }}
            >
              <i className="ri-pencil-fill align-bottom" />
            </button>
          </div>
        );
      },
    },
  ]);

  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
      validation.resetForm();
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  // Toggle Project Modal
  const toggleProject = () => {
    if (modalProject) {
      setModalProject(false);
    } else {
      setModalProject(true);
    }
  };

  // Update To do
  const handleTodoClick = useCallback(
    (arg) => {
      setTodo(arg);
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Add To do
  const handleTodoClicks = () => {
    setTodo("");
    setModalTodo(!modalTodo);
    setIsEdit(false);
    toggle();
  };

  // Delete To do
  const onClickTodoDelete = (todo) => {
    setTodo(todo);
    setDeleteModal(true);
  };

  const handleDeleteTodo = () => {
    if (todo) {
      setDeleteModal(false);
    }
  };

  const projectValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Project Title"),
    }),
    onSubmit: (values) => {
      const newProjectData = {
        id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        title: values.title,
        subItem: [{ id: 1, version: "v1.0.0", iconClass: "danger" }],
      };
      projectValidation.resetForm();
      toggleProject();
    },
  });

  // To do Task List validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      status: (todo && todo.status) || "",
      takentime: (todo && todo.takentime) || "",
      reason: (todo && todo.reason) || "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required(RequiredField("Status")),
      takentime: Yup.string().required(RequiredField("Taken time")),
      reason: Yup.string().required(RequiredField("reason")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const newTodo = {
        id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        dueDate: date,
        leaveType: values.leaveType,
        totalDays: values.totalDays,
        reason: values.reason,
      };
      approvetasksheet(todo.id, {
        id: profileId,
        addedBy: todo?.addedBy,
        estimateTime: values.takentime,
        status: values.status,
        reason: values.reason,
      })
        .then((response) => {
          if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(response?.data?.message);
            setLoading(true);
            viewTasksheet(idJuniour)
              .then((response) => {
                setJuniorList(response.data.data);
                setJuniorNameList(response.data.data[0]);
                setJuniorTaskList(response.data.data[0].taskSheet);
              })
              .catch((err) => err)
              .finally(() => {});
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      validation.resetForm();
      toggle();
    },
  });

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      Dragula([componentBackingInstance], options);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Review Task Sheet" pageTitle="HRMS" />

            <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
              <div className="file-manager-content w-100 p-4 pb-0">
                <Row className="mb-4">
                  <div className="col-auto order-1 d-block d-lg-none">
                    <button
                      type="button"
                      className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn"
                    >
                      <i className="ri-menu-2-fill align-bottom"></i>
                    </button>
                  </div>
                  <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                    <h5 className="fw-semibold mb-0">
                      {juniorListName.firstName} {juniorListName.lastName} Task
                      Sheet
                    </h5>
                  </div>
                </Row>
                {/* <div className="todo-content position-relative px-4 mx-n4" id="todo-content"> */}

                {/* <div id="elmLoader">
                                    <div className="spinner-border text-primary avatar-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div> */}
                {/* <div className="todo-task" id="todo-task">
                                    <div className="table-responsive">
                                        <table className="table align-middle position-relative table-nowrap">
                                            <thead className="table-active">
                                                <tr>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Employee Name</th>
                                                    <th scope="col">Details of Task</th>
                                                    <th scope="col">Estimated Time</th>
                                                    <th scope="col">Taken Time</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody id="task-list" ref={dragulaDecorator}>
                                                {(data || []).map((item, key) => (<tr key={key}>
                                                    <td>{item.id}</td>
                                                    <td>{item.date}</td>
                                                    <td>
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-grow-1">
                                                                <div className="form-check">
                                                                    <label className="form-check-label" htmlFor={"todo" + item.id}>{item.name} </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{item.decription}</td>
                                                    <td>{item.estimatedTime}</td>
                                                    <td>{item.takenTime}</td>
                                                    <td>
                                                        {item.status === "Pending" ?
                                                        <div className="hstack gap-2">
                                                            <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleTodoClick(item)}>
                                                                <i className="ri-check-line align-bottom" />
                                                            </button>
                                                            <button className="btn btn-sm btn-soft-danger edit-list" onClick={() => handleTodoClick(item)}>
                                                                <i className="ri-close-line align-bottom" />
                                                            </button>
                                                        </div> : <Status status={item.status} />}
                                                    </td>
                                                </tr>))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}

                <Card>
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Task Sheet </h4>
                  </CardHeader>

                  <CardBody>
                    {juniorList?.length ? (
                      <TableContainer
                        title={"Xyzz"}
                        columns={columns}
                        data={juniorTaskList || []}
                        isAddUserList={false}
                        isGlobalFilter={true}
                        customPageSize={5}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                    ) : (
                      <NoResultsMessage/>
                    )}
                  </CardBody>
                </Card>
                {/* </div> */}
              </div>
            </div>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {!!isEdit ? "Edit TaskSheet" : "Add TaskSheet"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />

            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Status
              </label>
              <select
                className={`form-select ${
                  validation.errors.status && "is-invalid"
                }`}
                aria-label="Default select example"
                id="selectreport"
                value={validation.values.status || ""}
                disabled={role === "Admin"}
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                name="status"
              >
                <option value="" selected hidden>
                  Select Leave Type
                </option>
                <option value="Approve">Approve</option>
                <option value="Pending">Pending</option>
                <option value="Reject">Reject</option>
              </select>
              <div className="invalid-feedback">{validation.errors.status}</div>
            </div>
            <Row className="g-4 mb-3">
              <Col lg={12}>
                <label htmlFor="task-duedate-input" className="form-label">
                  Taken Time :
                </label>
                <Flatpickr
                  name="takentime"
                  id="date-field"
                  className={`form-select ${
                    validation.errors.takentime && "is-invalid"
                  }`}
                  placeholder="Enter date"
                  value={validation.values.takentime}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                    onChange: function (selectedDates, dateStr, instance) {
                      validation
                        .setFieldValue("takentime", dateStr)
                        .then((res) => res)
                        .catch((err) => err);
                    },
                  }}
                />
                <div className="invalid-feedback">
                  {validation.errors.takentime}
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Reason
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter reason"
                name="reason"
                disabled={role === "Admin"}
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  validation.touched.reason && validation.errors.reason
                    ? true
                    : false
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => {
                  setModalTodo(false);
                  validation.resetForm();
                }}
              >
                <i className="ri-close-fill align-bottom"></i>{" "}
                {role === "Admin" ? "Reject" : "Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {role === "Admin" ? "Approve" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ReviewTaskSheet;
