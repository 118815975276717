import Dragula from "react-dragula";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container } from "reactstrap";
import { dateFormat } from "../../utils/helpers/common";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { listSupportAdmin } from "../../api/dashboardApi";
import NoResultsMessage from "../../components/noResultMessage";

const RecentlyJoinEmployee = (recentlyRegistrationdata) => {
  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      Dragula([componentBackingInstance], options);
    }
  };
  const QueryColumns = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "employeeName",
        filterable: false,
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: false,
      },
      {
        Header: "Email ID",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Technology",
        accessor: "technology",
        filterable: false,
        Cell: ({ cell }) => [
          <div className="text-center" key={cell?.row?.original?.id}>
            {cell.row.original.technology === null ? "---" : cell.row.original.technology}
          </div>
        ]
      },
      {
        Header: "Assigned Senior",
        accessor: "assignedSenior",
        filterable: false,
        Cell: ({ cell }) => [
          <div className="text-center" key={cell?.row?.original?.id}>
            {cell.row.original.assignedSenior === null
              ? "---"
              : cell.row.original.assignedSenior}
          </div>
        ]
      },
      {
        Header: "Date of Joining",
        Cell: (items) => {
          return <div>{dateFormat(items.row.original.DOJ)}</div>;
        },
      },
    ]
    // [handleOrderClick, checkedAll]
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Recently Join Employees
          </h4>
        </CardHeader>

        <CardBody>
          {recentlyRegistrationdata?.data?.length ? (
            <TableContainer
              columns={QueryColumns}
              data={recentlyRegistrationdata?.data}
              isAddUserList={false}
              customPageSize={5}
              divClass="table-responsive table-card mb-1"
              tableClass="align-middle table-nowrap"
              theadClass="table-light text-muted"
            // handleOrderClick={handleOrderClicks}
            />
          ) : (
            <NoResultsMessage/>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentlyJoinEmployee;
