import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import TableContainer from "../../components/common/tableContainer";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import * as Yup from "yup";
import { useFormik } from "formik";
import DeleteModal from "../../components/common/deleteModal";
import {
  addAliased,
  deleteAliased,
  editAliased,
  listOfAliasedData,
} from "../../api/alised";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import NoResultsMessage from "../../components/noResultMessage";
import Loader from "../../components/common/loader";

const MasterTable = () => {
  const [alisedDataState, setAlisedDataState] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [aliased, setAliased] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState();
  const [selectedEditId, setSelectedEditId] = useState();

  const recentlyRegistrationData = [
    {
      srNo: "1",
      employeeName: "Devang",
      technology: "Node js",
    },
    {
      srNo: "2",
      employeeName: "Devang",
      technology: "Node js",
    },
    {
      srNo: "3",
      employeeName: "Jay",
      technology: "Node js",
    },
    {
      srNo: "4",
      employeeName: "Jay",
      technology: "Node js",
    },
    {
      srNo: "5",
      employeeName: "JayDeep",
      technology: "Node js",
    },
    {
      srNo: "6",
      employeeName: "Mahek",
      technology: "Node js",
    },
  ];

  const aliasedDeleteFunc = (id) => {
    setSelectedDeleteId(id);
    setDeleteModal(true);
  };

  const aliasedDeleteTodo = () => {
    const data = { isDeleted: true };
    setDeleteModal(false);
    setLoading(true);
    deleteAliased(data, selectedDeleteId)
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          listOfAliasedData({
            modelName: "alisedNameModel",
            selectionCriteria: ["id", "alisedName"],
            condition: {
              isDeleted: false,
            },
          })
            .then((response) => {
              setAliased(response?.data);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              return err;
            })
            .finally(() => {
              setLoading(false);
            });
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Aliased
  useEffect(() => {
    setLoading(true);
    listOfAliasedData({
      modelName: "alisedNameModel",
      selectionCriteria: ["id", "alisedName"],
      condition: {
        isDeleted: false,
      },
    })
      .then((response) => {
        setAliased(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const aliasedColumn = useMemo(
    () => [
      {
        Header: "Sr No.",
        Cell: ({ row }) => row?.index + 1
      },
      {
        Header: "Aliased Name",
        accessor: "alisedName",
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div>
              <div className="hstack gap-2">
                <button
                  className="btn btn-sm btn-soft-info edit-list"
                  onClick={() => editAlisedFunc(cellProps?.cell?.row?.original)}
                >
                  <i className="ri-pencil-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="error"
                    content="Edit"
                    anchorId={`editTodo-${cellProps?.cell?.row?.index}`}
                  />
                </button>
                <button
                  id={`deleteTodo-${cellProps.cell.row.index}`}
                  className="btn btn-sm btn-soft-danger remove-list"
                  onClick={() =>
                    aliasedDeleteFunc(cellProps?.cell?.row?.original?.id)
                  }
                >
                  <i className="ri-delete-bin-5-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="error"
                    content="Delete"
                    anchorId={`deleteTodo-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              </div>
            </div>
          );
        },
      },
    ]
  );

  const addAlisedData = () => {
    setAlisedDataState(!alisedDataState);
    AlisedToggle();
  };

  const AlisedToggle = useCallback(() => {
    if (alisedDataState) {
      setAlisedDataState(false);
      validation.resetForm();
      setIsEdit(false);
      setSelectedEditId("");
    } else {
      setAlisedDataState(true);
    }
  }, [alisedDataState]);

  const editAlisedFunc = (data) => {
    // Here we have to do the Edit Operation or Api Calling
    setAlisedDataState(!alisedDataState);
    setIsEdit(true);
    AlisedToggle();
    setSelectedEditId(data);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      alisedName: (selectedEditId && selectedEditId?.alisedName) || "",
    },
    validationSchema: Yup.object({
      alisedName: Yup.string().required(RequiredField("Alised Name")),
    }),
    onSubmit: (values) => {
      setAlisedDataState(false);
      setLoading(true);
      if (!isEdit) {
        const payload = {
          alisedName: values.alisedName,
        };
        addAliased(payload)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              listOfAliasedData({
                modelName: "alisedNameModel",
                selectionCriteria: ["id", "alisedName"],
                condition: {
                  isDeleted: false,
                },
              })
                .then((response) => {
                  setAliased(response?.data);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                });
              validation.resetForm();
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        editAliased(selectedEditId?.id, { ...values })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              listOfAliasedData({
                modelName: "alisedNameModel",
                selectionCriteria: ["id", "alisedName"],
                condition: {
                  isDeleted: false,
                },
              })
                .then((response) => {
                  setAliased(response?.data);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                  setIsEdit(false)
                });
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
              setIsEdit(false);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
            setSelectedEditId("");
          });
      }
      validation?.resetForm();
    },
  });

  document.title = "Alise User";

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => aliasedDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading ?
          (<Loader />)
          :
          (<Container fluid>
            <BreadCrumb title="Alise User" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Alise Users</h5>
              </div>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg={10}>
                        <h4>Alised Name</h4>
                      </Col>
                      <Col lg={2} className="col-auto order-2 order-sm-3 ms-auto">
                        <button
                          className="btn btn-primary createTask"
                          type="button"
                          onClick={() => addAlisedData()}
                        >
                          + Add Alised
                        </button>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    {aliased?.length ? (
                      <TableContainer
                        columns={aliasedColumn}
                        data={aliased}
                        isAddUserList={false}
                        customPageSize={5}
                        isGlobalFilter={true}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                    ) : (
                      <NoResultsMessage />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>)
        }
      </div>

      <Modal
        id="createTask"
        isOpen={alisedDataState}
        toggle={AlisedToggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={AlisedToggle} className="p-3 bg-soft-success">
          {" "}
          {!isEdit ? "Add " : "Edit "}
          {"Alised"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={3}>
                    <label
                      htmlFor="task-title-input"
                      className="form-label pt-2"
                    >
                      Alised Name:
                    </label>
                  </Col>
                  <Col lg={9}>
                    <Input
                      type="text"
                      id="task-title-input"
                      className="form-control"
                      placeholder="Enter Alised Name"
                      name="alisedName"
                      rows="1"
                      validate={{ required: { value: true } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.alisedName || ""}
                      invalid={
                        validation.touched.alisedName &&
                          validation.errors.alisedName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.alisedName &&
                      validation.errors.alisedName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.alisedName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

              </Col>

              <div className="hstack gap-2 justify-content-end mt-5">
                <button
                  type="button"
                  className="btn btn-ghost-success masterAliased"
                  onClick={() => {
                    setAlisedDataState(false);
                    setIsEdit(false);
                    setSelectedEditId("");
                    validation.resetForm();
                  }}
                >
                  <i className="ri-close-fill align-bottom"></i> Close
                </button>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="addNewMember"
                  >
                    {isEdit === false ? "Save" : "Edit"}
                  </button>
                </div>
              </div>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MasterTable;
