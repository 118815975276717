import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import Flatpickr from "react-flatpickr";
import { Tooltip as ReactTooltip } from "react-tooltip";
import DeleteModal from "../../components/common/deleteModal";
import {
  addPaymentDetails,
  deletePaymentDetails,
  editPaymentDetails,
  filterOfPayment,
  listOfPaymentDetails,
  viewReceivedAmount,
} from "../../api/paymentApi";
import { ProjectTypes, ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import Loader from "../../components/common/loader";
import { listOfData } from "../../api/commonApi";
import NoResultsMessage from "../../components/noResultMessage";

const PaymentDetails = () => {
  const initialData = [
    {
      id: "",
      clientName: "",
      company_name: "",
      project_name: "",
      remaining_amount: "",
      total_price: "",
      receivedAmount: "",
    },
    {
      id: "",
      clientName: "",
      company_name: "",
      project_name: "",
      remaining_amount: "",
      total_price: "",
      receivedAmount: "",
    },
  ];

  const [data, setData] = useState(initialData);
  const [expandedRows, setExpandedRows] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalMasterTable, setModalMasterTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentDetailsData, setPaymentDetailsData] = useState();
  const [clientProjectId, setClientProjectId] = useState();
  const [viewReceivedAmountData, setViewReceivedAmountData] = useState();
  const [selectDeleteId, setSelectedDeleteId] = useState();
  const [listOfClientName, setListOfClientName] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedRecordFilled, setEditedRecordFilled] = useState();
  const [recordIdToEdit, setRecordIdToEdit] = useState();
  const [rowId, setSelectedRowID] = useState();
  const [dateData, setDateData] = useState();
  const [remainingAmountData, setRemainingAmountData] = useState();
  const [filterPaymentDetails, setFilteredPaymentDetails] = useState();
  const [filtersApplied, setFiltersApplied] = useState();

  const paymentDetailsFetchData = () => {
    setLoading(true);
    listOfPaymentDetails().then((res) => {
      setPaymentDetailsData(res?.data);
    });
    listOfData({
      modelName: "companyModel",
      selectionCriteria: ["id", "companyName"],
    })
      .then((res) => {
        setListOfClientName(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    paymentDetailsFetchData();
  }, []);

  const handleExpand = (rowId) => {
    setLoading(true);

    // Check if the clicked row is already expanded
    const isRowExpanded = expandedRows.includes(rowId);
    // Clear the expanded rows
    setExpandedRows([]);

    // Fetch additional data for the selected row
    viewReceivedAmount(rowId)
      .then((res) => {
        const receivedAmountData = res?.data?.data?.map((item) => item || "");
        const dateData = res?.data?.data?.map((item) => item?.toDate || "");
        setViewReceivedAmountData(receivedAmountData);
        setDateData(dateData);

        // Add the current rowId if it was not already expanded
        if (!isRowExpanded) {
          setExpandedRows([rowId]);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event, rowId, field) => {
    const updatedData = data?.map((item) => {
      if (item?.id === rowId) {
        return {
          ...item,
          [field]: event?.target?.value,
        };
      }
      return item;
    });
    setData(updatedData);
  };
  const addEmployeeData = (rowID, remaining_amount_props) => {
    setRemainingAmountData(remaining_amount_props);
    setModalMasterTable(!modalMasterTable);
    EmployeeToggle();
    setClientProjectId(rowID);
  };

  const EmployeeToggle = useCallback(() => {
    if (modalMasterTable) {
      setModalMasterTable(false);
      validation.resetForm();
    } else {
      setModalMasterTable(true);
    }
  }, [modalMasterTable]);

  const editModalOpenHere = (cellProps, response) => {
    setDialogOpen(!dialogOpen);
    EditToggle();
    setRecordIdToEdit(cellProps);
    setSelectedRowID(cellProps);
    setEditedRecordFilled(response);
  };

  const EditToggle = useCallback(() => {
    if (dialogOpen) {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  });

  const paymentDelete = (rowID, selectedDeleteId) => {
    setSelectedRowID(rowID);
    setSelectedDeleteId(selectedDeleteId);
    setDeleteModal(true);
  };

  const paymentDeleteTodo = () => {
    deletePaymentDetails(selectDeleteId, { isDeleted: true }).then((res) => {
      setDeleteModal(false);
      if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
        handleExpand(rowId);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    });
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      receivedAmount: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      receivedAmount: Yup.number().required(RequiredField("Received amount")),
      toDate: Yup.date().required(RequiredField("To date")),
    }),
    onSubmit: (values) => {
      addPaymentDetails({ ...values, clientProjectId: clientProjectId }).then(
        (res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            validation.resetForm();
            setModalMasterTable(false);
            paymentDetailsFetchData();
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        }
      );
    },
  });

  const paymentEditValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      receivedAmountOfEdit:
        (editedRecordFilled && editedRecordFilled?.receivedAmount) || "",
      toDateOfEdit: (editedRecordFilled && editedRecordFilled?.toDate) || "",
    },

    onSubmit: (values) => {
      const payload = {
        clientProjectId: recordIdToEdit,
        receivedAmount: values?.receivedAmountOfEdit,
        toDate: values?.toDateOfEdit,
      };
      setLoading(true);
      editPaymentDetails(editedRecordFilled.id, { ...payload })
        .then((res) => {
          setDialogOpen(false);
          if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            handleExpand(rowId);
            paymentEditValidation.resetForm();
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const paymentFilterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: null,
      projectType: null,
    },
  });

  const paymentFetchData = () => {
    // Filter the data based on the selected filters
    const filterCriteria = paymentFilterValidation?.values;
    setLoading(true);

    filterOfPayment({ ...filterCriteria })
      .then((res) => {
        const filteredData = res?.data?.data;
        // Set the filtered data in the state
        setFilteredPaymentDetails(filteredData);

        // Set filtersApplied to true
        setFiltersApplied(true);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  document.title = "Payment";

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => paymentDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Payment Details" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Payment Details</h5>
              </div>
            </Row>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                paymentFetchData();
                return false;
              }}>
              <Row>
                <Col lg={4}>
                  <div className="mb-4">
                    <Label htmlFor="text" className="form-label-company-name">
                      Company Name
                    </Label>
                    <Input
                      name="companyName"
                      type="select"
                      className={`form-select ${
                        paymentFilterValidation.errors.companyName &&
                        paymentFilterValidation.values.companyName
                          ? "in-valid"
                          : null
                      }`}
                      id="choices-publish-status-input"
                      placeholder="Leave Type"
                      onChange={paymentFilterValidation.handleChange}
                      onBlur={paymentFilterValidation.handleBlur}
                      value={paymentFilterValidation.values.companyName}>
                      <option>Select Client Name</option>
                      {listOfClientName?.map((item) => (
                        <option value={item?.companyName} key={item?.id}>
                          {item?.companyName}
                        </option>
                      ))}
                    </Input>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4">
                    <Label htmlFor="text" className="form-label-project-name">
                      Project Type
                    </Label>
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                      id="selectreport"
                      name="projectType"
                      onChange={paymentFilterValidation.handleChange}
                      onBlur={paymentFilterValidation.handleBlur}
                      value={paymentFilterValidation.values.projectType}>
                      <option>Project Type</option>
                      {ProjectTypes?.map((res) => {
                        return (
                          <>
                            <option value={res?.value}>{res?.label}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </Col>
                <Col className="col-lg-auto p-4">
                  <button className="btn btn-primary createTask" type="submit">
                    {!loading ? (
                      "SEARCH"
                    ) : (
                      <div className="d-inline-flex align-items-center">
                        <Spinner size="sm" className="me-2" />
                        Loading...
                      </div>
                    )}
                  </button>
                </Col>
              </Row>
            </Form>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                // paymentFilterValidation.handleSubmit();
                return false;
              }}>
              <Card>
                <CardBody>
                  <div className="tableTextAlignCenter">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="customTableThStyle">Expand</th>
                          <th className="customTableThStyle">Client Name</th>
                          <th className="customTableThStyle">Company Name</th>
                          <th className="customTableThStyle">Project Name</th>
                          <th className="customTableThStyle">
                            Remaining Amount
                          </th>
                          <th className="customTableThStyle">Total Price</th>
                          <th className="customTableThStyle">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filtersApplied ? (
                          filterPaymentDetails?.length > 0 ? (
                            filterPaymentDetails.map((row) => (
                              <React.Fragment key={row?.id}>
                                <tr>
                                  <td className="customTableTdStyle">
                                    <button
                                      className="customButtonStyle"
                                      onClick={() => handleExpand(row?.id)}>
                                      <i className="ri-arrow-down-fill"></i>
                                    </button>
                                  </td>
                                  <td className="customTableTdStyle">
                                    {editMode === row?.id ? (
                                      <input
                                        type="text"
                                        className="customInputStyle"
                                        value={row?.client?.clientName}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            row?.id,
                                            "clientName"
                                          )
                                        }
                                      />
                                    ) : (
                                      row?.client?.clientName
                                    )}
                                  </td>
                                  <td className="customTableTdStyle">
                                    {editMode === row?.id ? (
                                      <input
                                        type="text"
                                        className="customInputStyle"
                                        value={row?.company?.companyName}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            row?.id,
                                            "company_name"
                                          )
                                        }
                                      />
                                    ) : (
                                      row?.company?.companyName
                                    )}
                                  </td>
                                  <td className="customTableTdStyle">
                                    {editMode === row?.id ? (
                                      <input
                                        type="text"
                                        className="customInputStyle"
                                        value={row.projectName}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            row?.id,
                                            "project_name"
                                          )
                                        }
                                      />
                                    ) : (
                                      row?.projectName
                                    )}
                                  </td>
                                  <td className="customTableTdStyle">
                                    {editMode === row?.id ? (
                                      <input
                                        type="text"
                                        className="customInputStyle"
                                        value={row.remainingAmount}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            row?.id,
                                            "remaining_amount"
                                          )
                                        }
                                      />
                                    ) : (
                                      row?.remainingAmount
                                    )}
                                  </td>
                                  <td className="customTableTdStyle">
                                    {editMode === row?.id ? (
                                      <input
                                        type="text"
                                        className="customInputStyle"
                                        value={row?.amount}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            row?.id,
                                            "total_price"
                                          )
                                        }
                                      />
                                    ) : (
                                      row?.amount
                                    )}
                                  </td>
                                  <td className="customTableTdStyle">
                                    <button
                                      id={`deleteTodo-${row?.id}`}
                                      className="customMainButtonStyle btn btn-md btn-soft-success remove-list"
                                      onClick={() =>
                                        addEmployeeData(
                                          row?.id,
                                          row?.remainingAmount
                                        )
                                      }>
                                      <i className="ri-add-circle-fill"></i>
                                      <ReactTooltip
                                        place="bottom"
                                        variant="success"
                                        content="Add"
                                        anchorId={`deleteTodo-${row?.id}`}
                                      />
                                    </button>
                                  </td>
                                </tr>
                                {expandedRows?.includes(row?.id) && (
                                  <tr>
                                    <td
                                      colSpan="12"
                                      className="customTableTdStyle">
                                      <div className="tableTextAlign">
                                        <strong>Additional Details:</strong>
                                        <br />
                                        <Card>
                                          <CardBody>
                                            {viewReceivedAmountData?.length >
                                            0 ? (
                                              viewReceivedAmountData?.map(
                                                (response, index) => (
                                                  <Row key={index}>
                                                    <Col lg={4}>
                                                      <label>
                                                        Received Amount:
                                                      </label>
                                                      <input
                                                        type="number"
                                                        className="customInputStyle"
                                                        defaultValue={
                                                          response?.receivedAmount
                                                        }
                                                        disabled
                                                        onChange={(event) =>
                                                          handleInputChange(
                                                            event,
                                                            row?.id,
                                                            "receivedAmount"
                                                          )
                                                        }
                                                      />
                                                    </Col>
                                                    <Col lg={4}>
                                                      <label>Date:</label>
                                                      <Flatpickr
                                                        id={`datepicker-${row?.id}`}
                                                        className={`form-select ${
                                                          validation.errors
                                                            .date &&
                                                          "is-invalid"
                                                        }`}
                                                        placeholder="Select a date"
                                                        name="date"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        onBlur={
                                                          validation.handleBlur
                                                        }
                                                        value={dateData[index]}
                                                        disabled
                                                        options={{
                                                          altInput: true,
                                                          altFormat: "F j, Y",
                                                          dateFormat: "Y-m-d",
                                                          defaultDate:
                                                            dateData[index],
                                                          onChange: function (
                                                            selectedDates,
                                                            dateStr,
                                                            instance
                                                          ) {
                                                            // Update the date data in state
                                                            dateData[index] =
                                                              dateStr;
                                                            setDateData([
                                                              ...dateData,
                                                            ]);
                                                            validation.setFieldValue(
                                                              "date",
                                                              dateStr
                                                            );
                                                          },
                                                        }}
                                                      />
                                                    </Col>
                                                    <Col lg={4}>
                                                      <div className="p-4">
                                                        <div className="hstack gap-2 justify-content-end">
                                                          <button
                                                            className="btn btn-success"
                                                            onClick={() =>
                                                              editModalOpenHere(
                                                                row.id,
                                                                response
                                                              )
                                                            }>
                                                            Edit
                                                          </button>
                                                          <button
                                                            className="btn btn-danger"
                                                            onClick={() =>
                                                              paymentDelete(
                                                                row?.id,
                                                                response?.id
                                                              )
                                                            }>
                                                            Delete
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                )
                                              )
                                            ) : (
                                              <NoResultsMessage />
                                            )}
                                          </CardBody>
                                        </Card>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="customTableTdStyle">
                                <NoResultsMessage />
                              </td>
                            </tr>
                          )
                        ) : paymentDetailsData?.length > 0 ? (
                          paymentDetailsData?.map((row) => (
                            <React.Fragment key={row?.id}>
                              <tr>
                                <td className="customTableTdStyle">
                                  <button
                                    className="customButtonStyle"
                                    onClick={() => handleExpand(row?.id)}>
                                    <i className="ri-arrow-down-fill"></i>
                                  </button>
                                </td>
                                <td className="customTableTdStyle">
                                  {editMode === row?.id ? (
                                    <input
                                      type="text"
                                      value={row?.client?.clientName}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          row?.id,
                                          "clientName"
                                        )
                                      }
                                      className="customInputStyle"
                                    />
                                  ) : (
                                    row?.client?.clientName
                                  )}
                                </td>
                                <td className="customTableTdStyle">
                                  {editMode === row?.id ? (
                                    <input
                                      type="text"
                                      value={row?.company?.companyName}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          row?.id,
                                          "company_name"
                                        )
                                      }
                                      className="customInputStyle"
                                    />
                                  ) : (
                                    row?.company?.companyName
                                  )}
                                </td>
                                <td className="customTableTdStyle">
                                  {editMode === row?.id ? (
                                    <input
                                      type="text"
                                      value={row.projectName}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          row?.id,
                                          "project_name"
                                        )
                                      }
                                      className="customInputStyle"
                                    />
                                  ) : (
                                    row?.projectName
                                  )}
                                </td>
                                <td className="customTableTdStyle">
                                  {editMode === row?.id ? (
                                    <input
                                      type="text"
                                      value={row.remainingAmount}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          row?.id,
                                          "remaining_amount"
                                        )
                                      }
                                      className="customInputStyle"
                                    />
                                  ) : (
                                    row?.remainingAmount
                                  )}
                                </td>
                                <td className="customTableTdStyle">
                                  {editMode === row?.id ? (
                                    <input
                                      type="text"
                                      value={row?.amount}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          row?.id,
                                          "total_price"
                                        )
                                      }
                                      className="customInputStyle"
                                    />
                                  ) : (
                                    row?.amount
                                  )}
                                </td>
                                <td className="customTableTdStyle">
                                  <button
                                    id={`deleteTodo-${row?.id}`}
                                    className="customMainButtonStyle btn btn-md btn-soft-success remove-list"
                                    onClick={() =>
                                      addEmployeeData(
                                        row?.id,
                                        row?.remainingAmount
                                      )
                                    }>
                                    <i className="ri-add-circle-fill"></i>
                                    <ReactTooltip
                                      place="bottom"
                                      variant="success"
                                      content="Add"
                                      anchorId={`deleteTodo-${row?.id}`}
                                    />
                                  </button>
                                </td>
                              </tr>
                              {expandedRows?.includes(row?.id) && (
                                <tr>
                                  <td
                                    colSpan="12"
                                    className="customTableTdStyle">
                                    <div className="tableTextAlign">
                                      <strong>Additional Details:</strong>
                                      <br />
                                      <Card>
                                        <CardBody>
                                          {viewReceivedAmountData?.length >
                                          0 ? (
                                            viewReceivedAmountData?.map(
                                              (response, index) => (
                                                <Row key={index}>
                                                  <Col sm={3}>
                                                    <label>
                                                      Received Amount:{" "}
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="customInputStyle"
                                                      defaultValue={
                                                        response?.receivedAmount
                                                      }
                                                      disabled
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          row?.id,
                                                          "receivedAmount"
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col sm={3}>
                                                    <label>Date:</label>
                                                    <Flatpickr
                                                      id={`datepicker-${row?.id}`}
                                                      className={`form-select ${
                                                        validation.errors
                                                          .date && "is-invalid"
                                                      }`}
                                                      placeholder="Select a date"
                                                      name="date"
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                      onBlur={
                                                        validation.handleBlur
                                                      }
                                                      value={dateData[index]}
                                                      disabled
                                                      options={{
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d",
                                                        defaultDate:
                                                          dateData[index],
                                                        onChange: function (
                                                          selectedDates,
                                                          dateStr,
                                                          instance
                                                        ) {
                                                          // Update the date data in state
                                                          dateData[index] =
                                                            dateStr;
                                                          setDateData([
                                                            ...dateData,
                                                          ]);
                                                          validation.setFieldValue(
                                                            "date",
                                                            dateStr
                                                          );
                                                        },
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col sm={3}>
                                                    <div className="p-4">
                                                      <div className="hstack gap-2 justify-content-end">
                                                        <button
                                                          className="btn btn-success"
                                                          onClick={() =>
                                                            editModalOpenHere(
                                                              row?.id,
                                                              response
                                                            )
                                                          }>
                                                          Edit
                                                        </button>
                                                        <button
                                                          className="btn btn-danger"
                                                          onClick={() =>
                                                            paymentDelete(
                                                              row?.id,
                                                              response.id
                                                            )
                                                          }>
                                                          Delete
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              )
                                            )
                                          ) : (
                                            <NoResultsMessage />
                                          )}
                                        </CardBody>
                                      </Card>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="customTableTdStyle">
                              <div
                                className="py-4 mt-4 text-center"
                                id="noresult">
                                <lord-icon
                                  src="https://cdn.lordicon.com/msoeawqm.json"
                                  trigger="loop"
                                  colors="primary:#405189,secondary:#0ab39c"
                                  className="nodataFoundIcon"></lord-icon>
                                <h5 className="mt-4">No Data Available</h5>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Form>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        id="createTask"
        isOpen={modalMasterTable}
        toggle={EmployeeToggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={EmployeeToggle} className="p-3 bg-soft-success">
          {" "}
          Add Payment Details
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex justify-content-end p-2">
                      <h4>Remaining Amount: {remainingAmountData}</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Received Amount
                      </label>
                      <Input
                        type="number"
                        id="task-title-input"
                        className="form-control"
                        placeholder="Enter Your Received Amount"
                        name="receivedAmount"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.receivedAmount || ""}
                        invalid={
                          validation.touched.receivedAmount &&
                          validation.errors.receivedAmount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.receivedAmount &&
                      validation.errors.receivedAmount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.receivedAmount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <label
                      htmlFor="task-receivedDate-input"
                      className="form-label">
                      To :
                    </label>
                    <Flatpickr
                      name="toDate"
                      id="date-field"
                      validate={{ required: { value: true } }}
                      onBlur={validation.handleBlur}
                      className={`form-control ${
                        validation.errors.toDate && "is-invalid"
                      }`}
                      placeholder="Enter To date"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        onChange: function (selectedDates, dateStr, instance) {
                          validation.setFieldValue("toDate", dateStr);
                        },
                      }}
                      value={validation.values.toDate}
                    />
                    <div className="invalid-feedback">
                      {validation.errors.toDate}
                    </div>
                  </Col>
                  <div className="hstack gap-1 justify-content-end p-2">
                    <button
                      type="button"
                      className="btn btn-ghost-success buttonMarginStyle"
                      onClick={() => {
                        setModalMasterTable(false);
                      }}>
                      <i className="ri-close-fill align-bottom"></i>Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary buttonMarginStyle"
                      id="addNewMember">
                      {"Save "}
                    </button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        id="createTask"
        isOpen={dialogOpen}
        toggle={EditToggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={EditToggle} className="p-3 bg-soft-success">
          {" "}
          Edit record
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  paymentEditValidation.handleSubmit();
                  return false;
                }}>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Received Amount
                      </label>
                      <Input
                        type="number"
                        id="task-title-input"
                        className="form-control"
                        placeholder="Enter Your Received Amount"
                        name="receivedAmountOfEdit"
                        validate={{ required: { value: true } }}
                        onChange={paymentEditValidation.handleChange}
                        onBlur={paymentEditValidation.handleBlur}
                        value={
                          paymentEditValidation.values.receivedAmountOfEdit ||
                          ""
                        }
                        invalid={
                          paymentEditValidation.touched.receivedAmountOfEdit &&
                          paymentEditValidation.errors.receivedAmountOfEdit
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <label
                      htmlFor="task-receivedDate-input"
                      className="form-label">
                      To:
                    </label>
                    <Flatpickr
                      name="toDateOfEdit"
                      id="date-field"
                      validate={{ required: { value: true } }}
                      onBlur={paymentEditValidation.handleBlur}
                      className={`form-control ${
                        paymentEditValidation.errors.toDateOfEdit &&
                        "is-invalid"
                      }`}
                      placeholder="Enter To date"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        onChange: function (selectedDates, dateStr, instance) {
                          paymentEditValidation.setFieldValue(
                            "toDateOfEdit",
                            dateStr
                          );
                        },
                      }}
                      value={paymentEditValidation.values.toDateOfEdit}
                    />
                  </Col>
                  <div className="hstack gap-1 justify-content-end p-2">
                    <button
                      type="button"
                      className="btn btn-ghost-success buttonMarginStyle"
                      onClick={() => {
                        setDialogOpen(false);
                      }}>
                      <i className="ri-close-fill align-bottom"></i>Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary buttonMarginStyle"
                      id="addNew">
                      {"Save "}
                    </button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PaymentDetails;
