import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRole } from "../utils/helpers/custom";

const Navdata = () => {
    const history = useNavigate();
    const [isDashboard, setIsDashboard] = useState(false);
    const [isEmployee, setIsEmployee] = useState(false);
    const [isClient, setIsClient] = useState(false);
    const [isProject, setIsProject] = useState(false);
    const [isPayroll, setIsPayroll] = useState(false);
    const [isITP, setIsITP] = useState(false);
    const [isLeave, setIsLeave] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [isProjectManagement, setIsProjectManagement] = useState(false);
    const [isBDE, setIsBDE] = useState(false);
    const [isMaster, setIsMaster] = useState(false);
    const [isRecruitment, setIsRecruitment] = useState(false);
    const [isCourse, setIsCourse] = useState(false);
    const [isResource, setIsResource] = useState(false);
    const [isSupport, setIsSupport] = useState(false);
    const [isTraining, setIsTraining] = useState(false);
    const [isTimesheet, setIsTimesheet] = useState(false);
    const [isTasksheet, setIsTasksheet] = useState(false);
    const [isReview, setIsReview] = useState(false);
    const [IsInterview, setIsInterview] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    const [isDailyUpdate, setIsDailyUpdate] = useState(false);

    const role = getRole();

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Review') {
            setIsReview(false);
        }
        if (iscurrentState !== 'Employee') {
            setIsEmployee(false);
        }
        if (iscurrentState !== 'Client') {
            setIsEmployee(false);
        }
        if (iscurrentState !== 'Project') {
            setIsEmployee(false);
        }
        if (iscurrentState !== 'Payroll') {
            setIsEmployee(false);
        }
        if (iscurrentState !== 'ITP') {
            setIsEmployee(false);
        }
        if (iscurrentState !== 'Leave') {
            setIsLeave(false);
        }
        if (iscurrentState !== 'Report') {
            setIsReport(false);
        }
        if (iscurrentState !== 'ITP') {
            setIsCourse(false);
        }
        if (iscurrentState !== 'Recruitment') {
            setIsRecruitment(false);
        }
        if (iscurrentState !== 'Resource') {
            setIsResource(false);
        }
        if (iscurrentState !== 'Support') {
            setIsSupport(false);
        }
        if (iscurrentState !== 'Timesheet') {
            setIsTimesheet(false);
        }   
        if (iscurrentState !== 'Tasksheet') {
            setIsTasksheet(false);
        }
        if (iscurrentState !== 'Training') {
            setIsTraining(false);
        }
        if (iscurrentState !== 'Interview') {
            setIsInterview(false);
        }
        if (iscurrentState !== 'Project Management') {
            setIsProjectManagement(false);
        }
        if (iscurrentState !== 'BDE') {
            setIsBDE(false);
        }
        if (iscurrentState !== 'Alise User') {
            setIsMaster(false);
        }
        if (iscurrentState !== 'Reset Password') {
            setIsResetPassword(false);
        }
        if (iscurrentState !== 'Daily Update') {
            setIsDailyUpdate(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isEmployee,
        isClient,
        isProject,
        isPayroll,
        isITP,
        isLeave,
        isReport,
        isCourse,
        isRecruitment,
        isResource,
        isSupport,
        isTraining,
        isTasksheet,
        isTimesheet,
        IsInterview,
        isProjectManagement,
        isBDE,
        isMaster,
        isTimesheet,
        isDailyUpdate,
    ]);

    const menuItemsAdmin = [
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "employee",
            label: "Employees",
            icon: "ri-user-2-line",
            link: "/employee",
            stateVariables: isEmployee,
            click: function (e) {
                e.preventDefault();
                setIsEmployee(!isEmployee);
                setIscurrentState('Employee');
                updateIconSidebar(e);
            },
        }, 
        {
            id: "review",
            label: "Review",
            icon: "ri-eye-line",
            link: "/review",
            stateVariables: isReview,
            click: function (e) {
                e.preventDefault();
                setIsReview(!isReview);
                setIscurrentState('Review');
                updateIconSidebar(e);
            },
        },
        {
            id: "itp",
            label: "ITP",
            icon: "ri-book-open-line",
            link: "/itp",
            stateVariables: isITP,
            click: function (e) {
                e.preventDefault();
                setIsITP(!isITP);
                setIscurrentState('ITP');
                updateIconSidebar(e);
            },
        },
        {
            id: "leave",
            label: "Leaves",
            icon: "ri-calendar-2-line",
            link: "/leaves",
            stateVariables: isLeave,
            click: function (e) {
                e.preventDefault();
                setIsLeave(!isLeave);
                setIscurrentState('Leave');
                updateIconSidebar(e);
            },
        },
        {
            id: "support",
            label: "Support",
            icon: "ri-customer-service-2-line",
            link: "/support",
            stateVariables: isSupport,
            click: function (e) {
                e.preventDefault();
                setIsSupport(!isSupport);
                setIscurrentState('Support');
                updateIconSidebar(e);
            },
        },
        {
            id: "report",
            label: "Report",
            icon: "ri-file-list-3-line",
            link: "/report",
            stateVariables: isReport,
            click: function (e) {
                e.preventDefault();
                setIsReport(!isReport);
                setIscurrentState('Report');
                updateIconSidebar(e);
            },
        },
        {
            id: "bde",
            label: "BDE",
            icon: "ri-global-fill",
            link: "/bde",
            stateVariables: isBDE,
            click: function (e) {
                e.preventDefault();
                setIsBDE(!isBDE);
                setIscurrentState('BDE');
                updateIconSidebar(e);
            },
        },
        {
            id: "interview",
            label: "Interview",
            icon: "ri-file-list-3-line",
            link: "/interview_feedback",
            stateVariables: IsInterview,
            click: function (e) {
                e.preventDefault();
                setIsReport(!IsInterview);
                setIscurrentState('Interview');
                updateIconSidebar(e);
            }
        },
        {
            id: "project-management",
            label: "Project Management",
            icon: "ri-pie-chart-line",
            stateVariables: isProjectManagement,
            click: function (e) {
                e.preventDefault();
                setIsProjectManagement(!isProjectManagement);
                setIscurrentState('Project Management');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "company-name",
                    label: "Company",
                    link: "/company-management",
                    parentId: "company-management",
                },
                {
                    id: "project-name",
                    label: "Project",
                    link: "/project-details",
                    parentId: "project-management",
                },
                {
                    id: "payment",
                    label: "Payment",
                    link: "/payment-details",
                    parentId: "project-management",
                },
            ]
        },
        {
            id: "mastertable",
            label: "Alise User ",
            icon: "ri-scan-fill",
            link: "/employee-aliased-details",
            stateVariables: isMaster,
            click: function (e) {
                e.preventDefault();
                setIsMaster(!isBDE);
                setIscurrentState('Master Table');
                updateIconSidebar(e);
            },
        },
        {
            id: "daily-update",
            label: "Daily Update",
            icon: "ri-refresh-fill",
            link: "/daily-update",
            stateVariables: isDailyUpdate,
            click: function (e) {
                e.preventDefault();
                setIsDailyUpdate(!isDailyUpdate);
                setIscurrentState('Daily Update');
                updateIconSidebar(e);
            },
        },
    ];

    const menuItemsHR = [
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "timesheet",
            label: "Timesheet",
            icon: "ri-calendar-check-line",
            link: "/timesheet",
            stateVariables: isTimesheet,
            click: function (e) {
                e.preventDefault();
                setIsTimesheet(!isTimesheet);
                setIscurrentState('Timesheet');
                // updateIconSidebar(e);
            },
        },
        {
            id: "tasksheet",
            label: "Tasksheet",
            icon: "ri-calendar-check-line",
            link: "/tasksheet",
            stateVariables: isTasksheet,
            click: function (e) {
                e.preventDefault();
                setIsTasksheet(!isTasksheet);
                setIscurrentState('Tasksheet');
                updateIconSidebar(e);
            },
        },
        {
            id: "review",
            label: "Review",
            icon: "ri-eye-line",
            link: "/review",
            stateVariables: isReview,
            click: function (e) {
                e.preventDefault();
                setIsReview(!isReview);
                setIscurrentState('Review');
                updateIconSidebar(e);
            },
        },
        {
            id: "recruitment",
            label: "Recruitment",
            icon: "ri-gps-line",
            link: "/recruitment",
            stateVariables: isRecruitment,
            click: function (e) {
                e.preventDefault();
                setIsRecruitment(!isRecruitment);
                setIscurrentState('Recruitment');
                updateIconSidebar(e);
            },
        },
        {
            id: "resource",
            label: "Resources Management",
            icon: "ri-mac-line",
            link: "/resource",
            stateVariables: isResource,
            click: function (e) {
                e.preventDefault();
                setIsResource(!isResource);
                setIscurrentState('Resource');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "configuration",
                    label: "Configuration",
                    link: "/configuration",
                    parentId: "resource",
                },
                {
                    id: "allocation",
                    label: "Allocation",
                    link: "/allocation",
                    parentId: "resource",
                },
                {
                    id: "damagedResources",
                    label: "Damaged Resources",
                    link: "/damage-device",
                    parentId: "resource",
                },
            ]
        },
        {
            id: "employee",
            label: "Employee",
            icon: "ri-user-2-line",
            link: "/employee",
            stateVariables: isEmployee,
            click: function (e) {
                e.preventDefault();
                setIsEmployee(!isEmployee);
                setIscurrentState('Employee');
                updateIconSidebar(e);
            },
        },
        {
            id: "course",
            label: "ITP",
            icon: "ri-book-open-line",
            link: "/itp",
            stateVariables: isCourse,
            click: function (e) {
                e.preventDefault();
                setIsCourse(!isCourse);
                setIscurrentState('Course');
                updateIconSidebar(e);
            },
        },
        {
            id: "leave",
            label: "Leave",
            icon: "ri-calendar-2-line",
            stateVariables: isLeave,
            click: function (e) {
                e.preventDefault();
                setIsLeave(!isLeave);
                setIscurrentState('Leave');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "hrLeave",
                    label: "HR Leave",
                    link: "/hrleaves",
                    parentId: "leave",
                },
                {
                    id: "employeeLeave",
                    label: "Employee Leave",
                    link: "/leaves",
                    parentId: "leave",
                },
            ]
        },
        {
            id: "support",
            label: "Support",
            icon: "ri-customer-service-2-line",
            stateVariables: isSupport,
            click: function (e) {
                e.preventDefault();
                setIsSupport(!isSupport);
                setIscurrentState('Support');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "hrSupport",
                    label: "HR Support",
                    link: "/support",
                    parentId: "support",
                },
                {
                    id: "employeeSupport",
                    label: "Employee Support",
                    link: "/employee-support",
                    parentId: "support",
                },
            ]
        },
        {
            id: "resetpassword",
            label: "Reset Password",
            icon: "ri-dashboard-2-line",
            link: "/resetpassword",
            stateVariables: isResetPassword,
            click: function (e) {
                e.preventDefault();
                setIsResetPassword(!isResetPassword);
                setIscurrentState('Reset Password');
                updateIconSidebar(e);
            },
        },
        {
            id: "daily-update",
            label: "Daily Update",
            icon: "ri-refresh-fill",
            link: "/daily-update",
            stateVariables: isDailyUpdate,
            click: function (e) {
                e.preventDefault();
                setIsDailyUpdate(!isDailyUpdate);
                setIscurrentState('Daily Update');
                updateIconSidebar(e);
            },
        },
    ];

    const menuItemsEmployee = [
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "timesheet",
            label: "Timesheet",
            icon: "ri-calendar-todo-fill",
            link: "/timesheet",
            stateVariables: isTimesheet,
            click: function (e) {
                e.preventDefault();
                setIsTimesheet(!isTimesheet);
                setIscurrentState('Timesheet');
                updateIconSidebar(e);
            },
        },
        {
            id: "tasksheet",
            label: "Tasksheet",
            icon: "ri-calendar-check-line",
            link: "/tasksheet",
            stateVariables: isTasksheet,
            click: function (e) {
                e.preventDefault();
                setIsTasksheet(!isTasksheet);
                setIscurrentState('Tasksheet');
                updateIconSidebar(e);
            },
        },
        {
            id: "review",
            label: "Review",
            icon: "ri-eye-line",
            link: "/review",
            stateVariables: isReview,
            click: function (e) {
                e.preventDefault();
                setIsReview(!isReview);
                setIscurrentState('Review');
                updateIconSidebar(e);
            },
        },
        {
            id: "training",
            label: "ITP",
            icon: "ri-book-open-line",
            link: "/itp",
            stateVariables: isTraining,
            click: function (e) {
                e.preventDefault();
                setIsTraining(!isTraining);
                setIscurrentState('Training');
                updateIconSidebar(e);
            },
        },
        {
            id: "leave",
            label: "Leaves",
            icon: "ri-calendar-2-line",
            link: "/leaves",
            stateVariables: isLeave,
            click: function (e) {
                e.preventDefault();
                setIsLeave(!isLeave);
                setIscurrentState('Leave');
                updateIconSidebar(e);
            },
        },
        {
            id: "support",
            label: "Support",
            icon: "ri-customer-service-2-line",
            link: "/employee-support",
            stateVariables: isSupport,
            click: function (e) {
                e.preventDefault();
                setIsSupport(!isSupport);
                setIscurrentState('Support');
                updateIconSidebar(e);
            },
        },
        {
            id: "interview",
            label: "Interview",
            icon: "ri-file-list-3-line",
            link: "/interview_feedback",
            stateVariables: IsInterview,
            click: function (e) {
                e.preventDefault();
                setIsReport(!IsInterview);
                setIscurrentState('Interview');
                updateIconSidebar(e);
            },
        },
        role === "BDE" && {
            id: "bde",
            label: "BDE",
            icon: "ri-global-fill",
            link: "/bde",
            stateVariables: isBDE,
            click: function (e) {
                e.preventDefault();
                setIsBDE(!isBDE);
                setIscurrentState('BDE');
                updateIconSidebar(e);
            },
        },
        role === "BDE" && {
            id: "project-management",
            label: "Project Management",
            icon: "ri-pie-chart-line",
            stateVariables: isProjectManagement,
            click: function (e) {
                e.preventDefault();
                setIsProjectManagement(!isProjectManagement);
                setIscurrentState('Project Management');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "company-name",
                    label: "Company",
                    link: "/company-management",
                    parentId: "company-management",
                },
            ]
        },
    ];

    return <React.Fragment>{role === "Admin" ? menuItemsAdmin : role === "HR" ? menuItemsHR : menuItemsEmployee}</React.Fragment>;
};
export default Navdata;