export const setToken = (token) => {
  localStorage.setItem('token' , token)
};

export const getToken = () => {
  const authToken =  localStorage.getItem('token')
  return authToken;
};

export const setId = (id) => {
  localStorage.setItem("id", id, { maxAge: 1000 * 60 * 60 * 24 });
};

export const getId = () => {
  const authId = localStorage.getItem("id");
  return authId;
};

export const setRole = (role) => {
  localStorage.setItem("role", role, { maxAge: 1000 * 60 * 60 * 24 });
};

export const getRole = () => {
  const authRole = localStorage.getItem("role");
  return authRole;
};

export const removeRole = () => {
  localStorage.removeItem("role");
};

export const setName = (role) => {
  localStorage.setItem("Name", role, { maxAge: 1000 * 60 * 60 * 24 });
};

export const getName = () => {
  const authRole = localStorage.getItem("Name");
  return authRole;
};

export const removeName = () => {
  localStorage.removeItem("Name");
};

export const removeToken = () => {
 localStorage.removeItem("token");
};

export const removeId = () => {
  localStorage.removeItem("id");
};
