import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import {
  addConfiguration,
  addResource,
  editConfiguration,
  listOfConfiguration,
  vendorList,
} from "../../api/resourcesApi";
import BreadCrumb from "../../components/common/breadCrumb";
import { getId } from "../../utils/helpers/custom";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/loader";

const AddEditConfiguration = () => {
  const [vendor, setVendor] = useState([]);
  const [checkResource, setCheckResource] = useState();
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState();
  const { configurationId } = useParams();
  const [configurationData, setConfigurationData] = useState([]);
  const [selectedOs, setSelectedOs] = useState(null);
  const [selectedOsType, setSelectedOsType] = useState();
  const [selectedBrandData, setSelectedBrandData] = useState();
  const [selectedPaidByData, setSelectedPaidByData] = useState();
  const [selectedVendorData, setSelectedVendorData] = useState();
  const [selectedStorageTypeData, setSelectedStorageTypeData] = useState();
  const [selectedViewBrand, setSelectedViewBrand] = useState();
  const [selectedViewPaidBy, setSelectedViewPaidBy] = useState();
  const [selectedViewVendor, setSelectedViewVendor] = useState();
  const [selectedViewStorageType, setSelectedViewStorageType] = useState();
  const profileId = getId();
  const navigate = useNavigate();
  const amountRegex = /^\d+$/;
  const regexSIFormat = /^SI-\d{2}-\d{2}$/;

  useEffect(() => {
    if (!isNaN(configurationId)) {
      setLoading(true);
      setPageTitle("Edit Configuration");
      listOfConfiguration({
        id: configurationId,
      })
        .then((resp) => {
          setConfigurationData(resp?.data?.data?.[0]);
          setCheckResource(resp?.data?.data?.[0]?.resourceName);
          setSelectedOsType({
            label: resp?.data?.data?.[0]?.resourceConfiguration?.osType,
            value: resp?.data?.data?.[0]?.resourceConfiguration?.osType,
          });
          setSelectedViewBrand({
            label: resp?.data?.data?.[0]?.brand,
            value: resp?.data?.data?.[0]?.brand,
          });
          setSelectedViewPaidBy({
            label: resp?.data?.data?.[0]?.paidBy,
            value: resp?.data?.data?.[0]?.paidBy,
          });
          setSelectedViewVendor({
            label: resp?.data?.data?.[0]?.vendor?.business,
            value: resp?.data?.data?.[0]?.vendor?.id,
          });
          setSelectedViewStorageType({
            label: resp?.data?.data?.[0]?.resourceConfiguration?.storageType,
            value: resp?.data?.data?.[0]?.resourceConfiguration?.storageType,
          });
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPageTitle("Add Configuration");
    }
    lisOfVendor();
  }, []);

  const lisOfVendor = () => {
    vendorList().then((resp) => {
      setVendor(resp?.data?.data);
    });
  };

  const ResourceType = [
    { value: "Laptop", label: "Laptop" },
    { value: "Mouse", label: "Mouse" },
    { value: "Bag", label: "Bag" },
    { value: "Mouse Pad", label: "Mouse Pad" },
    { value: "KeyBoard", label: "KeyBoard" },
  ];

  const osType = [
    {
      label: "Select OS Type",
      value: "",
    },
    {
      value: "Window",
      label: "Window",
    },
    {
      value: "Linux",
      label: "Linux",
    },
    {
      value: "IOS",
      label: "IOS",
    },
  ];

  const brandName = [
    {
      label: "Select Brand",
      value: "",
    },
    {
      value: "HP",
      label: "HP",
    },
    {
      value: "Dell",
      label: "Dell",
    },
    {
      value: "Apple",
      label: "Apple",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];

  const paidByOption = [
    {
      label: "Select PaidBy",
      value: "",
    },
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "Online",
      label: "Online",
    },
    {
      value: "Cheque",
      label: "Cheque",
    },
  ]

  const storageTypeOption = [
    {
      label: "Select Storage Type",
      value: "",
    },
    {
      value: "SSD",
      label: "SSD",
    },
    {
      value: "HDD",
      label: "HDD",
    },
  ]

  const osDetails = Array.isArray(osType)
    ? osType?.map((status) => ({
        value: status?.value,
        label: status?.label,
      }))
    : null;

    const vendorDetails = Array.isArray(vendor)
    ? vendor?.map((status) => ({
        value: status?.id,
        label: status?.business,
      }))
    : null;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      resourceName: configurationData?.resourceName || "",
      allocatedNumber: configurationData?.resourceNo || "",
      amount: configurationData?.amount || "",
      paidby: configurationData?.paidBy || "",
      vendor: configurationData?.vendor?.id || "",
      purchaseDate: configurationData?.purchaseDate || "",
      brandName: configurationData?.brand || "",
      pcmodal: configurationData?.resourceConfiguration?.modelName || "",
      serialNo: configurationData?.resourceConfiguration?.serialNo || "",
      processor: configurationData?.resourceConfiguration?.processor || "",
      storagetype: configurationData?.resourceConfiguration?.storageType || "",
      osVersion: configurationData?.resourceConfiguration?.osVersion || "",
      warrantyStartDate:
        configurationData?.warrantyStartDate || "",
      warrantyEndDate:
        configurationData?.warrantyEndDate || "",
      ram: configurationData?.resourceConfiguration?.RAM || "",
      osType: configurationData?.resourceConfiguration?.osType || "",
      graphicCard: configurationData?.resourceConfiguration?.graphicsCard || "",
    },
    validationSchema: Yup.object({
      resourceName: Yup.string().required(RequiredField("Resource")),
      brandName: Yup.string().required(RequiredField("Brand name")),
      allocatedNumber: Yup.string()
        .required(RequiredField("Allocated number"))
        .matches(regexSIFormat, "Must be use format is SI-01-24"),
      amount: Yup.string()
        .required(RequiredField("Amount"))
        .matches(amountRegex, "Please enter valid amount."),
      paidby: Yup.string().required(RequiredField("Paid by")),
      vendor: Yup.string().required(RequiredField("Vendor")),
      purchaseDate: Yup.string().required(RequiredField("Purchase date")),
      pcmodal:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("Laptop/PC model name")),
      serialNo:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("Laptop/PC serial no")),
      processor:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("Processor")),
      storagetype:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("Storage type")),
      osVersion:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("OS version")),
      warrantyStartDate:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("Warranty start date")),
      warrantyEndDate: Yup.string().when(
        ["checkResource", "warrantyStartDate"],
        {
          is: (checkResource, warrantyStartDate) =>
            checkResource === "Laptop" && warrantyStartDate,
          then: Yup.string()
            .test(
              "date-comparison",
              "End date must be after start date",
              function (value) {
                const endDate = this.resolve(Yup.ref("warrantyEndDate"));
                if (endDate && value) {
                  return new Date(value) >= new Date(endDate);
                }
                return true; // Continue validation if either date is missing
              }
            )
            .required(RequiredField("Warranty end date")),
          otherwise: Yup.string().notRequired(),
        }
      ),
      ram:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("Ram")),
      osType:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("OS type ")),
      graphicCard:
        checkResource === "Laptop" &&
        Yup.string().required(RequiredField("Graphics card ")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (!isNaN(configurationId)) {

        let payload = {}
        if(values?.resourceName === "Laptop") {
        payload = {
          addedBy: profileId,
          userId: profileId,
           resourceName: values.resourceName,
           resourceNo: values.allocatedNumber,
           amount: values.amount,
           paidBy: values.paidby,
           brand: values.brandName,
           vendorId: values.vendor,
           purchaseDate: values.purchaseDate,
           serialNo: values.serialNo,
           vendor: values.vendor,
           modelName: values.pcmodal,
           processor: values.processor,
           RAM: values.ram,
           storageType: values.storagetype,
           osType: values.osType,
           osVersion: values.osVersion,
           graphicsCard: values.graphicCard,
           warrantyStartDate: values.warrantyStartDate,
           warrantyEndDate: values.warrantyEndDate,
         }
       } else {
         payload = {
           resourceName: values.resourceName,
           resourceNo: values.allocatedNumber,
           amount: values.amount,
           paidBy: values.paidby,
           brand: values.brandName,
           vendorId: values.vendor,
           purchaseDate: values.purchaseDate,
         }
       }

        editConfiguration(configurationId, payload)
          .then((resp) => {
            if (resp.data.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(resp.data.message);
            } else {
              toast.error(resp.data.message);
            }
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
            navigate(`/configuration`);
          });
      } else {
        let payload = {}
        if(values?.resourceName === "Laptop") {
         payload = {
           addedBy: profileId,
           userId: profileId,
            resourceName: values.resourceName,
            resourceNo: values.allocatedNumber,
            amount: parseInt(values.amount),
            paidBy: values.paidby,
            brand: values.brandName,
            vendorId: values.vendor,
            purchaseDate: values.purchaseDate,
            serialNo: values.serialNo,
            vendor: values.vendor,
            modelName: values.pcmodal,
            processor: values.processor,
            RAM: values.ram,
            storageType: values.storagetype,
            osType: values.osType,
            osVersion: values.osVersion,
            graphicsCard: values.graphicCard,
            warrantyStartDate: values.warrantyStartDate,
            warrantyEndDate: values.warrantyEndDate,
          }
        } else {
          payload = {
            userId: profileId,
            addedBy: profileId,
            resourceName: values.resourceName,
            resourceNo: values.allocatedNumber,
            amount: parseInt(values.amount),
            paidBy: values.paidby,
            brand: values.brandName,
            vendorId: values.vendor,
            purchaseDate: values.purchaseDate,
          }
        }
        addResource(payload)
          .then((resp) => {
            if (
              resp?.data?.statusCode === ResponseStatusEnum.SUCCESS 
            ) {
              toast.success(resp?.data?.message);
              navigate(`/configuration`);
            } else {
              toast.error(resp?.data?.message);
            }
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  document.title = pageTitle;
  return (
    <div className="page-content">
      {loading === false ? (
        <Container fluid>
          <BreadCrumb title={pageTitle} pageTitle="HRMS" />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">{pageTitle}</h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <Link to="/configuration">
                  <button className="btn btn-primary createTask" type="button">
                    Back
                  </button>
                </Link>
              </div>
            </div>
          </Row>
          <Card>
            <CardBody>
              <Form
                id="creattask-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>
                <input
                  type="hidden"
                  id="taskid-input"
                  className="form-control"
                />

                <Row className="g-2 mb-3">
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="task-title-input" className="form-label">
                        Brand Name{" "}
                      </Label>
                      <Select
                        className={` ${
                          validation.errors.brandName && "is-invalid red-border"
                        }`}
                        placeholder="Select brand"
                        value={selectedBrandData || selectedViewBrand}
                        isMulti={false}
                        onChange={(selectedOption) => {
                          setSelectedBrandData(selectedOption);
                          validation.setFieldValue(
                            "brandName",
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        options={brandName}
                        onBlur={validation.handleBlur}
                        name="brandName"
                      />
                      {validation.touched.brandName &&
                      validation.errors.brandName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.brandName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <div className="mb-3">
                        <Label htmlFor="skillsInput" className="form-label">
                          Resources
                        </Label>
                        <Select
                          placeholder="Please Select Resource"
                          value={ResourceType?.find(
                            (option) =>
                              option.value === validation.values.resourceName
                          )}
                          onChange={(selectedOption) => {
                            validation.setFieldValue(
                              "resourceName",
                              selectedOption?.value || ""
                            );
                            setCheckResource(selectedOption.value);
                          }}
                          options={ResourceType}
                        />
                        <div className="invalid-feedback">
                          {validation.errors.resourceName}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="task-title-input" className="form-label">
                        Resource Allocated Number{" "}
                      </Label>
                      <Input
                        type="text"
                        id="task-title-input"
                        className="form-control"
                        placeholder="Format is SI-01-21"
                        name="allocatedNumber"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.allocatedNumber || ""}
                        invalid={
                          !!(
                            validation.touched.allocatedNumber &&
                            validation.errors.allocatedNumber
                          )
                        }
                      />
                      {validation.touched.allocatedNumber &&
                      validation.errors.allocatedNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.allocatedNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="task-title-input" className="form-label">
                        Amount
                      </Label>
                      <Input
                        type="number"
                        id="task-title-input"
                        className="form-control"
                        placeholder="Enter Brand Amount"
                        name="amount"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.amount || ""}
                        maxlength="6"
                        invalid={
                          !!(
                            validation.touched.amount &&
                            validation.errors.amount
                          )
                        }
                      />
                      {validation.touched.amount && validation.errors.amount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.amount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                  <Label htmlFor="task-title1" className="form-label">
                  Paidby{" "}
                      </Label>
                      <Select
                        className={` ${
                          validation.errors.paidby && "is-invalid red-border"
                        }`}
                        placeholder="Select brand"
                        value={selectedPaidByData || selectedViewPaidBy}
                        isMulti={false}
                        onChange={(selectedOption) => {
                          setSelectedPaidByData(selectedOption);
                          validation.setFieldValue(
                            "paidby",
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        options={paidByOption}
                        onBlur={validation.handleBlur}
                        name="paidby"
                      />
                      {validation.touched.paidby &&
                      validation.errors.paidby ? (
                        <FormFeedback type="invalid">
                          {validation.errors.paidby}
                        </FormFeedback>
                      ) : null}
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="cityInput" className="form-label">
                        Vendor
                      </Label>
                      <Select
                        className={` ${
                          validation.errors.vendor && "is-invalid red-border"
                        }`}
                        placeholder="Select vendor"
                        value={selectedVendorData || selectedViewVendor}
                        isMulti={false}
                        onChange={(selectedOption) => {
                          setSelectedVendorData(selectedOption);
                          validation.setFieldValue(
                            "vendor",
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        options={vendorDetails}
                        onBlur={validation.handleBlur}
                        name="vendor"
                      />
                      {validation.touched.vendor &&
                      validation.errors.vendor ? (
                        <FormFeedback type="invalid">
                          {validation.errors.vendor}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="JoiningdatInput" className="form-label">
                        Purchase Date
                      </Label>
                      <Flatpickr
                        className={`form-select ${
                          validation.errors.purchaseDate && "is-invalid"
                        }`}
                        id="datepicker-publish-input"
                        placeholder="Select a date"
                        name="purchaseDate"
                        validate={{ required: { value: true } }}
                        onBlur={validation.handleBlur}
                        value={validation.values.purchaseDate}
                        invalid={
                          !!(
                            validation.touched.purchaseDate &&
                            validation.errors.purchaseDate
                          )
                        }
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          maxDate: "today",
                          onChange: function (
                            selectedDates,
                            dateStr,
                            instance
                          ) {
                            validation.setFieldValue("purchaseDate", dateStr);
                          },
                        }}
                      />
                      <div className="invalid-feedback">
                        {validation.errors.purchaseDate}
                      </div>
                    </div>
                  </Col>
                </Row>
                {checkResource === "Laptop" && (
                  <>
                    <h4>
                      {!isNaN(configurationId) ? "Edit" : "Add "} Laptop Details
                    </h4>
                    <hr />
                    <Row className="g-2 mb-3 mt-4">
                      <Col lg={6}>
                        <div className="">
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Laptop/PC Model Name
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder="Enter Modal Name"
                            name="pcmodal"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pcmodal || ""}
                            invalid={
                              !!(
                                validation.touched.pcmodal &&
                                validation.errors.pcmodal
                              )
                            }
                          />
                          {validation.touched.pcmodal &&
                          validation.errors.pcmodal ? (
                            <FormFeedback type="invalid">
                              {validation.errors.pcmodal}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="">
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Laptop/PC Serial No
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder="Enter Laptop/PC Serial No"
                            name="serialNo"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.serialNo || ""}
                            invalid={
                              !!(
                                validation.touched.serialNo &&
                                validation.errors.serialNo
                              )
                            }
                          />
                          {validation.touched.serialNo &&
                          validation.errors.serialNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.serialNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="">
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Processor{" "}
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder="Enter Processor"
                            name="processor"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.processor || ""}
                            invalid={
                              !!(
                                validation.touched.processor &&
                                validation.errors.processor
                              )
                            }
                          />
                          {validation.touched.processor &&
                          validation.errors.processor ? (
                            <FormFeedback type="invalid">
                              {validation.errors.processor}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Ram{" "}
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder="Enter OS version"
                            name="ram"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ram || ""}
                            invalid={
                              !!(
                                validation.touched.ram && validation.errors.ram
                              )
                            }
                          />
                          {validation.touched.ram && validation.errors.ram ? (
                            <FormFeedback type="invalid">
                              {validation.errors.ram}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="">
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Storage Type{" "}
                          </label>
                          <Select
                        className={` ${
                          validation.errors.storagetype && "is-invalid red-border"
                        }`}
                        placeholder="Select Storage Type"
                        value={selectedStorageTypeData || selectedViewStorageType}
                        isMulti={false}
                        onChange={(selectedOption) => {
                          setSelectedStorageTypeData(selectedOption);
                          validation.setFieldValue(
                            "storagetype",
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        options={storageTypeOption}
                        onBlur={validation.handleBlur}
                        name="storagetype"
                      />
                      {validation.touched.storagetype &&
                      validation.errors.storagetype ? (
                        <FormFeedback type="invalid">
                          {validation.errors.storagetype}
                        </FormFeedback>
                      ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <Label
                            htmlFor="task-title-input"
                            className="form-label">
                            Os Type{" "}
                          </Label>
                          <Select
                            className={` ${
                              validation.errors.osType &&
                              "is-invalid red-border"
                            }`}
                            placeholder="OS type"
                            value={selectedOs || selectedOsType}
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedOs(selectedOption);
                              validation.setFieldValue(
                                "osType",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={osDetails}
                            onBlur={validation.handleBlur}
                            name="osType"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            OS version{" "}
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder="Enter OS version"
                            name="osVersion"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.osVersion || ""}
                            invalid={
                              !!(
                                validation.touched.osVersion &&
                                validation.errors.osVersion
                              )
                            }
                          />
                          {validation.touched.osVersion &&
                          validation.errors.osVersion ? (
                            <FormFeedback type="invalid">
                              {validation.errors.osVersion}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Graphics Card{" "}
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder="Enter Graphics Card"
                            name="graphicCard"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.graphicCard || ""}
                            invalid={
                              !!(
                                validation.touched.graphicCard &&
                                validation.errors.graphicCard
                              )
                            }
                          />
                          {validation.touched.graphicCard &&
                          validation.errors.graphicCard ? (
                            <FormFeedback type="invalid">
                              {validation.errors.graphicCard}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Warranty Start Date
                          </label>
                          <Flatpickr
                            name="warrantyStartDate"
                            id="date-field"
                            className={`form-select ${
                              validation.errors.warrantyStartDate &&
                              "is-invalid"
                            }`}
                            placeholder="Enter Warranty Start Date"
                            invalid={
                              !!(
                                validation.touched.warrantyStartDate &&
                                validation.errors.warrantyStartDate
                              )
                            }
                            value={validation.values.warrantyStartDate || ""}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              onChange: function (
                                selectedDates,
                                dateStr,
                                instance
                              ) {
                                validation.setFieldValue(
                                  "warrantyStartDate",
                                  dateStr
                                );
                              },
                            }}
                          />
                          <div className="invalid-feedback">
                            {validation.errors.warrantyStartDate}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Warranty End Date{" "}
                          </label>
                          <Flatpickr
                            name="warrantyEndDate"
                            id="date-field"
                            className={`form-select ${
                              validation.errors.warrantyEndDate &&
                              validation.touched.warrantyEndDate
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Warranty End Date"
                            value={validation?.values?.warrantyEndDate || ""}
                            invalid={
                              validation.touched.warrantyEndDate &&
                              validation.errors.warrantyEndDate
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              onChange: function (
                                selectedDates,
                                dateStr,
                                instance
                              ) {
                                validation.setFieldValue(
                                  "warrantyEndDate",
                                  dateStr
                                );
                                validation.setFieldTouched(
                                  "warrantyEndDate",
                                  true,
                                  false
                                );
                              },
                              disable: [
                                function (date) {
                                  const startDate = new Date(
                                    validation.values.warrantyStartDate
                                  );
                                  return date < startDate;
                                },
                              ],
                            }}
                          />
                          {validation.touched.warrantyEndDate &&
                            validation.errors.warrantyEndDate && (
                              <div className="invalid-feedback">
                                {validation.errors.warrantyEndDate}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="addNewTodo">
                    {"Submit"}
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default AddEditConfiguration;
