import {LIST_OF_TASK, LIST_OF_ASSIGN_PROJECT ,ADD_PROJECT, ADD_TASKSHEET, LIST_OF_PROJECT, LIST_OF_TASKSHEET, ADD_SENIOR_TASKSHEET, EDIT_SENIOR_TASKSHEET, EDIT_TASKSHEET, LIST_OF_SENIOR_TASKSHEET, LIST_OF_TIMESHEET, VIEW_JUNIOR_Tasksheet, LIST_OF_SENIOR_PROJECT, EDIT_PROJECT, LIST_OF_JUNIOR_DEVELOPER , LIST_OF_PROJECTS } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const viewTasksheet = async (props) => {
    const id = props;
    const response = await apiService2.get(`${VIEW_JUNIOR_Tasksheet}/${id}`);
    return response;
};

export const addTasksheet = async (props) => {
    const response = await apiService2.post(`${ADD_TASKSHEET}`, props);
    return response;
};

export const addProject = async (data) => {
    const response = await apiService2.post(`${ADD_PROJECT}`, data);
    return response;
}; 

export const listOfProject = async (data) => {
    const response = await apiService2.post(`${LIST_OF_PROJECT}`, data);
    return response;
};
export const editProject = async (data) => {
    const response = await apiService2.put(`${EDIT_PROJECT}/${data.id}`, data);
    return response;
};
export const listOfSeniorProject = async (data) => {
    const response = await apiService2.post(`${LIST_OF_SENIOR_PROJECT}`, data);
    return response;
};

export const listOFTaskSheet = async (data) => {
    const response = await apiService2.post(`${LIST_OF_TASKSHEET}`, data);
    return response;
};

export const editTaskSheet = async (props) => {
    const response = await apiService2.put(`${EDIT_TASKSHEET}/${props.id}`, props);
    return response;
};

export const listOfTimesheet = async (props) => {
    const response = await apiService2.post(`${LIST_OF_TIMESHEET}`, props);
    return response;
};

export const listOfSeniorTaskSheet = async (props) => {
    const response = await apiService2.post(`${LIST_OF_SENIOR_TASKSHEET}`, props);
    return response;
};

export const addSeniorTaskSheet = async (props) => {
    const response = await apiService2.post(`${ADD_SENIOR_TASKSHEET}`, props);
    return response;
};

export const editSeniorTaskSheet = async (props) => {
    const response = await apiService2.put(`${EDIT_SENIOR_TASKSHEET}/${props.id}`, props);
    return response;
};

export const editTaskSheetHR = async (id, data) => {
    const response = await apiService2.put(`${EDIT_TASKSHEET}/${id}`, data);
    return response;
};

export const listofjunior = async (props) => {
    const response = await apiService2.post(`${LIST_OF_JUNIOR_DEVELOPER}`, props);
    return response;
};

export const listOfProjects = async (props) => {
    const response = await apiService2.post(`${LIST_OF_PROJECTS}`, props);
    return response;
};


export const listOfAssignProjects = async (props) => {
    const response = await apiService2.get(`${LIST_OF_ASSIGN_PROJECT}`, props);
    return response;
};

export const listOfTasks = async (data) => {
    const response = await apiService2.post(LIST_OF_TASK, data);
    return response;
};