import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../components/common/breadCrumb";
import { getId } from "../../utils/helpers/custom";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import {
  approveRejectTimesheet,
} from "../../api/reviewApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import NoResultsMessage from "../../components/noResultMessage";
import { Link, useLocation } from "react-router-dom";
import { listOFTaskSheet } from "../../api/tasksheetApi";

const Status = ({ status }) => {
  switch (status) {
    case "Completed":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    case "Rejected":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Approved":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
  }
};

const ReviewDetails = () => {
  document.title = "Review Details";
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState("");
  const [listJunior, setListJunior] = useState([]);
  const [cellData, setCellData] = useState();

  const handleApprove = (row) => {
    setSelectedRow({ ...row, action: "approve timesheet" });
    setUpdateModal(true);
    setAction("Approve");
  };

  function listOfReview() {
    setLoading(true);
    listOFTaskSheet({
      userId: location?.state?.userId,
      date: location?.state?.date
    })
      .then((response) => {
        setListJunior(response?.data?.data?.listOfTaskSheet);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleConfirmation = () => {
    // Handle the approve or reject action based on the selectedRow
    if (selectedRow && action) {
      approveRejectTimesheet(selectedRow?.row?.original?.id, {
        reviewStatus: "Approved",
      }).then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          listOfReview();
        } else {
          toast.error(response?.data?.message);
        }
      });
    }

    // Close the modal
    setUpdateModal(false);
    setSelectedRow(null);
    setAction("");
  };
  const id = getId();

  useEffect(() => {
    listOfReview();
  }, []);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleRejectTimesheet = (cellData) => {
    validation.handleSubmit();
    setCellData(cellData);
    toggle();
  };

  const columns = useMemo(() => [
    {
      Header: "Sr.no",
      filterable: false,
      Cell: ({ row }) => row?.index + 1,
    },
    {
      Header: "Project Name",
      accessor: "projectTask",
      filterable: false,
    },
    {
      Header: "Task Name",
      accessor: "taskSheet.taskName",
      filterable: false,
      Cell: (cell) => [
        <div className="text-wrap" key={cell?.row?.original?.id}>
          {cell?.row?.original?.taskSheet?.taskName}
        </div>,
      ],
    },
    {
      Header: "Details Of Task",
      accessor: "detailsOfTask",
      filterable: false,
      Cell: (cell) => [
        <div className="text-wrap" key={cell?.row?.original?.id}>
          {cell?.row?.original?.detailsOfTask}
        </div>,
      ],
    },
    {
      Header: "Task Status",
      accessor: "taskStatus",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell.value} />;
      },
    },
    {
      Header: "Taken Time",
      accessor: "takenTime",
      Cell: (cell) => {
        return (
          <div>
            {`${cell?.row?.original?.takenHours}:${cell?.row?.original?.takenMinutes}:00`}
          </div>
        )
      },
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <>
            {cellProps.row.original.reviewStatus === "Approved" ||
            cellProps.row.original.reviewStatus === "Rejected" ? (
              <Status status={cellProps.row.original.reviewStatus} />
            ) : (
              <div className="hstack gap-2">
                <div>
                  <ReactTooltip
                    className="bg-danger"
                    place="bottom"
                    variant="info"
                    content="Reject"
                    anchorId={`reject-${cellProps.row.index}`}
                  />
                  <button
                    id={`reject-${cellProps.row.index}`}
                    className="btn btn-sm btn-soft-danger edit-list"
                    onClick={() => {
                      handleRejectTimesheet(cellProps);
                    }}>
                    <i className="ri-close-line"></i>
                  </button>
                </div>
                <div>
                  <ReactTooltip
                    className="bg-success"
                    place="bottom"
                    variant="info"
                    content="Approve"
                    anchorId={`approve-${cellProps.row.index}`}
                  />
                  <button
                    id={`approve-${cellProps.row.index}`}
                    className="btn btn-sm btn-soft-success edit-list"
                    onClick={() => {
                      handleApprove(cellProps);
                    }}>
                    <i className="ri-check-line"></i>
                  </button>
                </div>
              </div>
            )}
          </>
        );
      },
    },
  ]);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required(RequiredField("Reason")),
    }),
    onSubmit: (value) => {
      approveRejectTimesheet(cellData?.row?.original?.id, {
        reviewStatus: "Rejected",
        ...value,
      })
        .then((response) => {
          if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(response?.data?.message);
            setModal(false);
            listOfReview();
          } else {
            toast.error(response?.data?.message);
            setModal(false);
          }
          validation.resetForm();
        })
        .catch((err) => {
          setLoading(err);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
      setModal(false);
      validation.resetForm();
    },
  });

  let message = "";
  if (selectedRow) {
    if (selectedRow.action === "approve") {
      message = `Are you sure you want to Approve the record with reason ${selectedRow.reason}?`;
    } else if (selectedRow.action === "reject") {
      message = `Are you sure you want to Reject the record with reason ${selectedRow.reason}?`;
    }
  }

  return (
    <>
      <ApproveRejectModal
        show={updateModal}
        onDeleteClick={() => handleConfirmation()}
        onCloseClick={() => setUpdateModal(false)}
        message={message}
        action={selectedRow ? selectedRow.action : ""}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb
              title="Review Details"
              pageTitle="Review Timesheet"
              navigation="/review"
            />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Review Timesheet</h5>
              </div>
              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <Link to="/review">
                    <button
                      className="btn btn-primary createTask"
                      type="button">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </Row>

            <div className="file-manager-content w-100 p-4 pb-0">
              <Card>
                <CardBody>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    modalClassName="zoomIn">
                    <Form onSubmit={validation.handleSubmit}>
                      <ModalHeader
                        toggle={toggle}
                        className="p-3 bg-soft-success">
                        {" "}
                        Reason for rejecting the time sheet
                      </ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col lg={12}>
                            <Label>Reason</Label>
                            <Input
                              type="textarea"
                              title="Reason"
                              name="reason"
                              placeholder={
                                "Enter reason for rejecting the timesheet"
                              }
                              value={validation.values.reason}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.reason &&
                                validation.errors.reason
                                  ? true
                                  : false
                              }></Input>
                            {validation.touched.reason &&
                            validation.errors.reason ? (
                              <FormFeedback type="invalid">
                                {validation.errors.reason}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="none"
                          className="btn btn-ghost-success"
                          onClick={toggle}>
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  {listJunior?.length ? (
                    <TableContainer
                      columns={columns}
                      data={listJunior || []}
                      isAddUserList={false}
                      isGlobalFilter={true}
                      customPageSize={5}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <NoResultsMessage />
                  )}
                </CardBody>
              </Card>
            </div>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default ReviewDetails;
