import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import { getId, getName } from "../../utils/helpers/custom";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../components/common/breadCrumb";
import {
  addLeave,
  applyCompOffLeave,
  addAcquireCompOfLeave,
  editDeleteAquireLeave,
} from "../../api/leaveApi";
import TableContainer from "../../components/common/tableContainer";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { dateFormat, dateFormatChange } from "../../utils/helpers/common";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import Loader from "../../components/common/loader";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import NoResultsMessage from "../../components/noResultMessage";
import { listOfData } from "../../api/commonApi";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const AquireLeaves = () => {
  document.title = "HR Leaves";
  const name = getName();
  const id = getId();
  const [loading, setLoading] = useState(false);
  const [isApproveRejectModalOpen, setApproveRejectModalOpen] = useState(false);
  const [typeOfLeaveModal, setTypeOfLeaveModal] = useState();
  const [listOfCompOffData, setListOfCompOffData] = useState();

  const navigate = useNavigate();

  function listDataOfCompOfLeave() {
    setLoading(true);
    listOfData({
      modelName: "compOfLeaveModel",
      condition: {
        userId: id,
        isDeleted: false,
        leaveStatus : ["Pending", "Rejected"] 
      },
      selectionCriteria: ["id", "date", "reason", "status"],
    })
      .then((response) => {
        setListOfCompOffData(response?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    listDataOfCompOfLeave();
  }, []);

  const [leaveModalTitle, setLeaveModalTitle] = useState(false);
  const [leaveModalDate, setLeaveModalDate] = useState();
  const [leaveModalID, setLeaveModalID] = useState();

  const handleOpenApproveRejectModal = (id) => {
    setEditItemId(id);
    setApproveRejectModalOpen(true);
  };

  const handleCloseApproveRejectModal = () => {
    setApproveRejectModalOpen(false);
  };

  const handleDeleteLeave = () => {
    setLoading(true);
    const payload = {
      isDeleted: true,
    };
    setApproveRejectModalOpen(true);
    editDeleteAquireLeave(editItemId, payload)
      .then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
          setLoading(true);
          toast.success(response?.data?.message);
          handleCloseApproveRejectModal();
          listDataOfCompOfLeave();
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAcquireApplyModal = (date, id) => {
    setLeaveModalTitle("Apply Acquire Leave");
    setLeaveModalDate(date);
    setLeaveModalID(id);
    setTypeOfLeaveModal("COL");
    setModalTodo(!modalTodo);
    toggle();
  };

  const getStatusBadgeClass = (status) => {
    if (status === "Pending") {
      return "warning";
    } else if (status === "Rejected") {
      return "danger";
    } else if (status === "Approved") {
      return "success";
    }
    return "info";
  };

  const getStatusText = (status) => {
    if (status === "Pending") {
      return "Pending";
    } else if (status === "Rejected") {
      return "Rejected";
    } else if (status === "Approved") {
      return "Approved";
    }
    return "Unknown";
  };

  const leaveColumns = useMemo(() => [
    {
      Header: "Date",
      accessor: "date",
      filterable: false,
      Cell: (cell) => {
        const { date } = cell.row.original;
        return date !== null ? <div>{dateFormat(date)}</div> : "-";
      },
    },
    {
      Header: "Reason",
      accessor: "reason",
      filterable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (item) => (
        <span
          className={`badge badge-soft-${getStatusBadgeClass(
            item.row.original.status
          )} text-uppercase`}>
          {getStatusText(item.row.original.status)}
        </span>
      ),
    },
    {
      Header: "Action",
      Cell: (item) => (
        <div>
          {item?.row?.original?.status === "Pending" && (
            <>
              <button
                className="btn btn-sm btn-soft-danger remove-list"
                onClick={() => {
                  handleOpenApproveRejectModal(item?.row?.original?.id);
                }}>
                <i className="fa fa-times" aria-hidden="true">
                  &times;
                </i>
              </button>
              &nbsp;&nbsp;
              <ReactTooltip
                place="bottom"
                variant="info"
                content="Edit Leave"
                anchorId={`editAction-${item?.row?.original?.id}`}
              />
              <Link
                to={`/edit-aquireleaves/${item?.row?.original?.id}`}
                className="btn btn-sm btn-soft-info edit-list"
                id={`editAction-${item?.row?.original?.id}`}
                onClick={() => handleEditClick(item?.row?.original?.id)}>
                <i className="ri-pencil-fill align-bottom" />
              </Link>
            </>
          )}
          {item?.row?.original?.status === "Approved" && (
            <button
              className="btn btn-sm btn-soft-success fs-13 fs-13 text-success"
              onClick={() => {
                handleAcquireApplyModal(
                  item?.row?.original?.date,
                  item?.row?.original?.id
                );
              }}>
              Apply
            </button>
          )}
          {item?.row?.original?.status === "Rejected" && (
            <button
              className="btn btn-sm btn-soft-danger remove-list"
              onClick={() => {
                handleOpenApproveRejectModal(item.row.original.id);
              }}>
              <i className="fa fa-times" aria-hidden="true">
                &times;
              </i>
            </button>
          )}
        </div>
      ),
    },
  ]);

  const [LeaveData, setLeaveData] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toggle = useCallback(() => {
    if (modalTodo) {
      leaveValidation.resetForm();
      setModalTodo(false);
      setLeaveData(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  const RegEx = /^[A-Za-z\s]*$/;
  // To do Task List validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      leaveType: LeaveData?.leaveType || "",
      leaveDays: LeaveData?.leaveDays || "",
      reason: LeaveData?.reason || "",
      fromDate: LeaveData?.fromDate || "",
      toDate: LeaveData?.toDate || "",
    },
    validationSchema: Yup.object({
      leaveType: Yup.string().required(RequiredField("select leave")),
      leaveDays: Yup.string().required(RequiredField("number of days")),
      reason: Yup.string()
        .required(RequiredField("Reason"))
        .matches(RegEx, "Enter Only Characters"),
      fromDate: Yup.string().required(RequiredField("number of days")),
      toDate: Yup.string().required(RequiredField("number of days")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        validation.resetForm();
      } else {
        setLoading(true);
        addLeave({
          userId: parseInt(id),
          fromDate: values.fromDate,
          toDate: values.toDate,
          leaveDays: values.leaveDays,
          reason: values.reason,
          status: "Pending",
          leaveType: values.leaveType,
        })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
            } else if (
              response?.data?.statusCode === ResponseStatusEnum.DUPLICATE
            ) {
              toast.warning(response?.data?.message);
            }
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
        validation.resetForm();
      }
      toggle();
    },
  });

  const leaveValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryLeave: typeOfLeaveModal,
      fromDate: "",
      toDate: "",
      aquiredDate: "",
      date: "",
      numberOfDays: "",
      leaveReason: "",
      leaveType: "",
    },
    validationSchema: Yup.lazy((values) => {
      const { categoryLeave } = values;

      return Yup.object().shape({
        leaveType: Yup.string().required(RequiredField("Type Of Leave")),
        date: Yup.string().required(RequiredField("Date")),
        leaveReason: Yup.string().required(RequiredField("Reason")),
        numberOfDays:
          categoryLeave === "COL"
            ? Yup.string().notRequired()
            : Yup.number("Must Be A Number").required(
                RequiredField("Number Of Days")
              ),
      });
    }),
    onSubmit: (values) => {
      setLoading(true);
      const payload = {
        userId: id,
        toDate: values.date,
        acquireDate: leaveModalDate,
        compOfLeaveId: leaveModalID,
        leaveType: values.leaveType,
        leaveReason: values.leaveReason,
      };

      applyCompOffLeave(payload)
        .then((response) => {
          if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            leaveValidation.resetForm();
            setModalTodo(false);
            toast.success(response?.data?.message);
            setLoading(false);
          } else {
            toast.error(response?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const [editItemId, setEditItemId] = useState(null);

  const handleEditClick = (id) => {
    setIsEdit(true);
    setEditItemId(id);
    const selectedItem = listOfCompOffData.find((item) => item.id === id);

    if (selectedItem) {
      setEditItemId(selectedItem.id);
      AcquireLeaveValidation.setValues({
        date: selectedItem.date,
        reason: selectedItem.reason,
      });
    }
  };

  const AcquireLeaveValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: "",
      reason: "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required(RequiredField("Date")),
      reason: Yup.string().required(RequiredField("Reason")),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      if (isEdit) {
        setIsEdit(false);
        setLoading(true);
        editDeleteAquireLeave(editItemId, values)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              navigate("/aquireleaves");
              listDataOfCompOfLeave();
              setIsEdit(false);
              resetForm();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        addAcquireCompOfLeave({ userId: parseInt(id), ...values })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              listDataOfCompOfLeave();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const handleSubmit = () => {
    navigate("/aquireleaves");
  };

  const formateDate = leaveModalDate && moment(leaveModalDate).format('LL');

  return (
    <React.Fragment>
      <ApproveRejectModal
        show={isApproveRejectModalOpen}
        onCloseClick={handleCloseApproveRejectModal}
        onDeleteClick={handleDeleteLeave}
        action="cancel leave"
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb
              title="Acquire Leaves"
              pageTitle="Leaves"
              navigation="/leaves"
            />
            <Row className="mb-4" id="leave-management">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Comp Off Leave</h5>
              </div>
              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <Link to="/hrleaves">
                    <button
                      className="btn btn-primary createTask"
                      type="button">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </Row>
            <Row>
              <Col lg={12}>
                <Row className="w-100">
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">
                        Acquire Comp Off Leave
                      </h5>
                    </CardHeader>
                    <CardBody className="mt-0">
                      <div className="p-3  rounded mb-4">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            AcquireLeaveValidation.handleSubmit();
                            return false;
                          }}>
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label>Select Date :</Label>
                                <Flatpickr
                                  name="date"
                                  id="date-field"
                                  placeholder="Select Date"
                                  value={AcquireLeaveValidation.values.date}
                                  className={`from-control ${
                                    AcquireLeaveValidation.errors.date &&
                                    AcquireLeaveValidation.touched.date
                                      ? "is-invalid"
                                      : null
                                  }`}
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d",
                                    onChange: function (
                                      selectedDates,
                                      dateStr,
                                      instance
                                    ) {
                                      AcquireLeaveValidation.setFieldValue(
                                        "date",
                                        dateStr
                                      );
                                    },
                                  }}
                                />
                                {AcquireLeaveValidation.errors.date &&
                                AcquireLeaveValidation.touched.date ? (
                                  <div className="invalid-feedback">
                                    {AcquireLeaveValidation.errors.date}
                                  </div>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div>
                                <Label>Enter your reason :</Label>
                                <Input
                                  type="textarea"
                                  className={`form-control ${
                                    AcquireLeaveValidation.errors.date &&
                                    AcquireLeaveValidation.touched.date
                                      ? "is-invalid"
                                      : null
                                  }`}
                                  placeholder="Enter your reason"
                                  name="reason"
                                  onChange={AcquireLeaveValidation.handleChange}
                                  onBlur={AcquireLeaveValidation.handleBlur}
                                  value={AcquireLeaveValidation.values.reason}
                                />
                                {AcquireLeaveValidation.errors.reason &&
                                AcquireLeaveValidation.touched.reason ? (
                                  <div className="invalid-feedback">
                                    {AcquireLeaveValidation.errors.reason}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <div className="mt-4 hstack gap-2 justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                id="addNewTodo"
                                onClick={handleSubmit}>
                                {isEdit ? "Update" : "Submit"}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>

            <Row>
              {!isEdit && (
                <Col lg={12}>
                  <Row className="w-100">
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">Leave Details</h5>
                      </CardHeader>
                      <CardBody className="mt-4">
                        <div className="p-3 bg-light rounded mb-4">
                          <Row className="g-2">
                            {listOfCompOffData?.length ? (
                              <TableContainer
                                columns={leaveColumns}
                                data={listOfCompOffData || []}
                                isAddUserList={false}
                                customPageSize={5}
                                divClass="table-responsive table-card mb-1"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                              />
                            ) : (
                              <NoResultsMessage />
                            )}
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              )}
            </Row>

            <Card></Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      {typeOfLeaveModal === "COL" ? (
        <Modal
          id="createTask"
          isOpen={modalTodo}
          toggle={toggle}
          modalClassName="zoomIn"
          centered
          tabIndex="-1">
          <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
            {" "}
            {leaveModalTitle}
          </ModalHeader>
          <ModalBody>
            <div id="task-error-msg" className="alert alert-danger py-2"></div>
            <Form
              id="creattask-form"
              onSubmit={(e) => {
                e.preventDefault();
                leaveValidation.handleSubmit();
                return false;
              }}>
              <input type="hidden" id="taskid-input" className="form-control" />
              <div className="mb-3">
                <Label htmlFor="task-full-name-input" className="form-label">
                  {" "}
                  Full Name :
                </Label>
                <Input
                  type="text"
                  id="task-full-name-input"
                  className="form-control"
                  placeholder="Enter Your Name"
                  name="name"
                  value={name}
                  disabled
                />
              </div>
              <Row className="g-4">
                {typeOfLeaveModal === "COL" && (
                  <Col lg={6}>
                    <div>
                      <Label
                        htmlFor="task-full-name-input"
                        className="form-label">
                        {" "}
                        Acquire Comp Off Leave :
                      </Label>
                      <Input
                        type="text"
                        id="selectedLeaveType"
                        className="form-control"
                        placeholder="Enter Date"
                        name="aquiredDate"
                        value={formateDate}
                        disabled
                      />
                    </div>
                  </Col>
                )}
                {typeOfLeaveModal === "COL" ? (
                  <Col lg={6}>
                    <label htmlFor="task-duedate-input" className="form-label">
                      Date :
                    </label>

                    <Flatpickr
                      className={
                        leaveValidation.errors.date &&
                        leaveValidation.touched.date
                          ? "is-invalid"
                          : null
                      }
                      name="date"
                      id="date-field"
                      onBlur={leaveValidation.handleBlur}
                      placeholder="Select Date"
                      value={leaveValidation.values.date}
                      invalid={
                        !!(leaveValidation.touched.date &&
                        leaveValidation.errors.date)
                      }
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        onChange: function (selectedDates, dateStr, instance) {
                          leaveValidation
                            .setFieldValue("date", dateStr)
                            .then((res) => res)
                            .catch((err) => err);
                        },
                      }}
                    />
                    {leaveValidation.errors.date &&
                    leaveValidation.touched.date ? (
                      <div className="invalid-feedback">
                        {leaveValidation.errors.date}
                      </div>
                    ) : null}
                  </Col>
                ) : (
                  <Col lg={6}>
                    <label htmlFor="task-duedate-input" className="form-label">
                      Date :
                    </label>
                    <Flatpickr
                      className={
                        leaveValidation.errors.date &&
                        leaveValidation.touched.date
                          ? "is-invalid"
                          : null
                      }
                      name="date"
                      id="date-field"
                      onBlur={leaveValidation.handleBlur}
                      placeholder="Select Date"
                      options={{
                        mode: "range",
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        onChange: function (selectedDates, dateStr, instance) {
                          leaveValidation.setFieldValue("date", dateStr);
                          leaveValidation.setFieldValue(
                            "fromDate",
                            dateFormatChange(selectedDates[0])
                          );
                          leaveValidation.setFieldValue(
                            "toDate",
                            dateFormatChange(selectedDates[1])
                          );
                        },
                      }}
                    />
                    {leaveValidation.errors.date &&
                    leaveValidation.touched.date ? (
                      <div className="invalid-feedback">
                        {leaveValidation.errors.date}
                      </div>
                    ) : null}
                  </Col>
                )}

                <Col lg={6}>
                  {typeOfLeaveModal === "COL" ? null : (
                    <div className="mb-3">
                      <label htmlFor="task-number-input" className="form-label">
                        Number of Days :
                      </label>
                      <Input
                        type="number"
                        id="task-number-input"
                        className={`form-control ${
                          leaveValidation.errors.numberOfDays &&
                          leaveValidation.touched.numberOfDays
                            ? "is-invalid"
                            : null
                        } `}
                        placeholder="Enter number of days"
                        name="numberOfDays"
                        onChange={leaveValidation.handleChange}
                        onBlur={leaveValidation.handleBlur}
                        value={leaveValidation.values.numberOfDays}
                      />
                      {leaveValidation.errors.numberOfDays &&
                      leaveValidation.touched.numberOfDays ? (
                        <div className="invalid-feedback">
                          {leaveValidation.errors.numberOfDays}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Col>
              </Row>
              <div className="mb-3">
                <label htmlFor="task-title-input" className="form-label">
                  Leave Reason :
                </label>
                <Input
                  type="textarea"
                  id="task-title-input"
                  className={`form-control ${
                    leaveValidation.errors.leaveReason &&
                    leaveValidation.touched.leaveReason
                      ? "is-invalid"
                      : null
                  }`}
                  placeholder="Enter leave reason"
                  name="leaveReason"
                  onChange={leaveValidation.handleChange}
                  onBlur={leaveValidation.handleBlur}
                  value={leaveValidation.values.leaveReason}
                />
                {leaveValidation.errors.leaveReason &&
                leaveValidation.touched.leaveReason ? (
                  <div className="invalid-feedback">
                    {leaveValidation.errors.leaveReason}
                  </div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="task-title-input" className="form-label">
                  Leave Type :
                </label>
                <select
                  className={`form-select ${
                    leaveValidation.errors.leaveType &&
                    leaveValidation.touched.leaveType
                      ? "is-invalid"
                      : null
                  }`}
                  aria-label="Default select example"
                  id="leaveType"
                  name="leaveType"
                  onChange={leaveValidation.handleChange}
                  onBlur={leaveValidation.handleBlur}
                  value={leaveValidation.values.leaveType}>
                  <option selected hidden>
                    Select Leave Type
                  </option>
                  <option value="Full"> Full</option>
                  <option value="Half">Half</option>
                  {typeOfLeaveModal === "COL" ? null : (
                    <option value="WFH">WFH</option>
                  )}
                </select>
                {leaveValidation.errors.leaveType &&
                leaveValidation.touched.leaveType ? (
                  <div className="invalid-feedback">
                    {leaveValidation.errors.leaveType}
                  </div>
                ) : null}
              </div>

              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-ghost-success"
                  onClick={() => {
                    setModalTodo(false);
                    setLeaveModalTitle("");
                    leaveValidation.resetForm();
                  }}>
                  <i className="ri-close-fill align-bottom"></i> Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="addNewTodo">
                  Submit
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default AquireLeaves;
