import { LIST_OF_EMPLOYEE, ADD_BDE, LIST_OF_EMPLOYEE_BDE_NAME, LIST_OF_BDE, UPDATE_BDE, DELETE_BDE } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const addBdeInterview = async (data) => {
    const response = await apiService2.post(`${ADD_BDE}`,data);
    return response;
};

export const listOfEmployee = async (data) => {
    const response = await apiService2.post(`${LIST_OF_EMPLOYEE}`,data);
    return response.data;
};

export const listOfEmployeeBdeData = async (data) => {
    const response = await apiService2.post(`${LIST_OF_EMPLOYEE_BDE_NAME}`, data);
    return response;
};

export const listOfBde = async (data) => {
    const response = await apiService2.post(`${LIST_OF_BDE}`,data);
    return response;
};

export const updateBdeInterview = async (id,data) => {
    const response = await apiService2.put(`${UPDATE_BDE}/${id}`,data);
    return response;
};

export const deleteBdeInterview = async(id, data) => {
    const response = await apiService2.put(`${DELETE_BDE}/${id}`,data);
    return response;
};