import { ADD_CONFIGURATION, ADD_RESOURCE, COUNT_OF_DEVICE, EDIT_CONFIGURATION, FIND_DEVICES_NUMBER, LIST_OF_ALLOCATION, LIST_OF_CONFIGURATION, LIST_OF_DAMAGED_DEVICES, LIST_OF_RESOURCE, MULIT_COUNT_CONFIG, RESOURCES_EDIT_DAMAGED_RESOURCE, RESOURCES_EDIT_DAMAGED_RESOURCE_FILTER, RESOURCES_FIND_ASSIGN_DEVICES, RESOURCES_HISTORY, RESOURCES_RESOURCE_FILTER, RESOURCE_ALLOCATION, RESOURCE_REALLOWCATON, RESOURCE_RETURN, VENDOR_LIST } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const addResource = async (props) => {
    const response = await apiService2.post(`${ADD_RESOURCE}`, props)
    return response
}

export const resourcesAllocation = async (props) => {
    const response = await apiService2.post(`${RESOURCE_ALLOCATION}`, props)
    return response
}

export const resourcesReturn = async (props) => {
    const response = await apiService2.post(`${RESOURCE_RETURN}`, props)
    return response
}

export const listOfResources = async (id) => {
    const response = await apiService2.get(`${LIST_OF_RESOURCE}/${id}`)
    return response
}

export const countOfDevice = async (props) => {
    const response = await apiService2.post(`${COUNT_OF_DEVICE}`, props)
    return response
}

export const listOfAllocation = async (props) => {
    const response = await apiService2.get(`${LIST_OF_ALLOCATION}`, props)
    return response
}

export const listOfDamagedResources = async (props) => {
    const response = await apiService2.post(`${LIST_OF_DAMAGED_DEVICES}`, props)
    return response
}

export const listOfConfiguration = async (props) => {
    const response = await apiService2.post(`${LIST_OF_CONFIGURATION}`, props)
    return response
}

export const addConfiguration = async (props) => {
    const response = await apiService2.post(`${ADD_CONFIGURATION}`, props)
    return response
}

export const editConfiguration = async (hrId, props) => {
    const response = await apiService2.put(`${EDIT_CONFIGURATION}/${hrId}`, props)
    return response
}

export const configurationCountData = async () => {
    const response = await apiService2.get(`${MULIT_COUNT_CONFIG}`)
    return response
}

export const vendorList = async () => {
    const response = await apiService2.post(`${VENDOR_LIST}`)
    return response
}

export const resourcesHistory = async (data) => {
    const response = await apiService2.post(`${RESOURCES_HISTORY}`, data);
    return response;
};

export const editDamagedResources = async (data) => {
    const response = await apiService2.post(`${RESOURCES_EDIT_DAMAGED_RESOURCE}`, data);
    return response;
};

export const listOfResourcesManageFilter = async (props) => {
    const response = await apiService2.post(`${RESOURCES_EDIT_DAMAGED_RESOURCE_FILTER}`, props)
    return response
}

export const filterOfResources = async (props) => {
    const response = await apiService2.post(`${RESOURCES_RESOURCE_FILTER}`, props)
    return response
}

export const deviceOfNumber = async (props) => {
    const response = await apiService2.post(`${FIND_DEVICES_NUMBER}`, props)
    return response
}

export const assignDeviceOfNumber = async (props) => {
    const response = await apiService2.post(`${RESOURCES_FIND_ASSIGN_DEVICES}`, props)
    return response
}