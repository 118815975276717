import { DELETE_EMPLOYEE, ADD_EMPLOYEE, COUNT_OF_EMPLOYEE,UPDATE_STATUS_OF_EMPLOYEE,LIST_OF_EMPLOYEE,CHANGE_SENIOR, EDIT_EMPLOYEE, FILTER_OF_EMPLOYEE } from "./apiRoutes"
import {apiService2} from "./baseURL"

export const listOfEmployee = async (data) => {
    const response = await apiService2.post(`${LIST_OF_EMPLOYEE}`,data);
    return response;
};

export const addEmployee = async (data) => {
    const response = await apiService2.post(`${ADD_EMPLOYEE}`,data);
    return response;
};

export const editEmployee = async (id,data) => {
    const response = await apiService2.put(`${EDIT_EMPLOYEE}/${id}`,data);
    return response;
};

export const deleteEmployee = async (id,data) => {
    const response = await apiService2.put(`${DELETE_EMPLOYEE}/${id}`,data);
    return response;
};

export const activeDeActiveEmployee = async (id,data) => {
    const response = await apiService2.put(`${UPDATE_STATUS_OF_EMPLOYEE}/${id}`,data);
    return response;
};

export const countOfEmployee = async (data) => {
    const response = await apiService2.post(`${COUNT_OF_EMPLOYEE}`,data);
    return response;
};

export const changeSenior = async (data) => {
    const response = await apiService2.post(`${CHANGE_SENIOR}`,data);
    return response;
};

export const filterOfEmployee = async (data) => {
    const response = await apiService2.post(`${FILTER_OF_EMPLOYEE}`,data);
    return response;
};
