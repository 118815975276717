import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import Flatpickr from "react-flatpickr";
import { listOfData } from "../../api/commonApi";
import {
  addBdeInterview,
  listOfBde,
  listOfEmployeeBdeData,
  updateBdeInterview,
} from "../../api/bdeApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/loader";

const BdeAddEdit = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [selectedClientName, setSelectedClientName] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [aliased, setAliased] = useState();
  const [selectedAlisedName, setSelectedAlisedName] = useState();
  const [employee, setEmployee] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [tech, setTeach] = useState();
  const [selectedTechnology, setSelectedTechnology] = useState();
  const [selectedHR, setSelectedHR] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedRound, setSelectedRound] = useState();
  const [listOfDataHR, setListOfDataHR] = useState();
  const [listOfBdeData, setListOfBdeData] = useState();
  const [selectedClientData, setSelectedClientData] = useState();
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const navigate = useNavigate();
  const { bdeId } = useParams();

  useEffect(() => {
    setLoading(true);

    if (!isNaN(bdeId)) {
      listOfBde({ id: bdeId })
        .then((response) => {
          setListOfBdeData(response?.data?.data?.[0]);
          setSelectedCompany({
            value: response?.data?.data?.[0]?.company?.id,
            label: response?.data?.data?.[0]?.company?.companyName,
          });
          setSelectedClientName({
            value: response?.data?.data?.[0]?.client?.id,
            label: response?.data?.data?.[0]?.client?.clientName,
          });
          setSelectedAlisedName({
            value: response?.data?.data?.[0]?.alisedName?.alisedName,
            label: response?.data?.data?.[0]?.alisedName?.alisedName,
          });
          setSelectedEmployee({
            value: response?.data?.data?.[0]?.authUser?.id,
            label: `${response?.data?.data?.[0]?.authUser?.firstName} ${response?.data?.data?.[0]?.authUser?.lastName}`,
          });
          setSelectedTechnology({
            value:
              response?.data?.data?.[0]?.bdeTechnology?.[0]?.technology?.id,
            label:
              response?.data?.data?.[0]?.bdeTechnology?.[0]?.technology
                ?.techName,
          });
          setSelectedHR({
            value: response?.data?.data?.[0]?.scheduledId,
            label: response?.data?.data?.[0]?.schedule?.developerName,
          });
          setSelectedStatus({
            value: response?.data?.data[0]?.status,
            label: response?.data?.data[0]?.status,
          });
          setSelectedRound({
            value: response?.data?.data[0]?.round,
            label: response?.data?.data[0]?.round,
          });
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [bdeId]);

  useEffect(() => {
    setLoading(true);
    listOfData({
      modelName: "companyModel",
      selectionCriteria: ["id", "companyName"],
      condition: {
        isDeleted: false,
      },
    })
      .then((response) => {
        setCompany(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });

    listOfData({
      modelName: "alisedNameModel",
      selectionCriteria: ["id", "alisedName"],
      condition: {
        isDeleted: false,
      },
    })
      .then((response) => {
        setAliased(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });

    listOfEmployeeBdeData({
      isDeleted: false,
      role: ["Employee"],
    })
      .then((response) => {
        setEmployee(response?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });

    listOfData({
      modelName: "technologyModel",
      selectionCriteria: ["id", "techName"],
      condition: {
        isDeleted: false,
      },
    })
      .then((response) => {
        setTeach(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });

    listOfEmployeeBdeData({
      isDeleted: false,
      role: ["BDE", "Admin"],
    })
      .then((response) => {
        setListOfDataHR(response?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (selectedCompanyId !== undefined || selectedCompanyId !== null) {
      listOfData({
        modelName: "clientModel",
        selectionCriteria: ["id", "clientName"],
        condition: {
          companyId : selectedCompanyId,
          isDeleted: false,
        },
      })
        .then((response) => {
          setSelectedClient(response?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedCompanyId]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      dateOfInterview: listOfBdeData?.dateOfInterview || "",
      experience: listOfBdeData?.yearOfExperience || "",
      technology: listOfBdeData?.bdeTechnology?.[0]?.technology?.id || "",
      alised_name: listOfBdeData?.alisedName?.id || "",
      developer_name: listOfBdeData?.authUser?.id || "",
      scheduledBy: listOfBdeData?.scheduledId || "",
      status: listOfBdeData?.status || "",
      round: listOfBdeData?.round || "",
      remark: listOfBdeData?.remark || "",
      clientId: listOfBdeData?.client?.id || "",
      companyId: listOfBdeData?.company?.id || "",
    },
    validationSchema: Yup.object({
      dateOfInterview: Yup.date().required(RequiredField("Date of interview")),
      experience: Yup.string().required(RequiredField("Years of experience")),
      technology: Yup.string().required(RequiredField("Technology")),
      alised_name: Yup.string().required(RequiredField("Alised name")),
      developer_name: Yup.string().required(RequiredField("Developer name")),
      scheduledBy: Yup.string().required(RequiredField("Scheduled by")),
      status: Yup.string().required(RequiredField("Status")),
      remark: Yup.string().optional(),
      round: Yup.string().required(RequiredField("Interview round")),
      companyId: Yup.string().required(RequiredField("Company name")),
      clientId: Yup.string().required(RequiredField("Client name")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (bdeId === undefined) {
        const payload = {
          dateOfInterview: values.dateOfInterview,
          yearOfExperience: values.experience,
          technologyId: [parseInt(values.technology)],
          alisedId: values.alised_name,
          clientId: values.clientId,
          companyId: values.companyId,
          scheduledId: values.scheduledBy,
          userId: values.developer_name,
          status: values.status,
          round: values.round,
        };

        if (values.remark) {
          payload.remark = values.remark;
        }
        addBdeInterview(payload)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              validation.resetForm();
              navigate("/bde");
              setSelectedCompany("");
              setSelectedAlisedName("");
              setSelectedEmployee("");
              setSelectedTechnology("");
              setSelectedHR("");
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        const payload = {
          dateOfInterview: values.dateOfInterview,
          yearOfExperience: values.experience,
          technologyId: [parseInt(values.technology)],
          alisedId: values.alised_name,
          clientId: values.clientId,
          companyId: values.companyId,
          scheduledId: values.scheduledBy,
          userId: values.developer_name,
          status: values.status,
          round: values.round,
          remark: values.remark,
        };
        updateBdeInterview(bdeId, { ...payload })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              validation.resetForm();
              navigate("/bde");
              toast.success(response?.data?.message);
            } else {
              toast.error(response?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const listOfCompany = company?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.companyName}`,
    };
  });

  const selectedAssignToCompanyObj = Array.isArray(selectedCompany)
    ? selectedCompany?.map((index) => {
        return {
          value: `${index}Company`,
          label: `${index}Company`,
        };
      })
    : null;

  function handleSelectCompany(selectedCompany) {
    validation.setFieldValue("companyId", selectedCompany?.value);
    setSelectedCompany(selectedCompany);
  }

  const listOfEmployee = employee?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.developerName}`,
    };
  });

  const selectedEmployeeObj = Array.isArray(selectedEmployee)
    ? selectedEmployee?.map((index) => {
        return {
          value: `${index}Company`,
          label: `${index}Company`,
        };
      })
    : null;

  function handleSelectEmployee(selectedEmployee) {
    validation.setFieldValue("developer_name", selectedEmployee?.value);
    setSelectedEmployee(selectedEmployee);
  }

  const listOfAlised = aliased?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.alisedName}`,
    };
  });

  const selectedAlisedObj = Array.isArray(selectedAlisedName)
    ? selectedAlisedName?.map((index) => {
        return {
          value: `${index}Company`,
          label: `${index}Company`,
        };
      })
    : null;

  function handleSelectAlised(selectedAlisedName) {
    validation.setFieldValue("alised_name", selectedAlisedName?.value);
    setSelectedAlisedName(selectedAlisedName);
  }

  const listOfTechnologies = tech?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.techName}`,
    };
  });

  const selectedTechnologyObj = Array.isArray(selectedTechnology)
    ? selectedTechnology?.map((index) => {
        return {
          value: `${index}Company`,
          label: `${index}Company`,
        };
      })
    : null;

  function handleSelectTechnology(selectedTechnology) {
    validation.setFieldValue("technology", selectedTechnology?.value);
    setSelectedTechnology(selectedTechnology);
  }

  const listOfHR = listOfDataHR?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.developerName}`,
    };
  });

  const listOfStatus = [
    {
      value: "Selected",
      label: "Selected",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "OnHold",
      label: "OnHold",
    },
  ];

  function handleStatus(selectedStatus) {
    validation.setFieldValue("status", selectedStatus?.value);
    setSelectedStatus(selectedStatus);
  }

  const selectedStatusResult = Array.isArray(selectedStatus)
    ? selectedStatus?.map((index) => {
        return {
          value: index,
          label: index,
        };
      })
    : null;

  function handleMultipleHR(selectedHR) {
    validation.setFieldValue("scheduledBy", selectedHR?.value);
    setSelectedHR(selectedHR);
  }

  const [selectedOs, setSelectedOs] = useState(null);

  const roundDetails = [
    {
      label: "Select Round",
      value: "",
    },
    {
      value: "Screening Round",
      label: "1(Screening Round)",
    },
    {
      value: "Second Round",
      label: "2",
    },
    {
      value: "Third Round",
      label: "3",
    },
    {
      value: "Fourth Round",
      label: "4",
    },
    {
      value: "Fifth Round",
      label: "5",
    },
  ];

  const interviewRound = Array.isArray(roundDetails)
    ? roundDetails?.map((status) => ({
        value: status.value,
        label: status.label,
      }))
    : null;

  function handleRound(selectedRound) {
    validation.setFieldValue("round", selectedRound?.value);
    setSelectedOs(selectedRound);
  }

    const clientDetails = Array.isArray(selectedClient)
    ? selectedClient?.map((status) => ({
        value: status?.id,
        label: status?.clientName,
      }))
    : null;

  const durationOptions = Array?.from({ length: 10 }, (_, i) => i + 1);
  const enable = (bdeId === undefined) ? selectedCompanyId === undefined : false
  return (
    <div className="page-content">
      {loading === false ? (
        <Container fluid>
          <BreadCrumb
            title={bdeId === undefined ? "ADD BDE" : "UPDATE BDE"}
            pageTitle="BDE"
          />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">
                {bdeId === undefined ? "ADD" : "UPDATE"} {"BDE"}
              </h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <Link to={"/bde"} className="btn btn-primary">
                  Back
                </Link>
              </div>
            </div>
          </Row>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Company Name
                      </label>
                      <Select
                        placeholder="Select Company"
                        className={` ${
                          validation.errors.companyId && "is-invalid"
                        }`}
                        value={selectedAssignToCompanyObj || selectedCompany}
                        isMulti={false}
                        onChange={(e) => {
                          setSelectedCompanyId(e.value)
                          handleSelectCompany(e);
                        }}
                        options={listOfCompany}
                        onBlur={validation.handleBlur}
                        name="companyId"
                      />
                      {validation.touched.companyId &&
                      validation.errors.companyId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.companyId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="task-title-input" className="form-label">
                        Client Name{" "}
                      </Label>
                      <Select
                        className={` ${
                          validation.errors.clientId &&
                          "is-invalid red-border"
                        }`}
                        placeholder="Enter client name"
                        value={selectedClientData || selectedClientName}
                        isMulti={false}
                        onChange={(selectedOption) => {
                          setSelectedClientData(selectedOption);
                          validation.setFieldValue(
                            "clientId",
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        isDisabled={enable}
                        options={clientDetails}
                        onBlur={validation.handleBlur}
                        name="clientId"
                      />
                      {validation.touched.clientId &&
                      validation.errors.clientId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.clientId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Developer Name
                      </label>
                      <Select
                        placeholder="Select Company"
                        className={` ${
                          validation.errors.developer_name && "is-invalid"
                        }`}
                        value={selectedEmployeeObj || selectedEmployee}
                        isMulti={false}
                        onChange={(e) => {
                          handleSelectEmployee(e);
                        }}
                        options={listOfEmployee}
                        onBlur={validation.handleBlur}
                        name="developer_name"
                      />
                      {validation.touched.developer_name &&
                      validation.errors.developer_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.developer_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Alised Name
                      </label>
                      <Select
                        placeholder="Select Company"
                        className={` ${
                          validation.errors.developer_name && "is-invalid"
                        }`}
                        value={selectedAlisedObj || selectedAlisedName}
                        isMulti={false}
                        onChange={(e) => {
                          handleSelectAlised(e);
                        }}
                        options={listOfAlised}
                        onBlur={validation.handleBlur}
                        name="alised_name"
                      />
                      {validation.touched.alised_name &&
                      validation.errors.alised_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.alised_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Technology
                      </label>
                      <Select
                        placeholder="Select Company"
                        className={` ${
                          validation.errors.technology && "is-invalid"
                        }`}
                        value={selectedTechnologyObj || selectedTechnology}
                        isMulti={false}
                        onChange={(e) => {
                          handleSelectTechnology(e);
                        }}
                        options={listOfTechnologies}
                        onBlur={validation.handleBlur}
                        name="technology"
                      />
                      {validation.touched.technology &&
                      validation.errors.technology ? (
                        <FormFeedback type="invalid">
                          {validation.errors.technology}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Year Of Experience
                      </label>
                      <select
                        className={`form-select ${
                          validation.errors.experience && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        id="experience"
                        name="experience"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.experience}>
                        <option value="">Select Experience</option>
                        {durationOptions?.map((option) => (
                          <option key={option} value={option}>
                            {option} {option === 1 ? "+" : "+"}
                          </option>
                        ))}
                      </select>
                      {validation.touched.experience &&
                      validation.errors.experience ? (
                        <FormFeedback type="invalid">
                          {validation.errors.experience}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label
                        htmlFor="task-receivedDate-input"
                        className="form-label">
                        Date Of Interview :
                      </label>
                      <Flatpickr
                        name="dateOfInterview"
                        id="dateOfInterview"
                        validate={{ required: { value: true } }}
                        onBlur={validation.handleBlur}
                        className={`form-control ${
                          validation.errors.dateOfInterview && "is-invalid"
                        }`}
                        placeholder="Enter Date Of Interview"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          onChange: function (
                            _selectedDates,
                            dateStr,
                            instance
                          ) {
                            validation.setFieldValue(
                              "dateOfInterview",
                              dateStr
                            );
                          },
                        }}
                        value={validation.values.dateOfInterview}
                      />
                      {validation.touched.dateOfInterview &&
                      validation.errors.dateOfInterview ? (
                        <FormFeedback type="invalid">
                          {validation.errors.dateOfInterview}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Scheduled By
                      </label>
                      <Select
                        placeholder="Select BDE"
                        className={` ${
                          validation.errors.scheduledBy && "is-invalid"
                        }`}
                        value={selectedHR}
                        isMulti={false}
                        onChange={(e) => {
                          handleMultipleHR(e);
                        }}
                        options={listOfHR}
                        onBlur={validation.handleBlur}
                        name="scheduledBy"
                      />
                      {validation.touched.scheduledBy &&
                      validation.errors.scheduledBy ? (
                        <FormFeedback type="invalid">
                          {validation.errors.scheduledBy}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input1" className="form-label">
                        Status
                      </label>
                      <Select
                        placeholder="Select Status"
                        className={` ${
                          validation.errors.status && "is-invalid"
                        }`}
                        value={selectedStatusResult || selectedStatus}
                        isMulti={false}
                        onChange={(e) => {
                          handleStatus(e);
                        }}
                        options={listOfStatus}
                        onBlur={validation.handleBlur}
                        name="status"
                      />
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-2">
                      <label htmlFor="task-title-input" className="form-label">
                        Interview Round
                      </label>
                      <Select
                        className={` ${
                          validation.errors.round && "is-invalid red-border"
                        }`}
                        placeholder="Round"
                        value={selectedOs || selectedRound}
                        isMulti={false}
                        onChange={(e) => {
                          handleRound(e);
                        }}
                        options={interviewRound}
                        onBlur={validation.handleBlur}
                        name="round"
                      />
                      {validation.touched.round && validation.errors.round ? (
                        <FormFeedback type="invalid">
                          {validation.errors.round}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-2">
                      <Label htmlFor="task-title-input" className="form-label">
                        Remark
                      </Label>
                      <Input
                        type="textarea"
                        id="task-title-input"
                        className="form-control"
                        placeholder="Enter remark"
                        name="remark"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.remark || ""}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="addNewMember">
                        {bdeId === undefined ? "Save" : "Update"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default BdeAddEdit;
