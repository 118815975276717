import { ADD_OF_TECHNICAL_INTERVIEWS, ADD_RECRUITMENT, EDIT_OF_TECHNICAL_INTERVIEWS, EDIT_RECRUITMENT, LIST_OF_RECRUITMENT, LIST_OF_TECHNICAL_INTERVIEWS, LIST_OF_TECHROUND2 } from "./apiRoutes";
import { apiService2, apiService3 } from "./baseURL"

export const listOfRecruitment = async () => {
    const response = await apiService2.get(`${LIST_OF_RECRUITMENT}`);
    return response;
};

export const addRecruitment = async (data) => {
    const formData = new FormData();
    Object.keys(data)?.forEach(key => formData.append(key, data[key]));
    const response = await apiService3.post(`${ADD_RECRUITMENT}`, formData);
    return response;
};

export const editRecruitment = async (RecruitmentId, data) => {
    const formData = new FormData();
    Object.keys(data)?.forEach(key => formData.append(key, data[key]));
    const response = await apiService3.put(`${EDIT_RECRUITMENT}/${RecruitmentId}`, formData);
    return response;
};

export const listofTechnicalInterviews = async (userId) => {
    const response = await apiService2.get(`${LIST_OF_TECHNICAL_INTERVIEWS}/${userId}`);
    return response;
};

export const addTechnicalRecruitment = async (RecruitmentId, data) => {
    const response = await apiService2.post(`${ADD_OF_TECHNICAL_INTERVIEWS}/${RecruitmentId}`, data);
    return response;
};

export const editTechnicalInterviews = async (RecruitmentId, data) => {
    const response = await apiService2.put(`${EDIT_OF_TECHNICAL_INTERVIEWS}/${RecruitmentId}`, data);
    return response;
};

export const listOfTechRound2 = async (data) => {
    const response = await apiService2.post(`${LIST_OF_TECHROUND2}`, data);
    return response;
};

