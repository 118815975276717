import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { useEffect, useState } from "react";
import { viewInterviewFeedBack } from "../../api/interviewApi";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../components/common/loader";
import userDummyImg from "../../../src/assets/image/auth-one-bg.jpg";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { EMPLOYEE_PROFILE_IMAGE } from "../../api/apiRoutes";
import { baseURL } from "../../api/baseURL";
import Rating from "react-rating";
import { dateMonthFormat } from "../../utils/helpers/common";
import SimpleBar from "simplebar-react";

const InterviewReview = () => {
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState();
  const [profileImage, setProfileImage] = useState();
  const location = useLocation();
  const imageUrl = `${baseURL}/${EMPLOYEE_PROFILE_IMAGE}`;

  useEffect(() => {
    setLoading(true);
    viewInterviewFeedBack(location?.state?.id)
      .then((response) => {
        setReview(response?.data?.data);
        setProfileImage(response?.data?.data?.assignUser?.userDetails);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  let str = review?.interviewReview;
  function HTMLToJSX(htmlString) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }

  const jsxElement = HTMLToJSX(str);

  //Common function for show read only rating.
  function RatingReadOnly(data) {
    return (
      <Rating
        initialRating={data}
        readonly
        emptySymbol="mdi mdi-star-outline text-muted "
        fullSymbol="mdi mdi-star text-warning "
      />
    );
  }

  return (
    <div className="page-content">
      {loading === false ? (
        <Container fluid>
          <BreadCrumb
            title="Review Of Interview"
            pageTitle="HRMS"
            beforeTitle="Review Of Interview"
          />
          <div></div>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img src={userDummyImg} alt="" className="profile-wid-img" />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img
                    src={
                      profileImage?.profileImage !== null
                        ? `${imageUrl}/${profileImage?.profileImage}`
                        : userDummyImg
                    }
                    alt="user-img"
                    className="img-thumbnail rounded-circle"
                  />
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {review?.assignUser?.firstName ||
                      ResponseStatusEnum.NO_DATA_FOUND}
                  </h3>
                  <p className="text-white-75">
                    {review?.assignUser?.lastName ||
                      ResponseStatusEnum.NO_DATA_FOUND}
                  </p>
                  <div className="text-white-50 gap-1">
                    <div className="mb-2">
                      <strong>Senior :&nbsp;&nbsp;</strong>
                      {review?.seniorPerson?.firstName ||
                        ResponseStatusEnum.NO_DATA_FOUND}{" "}
                      &nbsp;
                      {review?.seniorPerson?.lastName ||
                        ResponseStatusEnum.NO_DATA_FOUND}
                    </div>
                    <div className="mb-2">
                      <strong>Interviewer :&nbsp;&nbsp;</strong>
                      {review?.takenBy?.firstName ||
                        ResponseStatusEnum.NO_DATA_FOUND}{" "}
                      &nbsp;
                      {review?.takenBy?.lastName ||
                        ResponseStatusEnum.NO_DATA_FOUND}
                    </div>
                    <div className="mb-2">
                      <strong>Interview Level :&nbsp;&nbsp;</strong>
                      {review?.level || ResponseStatusEnum.NO_DATA_FOUND}
                    </div>
                    <div>
                      <strong>Interview Date :&nbsp;&nbsp;</strong>
                      {dateMonthFormat(review?.interviewDate) ||
                        ResponseStatusEnum.NO_DATA_FOUND}
                    </div>
                  </div>
                  ``
                </div>
              </Col>

              <Col xs={12} className="col-lg-auto order-last order-lg-0">
                <Row className="text text-white-50 text-center">
                  <Col lg={6} xs={4}>
                    <div className="p-2">
                      <h4 className="text-white mb-1">
                        {location?.state?.count + "%" || "70 %"}
                      </h4>
                      <p className="fs-14 mb-0">OverAll</p>
                    </div>
                  </Col>
                  <Col lg={6} xs={4}>
                    <div className="p-2">
                      <h4 className="text-white mb-1">100</h4>
                      <p className="fs-14 mb-0">Out Of</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="mb-0">
            <Col xxl={12}>
              <Card id="contact-view-detail">
                <CardBody>
                  <Row className="mb-0">
                    <Col lg={12}>
                      <div className="mb-4">
                        <h4> Rating Information </h4>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-0">
                    <Col lg={12}>
                      <div className="table-responsive table-card">
                        <Table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium">Introduction</td>
                              <td>{RatingReadOnly(review?.intro)}</td>
                              <td className="fw-medium">Project</td>
                              <td>{RatingReadOnly(review?.project)}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Confidence</td>
                              <td>{RatingReadOnly(review?.confidence)}</td>
                              <td className="fw-medium">Communication</td>
                              <td>{RatingReadOnly(review?.communication)}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Javascript</td>
                              <td>{RatingReadOnly(review?.javascript)}</td>
                              <td className="fw-medium">Technology</td>
                              <td>{RatingReadOnly(review?.debugging)}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Debugging</td>
                              <td>{RatingReadOnly(review?.technology)}</td>
                              <td className="fw-medium">Logical</td>
                              <td>{RatingReadOnly(review?.logical)}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Database</td>
                              <td>{RatingReadOnly(review?.database)}</td>
                              <td className="fw-medium">Explanation</td>
                              <td>{RatingReadOnly(review?.explanation)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-2">
                <CardHeader>
                  <h4 className="card-title mb-0">Remarks</h4>
                </CardHeader>

                <CardBody>
                  <div className="mx-n3">
                    <SimpleBar className="px-3 simpleBar">
                      {jsxElement || ResponseStatusEnum.NO_DATA_FOUND}
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mb-4 feedBackReviewPadding mt-2 ">
            <Link to="/interview_feedback">
              <button className="btn btn-success">Back</button>
            </Link>
          </div>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default InterviewReview;
