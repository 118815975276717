import { ADD_INTERVIEW_FEEDBACK, DELETE_INTERVIEW_FEEDBACK, EDIT_INTERVIEW_FEEDBACK, LIST_OF_INTERVIEW_FEEDBACK, VIEW_INTERVIEW_FEEDBACK } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const addInterviewFeedBack = async (data) => {
    const response = await apiService2.post(`${ADD_INTERVIEW_FEEDBACK}`,data);
    return response;
};

export const editInterviewFeedBack = async (id, data) => {
    const response = await apiService2.put(`${EDIT_INTERVIEW_FEEDBACK}/${id}`,data);
    return response;
};

export const viewInterviewFeedBack = async (id) => {
    const response = await apiService2.get(`${VIEW_INTERVIEW_FEEDBACK}/${id}`);
    return response;
};

export const listOfInterviewFeedBack = async (data) => {
    const response = await apiService2.post(`${LIST_OF_INTERVIEW_FEEDBACK}`,data);
    return response.data;
};

export const deleteInterviewFeedBack = async (data, id) => {
    const response = await apiService2.put(`${DELETE_INTERVIEW_FEEDBACK}/${id}`, data);
    return response;
};
