import React, { useState, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/deleteModal";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import TableContainer from "../../components/common/tableContainer";
import {
  editDamagedResources,
  listOfDamagedResources,
  listOfResourcesManageFilter,
  resourcesAllocation,
} from "../../api/resourcesApi";
import { countOfData, findDevicesNumber } from "../../api/commonApi";
import { getId } from "../../utils/helpers/custom";
import { LIST_OF_DEVICES, ResponseStatusEnum } from "../../utils/helpers/enums";
import Loader from "../../components/common/loader";
import { listOfEmployee } from "../../api/bdeApi";
import { dateFormat } from "../../utils/helpers/common";
import Select from "react-select";

const DamageDevice = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setISLoading] = useState(false);
  const [damageResourcesCardData, setDamageResourcesCardData] = useState("");
  const [filterDetails, setFilterDetails] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalTodo, setModalTodo] = useState(false);
  const [device, setDevice] = useState("");
  const [damagedDeviceList, setDamagedDeviceList] = useState([]);
  const [damagedFilteredDeviceList, setDamagedFilteredDeviceList] = useState();
  const [employeeList, setEmployeeList] = useState();
  const [todo, setTodo] = useState(null);
  const [selectedDamagesStatusMap, setSelectedDamagesStatusMap] = useState({});
  const [selectedEditDamagedPayload, setSelectedEditDamagedPayload] = useState();
  const [selectedResourceName, setSelectedResourceName] = useState(null);
  const [selectedFilteredResource, setSelectedFilteredResource] = useState(null);
  const [selectedFilteredResourceNumber, setSelectedFilteredResourceNumber] = useState(null);
  const [selectedResourceNumber, setSelectedResourceNumber] = useState(null);
  const [selectedHolderName, setSelectedHolderName] = useState(null);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState(null);
  const [isEnabled, setIsEnabled] = useState(true);
  const [selectedResourceId, setSelectedResourceId] = useState();

  const eComWidgets = [
    {
      id: 1,
      cardColor: "primary",
      label: "Damaged Resources",
      badgeClass: "success",
      counter: damageResourcesCardData,
      bgcolor: "success",
      icon: " bx bx-bug",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];

  const handleStatusChange = (rowIndex, selectedOption) => {
    setSelectedDamagesStatusMap(prevState => ({
      ...prevState,
      [rowIndex]: selectedOption
    }));
  };

  const handleDeleteTodo = () => {
    if (todo) {
      setDeleteModal(false);
    }
  };

  const AddDamagedDevice = () => {
    setTodo("");
    setModalTodo(!modalTodo);
    toggle();
  };

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  function listOfDamagedResourcesList() {
    setLoading(true);
    listOfDamagedResources()
    .then((resp) => {
      if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
      setDamagedDeviceList(resp?.data?.data);
      } else {
        toast.error(resp?.data?.message);
      }
    })
    .catch((err) => err)
    .finally(() => {});
  countOfData({
    modelName: "resourcesDetailsModel",
    condition: {
      status: "Damage",
    },
  })
    .then((res) => {
      setDamageResourcesCardData(res?.data?.data);
    })
    .catch((err) => err)
    .finally(() => {
      setLoading(false);
    });
  }

  const fetchData = () => {
    setLoading(true);
    listOfDamagedResourcesList()
    listOfEmployee({
      isDeleted: false,
    })
      .then((resp) => setEmployeeList(resp?.data))
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      resourceNo: todo?.resourceNo || "",
      resourceName: todo?.resourceName || "",
      reason: todo?.reason || "",
      assignTo: todo?.assignTo || "",
    },
    validationSchema: Yup.object({
      resourceNo: Yup.string().required(RequiredField("Resource no")),
      reason: Yup.string().required(RequiredField("Reason")),
      assignTo: Yup.string().required(RequiredField("Resource holder name")),
      resourceName: Yup.string().required(RequiredField("Resource name")),
    }),
    onSubmit: (values) => {
      const damagePayload = {
        resourceDetailsId: selectedResourceId,
        assignTo: parseInt(values.assignTo, 10),
        addedBy: parseInt(getId(), 10),
        reason: values.reason,
        status: "Damage",
      };
      resourcesAllocation(damagePayload)
        .then((res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(res?.data?.message);
            fetchData();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => toast.error(err))
        .finally(() => {
          setLoading(false);
        });
      validation.resetForm();
      toggle();
    },
  });

  const validationFilter = useFormik({
    enableReinitialize: true,

    initialValues: {
      resourceNo: "",
      resourceName: "",
      employeeName: "",
    },
    validationSchema: Yup.object({
      resourceNo: Yup.string("It Should be text").optional(),
      developerName: Yup.string("It Should be text").optional(),
      employeeName: Yup.string("It Should be text").optional(),
    }),
    onSubmit: (value) => {
      setLoading(true);
      setFilterDetails(true);
      listOfResourcesManageFilter(value)
        .then((resp) => {
          if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            setDamagedFilteredDeviceList(resp?.data?.data);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
          validationFilter.resetForm();
        });
    },
  });

  const validationStatusFilter = useFormik({
    enableReinitialize: true,

    initialValues: {
      damageResourceStatus: "",
    },
    validationSchema: Yup.object({
      damageResourceStatus: Yup.string("It Should be text").optional(),
    }),
    onSubmit: () => {
      let obj;

      if (selectedEditDamagedPayload !== undefined || selectedEditDamagedPayload !== null) {
        let damagedStatus;
        if (selectedDamagesStatusMap[selectedEditDamagedPayload?.id]) {
          damagedStatus = selectedDamagesStatusMap[selectedEditDamagedPayload?.id];
        }
        obj = {
          resourceDetailsId: selectedEditDamagedPayload?.resourcesDetails?.id,
          resourceAssignId: selectedEditDamagedPayload?.id,
          status: damagedStatus.value
        }
      }
      setISLoading(true);
      editDamagedResources(obj)
        .then((resp) => { 
          if (resp?.data) {
            listOfDamagedResources()
    .then((resp) => {
      if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
      setDamagedDeviceList(resp?.data?.data);
      countOfData({
        modelName: "resourcesDetailsModel",
        condition: {
          status: "Damage",
        },
      })
        .then((res) => {
          setDamageResourcesCardData(res?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      } else {
        toast.error(resp?.data?.message);
      }
    })
    .catch((err) => err)
            toast.success(resp?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setISLoading(false);
        });
    },
  });

  const damagedDevicesStatusColumns = useMemo(() => [
    {
      Header: "Holder Name",
      accessor: "resourcesAssign.authUser.developerName",
      filterable: false,
    },
    {
      Header: "Resource Name",
      accessor: "resourcesDetails.resourceName",
      filterable: false,
    },
    {
      Header: "Resource Number",
      accessor: "resourcesDetails.resourceNo",
      filterable: false,
    },
    {
      Header: "Resource Integrity Date",
      accessor: "resourcesAssign.createdAt",
      filterable: false,
      Cell: (cell) => {
        const date = cell?.row?.original?.resourcesAssign?.createdAt;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
    {
      Header: "Resource Manage Date",
      accessor: "damageDate",
      filterable: false,
      Cell: (cell) => {
        const date = cell?.row?.original?.damageDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
    {
      Header: "status",
      accessor: "status",
      filterable: false,
    },
  ]);

  const damagedDevicesColumns = useMemo(() => [
    {
      Header: "Holder Name",
      accessor: "users.firstName",
      filterable: false,
      Cell: (cell) => {
        return `${cell?.row?.original?.authUser?.firstName} ${cell?.row?.original?.authUser?.lastName}`;
      },
    },
    {
      Header: "Resource Name",
      accessor: "resourceName",
      filterable: false,
      Cell: (cell) => {
        return cell?.row?.original?.resourcesDetails?.resourceName;
      },
    },
    {
      Header: "Resource Number",
      accessor: "resourceNo",
      filterable: false,
      Cell: (cell) => {
        return cell?.row?.original?.resourcesDetails?.resourceNo;
      },
    },
    {
      Header: "Integrity Date",
      accessor: "",
      filterable: false,
      Cell: (cell) => {
        const date = cell?.row?.original?.createdAt;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
    {
      Header: "Reason",
      accessor: "reason",
      filterable: false,
    },
    {
      Header: "status",
      Cell: (cell) => {
        const rowIndex = cell?.row?.original?.id;
        const isEnabled = cell?.row?.original?.resourcesManage?.[0]?.status === "Success" ? true : false
        let preFilledValue = {
          label: cell?.row?.original?.resourcesManage?.[0]?.status,
          value: cell?.row?.original?.resourcesManage?.[0]?.status
        }
        return (
            <> 
            {isLoading ? (
              <Spinner size="sm" className="me-2" />
          ) : ( <Select
            placeholder="Damaged Status"
            value={
              selectedDamagesStatusMap[rowIndex] || 
              validationStatusFilter?.values?.damageResourceStatus ||
              preFilledValue
            }
            isMulti={false}
            onChange={(selectedOption) => {
              setSelectedEditDamagedPayload(cell?.row?.original);
              handleStatusChange(rowIndex, selectedOption);
              validationStatusFilter.handleSubmit();
            }}
            options={damagedStatus}
            isDisabled={isEnabled}
            onBlur={validationStatusFilter.handleBlur}
            name="resourceName"
          />)
        }
        </>
        );
      },
      filterable: false,
    },
  ]);

  function findResourceName(resourceData, status) {
    findDevicesNumber({
      resourceName: resourceData,
      status,
    }).then((res) => {
      setIsEnabled(false);
      setDevice(res?.data?.data);
    });
  }

  const designationDetails = [
    {
      label: "Select Designation",
      value: "",
    },
    {
      value: "Damage",
      label: "Damage",
    },
    {
      value: "On Process",
      label: "On Process",
    },
    {
      value: "Repair",
      label: "Repair",
    },
    {
      value: "Success",
      label: "Success",
    },
  ];

  const resourceNumber = Array.isArray(device)
    ? device?.map((status) => ({
        value: status?.id,
        label: status?.resourceNo,
      }))
    : null;

  let resourceNoData =resourceNumber?.length > 0 ? resourceNumber : []

  const holderName = Array.isArray(employeeList)
    ? employeeList?.map((status) => ({
        value: status?.id,
        label: `${status?.firstName} ${status?.lastName}`,
      }))
    : null;

    const employeeNameList = Array.isArray(employeeList)
    ? employeeList?.map((status) => ({
        value: `${status?.firstName} ${status?.lastName}`,
        label: `${status?.firstName} ${status?.lastName}`,
      }))
    : null;
  
    const damagedStatus = Array.isArray(designationDetails)
      ? designationDetails?.map((status) => ({
          value: status?.label,
          label: status?.value,
        }))
      : null;

  document.title = "Damaged Resources";
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Damaged Resources" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Damaged Resources</h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <button
                    className="btn btn-primary createTask"
                    type="button"
                    onClick={() => AddDamagedDevice()}>
                    + Damaged Resources
                  </button>
                </div>
              </div>
            </Row>
            <Row>
              <Widgets data={eComWidgets} />
            </Row>
            <Card>
              <CardBody className="mt-0">
                <Formik
                  initialValues={validationFilter.initialValues}
                  validationSchema={validationFilter.validationSchema}
                  onSubmit={validationFilter.handleSubmit}>
                  <Form onSubmit={validationFilter.handleSubmit}>
                    <Row>
                      <Col lg={3} md={3} sm={6}>
                        <div className="p-2">
                          <Select
                            className={` ${
                              validationFilter.errors.resourceName &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Resource name"
                            value={
                              selectedFilteredResource ||
                              validationFilter?.values?.resourceName
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedFilteredResource(selectedOption);
                              validationFilter.setFieldValue(
                                "resourceName",
                                selectedOption ? selectedOption?.value : null
                              );
                              findResourceName(selectedOption?.value,["Damage","Repair","On Process", "Fail","Success"]);
                            }}
                            options={LIST_OF_DEVICES}
                            onBlur={validationFilter.handleBlur}
                            name="resourceName"
                          />
                        </div>
                      </Col>
                      <Col lg={3} md={3} sm={6}>
                        <div className="p-2">
                          <Select
                            className={` ${
                              validationFilter.errors.resourceNo &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Resource number"
                            value={
                              selectedFilteredResourceNumber ||
                              validationFilter?.values?.resourceNo
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedFilteredResourceNumber(selectedOption);
                              validationFilter.setFieldValue(
                                "resourceNo",
                                selectedOption ? selectedOption?.label : null
                              );
                            }}
                            options={resourceNoData}
                            onBlur={validationFilter.handleBlur}
                            name="resourceNo"
                            isDisabled={isEnabled}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={6}>
                        <div className="p-2">
                          <Select
                            className={` ${
                              validationFilter.errors.employeeName &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Resource holder name"
                            value={
                              selectedEmployeeName ||
                              validationFilter?.values?.employeeName
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedEmployeeName(selectedOption);
                              validationFilter.setFieldValue(
                                "employeeName",
                                selectedOption ? selectedOption?.value : null
                              );
                            }}
                            options={employeeNameList}
                            onBlur={validationFilter.handleBlur}
                            name="employeeName"
                          />
                        </div>
                      </Col>
                      <Col lg={2} md={2} sm={6}>
                        <div className="m-2">
                          <button
                            className="btn btn-primary createTask"
                            type="submit">
                            {!loading ? (
                              "SEARCH"
                            ) : (
                              <div className="d-inline-flex align-items-center">
                                <Spinner size="sm" className="me-2" />
                                Loading...
                              </div>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Formik>
              </CardBody>
            </Card>
            <div className="todo-task" id="todo-task">
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Damaged Resources{" "}
                  </h4>
                </CardHeader>
                <CardBody>
                {isLoading === true ? <Loader/> : (
                    <>
                    {filterDetails && (
                      <TableContainer
                        columns={damagedDevicesStatusColumns}
                        data={damagedFilteredDeviceList || []}
                        isAddUserList={false}
                        isGlobalFilter={false}
                        customPageSize={5}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                    )}
                    </>
                  )}
                  {filterDetails === false && (
                    <TableContainer
                      columns={damagedDevicesColumns}
                      data={damagedDeviceList || []}
                      isAddUserList={false}
                      isGlobalFilter={false}
                      customPageSize={5}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) 
                  }
                </CardBody>
              </Card>
            </div>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        id="damageDevice"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {"Add Damaged Resources"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <Label htmlFor="task-title-input" className="form-label">
                Resource Name
              </Label>
              <Select
                className={` ${
                  validation.errors.resourceName && "is-invalid red-border"
                }`}
                placeholder="Resource Name"
                value={selectedResourceName || validation?.values?.resourceName}
                isMulti={false}
                onChange={(selectedOption) => {
                  setSelectedResourceName(selectedOption);
                  validation.setFieldValue(
                    "resourceName",
                    selectedOption ? selectedOption.value : null
                  );
                  findResourceName(selectedOption.value, ["Allocation","Available","Return"]);
                }}
                options={LIST_OF_DEVICES}
                onBlur={validation.handleBlur}
                name="resourceName"
              />
              <div className="invalid-feedback">
                {validation.errors.resourceName}
              </div>
            </div>

            <div className="mb-3">
              <Label htmlFor="task-title-input" className="form-label">
                Resource No
              </Label>
              <Select
                className={` ${
                  validation.errors.resourceNo && "is-invalid red-border"
                }`}
                placeholder="Resource Number"
                value={selectedResourceNumber || validation?.values?.resourceNo}
                isMulti={false}
                onChange={(selectedOption) => {
                  setSelectedResourceNumber(selectedOption);
                  validation.setFieldValue(
                    "resourceNo",
                    selectedOption ? selectedOption?.label : null
                  );
                  setSelectedResourceId(selectedOption?.value)
                }}
                options={resourceNoData}
                onBlur={validation.handleBlur}
                name="resourceNo"
                isDisabled={isEnabled}
              />
              <div className="invalid-feedback">
                {validation.errors.resourceNo}
              </div>
            </div>

            <div className="mb-3">
              <Label htmlFor="task-title-input" className="form-label">
                Resource Holder Name
              </Label>
              <Select
                className={` ${
                  validation.errors.assignTo && "is-invalid red-border"
                }`}
                placeholder="Resource Holder Name"
                value={selectedHolderName || validation?.values?.assignTo}
                isMulti={false}
                onChange={(selectedOption) => {
                  setSelectedHolderName(selectedOption);
                  validation.setFieldValue(
                    "assignTo",
                    selectedOption ? selectedOption.value : null
                  );
                }}
                options={holderName}
                onBlur={validation.handleBlur}
                name="assignTo"
              />
              <div className="invalid-feedback">
                {validation.errors.assignTo}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Reason{" "}
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Reason"
                name="reason"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  !!(validation.touched.reason && validation.errors.reason)
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>

            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodo(false)}>
                <i className="ri-close-fill align-bottom"></i> {"Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {"Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DamageDevice;
