import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  FormFeedback,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { getId, getRole } from "../../utils/helpers/custom";
import {
  profile,
  editProfile,
  editEmpHrProfile,
  adminProfile,
  editProfileImage,
  removeProfileImage,
} from "../../api/profileApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { listOfData } from "../../api/commonApi";
import {
  ResponseStatusEnum,
  nationality,
  religian,
  relation,
} from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import userDummyImg from "../../../src/assets/image/auth-one-bg.jpg";
import Loader from "../../components/common/loader";
import { EMPLOYEE_PROFILE_IMAGE } from "../../api/apiRoutes";
import { baseURL } from "../../api/baseURL";
import ApproveRejectModal from "../../components/common/approveRejectModal";
const EditProfile = (e) => {
  const [fname, setfname] = useState("");
  const id = getId();
  // validation
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [country, setCountry] = useState([]);
  const [profileData, setProfile] = useState([]);
  const profileId = getId();
  const role = getRole();
  const [resUserDetails, setResUserDetails] = useState({});
  const [resbankdetails, setResBankDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [employeeAddressData, setEmployeeAddressData] = useState({});
  const [employeeAddressDetailData, setEmployeeAddressDetailData] = useState(
    {}
  );
  const [employeeAddressDetailData1, setEmployeeAddressDetailData1] = useState(
    {}
  );
  const [employeeAddressDetailData2, setEmployeeAddressDetailData2] = useState(
    {}
  );
  const [educationData, setEducationData] = useState({});
  const [emergencyContactData, setEmergencyContactNameData] = useState([]);
  const [modalTodo, setModalTodo] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedImage, setDeletedImage] = useState();
  const navigate = useNavigate();
  const imageUrl = `${baseURL}/${EMPLOYEE_PROFILE_IMAGE}`;

  const fetchData = () => {
    setLoading(true);
    listOfData({
      modelName: "cityModel",
      condition: {
        id: selectedState,
      },
    })
      .then((res) => {
        setCity(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });

    listOfData({
      modelName: "stateModel",
    })
      .then((res) => {
        setState(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });

    listOfData({
      modelName: "countryModel",
    })
      .then((res) => {
        setCountry(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });

    if (role === "Admin") {
      adminProfile(profileId)
        .then((resp) => {
          setProfile(resp?.data);
          if (resp?.data?.userDetails === null) {
            setResUserDetails(null);
          } else {
            setResUserDetails(resp?.data?.userDetails);
          }
          if (resp?.data?.emergencyContact?.length === 0) {
            setEmergencyContactNameData(null);
          } else {
            setEmergencyContactNameData(resp?.data?.emergencyContact);
          }
          if (resp?.data?.address === null) {
            setEmployeeAddressData(null);
          } else {
            setEmployeeAddressData(resp?.data?.address);
            setEmployeeAddressDetailData(resp?.data?.address?.city);
            setEmployeeAddressDetailData1(resp?.data?.address?.state);
            setEmployeeAddressDetailData2(resp?.data?.address?.country);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      profile(profileId)
        .then((resp) => {
          if (resp?.data?.userDetails === null) {
            setResUserDetails(null);
          } else {
            setResUserDetails(resp?.data?.userDetails);
          }
          if (resp?.data?.bankDetails === null) {
            setResBankDetails(null);
          } else {
            setResBankDetails(resp?.data?.bankDetails);
          }
          if (resp?.data?.educationDetails === null) {
            setEducationData(null);
          } else {
            setEducationData(resp?.data?.educationDetails);
          }
          if (resp?.data?.emergencyContact?.length === 0) {
            setEmergencyContactNameData(null);
          } else {
            setEmergencyContactNameData(resp?.data?.emergencyContact);
          }
          if (resp?.data?.address === null) {
            setEmployeeAddressData(null);
          } else {
            setEmployeeAddressData(resp?.data?.address);
            setEmployeeAddressDetailData(resp?.data?.address.city);
            setEmployeeAddressDetailData1(resp?.data?.address.state);
            setEmployeeAddressDetailData2(resp?.data?.address.country);
          }
          setProfile(resp?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      // validation.resetForm();
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  const [newImage, setNewImage] = useState(null);
  const uploadImage = (e) => {
    if (role === 'Admin' || role === "HR" || role === "Employee") {
      setLoading(true);
      editProfileImage(profileId, e.target.files[0])
        .then((resp) => {
          if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(resp?.data?.message);
            navigate("/profile");
          } else {
            toast.error(resp?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setNewImage(e.target.files[0]);
    }
  };

  const validationMessages = {
    contactLength: (fieldName, minLength) =>
      `${fieldName} should be ${minLength} digit.`,
    positiveNumber: (fieldName) =>
      `${fieldName} must be positive`,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const emailFormat =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const numberRegex = /^\d{10}$/;

    const createPhoneValidation = (fieldName) =>
    Yup.string()
      .required(RequiredField(fieldName))
      .matches(phoneRegExp, `${fieldName} is not valid`)
      .min(10, "Too short")
      .max(10, "Too long");

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profileData === null ? "" : profileData?.firstName || "",
      lastName: profileData === null ? "" : profileData?.lastName || "",
      email: profileData === null ? "" : profileData?.email || "",
      phoneNo: profileData === null ? "" : profileData?.phoneNo || "",
      gender: resUserDetails === null ? "" : resUserDetails?.gender || "",
      dob: resUserDetails === null ? "" : resUserDetails?.dob || "",
      religion:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : resUserDetails === null
            ? ""
            : resUserDetails?.religion || "",
      nationality:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : resUserDetails === null
            ? ""
            : resUserDetails?.nationality || "",
      personalEmail:
        resUserDetails === null ? "" : resUserDetails?.personalEmail || "",
      skypeId: resUserDetails === null ? "" : resUserDetails?.skypeId || "",
      addressLine1:
        employeeAddressData === null
          ? ""
          : employeeAddressData?.addressLine1 || "",
      addressLine2:
        employeeAddressData === null
          ? ""
          : employeeAddressData?.addressLine2 || "",
      cityId:
        role === "Admin"
          ? profileData?.address?.city?.id
          : employeeAddressData === null
            ? ""
            : employeeAddressDetailData?.id || "",
      stateId:
        role === "Admin"
          ? profileData?.address?.state?.id
          : employeeAddressData?.id === null
            ? ""
            : employeeAddressDetailData1?.id || "",
      countryId:
        role === "Admin"
          ? profileData?.address?.country?.id
          : employeeAddressData === null
            ? ""
            : employeeAddressDetailData2.id || "",
      primaryId: role !== "Admin" && emergencyContactData?.[0]?.id,
      primaryName:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : emergencyContactData === null
            ? ""
            : emergencyContactData[0]?.name || "",
      primaryContactNo:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : emergencyContactData === null
            ? ""
            : emergencyContactData?.[0]?.phoneNo || parseInt(""),
      primaryRelation:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : emergencyContactData === null
            ? ""
            : emergencyContactData?.[0]?.relation || "",
      secondaryId: role !== "Admin" && emergencyContactData?.[1]?.id,
      secondaryName:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : emergencyContactData === null
            ? ""
            : emergencyContactData?.[1]?.name || "",
      secondaryContactNo:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : emergencyContactData === null
            ? ""
            : emergencyContactData[1]?.phoneNo || "",
      secondaryRelation:
        role === "Admin"
          ? ResponseStatusEnum.NO_DATA_FOUND
          : emergencyContactData === null
            ? ""
            : emergencyContactData[1]?.relation || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(RequiredField("First name")),
      lastName: Yup.string().required(RequiredField("Last name")),
      email: Yup.string()
        .required(RequiredField("Email"))
        .email("Email is not valid", emailFormat),
      phoneNo: Yup.string()
        .required(RequiredField("Phone number"))
        .matches(
          numberRegex,
          validationMessages.contactLength("Phone number", 10)),
      gender: Yup.string().required(RequiredField("Gender")),
      dob: Yup.string().required(RequiredField("DOB")),
      religion: Yup.string().required(RequiredField("Religion")),
      nationality: Yup.string().required(RequiredField("Nationality")),
      personalEmail: Yup.string()
        .required(RequiredField("Personal Email"))
        .email("Personal Email is not valid", emailFormat),
      skypeId: Yup.string()
        .required(RequiredField("Skype ID (Email)"))
        .email("Skype ID (Email) is not valid", emailFormat),
      addressLine1: Yup.string().required(RequiredField("Address Line1")),
      addressLine2: Yup.string().required(RequiredField("Address Line2")),
      cityId: Yup.string().required(RequiredField("City")),
      stateId: Yup.string().required(RequiredField("State")),
      countryId: Yup.string().required(RequiredField("Country")),

      primaryContactNo: role !== 'Admin' ? createPhoneValidation("Primary Phone number") : Yup.string(),
      secondaryContactNo: role !== 'Admin' ? createPhoneValidation("Secondary Phone number") : Yup.string(),

      primaryName: Yup.string().required(RequiredField("Primary name")),
      primaryRelation: Yup.string().required(RequiredField("Primary relation")),
      secondaryName: Yup.string().required(RequiredField("Secondary name")),
      secondaryRelation: Yup.string().required(RequiredField("Secondary relation")),
    }),
    onSubmit: (values) => {
      if (role === "Admin") {
        if (newImage !== null) {
          setLoading(true);
          editProfile(profileId, {
            user: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNo: values.phoneNo,
            },
            userDetails: {
              gender: values.gender,
              dob: values.dob,
              personalEmail: values.personalEmail,
              skypeId: values.skypeId,
            },
            address: {
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              cityId: parseInt(values.cityId),
              stateId: parseInt(values.stateId),
              countryId: parseInt(values.countryId),
            },
          })
            .then((resp) => {
              if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
                toast.success(resp?.data?.message);
                navigate("/profile");
              } else {
                toast.error(resp?.data?.message);
              }
            })
            .catch((err) => {
              setLoading(false);
              return err;
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(true);
          editProfile(profileId, {
            user: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNo: values.phoneNo,
            },
            userDetails: {
              gender: values.gender,
              dob: values.dob,
              personalEmail: values.personalEmail,
              skypeId: values.skypeId,
            },
            address: {
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              cityId: parseInt(values.cityId),
              stateId: parseInt(values.stateId),
              countryId: parseInt(values.countryId),
            },
          })
            .then((resp) => {
              if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
                toast.success(resp?.data?.message);
                navigate("/profile");
              } else {
                toast.error(resp?.data?.message);
              }
            })
            .catch((err) => {
              setLoading(false);
              return err;
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        setLoading(true);
        editEmpHrProfile(profileId, {
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNo: values.phoneNo,
          },
          userDetails: {
            gender: values.gender,
            dob: values.dob,
            profileImage: newImage,
            religion: values.religion,
            nationality: values.nationality,
            personalEmail: values.personalEmail,
            skypeId: values.skypeId,
          },
          address: {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            cityId: parseInt(values.cityId),
            stateId: parseInt(values.stateId),
            countryId: parseInt(values.countryId),
          },
          emergencyContacts: [
            {
              id: values.primaryId,
              name: values.primaryName,
              phoneNo: values.primaryContactNo,
              relation: values.primaryRelation,
            },
            {
              id: values.secondaryId,
              name: values.secondaryName,
              phoneNo: values.secondaryContactNo,
              relation: values.secondaryRelation,
            },
          ],
        })
          .then((resp) => {
            if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(resp?.data?.message);
              navigate("/profile");
            } else {
              toast.error(resp?.data?.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
        validation.resetForm();
      }
    },
  });

  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const handleCountryChange = (event) => {
    validation.setFieldValue("countryId", event.target.value);
    setSelectedCountry(event.target.value || validation.values.countryId);

  };
  const handleStateChange = (event) => {
    validation.setFieldValue("stateId", event.target.value);
    setSelectedState(event.target.value);
    listOfData({
      modelName: "cityModel",
      condition: {
        stateId: event.target.value,
      }
    })
      .then((res) => {
        setCity(res.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });
  };

  const handleDelete = (row) => {
    setSelectedRow({ ...row, action: 'to remove this image' });
    setDeletedImage(row);
    setDeleteModal(true);
  };

  const handleDeleteTodo = () => {
    setLoading(true);
    setDeleteModal(false);
    removeProfileImage(id, { oldImage: deletedImage }).then((res) => {
      if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
        toast.success(res?.data?.message);
        navigate("/profile");
      } else {
        toast.err(res.data.message);
      }
    }).catch((err) => { return err }).finally(() => { setLoading(false); })
  };

  document.title = "Profile Update";

  return (
    <React.Fragment>
      <ApproveRejectModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
        action={selectedRow ? selectedRow.action : ''} />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <div className="position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg profile-setting-img">
                <img
                  src={
                    profileData?.userDetails?.profileImage
                      ? `${imageUrl}/${profileData?.userDetails?.profileImage}`
                      : userDummyImg
                  }
                  onError={(e) => {
                    e.target.src = userDummyImg; // Set a fallback image when an error occurs
                  }}
                  className="profile-wid-img"
                  alt=""
                />
              </div>
            </div>
            <Row>
              <Col xxl={3}>
                <Card className="mt-n5">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                        <img
                          src={
                            profileData?.userDetails?.profileImage
                              ? `${imageUrl}/${profileData?.userDetails?.profileImage}`
                              : userDummyImg
                          }
                          onError={(e) => {
                            e.target.src = userDummyImg; // Set a fallback image when an error occurs
                          }}
                          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile"
                        />
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                          <button
                            type="button"
                            className="btn profile-edit-img-file-input"
                            onClick={() => { setModalTodo(true) }}
                          >
                            <Label
                              htmlFor="profile-img-file-input"
                              className="profile-photo-edit avatar-xs"
                            >
                              <span className="avatar-title rounded-circle bg-light text-body">
                                <i className="ri-pencil-fill align-bottom" />
                              </span>
                            </Label>
                          </button>
                        </div>
                      </div>
                      <h5 className="fs-16 mb-1">{`${profileData?.firstName} ${profileData?.lastName}`}</h5>
                      <p className="text-muted mb-0">{role}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xxl={9}>
                <Card className="mt-xxl-n5">
                  <CardBody className="p-4">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <CardHeader>
                              <h5>Personal Details</h5>
                            </CardHeader>
                            <Col lg={6} className="pt-3">
                              <div className="mb-3">
                                <Label
                                  htmlFor="firstnameInput"
                                  className="form-label"
                                >
                                  FirstName
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="firstnameInput"
                                  placeholder="Enter your firstname"
                                  name="firstName"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.firstName || ""}
                                  invalid={
                                    !!(validation.touched.firstName &&
                                      validation.errors.firstName)
                                  }
                                />
                                {validation.touched.firstName &&
                                  validation.errors.firstName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.firstName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6} className="pt-3">
                              <div className="mb-3">
                                <Label
                                  htmlFor="lastnameInput"
                                  className="form-label"
                                >
                                  Last Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="lastnameInput"
                                  name="lastName"
                                  placeholder="Enter your lastname"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.lastName || ""}
                                  invalid={
                                    !!(validation.touched.lastName &&
                                      validation.errors.lastName)
                                  }
                                />
                                {validation.touched.lastName &&
                                  validation.errors.lastName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.lastName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="phonenumberInput"
                                  className="form-label"
                                >
                                  Phone Number
                                </Label>
                                <Input
                                  type="tel"
                                  min="0"
                                  className="form-control"
                                  id="phonenumberInput"
                                  name="phoneNo"
                                  oninput="validity.valid||(value='');"
                                  placeholder="Enter your phone number"
                                  maxLength={10}
                                  validate={{ required: { value: true } }}
                                  onChange={(e) => {
                                    const input = e?.target?.value?.replace(/\D/g, '');
                                    e.target.value = input;
                                    validation.handleChange(e);
                                    }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.phoneNo || ""}
                                  invalid={
                                    !!(validation.touched.phoneNo &&
                                      validation.errors.phoneNo)
                                  }
                                />
                                {validation.touched.phoneNo &&
                                  validation.errors.phoneNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.phoneNo}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="designationInput"
                                  className="form-label"
                                >
                                  Personal Email Address
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="emailInput"
                                  name="personalEmail"
                                  placeholder="Enter your personal email"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.personalEmail || ""}
                                  invalid={
                                    !!(validation.touched.personalEmail &&
                                      validation.errors.personalEmail)
                                  }
                                />
                                {validation.touched.personalEmail &&
                                  validation.errors.personalEmail ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.personalEmail}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="skillsInput"
                                  className="form-label"
                                >
                                  Gender
                                </Label>
                                <select
                                  className={`form-select ${validation.errors.gender && "is-invalid"
                                    }`}
                                  aria-label="Default select example"
                                  id="selectreport"
                                  onChange={validation.handleChange}
                                  name="gender"
                                  validate={{ required: { value: true } }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.gender}
                                >
                                  <option selected hidden>
                                    Select your gender{" "}
                                  </option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                                <div className="invalid-feedback">
                                  {validation.errors.gender}
                                </div>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="JoiningdatInput"
                                  className="form-label"
                                >
                                  Date of Birth
                                </Label>
                                <Flatpickr
                                  className={`form-control ${validation.errors.dob && "is-invalid"
                                    }`}
                                  id="datepicker-publish-input"
                                  // onChange={(dateOfJoin) => validation.setFieldValue("dateOfJoin",dateOfJoin)}
                                  placeholder="Select a date"
                                  name="dob"
                                  validate={{ required: { value: true } }}
                                  onBlur={validation.handleBlur} // this all comments are needed
                                  value={validation.values.dob}
                                  invalid={
                                    !!(validation.touched.dob &&
                                      validation.errors.dob)
                                  }
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: "today",
                                    onChange: function (
                                      selectedDates,
                                      dateStr,
                                      instance
                                    ) {
                                      validation
                                        .setFieldValue("dob", dateStr)
                                        .then((res) => res)
                                        .catch((err) => err);
                                    },
                                  }}
                                />
                                <div className="invalid-feedback">
                                  {validation.errors.dob}
                                </div>
                              </div>
                            </Col>
                            {role !== "Admin" && (
                              <>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="cityInput"
                                      className="form-label"
                                    >
                                      Nationality
                                    </Label>
                                    <select
                                      className={`form-select ${validation.errors.nationality &&
                                        "is-invalid"
                                        }`}
                                      aria-label="Default select example"
                                      id="selectreport"
                                      name="nationality"
                                      validate={{ required: { value: true } }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.nationality}
                                    >
                                      <option selected hidden>
                                        Select Nationality
                                      </option>
                                      {nationality?.map((res) => {
                                        return (
                                          <>
                                            <option value={res}>{res}</option>
                                          </>
                                        );
                                      })}
                                    </select>
                                    <div className="invalid-feedback">
                                      {validation.errors.nationality}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="countryInput"
                                      className="form-label"
                                    >
                                      Religious
                                    </Label>
                                    <select
                                      className={`form-select ${validation.errors.religion &&
                                        "is-invalid"
                                        }`}
                                      aria-label="Default select example"
                                      id="selectreport"
                                      name="religion"
                                      validate={{ required: { value: true } }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.religion}
                                    >
                                      <option selected hidden>
                                        Select Religion
                                      </option>
                                      {religian?.map((res) => {
                                        return (
                                          <>
                                            <option value={res}>{res}</option>
                                          </>
                                        );
                                      })}
                                    </select>
                                    <div className="invalid-feedback">
                                      {validation.errors.religion}
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                            <CardHeader>
                              <h5>Address Details</h5>
                            </CardHeader>
                            <Col lg={6} className="pt-3">
                              <div className="mb-3">
                                <Label
                                  htmlFor="designationInput"
                                  className="form-label"
                                >
                                  Address Line1
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="designationInput"
                                  placeholder="Enter your address Line1"
                                  name="addressLine1"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.addressLine1 || ""}
                                  invalid={
                                    validation.touched.addressLine1 &&
                                      validation.errors.addressLine1
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.addressLine1 &&
                                  validation.errors.addressLine1 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.addressLine1}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6} className="pt-3">
                              <div className="mb-3">
                                <Label
                                  htmlFor="designationInput"
                                  className="form-label"
                                >
                                  Address Line2
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="designationInput"
                                  placeholder="Enter your address Line2"
                                  name="addressLine2"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.addressLine2 || ""}
                                  invalid={
                                    validation.touched.addressLine2 &&
                                      validation.errors.addressLine2
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.addressLine2 &&
                                  validation.errors.addressLine2 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.addressLine2}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="designationInput"
                                  className="form-label"
                                >
                                  Country
                                </Label>
                                <select
                                  className={`form-select ${validation.errors.countryId && "is-invalid"
                                    }`}
                                  aria-label="Default select example"
                                  id="selectreport"
                                  name="countryId"
                                  validate={{ required: { value: true } }}
                                  onChange={handleCountryChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.countryId ||
                                    selectedCountry
                                  }
                                >
                                  <option selected hidden>
                                    Select Country
                                  </option>
                                  {country?.map((res) => {
                                    return (
                                        <option value={res.id} key={res?.id}>
                                          {res?.countryName}
                                        </option>
                                    );
                                  })}
                                </select>
                                <div className="invalid-feedback">
                                  {validation.errors.countryId}
                                </div>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="designationInput"
                                  className="form-label"
                                >
                                  State
                                </Label>
                                <select
                                  className={`form-select ${validation.errors.stateId && "is-invalid"
                                    }`}
                                  aria-label="Default select example"
                                  id="selectreport"
                                  name="stateId"
                                  validate={{ required: { value: true } }}
                                  onChange={handleStateChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.stateId ||
                                    selectedState
                                  }
                                >
                                  <option selected hidden>
                                    Select State
                                  </option>
                                  {state?.map((res) => {
                                    return (
                                        <option value={res.id} key={res?.id}>
                                          {res.stateName}
                                        </option>
                                    );
                                  })}
                                </select>
                                <div className="invalid-feedback">
                                  {validation.errors.stateId}
                                </div>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="websiteInput1"
                                  className="form-label"
                                >
                                  City
                                </Label>
                                <select
                                  className={`form-select ${validation.errors.cityId && "is-invalid"
                                    }`}
                                  aria-label="Default select example"
                                  id="selectreport"
                                  name="cityId"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.cityId}
                                >
                                  <option selected hidden>
                                    Select City
                                  </option>

                                  {city?.map((res) => {
                                    return (
                                        <option value={res.id} key={res?.id}>
                                          {res?.cityName}
                                        </option>
                                    );
                                  })}
                                </select>
                                <div className="invalid-feedback">
                                  {validation.errors.cityId}
                                </div>
                              </div>
                            </Col>
                            <CardHeader>
                              <h5>Office Details</h5>
                            </CardHeader>
                            <Col lg={6} className="pt-3">
                              <div className="mb-3">
                                <Label
                                  htmlFor="designationInput"
                                  className="form-label"
                                >
                                  Email Address
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="designationInput"
                                  placeholder="Enter Email Address"
                                  name="email"
                                  disabled
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                      validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                  validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6} className="pt-3">
                              <div className="mb-3">
                                <Label
                                  htmlFor="designationInput"
                                  className="form-label"
                                >
                                  Skype ID (Email)
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="designationInput"
                                  placeholder="Enter Skype ID (Email)"
                                  name="skypeId"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.skypeId || ""}
                                  invalid={
                                    validation.touched.skypeId &&
                                      validation.errors.skypeId
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.skypeId &&
                                  validation.errors.skypeId ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.skypeId}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {role !== "Admin" && (
                              <>
                                <CardHeader>
                                  <h5>Emergency Details</h5>
                                </CardHeader>
                                <lable className="pt-2">
                                  Primary Emergency Contact
                                </lable>
                                <Col lg={4} className="pt-3">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="designationInput"
                                      className="form-label"
                                    >
                                      Name{" "}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="designationInput"
                                      placeholder="Enter primary name"
                                      name="primaryName"
                                      validate={{ required: { value: true } }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.primaryName || ""
                                      }
                                      invalid={
                                        validation.touched.primaryName &&
                                          validation.errors.primaryName
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.primaryName &&
                                      validation.errors.primaryName ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.primaryName}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={4} className="pt-3">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="relationInput"
                                      className="form-label"
                                    >
                                      Relation
                                    </Label>
                                    <select
                                      className={`form-select ${validation.errors.primaryRelation &&
                                        "is-invalid"
                                        }`}
                                      aria-label="Default select example"
                                      id="selectreport"
                                      name="primaryRelation"
                                      validate={{ required: { value: true } }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.primaryRelation}
                                    >
                                      <option selected hidden>
                                        Select Relation
                                      </option>
                                      {relation?.map((res) => {
                                        return (
                                            <option value={res} key={res}>{res}</option>
                                        );
                                      })}
                                    </select>
                                    <div className="invalid-feedback">
                                      {validation.errors.primaryRelation}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4} className="pt-3">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="designationInput"
                                      className="form-label"
                                    >
                                      Phone No
                                    </Label>
                                    <Input
                                      type="tel"
                                      className="form-control"
                                      id="designationInput"
                                      placeholder="Enter primary phone number"
                                      name="primaryContactNo"
                                      validate={{ required: { value: true } }}
                                      onChange={(e) => {
                                      const input = e?.target?.value?.replace(/\D/g, '');
                                      e.target.value = input;
                                      validation.handleChange(e);
                                      }}
                                      onBlur={validation.handleBlur}
                                      maxLength={10}
                                      value={
                                        validation.values.primaryContactNo || ""
                                      }
                                      invalid={
                                        validation.touched.primaryContactNo &&
                                          validation.errors.primaryContactNo
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.primaryContactNo &&
                                      validation.errors.primaryContactNo ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.primaryContactNo}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                                <lable className="pt-2">
                                  Secondary Emergency Contact
                                </lable>
                                <Col lg={4} className="pt-3">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="designationInput"
                                      className="form-label"
                                    >
                                      Name{" "}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="designationInput"
                                      placeholder="Enter secondary name"
                                      name="secondaryName"
                                      validate={{ required: { value: true } }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secondaryName || ""
                                      }
                                      invalid={
                                        validation.touched.secondaryName &&
                                          validation.errors.secondaryName
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.secondaryName &&
                                      validation.errors.secondaryName ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.secondaryName}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={4} className="pt-3">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="relationInput"
                                      className="form-label"
                                    >
                                      Relation
                                    </Label>
                                    <select
                                      className={`form-select ${validation.errors.secondaryRelation &&
                                        "is-invalid"
                                        }`}
                                      aria-label="Default select example"
                                      id="selectreport"
                                      name="secondaryRelation"
                                      validate={{ required: { value: true } }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secondaryRelation
                                      }
                                    >
                                      <option selected hidden>
                                        Select Relation
                                      </option>
                                      {relation?.map((res) => {
                                        return (
                                            <option value={res} key={res}>{res}</option>
                                        );
                                      })}
                                    </select>
                                    <div className="invalid-feedback">
                                      {validation.errors.secondaryRelation}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4} className="pt-3">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="designationInput"
                                      className="form-label"
                                    >
                                      Phone No
                                    </Label>
                                    <Input
                                      type="tel"
                                      min="0"
                                      oninput="validity.valid||(value='');"
                                      className="form-control"
                                      id="designationInput"
                                      placeholder="Enter secondary phone number"
                                      name="secondaryContactNo"
                                      validate={{ required: { value: true } }}
                                      onChange={(e) => {
                                      const input = e?.target?.value?.replace(/\D/g, '');
                                      e.target.value = input;
                                      validation.handleChange(e);
                                      }}
                                      onBlur={validation.handleBlur}
                                      maxLength={10}
                                      value={
                                        validation.values.secondaryContactNo ||
                                        ""
                                      }
                                      invalid={
                                        validation.touched.secondaryContactNo &&
                                          validation.errors.secondaryContactNo
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.secondaryContactNo &&
                                      validation.errors.secondaryContactNo ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.secondaryContactNo}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </>
                            )}
                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Updates
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-success"
                                  onClick={() => {
                                    navigate("/profile");
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              id="createTask"
              isOpen={modalTodo}
              toggle={toggle}
              modalClassName="zoomIn"
              centered
              tabIndex="-1"
            >
              <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
                {" "}
                {"Profile Photo"}
              </ModalHeader>
              <ModalBody>
                <div id="task-error-msg" className="alert alert-danger py-2"></div>
                <Form
                  id="creattask-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <input type="hidden" id="taskid-input" className="form-control" />
            
                  <div className="p-4 card-body">
                    <div className="text-center">
                      <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                        <img className="img-thumbnail  user-profile-image" onError={(e) => {
                          e.target.src = userDummyImg; // Set a fallback image when an error occurs
                        }} alt="Avatar" width="200" src={
                          profileData?.userDetails?.profileImage
                            ? `${imageUrl}/${profileData?.userDetails?.profileImage}`
                            : userDummyImg
                        } />
                      </div>
                    </div>
                  </div>


                  <div className="flex-grow-1 row">
                    <div className="col-sm-1 ms-2 fs-2">
                      <i className="ri-checkbox-circle-fill text-success"></i>
                    </div>
                    <div className="col-sm-2 ms-1 fs-2">
                      File :
                    </div>
                    <div className="col-sm-5 ms-2">
                      <Input
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        onChange={(e) => uploadImage(e)}
                      />
                    </div>
                    <div className="col-sm-1"></div>
                    {profileData?.userDetails?.profileImage && <div className="col-sm-2 ms-2">
                      <button type="button"
                        className="btn btn-md btn-danger remove-list profile-edit-img-file-input profile-photo-edit"
                        onClick={() => handleDelete(profileData?.userDetails?.profileImage)}
                      >
                        <i className="ri-close-circle-line"></i>
                      </button>
                    </div>}
                  </div>
                  <div className="hstack gap-2 justify-content-end p-3">
                    <button
                      type="button"
                      className="btn btn-ghost-success"
                      onClick={() => setModalTodo(false)}
                    >
                      <i className="ri-close-fill align-bottom"></i> Close
                    </button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
