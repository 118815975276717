import React, { useState, useMemo } from "react";
import { Card, CardBody, Col, Container, Row, Spinner, Form } from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import NoResultsMessage from "../../components/noResultMessage";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { filterOfResources } from "../../api/resourcesApi";

const FilterOfResource = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRam, setSelectedRam] = useState(null);
  const [selectedOs, setSelectedOs] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const resourceColumns = useMemo(() => [
    {
      Header: "Resource Name",
      accessor: "resourceName",
      filterable: false,
      Cell: (cell) => {
        return cell?.row?.original?.resourceName;
      },
    },
    {
      Header: "Resource Number",
      accessor: "resourcesDetails.resourceNo",
      filterable: false,
      Cell: (cell) => {
        return cell?.row?.original?.resourceNo;
      },
    },
    {
      Header: "OS Type",
      accessor: "resourcesDetails.osType",
      filterable: false,
      Cell: (cell) => {
        let output = cell?.row?.original?.resourceConfiguration?.osType;
        return output || "---";
      },
    },
    {
      Header: "Ram",
      accessor: "resourcesDetails.RAM",
      filterable: false,
      Cell: (cell) => {
        let output = cell?.row?.original?.resourceConfiguration?.RAM;
        return output || "---";
      },
    },
  ]);

  const validationFilter = useFormik({
    enableReinitialize: true,

    initialValues: {
      resourceName: "",
      RAM: "",
      status: "",
      osType: "",
    },
    validationSchema: Yup.object({
      resourceName: Yup.string("It Should be text"),
      RAM: Yup.string("It Should be text"),
      status: Yup.string("It Should be text"),
      osType: Yup.string("It Should be text"),
    }),
    onSubmit: (value) => {
      setLoading(true);

      filterOfResources({ ...value })
        .then((res) => {
          if (res?.status === 200) {
            setFilteredData(res?.data?.data);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
          validationFilter.resetForm();
        });
    },
  });

  const ResourceType = [
    { value: "Laptop", label: "Laptop" },
    { value: "Mouse", label: "Mouse" },
    { value: "Bag", label: "Bag" },
    { value: "Mouse Pad", label: "Mouse Pad" },
    { value: "KeyBoard", label: "KeyBoard" },
    { value: "", label: "All" },
  ];

  const ramType = [
    {
      label: "Select RAM",
      value: "",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "6",
      label: "6",
    },
    {
      value: "8",
      label: "8",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "32",
      label: "32",
    },
  ];

  const osType = [
    {
      label: "Select OS Type",
      value: "",
    },
    {
      value: "Window",
      label: "Window",
    },
    {
      value: "Linux",
      label: "Linux",
    },
    {
      value: "IOS",
      label: "IOS",
    },
  ];

  const resourceDetails = Array.isArray(ResourceType)
    ? ResourceType?.map((status) => ({
        value: status.value,
        label: status.label,
      }))
    : null;

  const ramDetails = Array.isArray(ramType)
    ? ramType?.map((status) => ({
        value: status.value,
        label: status.label,
      }))
    : null;

  const osDetails = Array.isArray(osType)
    ? osType?.map((status) => ({
        value: status.value,
        label: status.label,
      }))
    : null;

  document.title = "Available Resource";

  return (
    <div className="page-content">
      {loading === false ? (
        <Container fluid>
          <BreadCrumb title="Allocate Resources" pageTitle="HRMS" />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">
                Filter of Available Resources
              </h5>
            </div>

            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <Link to="/configuration">
                    <button
                      className="btn btn-primary createTask"
                      type="button">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
          <Card>
            <CardBody>
              <Row className="g-2">
                <Col xl={10}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationFilter.handleSubmit();
                      return false;
                    }}>
                    <Row>
                      <Col lg={3} md={6}>
                        <div className="p-2">
                          <Select
                            className={` ${
                              validationFilter.errors.resourceName &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Resource"
                            value={
                              selectedResource ||
                              validationFilter?.values?.resourceName
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedResource(selectedOption);
                              validationFilter.setFieldValue(
                                "resourceName",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={resourceDetails}
                            onBlur={validationFilter.handleBlur}
                            name="resourcename"
                          />
                        </div>
                      </Col>
                      <Col lg={3} md={6}>
                        <div className="p-2">
                          <Select
                            className={` ${
                              validationFilter.errors.RAM &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Ram"
                            value={selectedRam || validationFilter?.values?.RAM}
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedRam(selectedOption);
                              validationFilter.setFieldValue(
                                "RAM",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={ramDetails}
                            onBlur={validationFilter.handleBlur}
                            name="RAM"
                          />
                        </div>
                      </Col>
                      <Col lg={3} md={6}>
                        <div className="p-2">
                          <Select
                            className={` ${
                              validationFilter.errors.osType &&
                              "is-invalid red-border"
                            }`}
                            placeholder="OS type"
                            value={
                              selectedOs || validationFilter?.values?.osType
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedOs(selectedOption);
                              validationFilter.setFieldValue(
                                "osType",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={osDetails}
                            onBlur={validationFilter.handleBlur}
                            name="osType"
                          />
                        </div>
                      </Col>
                      <Col lg={2} md={6}>
                        <div className="m-2">
                          <button
                            className="btn btn-primary createTask"
                            type="submit"
                            onClick={validationFilter.handleSubmit}>
                            {!loading ? (
                              "SEARCH"
                            ) : (
                              <div className="d-inline-flex align-items-center">
                                <Spinner size="sm" className="me-2" />
                                Loading...
                              </div>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {filteredData?.length ? (
                <TableContainer
                  columns={resourceColumns}
                  data={filteredData || []}
                  isAddUserList={false}
                  customPageSize={5}
                  divClass="table-responsive table-card mb-1"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light text-muted"
                />
              ) : (
                <NoResultsMessage />
              )}
            </CardBody>
          </Card>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default FilterOfResource;
