import React, { useState, useCallback, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import {
  listOfAssignProjects,
  addProject,
  addTasksheet,
  editProject,
  editTaskSheetHR,
  listOFTaskSheet,
  listOfProject,
  listOfTimesheet,
  viewTasksheet,
  listOfTasks,
} from "../../api/tasksheetApi";
import { getId, getRole } from "../../utils/helpers/custom";
import { dateFormat, dateFormatChange } from "../../utils/helpers/common";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import Select from "react-select";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { listOfData } from "../../api/commonApi";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import NoResultsMessage from "../../components/noResultMessage";
import { TIME_ZERO } from "../../utils/helpers/enums";

const Status = ({ status }) => {
  switch (status) {
    case "Completed":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    case "Rejected":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Approved":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
  }
};

const TimeSheet = () => {
  const id = getId();
  const role = getRole();

  const [selectedTask, setSelectedTask] = useState(null);
  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [modalProjectName, setModalProjectName] = useState(false);
  const [modalTaskSheet, setModalTaskSheet] = useState(false);
  const [tasksheetInnerData, setTaskSheetInnerData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [selectedMultiDevloper, setselectedMultiDevloper] = useState([]);
  const [listOfDataState, setListOfData] = useState();
  const [techListState, setTechListState] = useState();
  const [selectedtechListState, setselectedTechListState] = useState();
  const [listOfProjectState, setListOfProjectState] = useState();
  const [listOfTasksheetData, setListOfTaskSheetData] = useState();
  const [listFilteredTaskSheetData, setFilteredTaskSheetData] = useState();
  const [listOfTimeSheet, setListOfTimeSheet] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isProjectEdit, setIsProjectEdit] = useState(false);
  const [editTaskCellData, setEditTaskCellData] = useState();
  const [editProjectData, seteditProjectData] = useState();
  const [listOfAssignProject, setListOfAssignProject] = useState(null);
  const [listOfTask, setlistOfTask] = useState();

  const fetchData = () => {
    setLoading(true);
    viewTasksheet(id)
      .then((response) => {
        setTaskSheetInnerData(response?.data?.data[0]?.taskSheet);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    listOfTimesheet({
      userId: id,
    })
      .then((res) => {
        setListOfTimeSheet(res?.data?.data?.listOfTaskSheet);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);

  useEffect(() => {
    if (role === "HR") {
      listOfProject({
        condition: {
          addedBy: id,
        },
      })
        .then((response) => {
          setListOfProjectState(response?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
    listOfAssignProjects()
      .then((res) => setListOfAssignProject(res?.data?.data))
      .catch((err) => setLoading(false));
  }, []);

  useEffect(() => {
    listOFTaskSheet({
      userId: id,
    })
      .then((res) => {
        setListOfTaskSheetData(res?.data?.data?.listOfTaskSheet);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
      fetchProjectData();
  }, []);

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  const techObj = techListState?.map((res) => {
    return {
      value: `${res?.techName}`,
      label: `${res?.techName}`,
    };
  });

  const developerObj = listOfDataState?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.firstName} ${res?.lastName}`,
    };
  });

  function handleMultiDevelop(selectedMultiDevloper) {
    setselectedMultiDevloper(selectedMultiDevloper);
  }

  const toggle = useCallback(() => {
    newAddTasksheet.resetForm();
    if (modalTodo) {
      setModalTodo(false);
      newAddTasksheet.resetForm();
      setModalTodo(null);
    } else {
      newAddTasksheet.resetForm();
      setModalTodo(true);
    }
  }, [modalTodo]);

  function fetchProjectData(){
    listOfData({
      modelName: "authUserModel",
      condition: {
        isDeleted: false,
      },
      selectionCriteria: ["id", "firstName", "lastName"],
    }).then((res) => {
      setListOfData(res?.data);
    });
    listOfData({
      modelName: "technologyModel",
      selectionCriteria: ["id", "techName"],
    })
      .then((res) => {
        setTechListState(res?.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }


  //Add Project Details from HR side
  const addProjectName = () => {
    fetchProjectData()
    setselectedMultiDevloper("");
    setselectedTechListState("");
    setIsProjectEdit(false);
    seteditProjectData("");
    setTodo("");
    setModalProjectName(!modalProjectName);
    setTodo([]);
    projectToggle();
  };

  const projectToggle = useCallback(() => {
    if (modalProjectName) {
      setselectedMultiDevloper("");
      setModalProjectName(false);
      addProjectValidation.resetForm();
      setTodo(null);
    } else {
      setModalProjectName(true);
    }
  }, [modalProjectName]);

  const TaskSheetToggle = useCallback(() => {
    if (modalTaskSheet) {
      setModalTaskSheet(false);
      validation.resetForm();
      setTodo(null);
    } else {
      setModalTaskSheet(true);
    }
  }, [modalTaskSheet]);

  //meomo
  const QueryColumns = useMemo(() => [
    {
      Header: "Serial No",
      filterable: false,
      Cell: ({ row }) => row?.index + 1,
    },
    {
      Header: "Project Name",
      accessor: "projectTask",
      filterable: false,
    },
    {
      Header: "Task Name",
      accessor: "taskSheet.taskName",
      filterable: false,
    },
    {
      Header: "Task Details",
      accessor: "detailsOfTask",
      filterable: false,
    },
    {
      Header: "Task Status",
      accessor: "taskStatus",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell.value} />;
      },
    },
    {
      Header: "Taken Time",
      accessor: "takenTime",
      Cell: (cell) => {
        return (
          <div>
            {`${cell.row.original.takenHours}:${cell.row.original.takenMinutes}:00`}
          </div>
        );
      },
      filterable: false,
    },
    {
      Header: "Date",
      accessor: "date",
      filterable: false,
      Cell: (items) => {
        return <div>{dateFormat(items.row.original.date)}</div>;
      },
    },
    {
      Header: "Review Status",
      accessor: "reviewStatus",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell.value} />;
      },
    },
    {
      Header: "Reason",
      accessor: "reason",
      filterable: false,
      Cell: (cell) => {
        return (
          <div>
            {cell.row.original.reason === null ? "-" : cell.row.original.reason}
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            {cellProps?.row?.original?.reviewStatus !== "Approved" ? (
              <div className="hstack gap-2">
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="Edit Timesheet"
                  anchorId={`editAction-${cellProps.row.index}`}
                />
                <Link
                  to={`/timesheet/edit-timesheet/${cellProps?.row?.original?.id}`}
                  className="btn btn-sm btn-soft-info edit-list"
                  id={`editAction-${cellProps.row.index}`}>
                  <i className="ri-pencil-fill align-bottom" />
                </Link>
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ]);

  //TaskSheet Data

  const columns = useMemo(() => [
    {
      Header: "Sr.no",
      Cell: ({ row }) => row?.index + 1,
    },
    {
      Header: "Task Name",
      accessor: "taskSheet.taskName",
      filterable: false,
    },
    {
      Header: "Details Of Task",
      accessor: "detailsOfTask",
      filterable: false,
    },
    {
      Header: "Taken Time",
      accessor: "takenTime",
      Cell: (cell) => {
        return (
          <div>
            {`${cell.row.original.takenHours}:${cell.row.original.takenMinutes}:00`}
          </div>
        );
      },
      filterable: false,
    },
    {
      Header: "Task Status",
      accessor: "taskStatus",
      filterable: false,
    },
    {
      Header: "Date",
      Cell: (items) => {
        return <div>{dateFormat(items.row.original.date)}</div>;
      },
    },

    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <Link
                to={`/timesheet/edit-timesheet/${cellProps?.row?.original?.id}`}
                id={`editAction-${cellProps.row.index}`}>
                <button className="btn btn-sm btn-soft-info edit-list">
                  <i className="ri-pencil-fill align-bottom" />
                </button>
              </Link>
            </div>
          </div>
        );
      },
    },
  ]);

  const handleProjectEdit = (data) => {
    setselectedTechListState(data?.technologies);
    projectToggle();
    seteditProjectData(data);
    setIsProjectEdit(true);
    setselectedMultiDevloper(
      data?.ProjectAssignTo.map((data, key) => {
        return {
          value: data?.authUser?.id,
          label: data?.authUser?.developerName,
        };
      })
    );
  };

  const projectColumns = useMemo(() => [
    {
      Header: "Sr.no",
      Cell: ({ row }) => row?.index + 1,
    },
    {
      Header: "Project Name",
      accessor: "projectName",
      filterable: false,
    },
    {
      Header: "Total Developer",
      accessor: "assignToCount",
      filterable: false,
    },
    {
      Header: "Description",
      accessor: "description",
      filterable: false,
    },
    {
      Header: "Technologies",
      accessor: "technologies",
      filterable: false,
    },
    {
      Header: "Duration(Months)",
      accessor: "duration",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() => handleProjectEdit(cellProps.row.original)}>
                <i className="ri-pencil-fill align-bottom" />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const inputTechnologyString = selectedtechListState || null;
  const techListSplitintoArray = inputTechnologyString?.split(",");
  const arrayOfTechLisObjects = techListSplitintoArray?.map((technologies) => ({
    technologies,
  }));
  const selectedTechObj = arrayOfTechLisObjects?.map((res) => {
    return {
      value: `${res?.technologies}`,
      label: `${res?.technologies}`,
    };
  });

  const addProjectValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: editProjectData?.description || "",
      duration: editProjectData?.duration || "",
      projectName: editProjectData?.projectName || "",
      assignTo: selectedMultiDevloper || "",
      technologies: selectedTechObj || "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required(RequiredField("Description")),
      duration: Yup.string().required(RequiredField("Duration")),
      projectName: Yup.string().required(RequiredField("Project name")),
      assignTo: Yup.array().required(RequiredField("Developer name")),
      technologies: Yup.array().required(RequiredField("Technologies")),
    }),
    onSubmit: (values) => {
      setModalProjectName(false);
      const selectedValues = values.assignTo.map((option) => option.value);
      const selectedTechValues = values.technologies.map(
        (option) => option.value
      );
      const payload = {
        description: values.description,
        duration: values.duration,
        projectName: values.projectName,
        assignTo: selectedValues,
        addedBy: parseInt(id),
        technologies: selectedTechValues.toString(),
      };
      if (isProjectEdit) {
        editProject({ id: editProjectData.id, ...payload })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              setLoading(true);
              toast.success(response?.data?.message);
              addProjectValidation.resetForm();
              listOfProject({
                modelName: "projectModel",
                condition: {
                  addedBy: id,
                },
              })
                .then((response) => {
                  setLoading(true);
                  setListOfProjectState(response?.data?.data);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                });
              setModalProjectName(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err?.data?.message);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        addProject(payload)
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              addProjectValidation.resetForm();
              listOfProject({
                modelName: "projectModel",
                condition: {
                  addedBy: id,
                },
              })
                .then((response) => {
                  setLoading(true);
                  setListOfProjectState(response?.data?.data);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                });
              setModalProjectName(false);
              setLoading(true);
            } else {
              toast.error(response?.data?.message);
              setLoading(false);
              setModalProjectName([]);
            }
          })
          .catch((err) => {
            setLoading(false);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
        addProjectValidation.resetForm();
      }
    },
  });

  const newAddTasksheet = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectTask: editTaskCellData?.projectTask || "",
      detailsOfTask: editTaskCellData?.detailsOfTask || "",
      taskStatus: editTaskCellData?.taskStatus || "",
      takenTime: editTaskCellData?.takenTime || "",
      date: editTaskCellData?.date || "",
      taskSheetId: editTaskCellData?.taskSheetId || "",
      takenHours: editTaskCellData?.takenHours || "",
      takenMinutes: editTaskCellData?.takenMinutes || "",
      dailyStatus: editTaskCellData?.dailyStatus || "",
    },
    validationSchema: Yup.object({
      detailsOfTask: Yup.string().required(RequiredField("Details of task")),
      projectTask: Yup.string().required(RequiredField("Project task")),
      taskStatus: Yup.string().required(RequiredField("Task status")),
      date: Yup.date().required(RequiredField("Date")),
      dailyStatus: Yup.string().required(RequiredField("Daily status")),
      takenMinutes: Yup.string().optional(),
      takenHours: Yup.string().required(RequiredField("Hour")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (isEdit) {
        editTaskSheetHR(editTaskCellData?.id, { ...values })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              newAddTasksheet.resetForm();
              toast.success("Time sheet updated successfully.");
              setLoading(true);
              listOFTaskSheet({
                userId: id,
              })
                .then((response) => {
                  setLoading(true);
                  setListOfTaskSheetData(response?.data?.data?.listOfTaskSheet);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                });
              setModalTodo(false);
              setModalTodo([]);
            } else {
              toast.error(response?.data?.message);
              setLoading(false);
              setModalTodo(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setModalTodo(false);
            return err;
          })
          .finally(() => {
            setLoading(false);
            setModalTodo(false);
          });
        setModalTodo(false);
        newAddTasksheet.resetForm();
      } else {
        let payload = {
          userId: id,
          projectTask: values?.projectTask,
          taskSheetId: values?.taskSheetId,
          detailsOfTask: values?.detailsOfTask,
          date: values?.date,
          takenMinutes: +values?.takenMinutes,
          takenHours: +values?.takenHours,
          taskStatus: values?.taskStatus,
          dailyStatus: values?.dailyStatus,
        };
        addTasksheet(payload)
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              setLoading(true);
              listOFTaskSheet({
                userId: id,
              })
                .then((response) => {
                  setListOfTaskSheetData(response?.data?.data?.listOfTaskSheet);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                });
              setModalTodo(false);
            } else {
              toast.error(response?.data?.message);
              setLoading(false);
              setModalTodo(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setModalTodo(false);
            return err;
          })
          .finally(() => {
            setModalTodo(false);
            setLoading(false);
          });
        setModalTodo(false);
        newAddTasksheet.resetForm();
      }
    },
  });

  useEffect(() => {
    if (newAddTasksheet?.values?.projectTask) {
      const projectName = newAddTasksheet?.values?.projectTask;
      listOfTasks({
        assignTo: parseInt(id),
        projectName,
        taskStatus: "Pending",
      })
        .then((res) => setlistOfTask(res?.data?.data))
        .catch((err) => {
          setlistOfTask([]);
          return err;
        });
    }
  }, [newAddTasksheet?.values?.projectTask, id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameOfTask: todo?.nameOfTask || "",
      detailsOfTask: todo?.detailsOfTask || "",
      dueDate: todo?.dueDate || "",
      takenTime: todo?.takenTime || "",
    },
    validationSchema: Yup.object({
      nameOfTask: Yup.string().required(RequiredField("Task status")),
      detailsOfTask: Yup.string().required(RequiredField("Details of task")),
      dueDate: Yup.string().required(RequiredField("Start date")),
      takenTime: Yup.string().required(RequiredField("Taken time")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      addTasksheet({
        addedBy: parseInt(id, 10),
        date: values.dueDate,
        nameOfTask: values.nameOfTask,
        detailsOfTask: values.detailsOfTask,
        takenTime: values.takenTime,
        taskSheetId: values?.taskSheetId,
        status: "Pending",
      })
        .then((response) => {
          if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(response?.data?.message);
            setLoading(true);
            viewTasksheet(id)
              .then((response) => {
                setTaskSheetInnerData(response?.data?.data[0].taskSheet);
              })
              .catch((err) => err)
              .finally(() => {});
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      setModalTodo(false);
      validation.resetForm();
      toggle();
    },
  });

  const validationOfFilter = useFormik({
    enableReinitialize: true,

    initialValues: {
      date: "",
      developerName: "",
    },
    validationSchema: Yup.object({
      date: Yup.string("It Should be text").optional(),
      developerName: Yup.string("It Should be text").optional(),
    }),
    onSubmit: (value) => {
      setLoading(true);
      listOFTaskSheet({
        userId: id,
        ...value,
      })
        .then((res) => {
          setFilteredTaskSheetData(res?.data?.data?.listOfTaskSheet);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
      validationOfFilter.resetForm();
    },
  });

  const durationOptions = Array?.from({ length: 10 }, (_, i) => i + 1);

  document.title = "Timesheet";
  const selectedDailyTaskStatusObj = [
    {
      options: [
        { label: "Select Stock Type", value: "" },
        { label: "Completed", value: "Completed" },
        { label: "Pending", value: "Pending" },
        { label: "On Process", value: "On Process" },
      ],
    },
  ];

  const dynamicFind = (array, validation) => {
    return array.find((option) => option.value === validation);
  };

  const selectedTaskObj = Array.isArray(listOfTask)
    ? listOfTask?.map((res) => {
        return {
          value: `${res?.id}`,
          label: `${res?.taskName}`,
          estimateTime: `${res?.estimateTime}`,
          remainingTime: `${res?.remainingTime}`,
        };
      })
    : null;

  let dataList = null;
  listFilteredTaskSheetData?.length > 0
    ? (dataList = listFilteredTaskSheetData)
    : (dataList = listOfTimeSheet);

  let hrDataList = null;
  if (role === "HR") {
    listFilteredTaskSheetData?.length > 0
      ? (hrDataList = listFilteredTaskSheetData)
      : (hrDataList = listOfTasksheetData);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Timesheet" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Timesheet</h5>
              </div>
              {role === "HR" ? (
                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <button
                      className="btn btn-warning createTask"
                      type="button"
                      onClick={() => addProjectName()}>
                      + Add Project
                    </button>
                    <Link to={"/timesheet/add-timesheet"}>
                      {" "}
                      <button
                        className="btn btn-primary createTask"
                        type="button">
                        + Add Timesheet
                      </button>
                    </Link>
                  </div>
                </div>
              ) : role === "Admin" ? null : (
                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <Link
                      to={"/timesheet/add-timesheet"}
                      className="btn btn-primary">
                      {" "}
                      + Add Timesheet
                    </Link>
                  </div>
                </div>
              )}
            </Row>
            <Row>
              <Card>
                <CardBody className="mt-0">
                  <Formik
                    initialValues={validationOfFilter.initialValues}
                    validationSchema={validationOfFilter.validationSchema}
                    onSubmit={validationOfFilter.handleSubmit}>
                    <Form onSubmit={validationOfFilter.handleSubmit}>
                      <Row>
                        <Col lg={6} md={6}>
                          <div className="p-2">
                            <Flatpickr
                              className="form-control"
                              id="datepicker-publish-input"
                              placeholder="Select date"
                              onBlur={validationOfFilter.handleBlur}
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "d.m.y",
                                onChange: function (selectedDates) {
                                  validationOfFilter.setFieldValue(
                                    "date",
                                    dateFormatChange(selectedDates[0])
                                  );
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div className="m-2">
                            <button
                              className="btn btn-primary createTask"
                              type="submit">
                              {!loading ? (
                                "SEARCH"
                              ) : (
                                <div className="d-inline-flex align-items-center">
                                  <Spinner size="sm" className="me-2" />
                                  Loading...
                                </div>
                              )}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Formik>
                </CardBody>
              </Card>
            </Row>
            {role === "HR" ? (
              <>
                <Card>
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Timesheet</h4>
                  </CardHeader>
                  <CardBody>
                    {hrDataList?.length ? (
                      <TableContainer
                        columns={columns}
                        data={hrDataList || []}
                        isAddUserList={false}
                        isGlobalFilter={false}
                        customPageSize={5}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-wrap"
                        theadClass="table-light text-muted"
                      />
                    ) : (
                      <NoResultsMessage />
                    )}
                  </CardBody>
                </Card>
                {listOfProjectState?.length && (
                  <Card>
                    <CardHeader className="align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">Project</h4>
                    </CardHeader>
                    <CardBody>
                      {listOfProjectState?.length ? (
                        <TableContainer
                          columns={projectColumns}
                          data={listOfProjectState || []}
                          isAddUserList={false}
                          isGlobalFilter={true}
                          customPageSize={5}
                          divClass="table-responsive table-card mb-1"
                          tableClass="align-middle table-wrap"
                          theadClass="table-light text-muted"
                        />
                      ) : (
                        <NoResultsMessage />
                      )}
                    </CardBody>
                  </Card>
                )}
              </>
            ) : (
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    List of Timesheet
                  </h4>
                </CardHeader>
                <CardBody>
                  {dataList?.length ? (
                    <TableContainer
                      columns={QueryColumns}
                      data={dataList || []}
                      isAddUserList={false}
                      customPageSize={5}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-wrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <NoResultsMessage />
                  )}
                </CardBody>
              </Card>
            )}
          </Container>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {isEdit === true ? "Edit" : "Add"} {"Timesheet"}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              newAddTasksheet.handleSubmit();
              return false;
            }}>
            <input type="hidden" id="taskid-input" className="form-control" />

            <Row className="mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="projectTaskInput" className="form-label">
                    Select Project :
                  </Label>
                  <select
                    className={`form-select ${
                      newAddTasksheet.errors.projectTask && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="projectTaskInput"
                    onChange={newAddTasksheet.handleChange}
                    name="projectTask"
                    validate={{ required: { value: true } }}
                    onBlur={newAddTasksheet.handleBlur}
                    value={newAddTasksheet?.values?.projectTask || ""}>
                    <option selected hidden>
                      Select your Project
                    </option>
                    {listOfAssignProject?.length &&
                      listOfAssignProject?.map((item) => {
                        return (
                          <option
                            key={item?.project?.projectName}
                            value={item?.project?.projectName}>
                            {item?.project?.projectName}
                          </option>
                        );
                      })}
                  </select>
                  {newAddTasksheet.touched.projectTask &&
                    newAddTasksheet.errors.projectTask && (
                      <div className="invalid-feedback">
                        {newAddTasksheet.errors.projectTask}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label className="form-label">Select Task:</label>
                  <select
                    className={`form-select ${
                      newAddTasksheet.errors.taskSheetId && "is-invalid"
                    }`}
                    onChange={(event) => {
                      const selectedTaskValue = event?.target?.value;
                      const data = selectedTaskObj?.find(
                        (task) => task?.value === selectedTaskValue
                      );
                      setSelectedTask(data);
                      newAddTasksheet.setFieldValue(
                        "taskSheetId",
                        selectedTaskValue
                      );
                    }}
                    name="taskSheetId"
                    onBlur={newAddTasksheet.handleBlur}
                    value={
                      selectedTask
                        ? selectedTask?.value
                        : newAddTasksheet?.values?.taskSheetId
                    }>
                    <option hidden>Select Task</option>
                    {selectedTaskObj?.length ? (
                      selectedTaskObj?.map((task) => (
                        <option key={task?.value} value={task?.value}>
                          {task?.label}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No Option
                      </option>
                    )}
                  </select>
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="dueDateInput" className="form-label">
                    Date :
                  </Label>
                  <Flatpickr
                    className={`form-control ${
                      newAddTasksheet.errors.date && "is-invalid"
                    }`}
                    id="dueDateInput"
                    placeholder="Select a date"
                    name="date"
                    onBlur={newAddTasksheet.handleBlur}
                    value={newAddTasksheet.values.date || ""}
                    invalid={
                      !!(
                        newAddTasksheet.touched.date &&
                        newAddTasksheet.errors.date
                      )
                    }
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      onChange: function (selectedDates, dateStr, instance) {
                        newAddTasksheet
                          .setFieldValue("date", dateStr)
                          .then((res) => res)
                          .catch((err) => err);
                      },
                    }}
                  />
                  {newAddTasksheet.touched.date &&
                  newAddTasksheet.errors.date ? (
                    <FormFeedback type="invalid">
                      {newAddTasksheet.errors.date}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-hour-input" className="form-label">
                    Taken Time :
                  </label>
                  <div className="input-group">
                    <select
                      name="takenHours"
                      id="task-hour-input"
                      className={`form-control ${
                        validation.errors.takenHours &&
                        validation.touched.takenHours
                          ? "is-invalid"
                          : ""
                      }`}
                      value={validation?.values?.takenHours || ""}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(
                          validation.touched.takenHours &&
                          validation.errors.takenHours
                        )
                      }>
                      <option value="00">Hours</option>
                      <option value="01">1 Hr</option>
                      <option value="02">2 Hr</option>
                      <option value="03">3 Hr</option>
                      <option value="04">4 Hr</option>
                      <option value="05">5 Hr</option>
                      <option value="06">6 Hr</option>
                      <option value="07">7 Hr</option>
                      <option value="08">8 Hr</option>
                      <option value="09">9 Hr</option>
                      <option value="10">10 Hr</option>
                    </select>
                    {validation.touched.takenHours &&
                    validation.errors.takenHours ? (
                      <FormFeedback type="invalid">
                        {validation.errors.takenHours}
                      </FormFeedback>
                    ) : null}
                    <span className="input-group-text">:</span>

                    <select
                      name="takenMinutes"
                      id="task-minute-input"
                      className={`form-control ${
                        validation.errors.takenMinutes &&
                        validation.touched.takenMinutes
                          ? "is-invalid"
                          : ""
                      }`}
                      value={validation?.values?.takenMinutes || ""}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}>
                      <option value="00">Minutes</option>
                      <option value="15">15 Min</option>
                      <option value="30">30 Min</option>
                      <option value="45">45 Min</option>
                      <option value="60">60 Min</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="taskStatusInput" className="form-label">
                    Task Status :
                  </Label>
                  <select
                    className={`form-select ${newAddTasksheet.errors.taskStatus}`}
                    aria-label="Default select example"
                    id="taskStatusInput"
                    onChange={newAddTasksheet.handleChange}
                    name="taskStatus"
                    validate={{ required: { value: true } }}
                    onBlur={newAddTasksheet.handleBlur}
                    value={newAddTasksheet.values.taskStatus || ""}>
                    <option selected hidden>
                      Select your task status
                    </option>
                    <option value="Completed">Complete</option>
                    <option value="Pending">Pending</option>
                  </select>
                  {newAddTasksheet.touched.taskStatus &&
                  newAddTasksheet.errors.taskStatus ? (
                    <FormFeedback type="invalid">
                      {newAddTasksheet.errors.taskStatus}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label pt-2">Daily Status :</Label>

                  <Select
                    className={` ${
                      validation.errors.dailyStatus && "is-invalid red-border"
                    }`}
                    placeholder="Select Daily Status"
                    value={dynamicFind(
                      selectedDailyTaskStatusObj,
                      validation.values.dailyStatus
                    )}
                    isMulti={false}
                    onChange={(selectedOption) => {
                      validation.setFieldValue(
                        "dailyStatus",
                        selectedOption?.value || ""
                      );
                    }}
                    options={selectedDailyTaskStatusObj}
                    onBlur={validation.handleBlur}
                    name="dailyStatus"
                  />
                  {validation.touched.dailyStatus &&
                  validation.errors.dailyStatus ? (
                    <div className="invalid-feedback">
                      {validation.errors.dailyStatus}
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Details Of Task :
                  </label>
                  <Input
                    type="textarea"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter details of task"
                    name="detailsOfTask"
                    rows="1"
                    validate={{ required: { value: true } }}
                    onChange={newAddTasksheet.handleChange}
                    onBlur={newAddTasksheet.handleBlur}
                    value={newAddTasksheet.values.detailsOfTask || ""}
                    invalid={
                      !!(
                        newAddTasksheet.touched.detailsOfTask &&
                        newAddTasksheet.errors.detailsOfTask
                      )
                    }
                  />
                  {newAddTasksheet.touched.detailsOfTask &&
                  newAddTasksheet.errors.detailsOfTask ? (
                    <FormFeedback type="invalid">
                      {newAddTasksheet.errors.detailsOfTask}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {newAddTasksheet?.values?.taskSheetId !== "" && (
                <Col lg={6}>
                  <div className="mb-3">
                    <label className="form-label">Estimated Time:</label>
                    <span>
                      {selectedTask?.estimateTime !== undefined
                        ? selectedTask?.estimateTime
                        : TIME_ZERO}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Remaining Time:</label>
                    <span>
                      {selectedTask?.remainingTime !== undefined
                        ? selectedTask?.remainingTime
                        : TIME_ZERO}
                    </span>
                  </div>
                </Col>
              )}
            </Row>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => {
                  setModalTodo(false);
                  newAddTasksheet.resetForm();
                }}>
                <i className="ri-close-fill align-bottom"></i> Close
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {isEdit === true ? "Edit Data" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        id="createTask"
        isOpen={modalProjectName}
        toggle={projectToggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={projectToggle} className="p-3 bg-soft-success">
          {" "}
          {isProjectEdit === true ? "Edit" : "Add"}
          {" Project"}{" "}
        </ModalHeader>
        <ModalBody>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              addProjectValidation.handleSubmit();
              return false;
            }}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Project Name :
                  </label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter your Project Name"
                    name="projectName"
                    validate={{ required: { value: true } }}
                    onChange={addProjectValidation.handleChange}
                    onBlur={addProjectValidation.handleBlur}
                    value={addProjectValidation.values.projectName || ""}
                    invalid={
                      !!(
                        addProjectValidation.touched.projectName &&
                        addProjectValidation.errors.projectName
                      )
                    }
                  />
                  {addProjectValidation.touched.projectName &&
                  addProjectValidation.errors.projectName ? (
                    <FormFeedback type="invalid">
                      {addProjectValidation.errors.projectName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Project Description :
                  </label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter your Project Description"
                    name="description"
                    validate={{ required: { value: true } }}
                    onChange={addProjectValidation.handleChange}
                    onBlur={addProjectValidation.handleBlur}
                    value={addProjectValidation?.values?.description || ""}
                    invalid={
                      !!(
                        addProjectValidation.touched.description &&
                        addProjectValidation.errors.description
                      )
                    }
                  />
                  {addProjectValidation.touched.description &&
                  addProjectValidation.errors.description ? (
                    <FormFeedback type="invalid">
                      {addProjectValidation.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Duration :
                  </label>
                  <select
                    id="duration-select"
                    className={`form-control ${
                      addProjectValidation.touched.duration &&
                      addProjectValidation.errors.duration
                        ? "is-invalid"
                        : ""
                    }`}
                    name="duration"
                    onChange={addProjectValidation.handleChange}
                    onBlur={addProjectValidation.handleBlur}
                    value={addProjectValidation?.values?.duration}
                    invalid={
                      addProjectValidation.touched.duration &&
                      addProjectValidation.errors.duration
                    }>
                    <option value="">Select Duration</option>
                    {durationOptions?.map((option) => (
                      <option key={option} value={option}>
                        {option} {option === 1 ? "month" : "months"}
                      </option>
                    ))}
                  </select>
                  {addProjectValidation.touched.duration &&
                    addProjectValidation.errors.duration && (
                      <div className="invalid-feedback">
                        {addProjectValidation.errors.duration}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="choices-multiple-default"
                    className="form-label">
                    Developer's Name :
                  </Label>
                  <Select
                    value={addProjectValidation?.values?.assignTo}
                    isMulti={true}
                    onChange={(selectedOption) => {
                      addProjectValidation.setFieldValue(
                        "assignTo",
                        selectedOption
                      );
                    }}
                    options={developerObj}
                    className={
                      addProjectValidation.touched.assignTo &&
                      addProjectValidation.errors.assignTo
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {addProjectValidation.touched.assignTo &&
                    addProjectValidation.errors.assignTo && (
                      <div className="invalid-feedback">
                        {addProjectValidation.errors.assignTo}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="choices-multiple-default"
                    className="form-label">
                    Technologies's Name :
                  </Label>
                  <Select
                    value={addProjectValidation?.values?.technologies}
                    isMulti={true}
                    onChange={(selectedOption) => {
                      addProjectValidation.setFieldValue(
                        "technologies",
                        selectedOption
                      );
                    }}
                    options={techObj}
                    className={
                      addProjectValidation.touched.technologies &&
                      addProjectValidation.errors.technologies
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {addProjectValidation.touched.technologies &&
                    addProjectValidation.errors.technologies && (
                      <div className="invalid-feedback">
                        {addProjectValidation.errors.technologies}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-ghost-success"
                    onClick={() => {
                      setModalProjectName(false);
                      addProjectValidation.resetForm();
                    }}>
                    <i className="ri-close-fill align-bottom"></i>Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="addNewMember">
                    {"Save "}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        id="createTask"
        isOpen={modalTaskSheet}
        toggle={TaskSheetToggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={TaskSheetToggle} className="p-3 bg-soft-success">
          {" "}
          {"Add Task"}{" "}
        </ModalHeader>
        <ModalBody>
          <Col xxl={12}>
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Row>
                          <Col lg={4}>
                            <label
                              htmlFor="task-title-input"
                              className="form-label pt-2">
                              Project Name :
                            </label>
                          </Col>
                          <Col lg={8}>
                            <select
                              className={`form-select ${
                                validation.errors.listOfProjectName &&
                                "is-invalid"
                              }`}
                              aria-label="Default select example"
                              id="selectreport"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="listOfProjectName"
                              value={validation.values.listOfProjectName}>
                              <option selected hidden>
                                Select Project
                              </option>
                              <option value="Selected"> Trainee Task</option>
                              <option value="Rejected">Project Task</option>
                            </select>
                            <div className="invalid-feedback">
                              {validation.errors.listOfProjectName}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Row>
                          <Col lg={4}>
                            <label
                              htmlFor="task-title-input"
                              className="form-label pt-2">
                              Task Name:
                            </label>
                          </Col>
                          <Col lg={8}>
                            <Input
                              type="text"
                              id="task-title-input"
                              className="form-control"
                              placeholder="Enter task title"
                              name="taskOfName"
                              rows="1"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation?.values?.taskOfName || ""}
                              invalid={
                                !!(
                                  validation.touched.taskOfName &&
                                  validation.errors.taskOfName
                                )
                              }
                            />
                            {validation.touched.taskOfName &&
                            validation.errors.taskOfName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.taskOfName}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Row>
                          <Col lg={4}>
                            <label
                              htmlFor="task-title-input"
                              className="form-label pt-2">
                              Task Details:
                            </label>
                          </Col>
                          <Col lg={8}>
                            <Input
                              type="textarea"
                              id="task-title-input"
                              className="form-control"
                              placeholder="Enter task title"
                              name="detailsOfTask"
                              rows="1"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation?.values?.detailsOfTask || ""}
                              invalid={
                                !!(
                                  validation.touched.detailsOfTask &&
                                  validation.errors.detailsOfTask
                                )
                              }
                            />
                            {validation.touched.detailsOfTask &&
                            validation.errors.detailsOfTask ? (
                              <FormFeedback type="invalid">
                                {validation.errors.detailsOfTask}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Row>
                          <Col lg={6}>
                            <label
                              htmlFor="task-startDate-input"
                              className="form-label">
                              Start Date :
                            </label>
                            <Flatpickr
                              name="startDate"
                              id="date-field"
                              className={`form-select ${
                                validation.errors.startDate && "is-invalid"
                              }`}
                              placeholder="Enter due date"
                              value={validation?.values?.startDate}
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                onChange: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  validation
                                    .setFieldValue("startDate", dateStr)
                                    .then((res) => res)
                                    .catch((err) => err);
                                },
                              }}
                            />
                            <div className="invalid-feedback">
                              {validation.errors.startDate}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <label
                              htmlFor="task-duedate-input"
                              className="form-label">
                              End Date :
                            </label>
                            <Flatpickr
                              name="dueDate"
                              id="date-field"
                              className={`form-select ${
                                validation.errors.dueDate && "is-invalid"
                              }`}
                              placeholder="Enter due date"
                              value={validation?.values?.dueDate}
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                onChange: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  validation
                                    .setFieldValue("dueDate", dateStr)
                                    .then((res) => res)
                                    .catch((err) => err);
                                },
                              }}
                            />
                            <div className="invalid-feedback">
                              {validation.errors.dueDate}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Row>
                          <Col lg={4}>
                            <label
                              htmlFor="task-duedate-input"
                              className="form-label pt-2">
                              Estimated Time :
                            </label>
                          </Col>
                          <Col lg={8}>
                            <Flatpickr
                              name="estimatedTime"
                              id="date-field"
                              className={`form-select ${
                                validation.errors.estimatedTime && "is-invalid"
                              }`}
                              placeholder="Enter Taken Time"
                              value={validation?.values?.estimatedTime}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true,
                                onChange: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  validation
                                    .setFieldValue("estimatedTime", dateStr)
                                    .then((res) => res)
                                    .catch((err) => err);
                                },
                              }}
                            />
                            <div className="invalid-feedback">
                              {validation.errors.estimatedTime}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Row>
                          <Col lg={4}>
                            <Label
                              htmlFor="choices-multiple-default"
                              className="form-label pt-2">
                              Devloper's Name
                            </Label>
                          </Col>
                          <Col lg={8}>
                            <Select
                              value={selectedMultiDevloper}
                              isMulti={true}
                              onChange={(e) => {
                                handleMultiDevelop(e);
                              }}
                              options={developerObj}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label
                          htmlFor="choices-multiple-default"
                          className="form-label">
                          Technologies
                        </Label>
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={(e) => {
                            handleMulti(e);
                          }}
                          options={techObj}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-ghost-success"
                          onClick={() => {
                            setModalTaskSheet(false);
                          }}>
                          <i className="ri-close-fill align-bottom"></i>Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="addNewMember">
                          {"Save "}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default TimeSheet;
