import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Dragula from "react-dragula";
import DeleteModal from "../../components/common/deleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import {
  multiCount,
  supportEmployeeData,
  updateStatusOfSupport,
  viewSupport,
} from "../../api/supportApi";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { getId, getRole } from "../../utils/helpers/custom";
import { dateFormat } from "../../utils/helpers/common";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import NoResultsMessage from "../../components/noResultMessage";

const Status = ({ status }) => {
  switch (status) {
    case "On Process":
      return (
        <span className="badge badge-soft-info text-uppercase">{status}</span>
      );
    case "Pending":
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
    case "Completed":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
  }
};

const EmployeeSupport = () => {
  document.title = "HR Support";
  const id = getId("id");
  const role = getRole();

  const [loading, setLoading] = useState(false);
  const [hrSupportList, setHrSupportList] = useState([]);
  const [viewSupportList, setViewSupportList] = useState([]);
  const [multipartCount, setMultiPartCount] = useState();
  const [updateModal, setUpdateModal] = useState(false);
  const [updateOnProcessModal, setUpdateOnProcessModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [approvedId, setApprovedId] = useState();
  const [approvedAuthId, setApprovedAuthId] = useState();
  const [onProcessId, setOnProcessId] = useState();
  const [onProcessAuthId, setOnProcessAuthId] = useState();
 
  const fetchData = () => {
    setLoading(true);
    let condition = role !== 'Admin'? {role: ['Employee', 'BDE']} : {role: ['HR']}
    supportEmployeeData(id,condition)
      .then((resp) => {
        setHrSupportList(resp?.data?.data?.supportEmployeeData);
      })
      .catch((err) => err);
    viewSupport(id)
      .then((response) => {
        setViewSupportList(response?.data?.data);
      })
      .catch((err) => err);
    multiCount(id)
      .then((response) => {
        setMultiPartCount(response?.data?.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const QueryColumns = useMemo(() => [
    {
      Header: "Employee Name",
      accessor: "userName",
      Cell: ({ cell }) => {
        return (
          <div>
            {cell?.row?.original?.authUser?.firstName} {cell?.row?.original?.authUser?.lastName}
          </div>
        );
      },
    },
    {
      Header: "Issue",
      accessor: "reason",
      filterable: false,
    },
    {
      Header: "Date of request",
      Cell: (items) => {
        return <div>{dateFormat(items?.row?.original?.dateOfRequest)}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell?.value} />;
      },
    },
    {
      Header: "Action",
      Cell: (item) => {
        return (
          <div>
            {item.cell.row.original.status !== "Completed" ?
              <div className="hstack gap-2">
                <button
                  id={`modalTodo-${item?.cell?.row?.index}`}
                  className="btn btn-sm btn-soft-success edit-list"
                  onClick={() => { handleApprove(item?.cell?.row?.original, item?.cell?.row?.original) }}
                >
                
                  <i className="ri-check-line align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="success"
                    content="Approve"
                    anchorId={`modalTodo-${item?.cell?.row?.index}`}
                  />
                </button>
                <button
                  id={`deleteTodo-${item?.cell?.row?.index}`}
                  className="btn btn-sm btn-soft-warning remove-list"
                  disabled={item.cell.row.original.status === "On Process"}
                  onClick={() => { handleOnProcess(item?.cell?.row?.original, item?.cell?.row?.original) }}
                >
                  <i class="ri-arrow-down-circle-line"></i>
                  <ReactTooltip
                    place="bottom"
                    variant="warning"
                    content="On Process"
                    anchorId={`deleteTodo-${item?.cell?.row?.index}`}
                  />
                </button>
              </div> 
              :
               "-"
               }
          </div>
        );
      },
    },
  ]);

  const handleApprove = (row, authApprovedId) => {
    setSelectedRow({ ...row, action: 'approve support query' });
    setUpdateModal(true);
    setApprovedId(row);
    setApprovedAuthId(authApprovedId)
  };

  const handleApproveSupport = () => {
    setUpdateModal(false);
    setLoading(true);
    updateStatusOfSupport({ id: approvedId.id,  userId: onProcessAuthId?.authUser?.id, status: "Completed",supportIssue: onProcessAuthId?.supportIssue}).then((response) => {
      if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
        toast.success(response?.data?.message);
        setLoading(true);
        let condition = role !== 'Admin'? {role: ['Employee', 'BDE']} : {role: ['HR']}
        supportEmployeeData(id,condition)
          .then((resp) => {
            setHrSupportList(resp?.data?.data?.supportEmployeeData);
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error(response?.data?.message);
      }
    }).catch((err) => {
      return err;
    }).finally(() => {
      setLoading(false);
    })
    setSelectedRow(null);
  };

  const handleOnProcess = (row, authId) => {
    setSelectedRow({ ...row, action: 'onprocess support query' });
    setUpdateOnProcessModal(true);
    setOnProcessId(row);
    setOnProcessAuthId(authId)
  }

  const handleOnProcessSupport = () => {
    setUpdateOnProcessModal(false);
    setLoading(true);

    updateStatusOfSupport({ id: onProcessId.id, userId: onProcessAuthId?.authUser?.id, status: "On Process",supportIssue: onProcessAuthId?.supportIssue}).then((response) => {
      if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
        toast.success(response?.data?.message);
        setLoading(true);
        let condition = role !== 'Admin'? {role: ['Employee', 'BDE']} : {role: ['HR']}
        supportEmployeeData(id, condition)
          .then((resp) => {
            setHrSupportList(resp?.data?.data?.supportEmployeeData);
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error(response?.data?.message);
      }
    }).catch((err) => {
      return err;
    }).finally(() => {
      setLoading(false);
    })
    setSelectedRow(null);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const QueryTicket = [
    {
      id: 1,
      cardColor: "primary",
      label: "Open Tickets",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: multipartCount?.openTicketOfCurrentMonthData,
      counter: multipartCount?.openTicketData,
      bgcolor: "success",
      icon: "ri-account-box-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      cardColor: "primary",
      label: "Close Tickets",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: multipartCount?.closeTicketOfCurrentMonthData,
      counter: multipartCount?.closeTicketData,
      bgcolor: "success",
      icon: "ri-team-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 3,
      cardColor: "primary",
      label: "In Progress Tickets",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: multipartCount?.inProgressTicketOfCurrentMonthData,
      counter: multipartCount?.inProgressTicketData,
      bgcolor: "success",
      icon: "ri-file-list-3-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    //---------------------------- its a important for future---------------------------
    {
      id: 4,
      cardColor: "primary",
      label: "Total Tickets",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: multipartCount?.totalTicketOfCurrentMonthData,
      counter: multipartCount?.totalTicketData,
      bgcolor: "success",
      icon: "ri-file-list-3-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    //---------------------------- its a important for future---------------------------
  ];

  const SingleOptions = [
    { value: "Laptop", label: "Laptop" },
    { value: "Mouse", label: "Mouse" },
    { value: "Keyboard", label: "Keyboard" },
    { value: "CPU", label: "CPU" },
    { value: "Monitar", label: "Monitar" },
  ];

  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  // Update To do
  const handleTodoClick = useCallback(
    (arg) => {
      const todo = arg;

      setTodo({
        date: todo.date,
        id: todo.id,
        device: todo.device,
        reason: todo.reason,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Add To do
  const handleTodoClicks = () => {
    setTodo("");
    setModalTodo(!modalTodo);
    setIsEdit(false);
    toggle();
  };

  // Delete To do
  const onClickTodoDelete = (todo) => {
    setTodo(todo);
    setDeleteModal(true);
  };

  const handleDeleteTodo = () => {
    if (todo) {
      setDeleteModal(false);
    }
  };

  // To do Task List validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      device: (todo && todo.device) || "",
      reason: (todo && todo.reason) || "",
    },
    validationSchema: Yup.object({
      device: Yup.string().required("Please select Device"),
      reason: Yup.string().required("Please Enter Issue"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateTodo = {
          id: todo ? todo.id : 0,
          device: values.device,
          reason: values.reason,
        };
        validation.resetForm();
      } else {
        const newTodo = {
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          device: values.device,
          reason: values.reason,
        };
        validation.resetForm();
      }
      toggle();
    },
  });

  const dateFormat2 = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      Dragula([componentBackingInstance], options);
    }
  };

  let message = "";
  if (selectedRow) {
    if (selectedRow.action === "approve") {
      message = `Are you sure you want to Approve the record with reason ${selectedRow.reason}?`;
    } else if (selectedRow.action === "reject") {
      message = `Are you sure you want to Reject the record with reason ${selectedRow.reason}?`;
    }
  }

  return (
    <React.Fragment>

      <ApproveRejectModal
        show={updateModal}
        onDeleteClick={() => handleApproveSupport()}
        onCloseClick={() => setUpdateModal(false)}
        message={message}
        action={selectedRow ? selectedRow.action : ""}
      />

      <ApproveRejectModal
        show={updateOnProcessModal}
        onDeleteClick={() => handleOnProcessSupport()}
        onCloseClick={() => setUpdateOnProcessModal(false)}
        message={message}
        action={selectedRow ? selectedRow.action : ""}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Support" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Raised Queries</h5>
              </div>
            </Row>
            <Row>
              <Widgets data={QueryTicket} />
            </Row>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Support Request HR</h4>
              </CardHeader>

              <CardBody>
                {hrSupportList?.length ? (
                  <TableContainer
                    columns={QueryColumns}
                    data={hrSupportList || []}
                    isAddUserList={false}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  />
                ) : (
                 <NoResultsMessage/>
                )}
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {!!isEdit ? "Edit Leave" : "Add Leave"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Device
              </label>
              <Select
                value={validation.values.device}
                onChange={validation.handleChange}
                options={SingleOptions}
              />
              {validation.touched.device && validation.errors.device ? (
                <FormFeedback type="invalid">
                  {validation.errors.device}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Issue
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Issue"
                name="reason"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  validation.touched.reason && validation.errors.reason
                    ? true
                    : false
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodo(false)}
              >
                <i className="ri-close-fill align-bottom"></i> Close
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {!!isEdit ? "Submit" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
  // }
};

export default EmployeeSupport;
