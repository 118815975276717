import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "../../components/common/breadCrumb";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rating from "react-rating";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  addInterviewFeedBack,
  editInterviewFeedBack,
  viewInterviewFeedBack,
} from "../../api/interviewApi";
import { listOfEmployee } from "../../api/employeeApi";
import { Formik, useFormik, Form } from "formik";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import * as Yup from "yup";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";

const InterviewFeedBackForm = () => {
  const navigate = useNavigate();
  const { interviewId } = useParams();

  const [employee, setEmployee] = useState();
  const [introRating, setIntroRating] = useState();
  const [projectRating, setProjectRating] = useState();
  const [confidenceRating, setConfidenceRating] = useState();
  const [communicationRating, setCommunicationRating] = useState();
  const [javascriptRating, setJavascriptRating] = useState();
  const [technologyRating, setTechnologyRating] = useState();
  const [debuggingRating, setDebuggingRating] = useState();
  const [databaseRating, setDatabaseRating] = useState();
  const [logicalRating, setLogicalRating] = useState();
  const [explanationRating, setExplanationRating] = useState();
  const [specificInterviewFeedBack, setSpecificInterviewFeedBack] = useState();
  const maxCharacterLimit = 500;

  const handleRatingChange = (value, category) => {
    switch (category) {
      case "intro":
        setIntroRating(value);
        break;
      case "project":
        setProjectRating(value);
        break;
      case "confidence":
        setConfidenceRating(value);
        break;
      case "communication":
        setCommunicationRating(value);
        break;
      case "javascript":
        setJavascriptRating(value);
        break;
      case "technology":
        setTechnologyRating(value);
        break;
      case "debugging":
        setDebuggingRating(value);
        break;
      case "database":
        setDatabaseRating(value);
        break;
      case "logical":
        setLogicalRating(value);
        break;
      case "explanation":
        setExplanationRating(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    listOfEmployee({
      isDeleted: false,
      role: ["Employee"],
    })
      .then((response) => {
        setEmployee(response?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    viewInterviewFeedBack(interviewId)
      .then((response) => {
        setSpecificInterviewFeedBack(response?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {});
  }, [interviewId]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      assignTo: specificInterviewFeedBack?.assignUser?.id ||
        "",
      level: specificInterviewFeedBack?.level || "",
      interviewReview: specificInterviewFeedBack?.interviewReview ||
        "",
      interviewDate: specificInterviewFeedBack?.interviewDate || "",
      intro:
        specificInterviewFeedBack?.intro || "",
      project:
        specificInterviewFeedBack?.project|| "",
      confidence:
        specificInterviewFeedBack?.confidence ||
        "",
      communication:
          specificInterviewFeedBack?.communication ||
        "",
      javascript:
         specificInterviewFeedBack?.javascript ||
        "",
      technology:
         specificInterviewFeedBack?.technology ||
        "",
      debugging:
        specificInterviewFeedBack?.debugging ||
        "",
      database:
        specificInterviewFeedBack?.database ||
        "",
      logical:
        specificInterviewFeedBack?.logical || "",
      explanation:
        specificInterviewFeedBack?.explanation ||
        "",
    },
    validationSchema: Yup.object({
      assignTo: Yup.number().required(RequiredField("Employee")),
      level: Yup.string().required(RequiredField("Interview Level")),
      interviewReview: Yup.string().required(RequiredField("Interview Review")),
      interviewDate: Yup.string().required(RequiredField("Interview Date")),
      intro: Yup.number().required(RequiredField("Introduction")),
      project: Yup.number().required(RequiredField("Project")),
      confidence: Yup.number().required(RequiredField("Confidence")),
      communication: Yup.number().required(RequiredField("Communication")),
      javascript: Yup.number().required(RequiredField("Javascript")),
      technology: Yup.number().required(RequiredField("Technology")),
      debugging: Yup.number().required(RequiredField("Debugging")),
      database: Yup.number().optional(),
      logical: Yup.number().required(RequiredField("Logical")),
      explanation: Yup.number().required(RequiredField("Explanation")),
    }),
    onSubmit: (values) => {
      if (interviewId === undefined) {
        addInterviewFeedBack({
          assignTo: values?.assignTo,
          interviewReview: values?.interviewReview,
          level: values?.level,
          interviewDate: values?.interviewDate,
          intro:
            introRating === undefined
              ? specificInterviewFeedBack?.introRating
              : introRating,
          project:
            projectRating === undefined
              ? specificInterviewFeedBack?.projectRating
              : projectRating,
          confidence:
            confidenceRating === undefined
              ? specificInterviewFeedBack?.confidenceRating
              : confidenceRating,
          communication:
            communicationRating === undefined
              ? specificInterviewFeedBack?.communication
              : communicationRating,
          javascript:
            javascriptRating === undefined
              ? specificInterviewFeedBack?.javascriptRating
              : javascriptRating,
          technology:
            technologyRating === undefined
              ? specificInterviewFeedBack?.technologyRating
              : technologyRating,
          debugging:
            debuggingRating === undefined
              ? specificInterviewFeedBack?.debuggingRating
              : debuggingRating,
          database:
            databaseRating === undefined
              ? specificInterviewFeedBack?.databaseRating
              : databaseRating,
          logical:
            logicalRating === undefined
              ? specificInterviewFeedBack?.logicalRating
              : logicalRating,
          explanation:
            explanationRating === undefined
              ? specificInterviewFeedBack?.explanationRating
              : explanationRating,
        })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              viewInterviewFeedBack(interviewId)
                .then((response) => {
                  setSpecificInterviewFeedBack(response?.data?.data);
                })
                .catch((err) => {
                  return err;
                })
                .finally(() => {});
              validation.resetForm();
              toast.success(res?.data?.message);
              navigate("/interview_feedback");
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {});
      } else {
        const payload = {
          assignTo: specificInterviewFeedBack?.assignTo,
          level: values?.level,
          interviewDate: values?.interviewDate,
          intro: introRating,
          project: projectRating,
          confidence: confidenceRating,
          communication: communicationRating,
          javascript: javascriptRating,
          technology: technologyRating,
          debugging: debuggingRating,
          database: databaseRating,
          logical: logicalRating,
          explanation: explanationRating,
          interviewReview: values?.interviewReview,
          seniorId: specificInterviewFeedBack?.seniorId,
          adminId: specificInterviewFeedBack?.adminId,
        };

        editInterviewFeedBack(interviewId, payload)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              viewInterviewFeedBack(interviewId)
                .then((response) => {
                  setSpecificInterviewFeedBack(response?.data?.data);
                })
                .catch((err) => {
                  return err;
                })
                .finally(() => {});
              toast.success(res?.data?.message);
              navigate("/interview_feedback");
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {});
      }
    },
  });

  const [content, setContent] = useState(
    validation.values.interviewReview || ""
  ); // Initialize content

  useEffect(() => {
    // Update content when the initial value changes
    setContent(validation.values.interviewReview || "");
  }, [validation.values.interviewReview]);

  const customToolbarConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "link",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "undo",
        "redo",
      ],
    },
  };

  return (
    <div className="page-content">
        <BreadCrumb
          title={
            interviewId === undefined
              ? "Add Interview Feed Back"
              : "Update Interview Feed Back"
          }
          pageTitle="HRMS"
        />
        <Row className="mb-4">
          <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
            <h5 className="fw-semibold mb-0">
              {interviewId === undefined ? "ADD" : "UPDATE"} {"INTERVIEW"}
            </h5>
          </div>
          <div className="col-auto order-2 order-sm-3 ms-auto">
            <div className="hstack gap-2">
              <Link to={"/interview_feedback"} className="btn btn-primary">
                Back
              </Link>
            </div>
          </div>
        </Row>
        <Card>
          <CardBody className="card-body">
            <Formik
              initialValues={validation.initialValues}
              validationSchema={validation.validationSchema}
              onSubmit={validation.handleSubmit}
              enableReinitialize
            >
              <Form>
                <div className="live-preview interviewFeedBackMargin">
                  <Row className="gy-4">
                    <Col xxl={6} md={6}>
                      <div className="interviewFeedBackPadding">
                        <label htmlFor="assignTo" className="form-label">
                          <strong>Employee</strong>
                        </label>
                        <select
                          className={`form-select ${
                            validation.errors.assignTo &&
                            validation.touched.assignTo &&
                            "is-invalid"
                          }`}
                          aria-label="Default select example"
                          id="assignTo"
                          name="assignTo"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation?.values?.assignTo}
                        >
                          <option>Select Employee</option>
                          {employee?.map((item) => (
                            <option value={item?.id} key={item?.id}>
                              {item?.firstName} {item?.lastName}
                            </option>
                          ))}
                        </select>
                        {validation.errors.assignTo &&
                          validation.touched.assignTo && (
                            <div className="invalid-feedback">
                              {validation.errors.assignTo}
                            </div>
                          )}
                      </div>
                      <div className="inputPadding">
                        <label htmlFor="assignTo" className="form-label">
                          <strong>Level</strong>
                        </label>
                        <select
                          className={`form-select ${
                            validation.errors.level &&
                            validation.touched.level &&
                            "is-invalid"
                          }`}
                          aria-label="Default select example"
                          id="level"
                          name="level"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation?.values?.level}
                        >
                          <option selected hidden>
                            Select Level{" "}
                          </option>
                          <option value="Beginners">Beginners</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advance">Advance</option>
                        </select>
                        {validation.errors.level &&
                          validation.touched.level && (
                            <div className="invalid-feedback">
                              {validation.errors.level}
                            </div>
                          )}
                      </div>
                      <div className="inputPadding">
                        <Label htmlFor="interviewReview" className="form-label">
                          <strong>InterviewReview</strong>
                        </Label>

                        <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.interviewReview}
                          config={customToolbarConfig}
                          onChange={(event, editor) => {
                            const data = editor?.getData();
                            const textContent = data?.replace(/<[^>]*>/g, '');
                            if (textContent?.length <= maxCharacterLimit) {
                              setContent(data);
                              validation.setFieldValue("interviewReview", data);
                            }
                          }}
                        />
                        <p>
                          Character Count: {content?.replace(/<[^>]*>/g, '')?.length} /{" "}
                          {maxCharacterLimit}
                        </p>
                        {content?.replace(/<[^>]*>/g, '')?.length >= maxCharacterLimit && (
                          <p className="validateError">
                            Character limit exceeded!
                          </p>
                        )}
                        {validation.touched.interviewReview &&
                        validation.errors.interviewReview ? (
                          <div className="error validateError">
                            {validation.errors.interviewReview}
                          </div>
                        ) : null}

                      </div>
                    </Col>
                    <Col xxl={6} md={6}>
                      <div className="mb-2 interviewFeedBackPadding">
                        <label htmlFor="interviewDate" className="form-label">
                          <strong>Interview Date</strong>
                        </label>
                        <Flatpickr
                          name="interviewDate"
                          id="interviewDate-field"
                          onBlur={validation.handleBlur}
                          className={`form-select  ${
                            validation.errors.interviewDate &&
                            validation.touched.interviewDate
                              ? "is-invalid"
                              : null
                          }`}
                          placeholder="Enter Interview Date"
                          value={validation.values.interviewDate}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                            onChange: function (
                              selectedDates,
                              dateStr,
                              instance
                            ) {
                              validation.setFieldValue(
                                "interviewDate",
                                dateStr
                              );
                            },
                          }}
                        />
                        {validation.touched.interviewDate &&
                        validation.errors.interviewDate ? (
                          <div className="invalid-feedback">
                            {validation.errors.interviewDate}
                          </div>
                        ) : null}
                      </div>
                      <Card>
                        <CardBody>
                          <div className="live-preview">
                            <div className="table-responsive">
                              <table className="table align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Rating</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">Introduction</th>
                                    <td>
                                      <div
                                        id="rater-step-introduction"
                                        dir="ltr"
                                      >
                                        <Rating
                                          name="intro"
                                          initialRating={
                                            validation.values.intro
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(value, "intro");
                                            validation.setFieldValue(
                                              "intro",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.intro &&
                                        validation.errors.intro ? (
                                          <div className="error validateError">
                                            {validation.errors.intro}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Project</th>
                                    <td>
                                      <div id="rater-step-project" dir="ltr">
                                        <Rating
                                          name="project" // Replace "intro" with the correct field name
                                          initialRating={
                                            validation.values.project
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "project"
                                            );
                                            validation.setFieldValue(
                                              "project",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.project &&
                                        validation.errors.project ? (
                                          <div className="error validateError">
                                            {validation.errors.project}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Confidence</th>
                                    <td>
                                      <div id="rater-step-confidence" dir="ltr">
                                        <Rating
                                          name="confidence"
                                          initialRating={
                                            validation.values.confidence
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "confidence"
                                            );
                                            validation.setFieldValue(
                                              "confidence",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.confidence &&
                                        validation.errors.confidence ? (
                                          <div className="error validateError">
                                            {validation.errors.confidence}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Communication</th>
                                    <td>
                                      <div
                                        id="rater-step-communication"
                                        dir="ltr"
                                      >
                                        <Rating
                                          name="communication"
                                          initialRating={
                                            validation.values.communication
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "communication"
                                            );
                                            validation.setFieldValue(
                                              "communication",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.communication &&
                                        validation.errors.communication ? (
                                          <div className="error validateError">
                                            {validation.errors.communication}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Javascript</th>
                                    <td>
                                      <div id="rater-step-javascript" dir="ltr">
                                        <Rating
                                          name="javascript"
                                          initialRating={
                                            validation.values.javascript
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "javascript"
                                            );
                                            validation.setFieldValue(
                                              "javascript",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.javascript &&
                                        validation.errors.javascript ? (
                                          <div className="error validateError">
                                            {validation.errors.javascript}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Technology</th>
                                    <td>
                                      <div id="rater-step-technology" dir="ltr">
                                        <Rating
                                          name="technology"
                                          initialRating={
                                            validation.values.technology
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "technology"
                                            );
                                            validation.setFieldValue(
                                              "technology",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.technology &&
                                        validation.errors.technology ? (
                                          <div className="error validateError">
                                            {validation.errors.technology}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Debugging</th>
                                    <td>
                                      <div id="rater-step-debugging" dir="ltr">
                                        <Rating
                                          name="debugging" // Replace "intro" with the correct field name
                                          initialRating={
                                            validation.values.debugging
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "debugging"
                                            );
                                            validation.setFieldValue(
                                              "debugging",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.debugging &&
                                        validation.errors.debugging ? (
                                          <div className="error validateError">
                                            {validation.errors.debugging}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Database</th>
                                    <td>
                                      <div id="rater-step-database" dir="ltr">
                                        <Rating
                                          initialRating={
                                            databaseRating ||
                                            specificInterviewFeedBack?.database
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted "
                                          fullSymbol="mdi mdi-star text-warning "
                                          onChange={(value) =>
                                            handleRatingChange(
                                              value,
                                              "database"
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Logical</th>
                                    <td>
                                      <div id="rater-step-logical" dir="ltr">
                                        <Rating
                                          name="logical"
                                          initialRating={
                                            validation.values.logical
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "logical"
                                            );
                                            validation.setFieldValue(
                                              "logical",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.logical &&
                                        validation.errors.logical ? (
                                          <div className="error validateError">
                                            {validation.errors.logical}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Explanation</th>
                                    <td>
                                      <div
                                        id="rater-step-explanation"
                                        dir="ltr"
                                      >
                                        <Rating
                                          name="explanation"
                                          initialRating={
                                            validation.values.explanation
                                          }
                                          fractions={2}
                                          emptySymbol="mdi mdi-star-outline text-muted"
                                          fullSymbol="mdi mdi-star text-warning"
                                          onChange={(value) => {
                                            handleRatingChange(
                                              value,
                                              "explanation"
                                            );
                                            validation.setFieldValue(
                                              "explanation",
                                              value
                                            );
                                          }}
                                        />
                                        {validation.touched.explanation &&
                                        validation.errors.explanation ? (
                                          <div className="error validateError">
                                            {validation.errors.explanation}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>{" "}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="hstack gap-2 justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    {interviewId === undefined ? "Submit" : "Update"}
                  </button>
                </div>
              </Form>
            </Formik>
          </CardBody>
        </Card>
    </div>
  );
};

export default InterviewFeedBackForm;
