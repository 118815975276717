import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Button,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import SwiperCore, { Autoplay } from "swiper";
import { getId, getRole, getToken } from "../../utils/helpers/custom";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import { adminProfile, profile } from "../../api/profileApi";
import { dateFormat } from "../../utils/helpers/common";
import {
  BasicPlaceholderFormat,
  RequiredField,
} from "../../utils/helpers/validationMasseges";
import { listOfData } from "../../api/commonApi";
import userDummyImg from "../../../src/assets/image/auth-one-bg.jpg";
import { EMPLOYEE_PROFILE_IMAGE } from "../../api/apiRoutes";
import { toast } from "react-toastify";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import {
  addDocumentDetails,
  addEducationDetails,
  deleteDocumentDetails,
  viewDocumentDetails,
} from "../../api/documentsDetailsApi";
import { listOfResources } from "../../api/resourcesApi";
import { baseURL } from "../../api/baseURL";
import { aadharRegex } from "../../utils/helpers/validationMasseges";
import NoResultsMessage from "../../components/noResultMessage";
const imageUrl = `${baseURL}/${EMPLOYEE_PROFILE_IMAGE}`;


const Profile = () => {
  const [profileData, setprofileData] = useState([]);
  const profileId = getId();
  const role = getRole();
  const [resUserDetails, setResUserDetails] = useState({});
  const [resBankDetails, setResBankDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [employeeAddressData, setEmployeeAddressData] = useState({});
  const [employeeAddressDetailData, setEmployeeAddressDetailData] = useState(
    {}
  );
  const [employeeAddressDetailData1, setEmployeeAddressDetailData1] = useState(
    {}
  );
  const [educationData, setEducationData] = useState({});
  const [employeeReportData, setEmployeeReportData] = useState([]);
  const [emergencyContactNameData, setEmergencyContactNameData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const viewAdminProfile = () => {
    setLoading(true);
    listOfData({
      modelName: "qualificationModel",
    })
      .then((res) => {
        setQualication(res.data);
      })
      .catch((err) => err)
      .finally(() => { });

    adminProfile(profileId)
      .then((resp) => {
        setprofileData(resp.data);
        if (resp.data.userDetails === null) {
          setResUserDetails(null);
        } else {
          setResUserDetails(resp.data.userDetails);
        }
        if (resp.data.emergencyContact.length === 0) {
          setEmergencyContactNameData(null);
        } else {
          setEmergencyContactNameData(resp.data.emergencyContact);
        }
        if (resp.data.address === null) {
          setEmployeeAddressData(null);
        } else {
          setEmployeeAddressData(resp.data.address);
          setEmployeeAddressDetailData(resp.data.address.city);
          setEmployeeAddressDetailData1(resp.data.address.state);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  const viewHrEmployeeProfile = () => {
    setLoading(true);
    listOfData({
      modelName: "qualificationModel",
    })
      .then((res) => {
        setQualication(res.data);
      })
      .catch((err) => err)
      .finally(() => { });

    profile(profileId)
      .then((resp) => {
        setprofileData(resp.data);
        if (resp.data.userDetails === null) {
          setResUserDetails(null);
        } else {
          setResUserDetails(resp.data.userDetails);
        }
        if (resp.data.bankDetails === null) {
          setResBankDetails(null);
        } else {
          setResBankDetails(resp.data.bankDetails);
        }
        if (resp?.data?.educationDetails === null) {
          setEducationData(null);
        } else {
          setEducationData(resp?.data?.educationDetails);
        }
        if (resp.data.emergencyContact.length === 0) {
          setEmergencyContactNameData(null);
        } else {
          setEmergencyContactNameData(resp.data.emergencyContact);
        }
        if (resp.data.address === null) {
          setEmployeeAddressData(null);
        } else {
          setEmployeeAddressData(resp.data.address);
          setEmployeeAddressDetailData(resp.data.address.city);
          setEmployeeAddressDetailData1(resp.data.address.state);
        }
        if (resp.data.reportTo === null) {
          setEmployeeReportData(null);
        } else {
          setEmployeeReportData(resp.data.reportTo);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (role === "Admin") {
      viewAdminProfile();
    } else {
      viewHrEmployeeProfile();
    }
  }, []);

  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState("1");
  const [deleteModal, setDeleteModal] = useState(false);
  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fileName, setFileName] = useState("");
  const [resourcesData, setResourcesData] = useState([]);

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
      setFileName("");
      validation.resetForm();
      validationAadhardocument.resetForm();
      validationdocument.resetForm();
      setNewimage(null);
    } else {
      validation.resetForm();
      validationAadhardocument.resetForm();
      validationdocument.resetForm();
      setModalTodo(true);
    }
  }, [modalTodo]);

  const attachments = [
    { value: "Education", label: "Education" },
    { value: "Aadhar Card", label: "Aadhar Card" },
    { value: "Pan Card", label: "Pan Card" },
    { value: "Driving License", label: "Driving License" },
  ];

  const [Qualification, setQualication] = useState([]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleTodoClicks = () => {
    setTodo("");
    setModalTodo(!modalTodo);
    setIsEdit(false);
    toggle();
    validation.resetForm();
    validationAadhardocument.resetForm();
    validationdocument.resetForm();
  };

  const handleApprove = (row) => {
    setSelectedRow({ ...row, action: 'to delete this record' });
    setDeleteModal(true);
  };

  const handleConfirmation = () => {
    deleteDocumentDetails(
      {
        documentNameId: selectedRow.documentsName.id,
      },
      profileId
    )
      .then((res) => {
        if (res.data.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(res.data.message);
          listofDocument();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });

    // Close the modal
    setDeleteModal(false);
    setSelectedRow(null);
  }

  const documentColumns = useMemo(
    () => [
      {
        Header: "Document Type",
        accessor: "documentsName.fileName",
        filterable: false,
      },
      {
        Header: "Update Date",
        accessor: "updatedAt",
        filterable: false,
        Cell: (cell) => [
          <>
            {dateFormat(cell?.row?.original?.updatedAt)}
          </>
        ]
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const orderData = cellProps.row.original;
          const fileURL = `${imageUrl}/${orderData?.fileUpload}`

          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit">
                <a
                  href={fileURL}
                  target="_blank"
                  download
                  className="text-primary d-inline-block edit-item-btn"
                  rel="noreferrer"
                >
                  <i className="ri-download-fill fs-16"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <Link
                  to=""
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    if (
                      orderData.fileName === "Education" ||
                      orderData.fileName === "Aadhar Card" ||
                      orderData.fileName === "Pan Card" ||
                      orderData.fileName === "Driving License"
                    ) {
                      handleApprove(orderData);
                    } else {
                      handleApprove(orderData);
                    }
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      }
    ],
    []
  );

  const listOfResourcesProfile = () => {
    setLoading(true);
    listOfResources(profileId)
      .then((res) => {
        setResourcesData(res.data.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };


  const columns2 = useMemo(() => [
    {
      Header: "college Name",
      accessor: "collegeName",
      filterable: false,
    },
    {
      Header: "Qualification",
      accessor: "qualification.qualification",
      filterable: false,
    },
    {
      Header: "Year",
      accessor: "passingYear",
      filterable: false,
    },
  ]);

  const columns = useMemo(() => [
    {
      Header: "Resource Name",
      accessor: "resourcesDetails.resourceName",
      filterable: false,
    },
    {
      Header: "Resource ID",
      accessor: "resourcesDetails.resourceNo",
      filterable: false,
    },
    {
      Header: "Allocated Date",
      accessor: "resourceAllocationDate",
      Cell: (cell) => {
        const date = cell?.row?.original?.resourceAllocationDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
      filterable: false,
    },
    {
      Header: "Submitted Date",
      accessor: "returnDate",
      Cell: (cell) => {
        const date = cell?.row?.original?.returnDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
      filterable: false,
    },
  ]);

  const [newImage, setNewimage] = useState(null);

  const uploadImage = (e) => {
    setNewimage(e.target.files[0]);
    validationEducation.setFieldValue("fileUpload", e.target.files[0]);
    fileName.value === "Aadhar Card"
      ? validationAadhardocument.setFieldValue("fileUpload", e.target.files[0])
      : validationdocument.setFieldValue("fileUpload", e.target.files[0]);
  };
  const inputRef = useRef(null);
  const handleRemoveFile = () => {
    document.getElementById("fileInput").value = "";
    // 👇️ reset input value
    inputRef.current.value = null;
    setNewimage(null);
    // validationEducation.values.fileUpload=""
    validationEducation.setFieldValue("fileUpload", "");
    fileName.value === "Aadhar Card"
      ? validationAadhardocument.setFieldValue("fileUpload", "")
      : validationdocument.setFieldValue("fileUpload", "");
  };

  const listofDocument = () => {
    setLoading(true);
    viewDocumentDetails(profileId)
      .then((res) => {
        if (res.data.statusCode === ResponseStatusEnum.SUCCESS) {
          setDocumentData(res.data.data);
        } else {
          setDocumentData([]);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      leaveType: (todo?.leaveType) || "",
      totalDays: (todo?.totalDays) || "",
      reason: (todo?.reason) || "",
      dueDate: (todo?.dueDate) || "",
    },
    validationSchema: Yup.object({
      dueDate: Yup.string().required("Please Enter Date"),
      leaveType: Yup.string().required("Please Enter select leave"),
      totalDays: Yup.string().required("Please Enter number of days"),
      reason: Yup.string().required("Please Enter Reason"),
    }),
    onSubmit: (values) => {
      validation.resetForm();
      toggle();
    },
  });
  document.title = "Profile";

  const validationEducation = useFormik({
    enableReinitialize: true,
    initialValues: {
      qualification: "",
      collageName: "",
      passingYear: "",
      fileUpload: "",
    },
    validationSchema: Yup.object({
      qualification: Yup.string().required(RequiredField("Qualification")),
      collageName: Yup.string().required(RequiredField("College Name")),
      passingYear: Yup.number().required(RequiredField("Passing Year")),
      fileUpload: Yup.mixed().test('is-pdf', 'File must be a PDF only', (value) => {
        if (value) {
          return value && value?.type === 'application/pdf';
        }
        return true; // Allow empty value
      }).required(RequiredField("File")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setModalTodo(false);
      setFileName("");
      addEducationDetails({
        userId: profileId,
        qualificationId: values?.qualification,
        fileName: fileName?.value || fileName,
        collegeName: values?.collageName,
        passingYear: values?.passingYear,
        fileUpload: newImage,
      })
        .then((res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(res?.data?.message);
            setNewimage(null);
            listofDocument();
          } else {
            toast.error(res?.message); 
          }
          setModalTodo(false);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      validationEducation.resetForm();
    },
  });

  const validationAadhardocument = useFormik({
    enableReinitialize: true,
    initialValues: {
      aadharDocumentNumber: "",
      fileUpload: "",
    },
    validationSchema: Yup.object({
      aadharDocumentNumber: Yup.string()
        .matches(aadharRegex, "Enter Valid Aadhar card Number")
        .max(12, "Too long, Aadhar card number must be 12 digit")
        .min(12, "Too Short, Aadhar card number must be 12 digit")
        .required(RequiredField(`${fileName.label} Number`)),
      fileUpload: Yup.mixed().test('is-pdf', 'File must be a PDF only', (value) => {
        if (value) {
          return value && value?.type === 'application/pdf';
        }
        return true; // Allow empty value
      }).required(RequiredField("File")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setModalTodo(false);
      setFileName("");
      addDocumentDetails({
        userId: profileId,
        fileName: fileName?.value || fileName,
        documentNo: values?.aadharDocumentNumber,
        fileUpload: newImage,
      })
        .then((res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(res?.data?.message);
            setNewimage(null);
          } else {
            toast.error(res?.data?.message);
          }
          listofDocument();
          setModalTodo(false);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      validationAadhardocument.resetForm();
    },
  });

  const validationdocument = useFormik({
    enableReinitialize: true,
    initialValues: {
      documentNumber: "",
      fileUpload: "",
    },
    validationSchema: Yup.object({
      documentNumber: Yup.string().required(
        RequiredField(`${fileName.label} Number`)
      ),
      fileUpload: Yup.mixed().test('is-pdf', 'File must be a PDF only', (value) => {
        if (value) {
          return value && value?.type === 'application/pdf';
        }
        return true; // Allow empty value
      }).required(RequiredField("File")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setModalTodo(false);
      setFileName("");
      addDocumentDetails({
        userId: profileId,
        fileName: fileName?.value,
        documentNo: values?.documentNumber,
        fileUpload: newImage,
      })
        .then((res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(res?.data?.message);
            setNewimage(null);
          } else {
            toast.error(res?.data?.message);
          }
          listofDocument();
          setModalTodo(false);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      validationdocument.resetForm();
    },
  });

  let data = documentData?.sort((a, b) => a?.id - b?.id);

  return (
    <React.Fragment>
      <div className="page-content">
        <ApproveRejectModal
          show={deleteModal}
          onDeleteClick={() => handleConfirmation()}
          onCloseClick={() => setDeleteModal(false)}
          action={selectedRow ? selectedRow?.action : ''}
        />

        {loading === false ? (
          <Container fluid>
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg">
                <img
                  src={
                    profileData?.userDetails?.profileImage
                      ? `${imageUrl}/${profileData?.userDetails?.profileImage}`
                      : userDummyImg
                  }
                  onError={(e) => {
                    e.target.src = userDummyImg; // Set a fallback image when an error occurs
                  }}
                  alt=""
                  className="profile-wid-img"
                />
              </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
              <Row className="g-4">
                <div className="col-auto">
                  <div className="">
                    <img
                      src={
                        profileData?.userDetails?.profileImage
                          ? `${imageUrl}/${profileData?.userDetails?.profileImage}`
                          : userDummyImg
                      }
                      onError={(e) => {
                        e.target.src = userDummyImg; // Set a fallback image when an error occurs
                      }}
                      alt="user-img"
                      className="rounded-circle p-0 avatar-lg img-thumbnail user-profile-image"
                    />
                  </div>
                </div>

                <Col>
                  <div className="p-2">
                    <h3 className="text-white mb-1">
                      {profileData?.firstName} {profileData?.lastName}
                    </h3>
                    {role === "Employee" && (
                      <p className="text-white-75">
                        <b>Role : </b>Developer
                      </p>
                    )}
                    <p className="text-white-75">
                      <b>Phone No. : </b>
                      {profileData?.phoneNo}
                    </p>
                    <p className="text-white-75">
                      <b>Email Id : </b>
                      {profileData?.email}
                    </p>
                    {role === "Employee" && (
                      <p className="text-white-75">
                        <b>Senior : </b>
                        {employeeReportData?.firstName}{" "}
                        {employeeReportData?.lastName}
                      </p>
                    )}
                  </div>
                </Col>

                <Col xs={12} className="col-lg-auto order-last order-lg-0">
                  <Row className="text text-white-50 text-center">
                    <Col lg={6} xs={4}>
                      {role === "Admin" && (
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <Link
                              to="/update-profile"
                              className="btn btn-success"
                            >
                              <i className="ri-edit-box-line align-bottom"></i>{" "}
                              Edit Profile
                            </Link>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg={12}>
                <div>
                  <div className="d-flex">
                    {role !== "Admin" && (
                      <>
                        <Nav
                          pills
                          className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggleTab("1");
                                viewHrEmployeeProfile();
                              }}
                            >
                              <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                              <span className="d-none d-md-inline-block">
                                Overview
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggleTab("2");
                                listOfResourcesProfile();
                              }}
                            >
                              <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                              <span className="d-none d-md-inline-block">
                                Resources
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "3",
                              })}
                              onClick={() => {
                                toggleTab("3");
                                listofDocument();
                              }}
                            >
                              <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                              <span className="d-none d-md-inline-block">
                                Documents
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <div className="flex-shrink-0">
                          <Link
                            to="/update-profile"
                            className="btn btn-success"
                          >
                            <i className="ri-edit-box-line align-bottom"></i>{" "}
                            Edit Profile
                          </Link>
                        </div>
                      </>
                    )}
                  </div>

                  <TabContent activeTab={activeTab} className="pt-4">
                    <TabPane tabId="1">
                      <Row>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-3">Info</h5>

                            <Row xs="2">
                              <Col className="p-3" >
                                <th>Date of Joining :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">{dateFormat(
                                  !profileData?.dateOfJoin
                                    ? "No Data Found"
                                    : profileData?.dateOfJoin
                                )}</td>
                              </Col>
                              <Col className="p-3">
                                <th>Address Line1 :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {employeeAddressData === null
                                    ? "No Data Found"
                                    : employeeAddressData?.addressLine1}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>Address Line2 :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {employeeAddressData === null
                                    ? "No Data Found"
                                    : employeeAddressData?.addressLine2}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>City :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {employeeAddressData === null
                                    ? "No Data Found"
                                    : employeeAddressDetailData?.cityName}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>State :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {employeeAddressData === null
                                    ? "No Data Found"
                                    : employeeAddressDetailData1?.stateName}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>Gender :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {!resUserDetails?.gender
                                    ? "No Data Found"
                                    : resUserDetails?.gender}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>Date of Birth :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {dateFormat(
                                    !resUserDetails?.dob
                                      ? "No Data Found"
                                      : resUserDetails?.dob
                                  )}
                                </td>
                              </Col>
                              {role !== "Admin" &&
                                <>
                                  <Col className="p-3">
                                    <th>Nationality :</th>
                                  </Col>
                                  <Col className="p-3">
                                    <td className="text-muted">
                                      {!resUserDetails?.nationality
                                        ? "No Data Found"
                                        : resUserDetails?.nationality}
                                    </td>
                                  </Col>
                                  <Col className="p-3">
                                    <th> Religious :</th>
                                  </Col>
                                  <Col className="p-3">
                                    <td className="text-muted">
                                      {!resUserDetails?.religion
                                        ? "No Data Found"
                                        : resUserDetails?.religion}
                                    </td>
                                  </Col>
                                  <Col className="p-3">
                                    <th>Personal Email ID :</th>
                                  </Col>
                                  <Col className="p-3">
                                    <td className="text-muted">
                                      {!resUserDetails?.personalEmail
                                        ? "No Data Found"
                                        : resUserDetails?.personalEmail}
                                    </td>
                                  </Col>
                                  <Col className="p-3">
                                    <th>Skype ID (Email) :</th>
                                  </Col>
                                  <Col className="p-3">
                                    <td className="text-muted">
                                      {!resUserDetails?.skypeId
                                        ? "No Data Found"
                                        : resUserDetails?.skypeId}
                                    </td>
                                  </Col>
                                </>}
                            </Row>
                          </CardBody>
                        </Card>
                        {role !== "Admin" && (
                          <>
                            <Card>
                              <CardBody>
                                <h5 className="card-title mb-3">Emergency Contact</h5>

                                {emergencyContactNameData === null ? (
                                  <NoResultsMessage />
                                ) : (
                                  <Row xs="2">
                                    {emergencyContactNameData?.map((data, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {index === 1 ? (
                                            <hr className="fullwidth"></hr>
                                          ) : null}
                                          <Col className="p-3">
                                            <th>Name :</th>
                                          </Col>
                                          <Col className="p-3">
                                            <td className="text-muted">
                                              {data.name}
                                            </td>
                                          </Col>
                                          <Col className="p-3">
                                            <th>Relation :</th>
                                          </Col>
                                          <Col className="p-3">
                                            <td className="text-muted">
                                              {data?.relation}
                                            </td>
                                          </Col>
                                          <Col className="p-3">
                                            <th> Phone No :</th>
                                          </Col>
                                          <Col className="p-3">
                                            <td className="text-muted">
                                              {data?.phoneNo}
                                            </td>
                                          </Col>
                                        </React.Fragment>
                                      );
                                    })}
                                  </Row>
                                )}
                              </CardBody>
                            </Card>


                            <Card>
                              <CardBody>
                                <h5 className="card-title mb-3">Bank Details</h5>

                            <Row xs="2">
                              <Col className="p-3" >
                                <th>Account Holder Name :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails?.holderName}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>Bank Name :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails?.bankName}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>Bank Account No :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails?.accountNo}
                                </td>
                              </Col>
                              <Col className="p-3">
                                <th>IFSC Codes :</th>
                              </Col>
                              <Col className="p-3">
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails.ifscCode}
                                </td>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                          </>)}
                      </Row>

                      {role !== "Admin" && (
                        <TabPane tabId="1">
                          <Card>
                            <CardBody>
                              <h5 className="card-title mb-3">
                                Education Information
                              </h5>
                              {educationData.length > 0 ? (
                                <TableContainer
                                  columns={columns2}
                                  data={educationData || {}}
                                  customPageSize={5}
                                  divClass="table-responsive table-card mb-1"
                                  tableClass="align-middle table-nowrap"
                                  theadClass="table-light text-muted"
                                // handleOrderClick={handleOrderClicks}
                                />
                              ) : (
                                <NoResultsMessage />
                              )}
                            </CardBody>
                          </Card>
                        </TabPane>
                      )}
                    </TabPane>

                    {role !== "Admin" && (
                      <TabPane tabId="2">
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-3">Resources</h5>
                            {resourcesData?.length ? (
                              <TableContainer
                                columns={columns}
                                data={resourcesData || []}
                                isAddUserList={false}
                                customPageSize={5}
                                divClass="table-responsive table-card mb-1"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                              // handleOrderClick={handleOrderClicks}
                              />
                            ) : (
                              <NoResultsMessage />
                            )}
                          </CardBody>
                        </Card>
                      </TabPane>
                    )}

                    {role !== "Admin" && (
                      <TabPane tabId="3">
                        <Card>
                          <CardBody>
                            <div className="d-flex">
                              <h5 className="card-title mb-3">Documents</h5>
                              <div className="col-auto order-2 order-lg-3 ms-auto">
                                <button
                                  className="btn btn-primary createTask"
                                  type="button"
                                  onClick={() => handleTodoClicks()}
                                >
                                  Upload
                                </button>
                              </div>
                            </div>
                            <TableContainer
                              columns={documentColumns}
                              data={data}
                              isAddUserList={false}
                              customPageSize={5}
                              divClass="table-responsive table-card mb-1"
                              tableClass="align-middle table-nowrap"
                              theadClass="table-light text-muted"
                            // handleOrderClick={handleOrderClicks}
                            >
                              {documentData.length === 0 ? (
                                <NoResultsMessage />
                              ) : null}
                            </TableContainer>
                          </CardBody>
                        </Card>
                      </TabPane>
                    )}
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          Upload Document{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (fileName.label === "Education") {
                validationEducation.handleSubmit();
              } else {
                if (fileName.label === "Aadhar Card") {
                  validationAadhardocument.handleSubmit();
                } else {
                  validationdocument.handleSubmit();
                }
              }
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Document Type
              </label>

              <Select
                name="name"
                placeholder="Select document type"
                value={fileName}
                onChange={(value) => {
                  // Perform validation here
                  if (value !== "") {
                    validationEducation.resetForm();
                    validationAadhardocument.resetForm();
                    validationdocument.resetForm();
                    setFileName(value);
                    setNewimage(null);
                  } else {
                    // Handle validation error, e.g., display an error message
                    // setErrorMessage("")
                  }
                }}
                // onChange={(value) => setFileName(value)}
                options={attachments}
              // required
              />

              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            {fileName !== "" && (
              <>
                {" "}
                {fileName.label === "Education" ? (
                  <>
                    <div className="mb-3" key="Education">
                      <Label htmlFor="empDatabersName" className="form-label">
                        Qualification
                      </Label>
                      <select
                        className={`form-select ${validationEducation.errors.qualification &&
                          "is-invalid"
                          }`}
                        aria-label="Default select example"
                        id="selectreport"
                        name="qualification"
                        validate={{ required: { value: true } }}
                        onChange={validationEducation.handleChange}
                        onBlur={validationEducation.handleBlur}
                      >
                        {Qualification.map((res) => {
                          return (
                            <>
                              <option hidden disabled selected>
                                Select Qualification
                              </option>
                              <option value={res.id}>
                                {res.qualification}
                              </option>
                            </>
                          );
                        })}
                      </select>

                      <div className="invalid-feedback">
                        {validationEducation.errors.qualification}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        College Name
                      </label>
                      <Input
                        type="text"
                        id="task-title-input"
                        className="form-control"
                        placeholder={`${BasicPlaceholderFormat(
                          "college name "
                        )}`}
                        name="collageName"
                        validate={{ required: { value: true } }}
                        onChange={validationEducation.handleChange}
                        onBlur={validationEducation.handleBlur}
                        value={validationEducation.values.collageName || ""}
                        invalid={
                          validationEducation.touched.collageName &&
                            validationEducation.errors.collageName
                            ? true
                            : false
                        }
                      />
                      {validationEducation.touched.collageName &&
                        validationEducation.errors.collageName ? (
                        <FormFeedback type="invalid">
                          {validationEducation.errors.collageName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Passing Year
                      </label>
                      <Input
                        type="number"
                        id="task-title-input"
                        className="form-control"
                        placeholder={`${BasicPlaceholderFormat(
                          "Passing year"
                        )}`}
                        name="passingYear"
                        validate={{ required: { value: true } }}
                        onChange={validationEducation.handleChange}
                        onBlur={validationEducation.handleBlur}
                        value={validationEducation.values.passingYear || ""}
                        invalid={
                          validationEducation.touched.passingYear &&
                            validationEducation.errors.passingYear
                            ? true
                            : false
                        }
                      />
                      {validationEducation.touched.passingYear &&
                        validationEducation.errors.passingYear ? (
                        <FormFeedback type="invalid">
                          {validationEducation.errors.passingYear}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        File
                      </label>
                      <Input
                        ref={inputRef}
                        type="file"
                        accept="application/pdf"
                        id="fileInput"
                        className="form-control"
                        placeholder="please Upload your file here"
                        name="fileUpload"
                        validate={{ required: { value: true } }}
                        // onChange={validationEducation.handleChange}
                        onChange={(e) => uploadImage(e)}
                        onBlur={validationEducation.handleBlur}
                        // value={validationEducation.values.fileUpload}
                        invalid={
                          validationEducation.touched.fileUpload &&
                            validationEducation.errors.fileUpload
                            ? true
                            : false
                        }
                      />
                      {validationEducation.touched.fileUpload &&
                        validationEducation.errors.fileUpload ? (
                        <FormFeedback type="invalid">
                          {validationEducation.errors.fileUpload}
                        </FormFeedback>
                      ) : null}
                      <div>
                        {newImage && (
                          <Badge color="primary" className="my-3">
                            {newImage?.name}{" "}
                            <Button
                              color="primary"
                              className="px-2 py-1"
                              onClick={handleRemoveFile}
                            >
                              &times;
                            </Button>
                          </Badge>
                        )}
                      </div>
                      <p className="notebold mt-2">
                        Note : Upload
                        {
                          Qualification[
                            parseInt(
                              validationEducation?.values?.qualification
                            ) - 1
                          ]?.qualification
                        }
                        Marksheet (PDF Only)
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {fileName === "Aadhar Card" ||
                      fileName.label === "Aadhar Card" ? (
                      <>
                        <div className="mb-3" key={fileName.label}>
                          <label
                            htmlFor="task-title-input"
                            className="form-label"
                          >
                            {fileName.label} No.
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder={`${BasicPlaceholderFormat(
                              `${fileName.label} No`
                            )} `}
                            name="aadharDocumentNumber"
                            validate={{ required: { value: true } }}
                            onChange={validationAadhardocument.handleChange}
                            onBlur={validationAadhardocument.handleBlur}
                            value={
                              validationAadhardocument.values
                                .aadharDocumentNumber || ""
                            }
                            invalid={
                              validationAadhardocument.touched
                                .aadharDocumentNumber &&
                                validationAadhardocument.errors
                                  .aadharDocumentNumber
                                ? true
                                : false
                            }
                          />
                          {validationAadhardocument.touched
                            .aadharDocumentNumber &&
                            validationAadhardocument.errors
                              .aadharDocumentNumber ? (
                            <FormFeedback type="invalid">
                              {
                                validationAadhardocument.errors
                                  .aadharDocumentNumber
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="task-title-input"
                            className="form-label"
                          >
                            File
                          </label>
                          <Input
                            ref={inputRef}
                            accept="application/pdf"
                            type="file"
                            id="fileInput"
                            className="form-control"
                            placeholder={`Please Upload ${fileName.label}`}
                            name="fileUpload"
                            validate={{ required: { value: true } }}
                            onChange={(e) => uploadImage(e)}
                            onBlur={validationAadhardocument.handleBlur}
                            // value={
                            //   validationAadhardocument.values.fileName || null
                            // }
                            invalid={
                              validationAadhardocument.touched.fileUpload &&
                                validationAadhardocument.errors.fileUpload
                                ? true
                                : false
                            }
                          />
                          {validationAadhardocument.touched.fileUpload &&
                            validationAadhardocument.errors.fileUpload ? (
                            <FormFeedback type="invalid">
                              {validationAadhardocument.errors.fileUpload}
                            </FormFeedback>
                          ) : null}
                          <div>
                            {newImage && (
                              <Badge color="primary" className="my-3">
                                {newImage?.name}{" "}
                                <Button
                                  color="primary"
                                  className="px-2 py-1"
                                  onClick={handleRemoveFile}
                                >
                                  &times;
                                </Button>
                              </Badge>
                            )}
                          </div>
                          <p className="notebold mt-2">
                            Note : Upload Front and back side of
                            {fileName.label} in single file.(PDF Only)
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="mb-3"
                          key={
                            fileName === "Pan Card" ||
                              fileName.label === "Pan Card"
                              ? "Pan Card"
                              : "Driving License"
                          }
                        >
                          <label
                            htmlFor="task-title-input"
                            className="form-label"
                          >
                            {fileName.label} No.
                          </label>
                          <Input
                            type="text"
                            id="task-title-input"
                            className="form-control"
                            placeholder={`${BasicPlaceholderFormat(
                              `${fileName.label} No`
                            )} `}
                            name="documentNumber"
                            validate={{ required: { value: true } }}
                            onChange={validationdocument.handleChange}
                            onBlur={validationdocument.handleBlur}
                            value={
                              validationdocument.values.documentNumber || ""
                            }
                            invalid={
                              validationdocument.touched.documentNumber &&
                                validationdocument.errors.documentNumber
                                ? true
                                : false
                            }
                          />
                          {validationdocument.touched.documentNumber &&
                            validationdocument.errors.documentNumber ? (
                            <FormFeedback type="invalid">
                              {validationdocument.errors.documentNumber}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="task-title-input"
                            className="form-label"
                          >
                            File
                          </label>
                          <Input
                            ref={inputRef}
                            accept="application/pdf"
                            type="file"
                            id="fileInput"
                            className="form-control"
                            placeholder={`Upload ${fileName.label}`}
                            name="fileUpload"
                            validate={{ required: { value: true } }}
                            onChange={(e) => uploadImage(e)}
                            onBlur={validationdocument.handleBlur}
                            value={validationdocument.values.fileName || null}
                            invalid={
                              validationdocument.touched.fileUpload &&
                                validationdocument.errors.fileUpload
                                ? true
                                : false
                            }
                          />
                          {validationdocument.touched.fileUpload &&
                            validationdocument.errors.fileUpload ? (
                            <FormFeedback type="invalid">
                              {validationdocument.errors.fileUpload}
                            </FormFeedback>
                          ) : null}
                          <div>
                            {newImage && (
                              <Badge color="primary" className="my-3">
                                {newImage?.name}{" "}
                                <Button
                                  color="primary"
                                  className="px-2 py-1"
                                  onClick={handleRemoveFile}
                                >
                                  &times;
                                </Button>
                              </Badge>
                            )}
                          </div>

                          <p className="notebold mt-2">
                            Note : Upload Front and back side of
                            {fileName.label} in single file.(PDF Only)
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => toggle()}
              >
                <i className="ri-close-fill align-bottom"></i>{" "}
                {role === "Admin" ? "Reject" : "Cancel"}
              </button>
              <button
                type="submit"
                className={`btn btn-primary ${fileName === "" ? 'red-background-button' : ''}`}
                id="addNewTodo"
                style={{ cursor: fileName === "" ? 'not-allowed' : 'pointer' }}
              >
                Save
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Profile;
