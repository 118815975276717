import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/deleteModal";
import { getId, getName, getRole } from "../../utils/helpers/custom";
import {
  deleteLeave, filterOfLeaveDetails,
  countOfLeave,
} from "../../api/leaveApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import {
  addLeave,
  approveRejectLeave,
  listOfLeave,
  viewLeave,
  viewLeaveOfEmployee,
} from "../../api/leaveApi";
import TableContainer from "../../components/common/tableContainer";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { dateFormat, dateFormatChange } from "../../utils/helpers/common";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { countOfData } from "../../api/commonApi";
import Loader from "../../components/common/loader";
import FixedHeaderDatatables from "../../components/common/datatableCom";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import NoResultsMessage from "../../components/noResultMessage";
import { leaveStatus } from "../../utils/helpers/enums";

const Status = ({ status }) => {
  switch (status) {
    case "Pending":
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
    case "Rejected":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Approved":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
  }
};

const HRLeaves = () => {

  document.title = "HR Leaves";
  const role = getRole();
  const name = getName();
  const id = getId();
  const [listLeave, setListLeave] = useState([]);
  const [datee, setDatee] = useState("");
  const [date1, setDate2] = useState("");
  const [leaveCardData, setLeaveCardDate] = useState();
  const [loading, setLoading] = useState(false);
  const [approveCardData, setApproveCardData] = useState();
  const [pedningCardData, setPendingCardData] = useState();
  const [leaveRejectData, setLeaveRejectData] = useState([]);
  const [leaveRejectNameData, setLeaveRejectNameData] = useState([]);
  const [countOfCardData, setCountOfCardData] = useState();
  const [isApproveRejectModalOpen, setApproveRejectModalOpen] = useState(false);
  const [response, setResponse] = useState(null);
  const [stateValue, setStateValue] = useState(false)

  const fetchData = () => {
    if (role === "Admin") {
      setLoading(true);
      listOfLeave()
        .then((response) => {
          setListLeave(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      viewLeave(id)
        .then((response) => {
          setListLeave(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => { });
    }
    countOfData({
      modelName: "leaveModel",
      condition: {
        status: "Pending",
        userId: id,
      },
    })
      .then((response) => {
        setPendingCardData(response?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });
    countOfData({
      modelName: "leaveModel",
      condition: {
        status: "Approve",
        userId: id,
      },
    })
      .then((response) => {
        setApproveCardData(response?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });
    if (role === "Admin") {
      countOfData({
        modelName: "leaveModel",
      })
        .then((response) => {
          setLeaveCardDate(response?.data?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      countOfData({
        modelName: "leaveModel",
        condition: {
          userId: id,
        },
      })
        .then((response) => {
          setLeaveCardDate(response?.data?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
      countOfLeave(id)
        .then((response) => {
          setCountOfCardData(response?.data?.data)
        })
        .catch((err) => {
          setLoading(false);
          return err;
        }).finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
    setDatee("");
    setDate2("");
  }, []);
  const [deleteState, setDeleteState] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalProject, setModalProject] = useState(false);
  const deleteFunc = (data) => {
    setDeleteState(data)
    setDeleteModal(true);
  }

  const [selectedLeaveId, setSelectedLeaveId] = useState(null);

const handleOpenApproveRejectModal = (leaveId) => {
  setSelectedLeaveId(leaveId);
  setApproveRejectModalOpen(true);
};

  const handleCloseApproveRejectModal = () => {
    setApproveRejectModalOpen(false);
  };

  const handleDeleteLeave = (leaveId) => {
    setLoading(true);
    const payload = {
      id: leaveId,
      isDeleted: true,
    };
    setApproveRejectModalOpen(true);
    deleteLeave(payload)
        .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
                toast.success(response?.data?.message);
                handleCloseApproveRejectModal(false);
            }
            setResponse(response?.data);
            filterOfLeaveDetails({
              userId: id,
            }).then((response) => setlistOfLeaveData(response?.data?.data))
        })
        .catch((err) => {
            toast.error(err?.data?.message);
            return err;
        })
        .finally(() => {
            setLoading(false);
        });
};


  const leaveColumns = useMemo(() => [
    {
      Header: "Employee Name",
      Cell: (item) => {
        if (role === "Admin") {
          return (
            <div>
              {item?.cell?.row?.original?.users?.firstName}{" "}
              {item?.cell?.row?.original?.users?.lastName}
            </div>
          );
        } else {
          return (
            <>
              {name}
            </>
          )
        }
      },
    },
    {
      Header: "Type Of Leave",
      accessor: "categoryLeave",
      filterable: false,
    },
    {
      Header: "From Date",
      accessor: "fromDate",
      filterable: false,
      Cell: (cell) => {
        const { fromDate } = cell.row.original;
        return fromDate !== null ? <div>{dateFormat(fromDate)}</div> : "-";
      },
    },
    {
      Header: "To Date",
      accessor: "toDate",
      filterable: false,
      Cell: (cell) => {
        const { toDate } = cell.row.original;
        return toDate !== null ? <div>{dateFormat(toDate)}</div> : "-";
      },
    },
    {
      Header: "Leave Days",
      accessor: "numberOfDays",
      filterable: false,
    },
    {
      Header: "Reason",
      accessor: "leaveReason",
      filterable: false,
    },
    {
      Header: "Leave Type",
      accessor: "leaveType",
      filterable: false,
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: (item) => {
        if (role === "Admin") {
          return (
            <div>
              {item?.row?.original?.status === "Approve" ||
                item?.row?.original?.status === "Reject" ? (
                <Status status={item?.row?.original?.status} />
              ) : (
                <div className="hstack gap-2">
                  <button
                    id={`modalTodo-${item?.cell?.row?.index}`}
                    className="btn btn-sm btn-soft-info edit-list"
                    onClick={() => {
                      approveRejectLeave({
                        id: item?.cell?.row?.original?.id,
                        userId: item?.cell?.row?.original?.userId,
                        reason: "",
                        status: "Approve",
                      }).then((response) => {
                        if (
                          response?.data?.statusCode ===
                          ResponseStatusEnum.SUCCESS
                        ) {
                          toast.success(response?.data?.message);
                          listOfLeave().then((response) => {
                            setListLeave(response?.data?.data);
                          });
                        }
                      });
                    }}
                  >
                    <i className="ri-check-line align-bottom" />
                    <ReactTooltip
                      place="bottom"
                      variant="info"
                      content="Approve"
                      anchorId={`modalTodo-${item?.cell?.row?.index}`}
                    />
                  </button>
                  <button
                    id={`deleteTodo-${item?.cell?.row?.index}`}
                    className="btn btn-sm btn-soft-danger remove-list"
                    onClick={() => {
                      handleTodoClicksReject();
                      setLeaveRejectData(item?.cell?.row?.original);
                      setLeaveRejectNameData(item?.cell?.row?.original?.users);
                    }}
                  >
                    <i className="ri-close-line align-bottom" />
                    <ReactTooltip
                      place="bottom"
                      variant="error"
                      content="Reject"
                      anchorId={`deleteTodo-${item?.cell?.row?.index}`}
                    />
                  </button>
                </div>
              )}
            </div>
          );
        } else {
          return <Status status={item?.value} />;
        }
      },
    },
    {
      Header: "Action",
      Cell: (item) => (
        <div>
          {item?.row?.original?.status === "Approved" || item?.row?.original?.status === "Rejected" ? (
            "-"
          ) : (
            <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => handleOpenApproveRejectModal(item?.row?.original?.id)}>
              <i className="fa fa-times" aria-hidden="true">&times;</i>
            </button>
          )}
        </div>
      ),
    }
  ]);
  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [modalTodoReject, setModalTodoReject] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditReject, setIsEditReject] = useState(false);
  const [listOfLeaveData, setlistOfLeaveData] = useState([]);

  const employeeLeave = [
    {
      id: 1,
      cardColor: "primary",
      label: "Pending Leave",
      badgeClass: "success",
      counter: countOfCardData?.pending_count,
      bgcolor: "success",
      icon: "ri-account-box-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      cardColor: "primary",
      label: "Approve Leave",
      badgeClass: "success",
      counter: countOfCardData?.approved_count,
      bgcolor: "success",
      icon: "ri-team-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 3,
      cardColor: "primary",
      label: "Rejected Leave",
      badgeClass: "success",
      counter: countOfCardData?.rejected_count,
      bgcolor: "success",
      icon: "ri-file-list-3-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 4,
      cardColor: "primary",
      label: "Total Leave in Bucket",
      badgeClass: "success",
      counter: countOfCardData?.total_leave_count,
      bgcolor: "success",
      icon: "ri-file-list-3-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];
  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  const toggleReject = useCallback(() => {
    if (modalTodoReject) {
      setModalTodoReject(false);
    } else {
      setModalTodoReject(true);
    }
  }, [modalTodoReject]);


  const handleTodoClicksReject = () => {
    setModalTodoReject(!modalTodo);
    setIsEditReject(false);
    toggleReject();
  };

  const handleDeleteTodo = (data) => {
    if (todo) {
      setDeleteModal(false);
    }
    deleteLeave({ id: deleteState, isDeleted: true }).then((response) => {
      if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
        toast.success(response?.data?.message);
        setDeleteModal(false);
        viewLeave(id).then((response) => {
          setListLeave(response?.data?.data);
        });
      } else {
        toast.warning(response?.data?.message);
      }
    })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  const RegEx = /^[A-Za-z\s]*$/;
  // To do Task List validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      leaveType: (todo?.leaveType) || "",
      leaveDays: (todo?.leaveDays) || "",
      reason: (todo?.reason) || "",
      fromDate: (todo?.fromDate) || "",
      toDate: (todo?.toDate) || "",
    },
    validationSchema: Yup.object({
      leaveType: Yup.string().required(RequiredField("select leave")),
      leaveDays: Yup.string().required(RequiredField("number of days")),
      reason: Yup.string()
        .required(RequiredField("Reason"))
        .matches(RegEx, "Enter Only Characters"),
      fromDate: Yup.string().required(RequiredField("number of days")),
      toDate: Yup.string().required(RequiredField("number of days")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        validation.resetForm();
      } else {
        setLoading(true);
        addLeave({
          userId: parseInt(id),
          fromDate: values.fromDate,
          toDate: values.toDate,
          leaveDays: values.leaveDays,
          reason: values.reason,
          status: "Pending",
          leaveType: values.leaveType,
        })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              viewLeave(id).then((response) => {
                setListLeave(response?.data?.data);
              });
            } else if (
              response?.data?.statusCode === ResponseStatusEnum.DUPLICATE
            ) {
              toast.warning(response?.data?.message);
            }
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
        setDate2("");
        setDatee("");
        validation.resetForm();
      }
      toggle();
    },
  });

  const validationReject = useFormik({

    enableReinitialize: true,

    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Please Enter Reason"),
    }),
    onSubmit: (values) => {
      approveRejectLeave({
        id: leaveRejectData.id,
        userId: leaveRejectData.userId,
        rejectReason: values.reason,
        status: "Reject",
      }).then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          listOfLeave().then((response) => {
            setListLeave(response?.data?.data);
          });
        }
      });
      validationReject.resetForm();
      toggleReject();
    },
  });

  const hrLeaveType = [
    {
      options: [
        { label: "ALL", value: "ALL" }, 
        { label: "Casual Leave", value: "CL" },
        { label: "Comp Of Leave", value: "COL" },
        { label: "LWP Leave", value: "LWP" },
      ],
    },
  ];
  useEffect(() => {
    setLoading(true)
    filterOfLeaveDetails({
      userId: id,
    }).then((response) => {
      setlistOfLeaveData(response?.data?.data);
      setLoading(false);
    })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  useEffect(() => {
    setLoading(true)
    filterOfLeaveDetails({
      userId: id,
    }).then((response) => {
      setlistOfLeaveData(response?.data?.data);
      setLoading(false);
    })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
        setStateValue(false)
      });
  }, [stateValue])

  const leaveFilterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryLeave: null,
      status: null,
      startDate: null,
      endDate: null,
    },
    onSubmit: (values) => {
      setLoading(true)
      filterOfLeaveDetails({
        userId: id,
        startDate: values?.startDate,
        endDate: values?.endDate,
        categoryLeave: values?.categoryLeave,
        status: values?.status,
      })
        .then((response) => setlistOfLeaveData(response?.data?.data))
        .catch((err) => { return err }).finally(() => { setLoading(false) });
    },
  });

  const downloadTableData = () => {
    const main = document.getElementById('your-leavesId');
    const management = document.getElementById('leave-management');
    const filterId = document.getElementById('filteredId');
    const button = document.getElementById('leave-button');
    const paginationId = document.getElementById('paginationId');


    main.classList.add('nonPrintable');
    management.classList.add('nonPrintable');
    filterId.classList.add('nonPrintable');
    button.classList.add('nonPrintable');
    paginationId.classList.add('nonPrintable');
    window.print()

    main.classList.remove('nonPrintable');
    management.classList.remove('nonPrintable');
    filterId.classList.remove('nonPrintable');
    button.classList.remove('nonPrintable');
    paginationId.classList.remove('nonPrintable');
  }

  let data = listOfLeaveData?.sort((a, b) => b?.id - a?.id);
  return (

    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ApproveRejectModal
        show={isApproveRejectModalOpen}
        onCloseClick={handleCloseApproveRejectModal}
        onDeleteClick={() => handleDeleteLeave(selectedLeaveId)} 
        action="cancel leave" 
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid id="main">
            <BreadCrumb title="Leaves" pageTitle="HRMS" />
            <Row className="mb-4" id = 'leave-management'>
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Leave Management</h5>
              </div>

            </Row>
            <Row id = 'your-leavesId'>
              <Col lg={2}>
                <Widgets data={[employeeLeave[0], employeeLeave[1]]} />
              </Col>
              <Col lg={2}>
                <Widgets data={[employeeLeave[2], employeeLeave[3]]} />
              </Col>

              <Col lg={8}>
                <CardHeader className="align-items-center d-flex">
                  <Row className="w-100">
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">Your Leaves</h5>
                      </CardHeader>
                      <CardBody>
                        <FixedHeaderDatatables setStateValue={setStateValue} stateValue={stateValue}/>
                      </CardBody>
                    </Card>
                  </Row>
                </CardHeader>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Row className="w-100">
                  <Card id = "leaveDetails">
                    <CardHeader>
                      <h5 className="card-title mb-0">Leave Details</h5>
                    </CardHeader>
                    <CardBody className="mt-4">
                      <div className="p-3 bg-light rounded mb-4">
                        <Form
                          onSubmit={
                            leaveFilterValidation.handleSubmit
                          }
                        >
                          <Row className="g-2" id = 'filteredId'>
                            <Col>
                              <Input
                                name="categoryLeave"
                                type="select"
                                className={`form-select ${leaveFilterValidation.errors
                                  .categoryLeave &&
                                  leaveFilterValidation.values
                                    .categoryLeave
                                  ? "in-valid"
                                  : null
                                  }`}
                                id="choices-publish-status-input"
                                placeholder="Leave Type"
                                onChange={
                                  leaveFilterValidation.handleChange
                                }
                                onBlur={
                                  leaveFilterValidation.handleBlur
                                }
                                value={
                                  (leaveFilterValidation.values
                                    .categoryLeave)
                                }
                              >
                                {hrLeaveType?.map((item, key) => (                               
                                  <React.Fragment key={item?.id}>
                                  <option selected hidden>Leave Type</option>
                                    {item?.options?.map((item, key) => (
                                      <option
                                        value={item?.value}
                                        key={item?.id}
                                      >
                                        {item?.label}
                                      </option>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </Input>
                              {leaveFilterValidation?.errors
                                ?.categoryLeave &&
                                leaveFilterValidation?.values
                                  ?.categoryLeave ? (
                                <div className="invalid-feedback">
                                  {
                                    leaveFilterValidation?.errors
                                      ?.categoryLeave
                                  }
                                </div>
                              ) : null}
                            </Col>
                            <Col>
                              <select
                              className="form-select mb-3"
                              aria-label="Default select example"
                              id="selectreport"
                              name="status"
                              onChange={
                                leaveFilterValidation.handleChange
                              }
                              onBlur={
                                leaveFilterValidation.handleBlur
                              }
                              value={
                                leaveFilterValidation.values.status
                              }
                            >
                            <option selected hidden>Leave Status</option>
                              {leaveStatus?.map((res) => {
                                return (
                                  <>
                                    <option value={res}>{res}</option>
                                  </>
                                );
                              })}
                            </select>
                            </Col>
                            <Col>
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select date"
                                onBlur={
                                  leaveFilterValidation.handleBlur
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "d.m.y",
                                  mode: "range",
                                  onChange: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {

                                    leaveFilterValidation.setFieldValue(
                                      "startDate",
                                      dateFormatChange(
                                        selectedDates[0]
                                      )
                                    );
                                    leaveFilterValidation.setFieldValue(
                                      "endDate",
                                      dateFormatChange(
                                        selectedDates[1]
                                      )
                                    );
                                  },
                                }}
                              />
                            </Col>
                            <Col className="col-lg-auto">
                              <button
                                className="btn btn-primary createTask"
                                type="submit"
                              >
                                {!loading ? (
                                  "SEARCH"
                                ) : (
                                  <div className="d-inline-flex align-items-center">
                                    <Spinner size="sm" className="me-2" />
                                    Loading...
                                  </div>
                                )}
                              </button>
                            </Col> 
                          </Row>
                          <Row>
                            {listOfLeaveData?.length ? (
                              <TableContainer
                                id = 'dataTableId'
                                columns={leaveColumns}
                                data={data || []}
                                isAddUserList={false}
                                customPageSize={5}
                                divClass="table-responsive table-card mb-1"
                                tableClass="text-center table-wrap"
                                theadClass="table-light text-muted"
                              />
                            ) : (
                              <NoResultsMessage/>
                            )
                            }
                          </Row>
                        </Form>
                      </div>

                      {/* <div className='col-md-4' id = 'leave-button'>
                        <button type="button" class="custom-toggle active btn btn-success mt-3 align-right" fdprocessedid="amod4n" onClick={downloadTableData}>
                          <span class="icon-off"><i class="ri-download-line align-bottom me-1"></i>
                            Download Leave Details
                          </span>
                        </button>
                      </div> */}

                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>

            <Card>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {!!isEdit ? "Edit Leave" : "Add Leave"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                {" "}
                 :
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Your Name"
                name="name"
                disabled
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={name}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  From :
                </label>
                <Flatpickr
                  name="fromDate"
                  id="date-field"
                  validate={{ required: { value: true } }}
                  onBlur={validation.handleBlur}
                  disabled={role === "Admin"}
                  className={`form-control ${validation.errors.fromDate && "is-invalid"
                    }`}
                  placeholder="From date"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    onChange: function (selectedDates, dateStr, instance) {
                      validation.setFieldValue("fromDate", dateStr);
                    },
                  }}
                  value={validation.values.fromDate}
                />
                <div className="invalid-feedback">
                  {validation.errors.fromDate}
                </div>
              </Col>
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  To :
                </label>
                <Flatpickr
                  name="toDate"
                  id="date-field"
                  validate={{ required: { value: true } }}
                  onBlur={validation.handleBlur}
                  disabled={role === "Admin"}
                  className={`form-control ${validation.errors.toDate && "is-invalid"
                    }`}
                  placeholder="To date"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    onChange: function (selectedDates, dateStr, instance) {
                      validation.setFieldValue("toDate", dateStr);
                    },
                  }}
                  value={validation.values.toDate}
                />
                <div className="invalid-feedback">
                  {validation.errors.toDate}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Number of Days :
                  </label>
                  <Input
                    type="number"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter number of days"
                    name="leaveDays"
                    disabled={role === "Admin"}
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.leaveDays || ""}
                    invalid={
                      validation.touched.leaveDays &&
                        validation.errors.leaveDays
                        ? true
                        : false
                    }
                  />
                  {validation.touched.leaveDays &&
                    validation.errors.leaveDays ? (
                    <FormFeedback type="invalid">
                      {validation.errors.leaveDays}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Leave Reason :
              </label>
              <Input
                type="textarea"
                id="task-title-input"
                className="form-control"
                placeholder="Enter leave reason"
                name="reason"
                disabled={role === "Admin"}
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  validation.touched.reason && validation.errors.reason
                    ? true
                    : false
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Leave Type :
              </label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                id="selectreport"
                value={validation.values.leaveType || ""}
                name="leaveType"
                disabled={role === "Admin"}
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              >
                <option selected hidden>
                  Select Leave Type
                </option>
                <option value="Full"> Full</option>
                <option value="Half">Half</option>
                <option value="WFH">WFH</option>
              </select>
              {validation.touched.leaveType && validation.errors.leaveType ? (
                <FormFeedback type="invalid">
                  {validation.errors.leaveType}
                </FormFeedback>
              ) : null}
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodo(false)}
              >
                <i className="ri-close-fill align-bottom"></i>{" "}
                {role === "Admin" ? "Reject" : "Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {role === "Admin" ? "Approve" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        id="createTask"
        isOpen={modalTodoReject}
        toggle={toggleReject}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggleReject} className="p-3 bg-soft-success">
          {" "}
          {!!isEditReject ? "Edit Leave" : "Reject Leave"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validationReject.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                {" "}
                Full Name :
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Your Name"
                name="name"
                disabled
                // validate={{ required: { value: true }, }}
                // onChange={validation.handleChange}
                // onBlur={validation.handleBlur}
                value={leaveRejectNameData.firstName}
              // invalid={validation.touched.name && validation.errors.name ? true : false}
              />
              {/* {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null} */}
            </div>
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  From :
                </label>
                <Flatpickr
                  name="fromDate"
                  id="date-field"
                  disabled={role === "Admin"}
                  className="form-control"
                  placeholder="From date"
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  value={leaveRejectData.fromDate}
                />
              </Col>
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  To :
                </label>
                <Flatpickr
                  name="toDate"
                  id="date-field"
                  disabled={role === "Admin"}
                  className="form-control"
                  placeholder="To date"
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  value={leaveRejectData.toDate}
                />
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Number of Days :
                  </label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter task title"
                    name="leaveDays"
                    disabled={role === "Admin"}
                    value={leaveRejectData.leaveDays}
                  />
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Reject Leave Reason :
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Reject Reason"
                name="reason"
                validate={{ required: { value: true } }}
                onChange={validationReject.handleChange}
                onBlur={validationReject.handleBlur}
                value={validationReject.values.reason || ""}
                invalid={
                  validationReject.touched.reason &&
                    validationReject.errors.reason
                    ? true
                    : false
                }
              />
              {validationReject.touched.reason &&
                validationReject.errors.reason ? (
                <FormFeedback type="invalid">
                  {validationReject.errors.reason}
                </FormFeedback>
              ) : null}
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodoReject(false)}
              >
                <i className="ri-close-fill align-bottom"></i> {"Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {"Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default HRLeaves;
