export const ResponseStatusEnum = {
  SUCCESS: 200 || 201,
  CREATE: 201,
  ERROR: 400 || 404,
  DATA_NOT_FOUND: 404,
  DB_SERVER_ERROR: 500,
  DUPLICATE: 409,
  ACCEPTED: 202,
  ACCESS_DENIED: 403,
  NO_DATA_FOUND: "No Data Found!",
  DEFAULT_DATE: "2021-04-13T18:30:00.000Z",
  NOT_FOUND: 404,
};

export const religian = ["Hindu", "Muslim", "Christian", "Jain"];
export const nationality = ["Indian", "Canadian", "Pakistani", "Australian"];
export const relation = [
  "Mother",
  "Father",
  "Sister",
  "Brother",
  "Wife",
  "Husband",
];

export const vendor = ["Vendor1", "Vendor2", "Vendor3"];
export const leaveStatus = ["ALL", "Pending", "Approved", "Rejected"];

export const LIST_OF_ISSUES = [
  { value: 1, label: "Forget Password Issue" },
  { value: 2, label: "Device Issue" },
];

export const LIST_OF_DEVICES = [
  { value: "Laptop", label: "Laptop" },
  { value: "Mouse", label: "Mouse" },
  { value: "Bag", label: "Bag" },
  { value: "MousePad", label: "Mouse Pad" },
  { value: "KeyBoard", label: "KeyBoard" },
];

export const LIST_OF_CLIENT_DESIGNATION = [
  { value: "Director", label: "Director" },
  {
    value: "CEO",
    label: "Chief_Executive_Officer_(CEO)",
  },
  { value: "President", label: "President" },
  { value: "Vice President", label: "Vice President" },
  {
    value: "Business Development Executive",
    label: "Business Development Executive",
  },
  {
    value: "Business Development Manager",
    label: "Business Development Manager",
  },
  {
    value: "Human Resource",
    label: "Human Resource",
  },
  { value: "Talent Acquisition", label: "Talent Acquisition" },
  { value: "Others", label: "Others" },
];

export const ProjectTypes = [
  { value: "Fixed Project", label: "Fixed Project" },
  { value: "Dedicated Project", label: "Dedicated Project" },
];
export const NO_DATA_FOUND = "No Data Found!";
export const DEFAULT_DATE = "2021-04-13T18:30:00.000Z";
export const TIME_ZERO = "00:00:00";
