import { ADD_RESOURCES, COUNT_OF_SUPPORT, HR_LIST_SUPPORT_EMP_DATA, MULTI_COUNT, UPDATE_STATUS_OF_SUPPORT, VIEW_SUPPORT } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const supportEmployeeData = async (id, data) => {
    const response = await apiService2.post(`${HR_LIST_SUPPORT_EMP_DATA}/${id}`, data);
    return response;
};

export const addHrRaiseQuery = async (props) => {
    const response = await apiService2.post(`${ADD_RESOURCES}`, props);
    return response;
};

export const updateSupport = async (props) => {
    const response = await apiService2.post(`${ADD_RESOURCES}`, props);
    return response;
};

export const viewSupport = async (props) => {
    const id = props;
    const response = await apiService2.get(`${VIEW_SUPPORT}/${id}`);
    return response;
};

export const totalTicket = async (props) => {
    const response = await apiService2.post(`${COUNT_OF_SUPPORT}`, props);
    return response;
};

export const inProgress = async (props) => {
    const response = await apiService2.post(`${COUNT_OF_SUPPORT}`, props);
    return response;
};

export const openTicket = async (props) => {
    const response = await apiService2.post(`${COUNT_OF_SUPPORT}`, props);
    return response;
};

export const closeTicket = async (props) => {
    const response = await apiService2.post(`${COUNT_OF_SUPPORT}`, props);
    return response;
};

export const multiCount = async (id) => {
    const response = await apiService2.get(`${MULTI_COUNT}/${id}`);
    return response;
}

export const updateStatusOfSupport = async (props) => {
    const response = await apiService2.post(`${UPDATE_STATUS_OF_SUPPORT}`, props);
    return response;
}