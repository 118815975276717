import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/deleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import { getId, getToken } from "../../utils/helpers/custom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import {
  addRecruitment,
  addTechnicalRecruitment,
  editRecruitment,
  listOfRecruitment,
} from "../../api/recruitmentApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import TableContainer from "../../components/common/tableContainer";
import Rating from "react-rating";
import { countOfData, listOfData } from "../../api/commonApi";
import { listOfEmployee } from "../../api/employeeApi";
import Loader from "../../components/common/loader";
import NoResultsMessage from "../../components/noResultMessage";
import { conditionallyRequiredGoogle } from "../../utils/helpers/formUtils";
import { dateFormat, dateFormatChange } from "../../utils/helpers/common";

const Recruitment = () => {
  document.title = "Recruitment";

  const [deleteModal, setDeleteModal] = useState(false);
  const [modalProject, setModalProject] = useState(false);
  const [recruitmentData, setRecruitmentData] = useState([]);
  const [recruitmentId, setRecruitmentId] = useState();
  const [technicalRoundStatus, setTechnicalRoundStatus] = useState();
  const [recruitmentPerData, setRecruitmentPerData] = useState([]);
  const [recruitmentPerDataFeedBack, setRecruitmentPerDataFeedBack] = useState(
    []
  );

  const [recruitmentDataCard, setRecruitmentDataCard] = useState("");
  const [designation, setDesignation] = useState([]);
  const [Qualication, setQualication] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const role = getToken();
  const profileId = getId();
  const Status = ({ status }) => {
    switch (status) {
      case "Rejected":
        return (
          <span className="badge badge-soft-danger text-uppercase">
            {status}
          </span>
        );
      case "Selected":
        return (
          <span className="badge badge-soft-success text-uppercase">
            {status}
          </span>
        );
      default:
        return (
          <span className="badge badge-soft-warning text-uppercase">
            {status}
          </span>
        );
    }
  };


  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalRecruitment, setTotalRecruitment] = useState();
  const [technicalRound, setTecnicalRound] = useState("");
  const [editRecruitmentCellData, setEditRecruitmentCellData] = useState();

  // details modal
  const [detail, setDetail] = useState(null);
  const [modaldetail, setmModaldetail] = useState(false);

  const detailtoggle = useCallback(() => {
    if (modaldetail) {
      setmModaldetail(false);
      setDetail(null);
    } else {
      setmModaldetail(true);
    }
  }, [detail]);

  const candidateDetails = () => {
    setDetail("");
    setmModaldetail(!modaldetail);
    detailtoggle();
  };

  // tech round modal
  const [techround, settechround] = useState(null);
  const [modaltechround, setmodaltechround] = useState(false);

  const techroundtoggle = useCallback(() => {

    if (modaltechround) {
      setmodaltechround(false);
      settechround(null);
      setIsEdit(false);
      validation.resetForm();
      validationRecruitment.resetForm();
    } else {
      validation.resetForm();

      setmodaltechround(true);
    }
  }, [techround]);

  const addtechround = () => {
    settechround("");
    setmodaltechround(!modaltechround);
    techroundtoggle();
  };

  // end tech round modal
  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
      validation.resetForm();
      setIsEdit(false);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  // Toggle Project Modal
  const toggleProject = () => {
    if (modalProject) {
      setModalProject(false);
    } else {
      setModalProject(true);
    }
  };

  const fetchData = () => {
    setLoading(true);
    listOfData({
      modelName: "qualificationModel",
    })
      .then((res) => {
        setQualication(res?.data);
      })
      .catch((err) => err)
      .finally(() => { });

    listOfData({
      modelName: "technologyModel",
      selectionCriteria: ["id", "techName"],
    })
      .then((resp) => {
        setDesignation(resp?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });

    listOfEmployee({
      isDeleted: false,
      role: ["Employee"],
    })
      .then((resp) => {
        setEmployeeData(resp?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });

    countOfData({
      modelName: "recruitmentModel",
      condition: {
        "isCancel": 0
      }
    })
      .then((response) => {
        setTotalRecruitment(response?.data?.data);
      })
      .catch((err) => err);
    countOfData({
      modelName: "feedBackModel",
      condition: {},
    })
      .then((response) => {
        setTecnicalRound(response?.data?.data);
      })
      .catch((err) => err);

    countOfData({
      modelName: "recruitmentModel",
      condition: {
        "isCancel": 0
      }
    })
      .then((res) => {
        setRecruitmentDataCard(res?.data?.data);
      })
      .catch((err) => err)
      .finally(() => { });
    listOfRecruitment()
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          setRecruitmentData(res?.data?.data);
        } else {
          setRecruitmentData([]);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteTodo = () => {
    if (todo) {
      setDeleteModal(false);
    }
  };
  const [newimage, setNewimage] = useState(null);

  const uploadImage = (e) => {
    setNewimage(e?.target?.files[0]);
  };

  const projectValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Project Title"),
    }),
    onSubmit: (values) => {
      const newProjectData = {
        id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        title: values.title,
        subItem: [{ id: 1, version: "v1.0.0", iconClass: "danger" }],
      };
      projectValidation.resetForm();
      toggleProject();
    },
  });

  // To do Task List validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      cname: editRecruitmentCellData?.candidateName || "",
      education: editRecruitmentCellData?.qualification?.id || "",
      status: editRecruitmentCellData?.status || "",
      hrfeedback: editRecruitmentCellData?.review || "",
      technology: editRecruitmentCellData?.technology?.id || "",
      hrintdate: editRecruitmentCellData?.dateOfInterview || "",
      hrintTime: editRecruitmentCellData?.timeOfInterview || "",
      typeOfExp: editRecruitmentCellData?.experience || "",
      english: editRecruitmentCellData?.english,
      communication: editRecruitmentCellData?.communication,
      confidence: editRecruitmentCellData?.confidence,
      resume: editRecruitmentCellData?.resume,
    },
    validationSchema: Yup.object({
      cname: Yup.string().required(RequiredField("Candidate Name")),
      education: Yup.string().required(RequiredField("Education")),
      hrfeedback: Yup.string().required(RequiredField("HR Feedback")),
      hrintdate: Yup.string().required(RequiredField("Date of HR Interview")),
      typeOfExp: Yup.string().required(RequiredField("Experience ")),
      status: Yup.string().required(RequiredField("Status")),
      hrintTime: Yup.string().required(RequiredField("Time of HR Interview")),
      technology: Yup.string().required(RequiredField("Technology")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (isEdit) {
        const payload = {
          candidateName: values.cname,
          qualificationId:  parseInt(values.education),
          status: values.status,
          resume: newimage,
          review: values.hrfeedback,
          english:  values.english !== undefined ? values.english : editRecruitmentCellData?.english,
          communication: values.communication !== undefined ? values.communication : editRecruitmentCellData?.communication,
          confidence:  values.confidence !== undefined ? values.confidence : editRecruitmentCellData?.confidence,
          technologyId:  parseInt(values.technology),
          timeOfInterview: values.hrintTime,
          dateOfInterview: values.hrintdate,
          experience: `${values.typeOfExp}`,
        }
        editRecruitment(editRecruitmentCellData?.id, payload).then((resp) => {
          if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(resp?.data?.message);
            fetchData();
          } else {
            toast.error(resp?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      } else {
        const payload = {
          addedBy: profileId,
          candidateName: values.cname,
          qualificationId: values.education,
          status: values.status,
          resume: newimage,
          review: values.hrfeedback,
          english: values.english,
          communication: values.communication,
          confidence: values.confidence,
          link: values.googlemeetlink,
          technologyId: values.technology,
          timeOfInterview: values.hrintTime,
          dateOfInterview: values.hrintdate,
          experience: `${values.typeOfExp}`,
        }
        addRecruitment(payload)
          .then((resp) => {
            if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(resp?.data?.message);
              fetchData();
            } else {
              toast.error(resp?.data?.message);
            }
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
        validation.resetForm();
      }
      toggle();
    },
  });
  
  const validationRecruitment = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      googlemeetlink: "",
      technology: "",
      round: "",
      mode: "",
      Assignee: "",
      dateOfInterview: "",
      timeOfInterview: "",
    },
    validationSchema: Yup.object({
      Assignee: Yup.string().required(RequiredField("Assignee")),
      technology: Yup.string().required(RequiredField("Technology")),
      round: Yup.string().required(RequiredField("Technical Round")),
      mode: Yup.string().required(RequiredField("mode")),

      googlemeetlink: Yup.string().when(['mode'], (mode, schema) => {
        if (mode === 'Offline') {
          return schema.notRequired();
        } else {
          return schema.concat(conditionallyRequiredGoogle(isEdit, RequiredField('Google Meet Link')));
        }
      }),
      dateOfInterview: Yup.string().required(
        RequiredField("Date Of Interview")
      ),
      timeOfInterview: Yup.string().required(
        RequiredField("Time Of Interview")
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      addTechnicalRecruitment(recruitmentId, {
        assineeId: values.Assignee,
        link: values.googlemeetlink,
        technologyId: values.technology,
        timeOfInterview: values.timeOfInterview,
        type: values.mode,
        round: values.round,
        dateOfInterview: values.dateOfInterview,
      })
        .then((resp) => {
          if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(resp?.data?.message);
            fetchData();
          } else {
            toast.error(resp?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      validationRecruitment.resetForm();
      techroundtoggle();
    },
  });

   // Add To do
   const handleTodoClicks = () => {
    validation.resetForm();
    setEditRecruitmentCellData("");
    setTodo("");
    setModalTodo("");
    setModalTodo(!modalTodo);
    setModalTodo([]);
    toggle();
    setIsEdit(false);
  };

  const editRecruitmentHandle = (data) => {
    validation.resetForm();
    setEditRecruitmentCellData(data);

    setModalTodo("");
    setIsEdit(true);
    setModalTodo(!modalTodo);
    setModalTodo([]);
    setTodo([]);
    toggle();
  };

  const recruitmentSortingData = recruitmentData?.sort((a, b) => b?.id - a?.id);

  const QueryColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "dateOfInterview",
        Cell: (cell) => {
          const date = cell?.row?.original?.dateOfInterview;
          return (
            <div>
              {dateFormat(date)}
            </div>
          )
        }
      },
      {
        Header: "Candidate Name",
        accessor: "candidateName",
        filterable: false,
      },
      {
        Header: "HR Status",
        accessor: "status",
        filterable: false,
        Cell: (cell) => [<><Status status={cell?.value} /></>]
      },
      {
        Header: "Tech 1",
        accessor: "interviewFeedback",
        filterable: false,
        Cell: (cell) => [
          <>{cell?.row?.original?.feedBack?.[0]?.status === undefined ? "---" :
            <Status status={cell?.row?.original?.feedBack?.[0]?.status} />
          }
          </>
        ]
      },
      {
        Header: "Tech 2",
        accessor: "review",
        filterable: false,
        Cell: (cell) => [
          <>
            {cell?.row?.original?.feedBack?.[1]?.status === undefined ? "---" :
              <Status status={cell?.row?.original?.feedBack?.[1]?.status} />
            }

          </>
        ]
      },
      {
        Header: "Action",
        Cell: (cellProps, key) => {
          return (
            <div className="hstack gap-2 custom-css-action">
              <button
                id={`fullreview-${key + 1}`}
                className="detailTask btn btn-sm btn-soft-info remove-list"
                onClick={() => {
                  setRecruitmentPerData(cellProps.row.original);
                  setRecruitmentPerDataFeedBack(
                    cellProps.row.original.feedBack
                  );
                  candidateDetails();
                }}
              >
                <i className="ri-eye-line"></i>
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="View Full Feedbacks"
                  anchorId={`fullreview-${key + 1}`}
                />
              </button>
              {cellProps?.row?.original?.status !== "Rejected" && <button
                id={`techround-${key + 1}`}
                disabled={
                  cellProps?.row?.original?.feedBack?.[0]?.status === "Rejected" ||
                  cellProps?.row?.original?.feedBack?.[1]?.status === "Rejected" ||
                  cellProps?.row?.original?.feedBack?.[1]?.status === "Selected" ||
                  cellProps?.row?.original?.status === "Pending" ||
                  cellProps?.row?.original?.feedBack?.[0]?.status === "Pending"
                }
                className="techround btn btn-sm btn-soft-info remove-list"
                onClick={() => {
                  setRecruitmentId(cellProps.row.original.id);
                  setTechnicalRoundStatus(cellProps.row.original.feedBack[0])
                  addtechround();
                }}
              >
                <i className="ri-macbook-line"></i>
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  className="zindex1"
                  content="Technical Round"
                  anchorId={`techround-${key + 1}`}
                />
              </button> }
              <button
              id="edit"
              className="btn btn-sm btn-soft-info edit-list"
              disabled={
                cellProps?.row?.original?.feedBack?.length > 0
              }
              onClick={() => editRecruitmentHandle(cellProps.row.original)}
            >
              <i className="ri-pencil-fill align-bottom" />
              <ReactTooltip
                  place="bottom"
                  variant="info"
                  className="zindex1"
                  content="Edit"
                  anchorId= "edit"
                />
            </button>
            </div>
          );
        },
      },
    ]
  );

  const ecomWidgets = [
    {
      id: 1,
      cardColor: "primary",
      label: "Total Recruitment",
      // badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      // percentage: "+10",
      counter: totalRecruitment,
      bgcolor: "success",
      icon: "bx bx-devices",
      decimals: 0,
      prefix: "",
      suffix: "",
    }
  ];
  const isOfflineMode = validationRecruitment.values.mode === 'Offline';

  const qualification = recruitmentPerData?.qualification?.qualification;
  const technologies = recruitmentPerData?.technology?.techName;

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Recruitment" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Recruitment</h5>
              </div>

              {role !== "Admin" && (
                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <button
                      className="btn btn-primary createTask"
                      type="button"
                      onClick={() => handleTodoClicks()}
                    >
                      + Candidate
                    </button>
                  </div>
                </div>
              )}
            </Row>
            <Row>
              <Widgets data={ecomWidgets} />
            </Row>
            <div className="table-responsive">
              <Card>
                <CardBody>
                  {recruitmentData?.length ? (
                    <TableContainer
                      columns={QueryColumns}
                      data={recruitmentSortingData || []}
                      isAddUserList={false}
                      customPageSize={5}
                      divClass="table-responsive table-card mb-1"
                      tableClass="text-center table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <NoResultsMessage />
                  )}
                </CardBody>
              </Card>
            </div>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {"Add Candidate"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Candidate Name
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Candidate Name"
                name="cname"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.cname || ""}
                invalid={
                  validation.touched.cname && validation.errors.cname
                    ? true
                    : false
                }
              />
              {validation.touched.cname && validation.errors.cname ? (
                <FormFeedback type="invalid">
                  {validation.errors.cname}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  HR Interviewed Date :
                </label>
                <Flatpickr
                  name="hrintdate"
                  id="date-field"
                  className={`form-select ${
                    validation.errors.hrintdate && "is-invalid"
                  }`}
                  // className="form-control"
                  placeholder="Enter interviewed date"
                  value={validation.values.hrintdate}
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    onChange: function (selectedDates, dateStr, instance) {
                      validation
                        .setFieldValue("hrintdate", dateStr)
                        .then((res) => res)
                        .catch((err) => err);
                    },
                  }}
                />
                <div className="invalid-feedback">
                  {validation.errors.hrintdate}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Type of Experience
                  </label>

                  <select
                    className={`form-select ${
                      validation.errors.typeOfExp && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="typeofExperience"
                    name="typeOfExp"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.typeOfExp}
                  >
                    <option value="" selected hidden>
                      Select Experience
                    </option>
                    <option value="0">0 Year</option>
                    <option value="1">1 Year</option>
                  </select>
                  <div className="invalid-feedback">
                    {validation.errors.typeOfExp}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={12}>
                <label htmlFor="task-duedate-input" className="form-label">
                  HR Interviewed Time :
                </label>
                <Flatpickr
                  name="hrintTime"
                  id="date-field"
                  className={`form-select ${
                    validation.errors.hrintTime && "is-invalid"
                  }`}
                  // className="form-control"
                  placeholder="Enter interviewed time"
                  value={validation.values.hrintTime}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                    onChange: function (selectedDates, dateStr, instance) {
                      validation
                        .setFieldValue("hrintTime", dateStr)
                        .then((res) => res)
                        .catch((err) => err);
                    },
                  }}
                />
                <div className="invalid-feedback">
                  {validation.errors.hrintTime}
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Qualification{" "}
                  </label>
                  <select
                    className={`form-select ${
                      validation.errors.education && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    validate={{ required: { value: true } }}
                    value={validation.values.education}
                    name="education"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  >
                    <option value="" selected disabled hidden>
                      Select qualification
                    </option>
                    {Qualication?.map((res) => {
                      return (
                        <>
                          <option key={res.id} value={res.id}>
                            {res.qualification}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {validation.errors.education}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    HR Feedback
                  </label>
                  <Input
                    type="text"
                    id="task-title1"
                    className="form-control"
                    placeholder="Enter HR Feedback"
                    name="hrfeedback"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.hrfeedback || ""}
                    invalid={
                      validation.touched.hrfeedback &&
                      validation.errors.hrfeedback
                        ? true
                        : false
                    }
                  />
                  {validation.touched.hrfeedback &&
                  validation.errors.hrfeedback ? (
                    <FormFeedback type="invalid">
                      {validation.errors.hrfeedback}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Upload Resume{" "}
                  </label>
                  <Input
                    type="file"
                    id="task-title2"
                    className="form-control"
                    placeholder="Enter task title"
                    onChange={(e) => uploadImage(e)}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Status{" "}
                  </label>
                  <select
                    className={`form-select ${
                      validation.errors.status && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="status"
                    value={validation.values.status}
                  >
                    <option value="" selected disabled hidden>
                      Select status
                    </option>
                    <option value="Selected"> Selected</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                  <div className="invalid-feedback">
                    {validation.errors.status}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Technology
                  </label>
                  <select
                    className={`form-select ${
                      validation.errors.typeOfExp && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="technology"
                    value={validation.values.technology}
                  >
                    <option value="" selected disabled hidden>
                      Select designation
                    </option>
                    {designation?.map((res) => {
                      return (
                        <>
                          <option key={res.id} value={res.id}>
                            {res.techName}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {validation.errors.technology}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <label htmlFor="task-title-input0" className="form-label">
                  English
                </label>
                <div id="rater-step-english">
                  <Rating
                    name="english"
                    initialRating={validation.values.english || 0}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("english", value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <label htmlFor="task-title-input1" className="form-label">
                  Communication
                </label>
                <div id="rater-step-communication" dir="ltr">
                  <Rating
                    name="communication"
                    initialRating={validation.values.communication}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("communication", value);
                    }}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <label htmlFor="task-title-input2" className="form-label">
                  Confidence
                </label>
                <div id="rater-step-confidence" dir="ltr">
                  <Rating
                    name="confidence"
                    initialRating={validation.values.confidence}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("confidence", value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => {
                  setModalTodo(false);
                }}
              >
                <i className="ri-close-fill align-bottom"></i>{" "}
                Cancel
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
              {isEdit === true ? "Edit Data" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      {/* deatils modal */}

      <Modal
        id="detailTask"
        isOpen={modaldetail}
        toggle={detailtoggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={detailtoggle} className="p-3 bg-soft-success">
          {" "}
          {"Candidate Full Details"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="d-flex">
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Candidate Name :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.candidateName
                    ? "No Data Found"
                    : recruitmentPerData?.candidateName}
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Date :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.dateOfInterview
                    ? "No Data Found"
                    : recruitmentPerData?.dateOfInterview}
                </div>
              </div>
            </div>
            
            {recruitmentPerDataFeedBack?.length !== 0 && (
              <div className="d-flex mt-1">
                <div className="flex-shrink-0">
                  <i className="ri-checkbox-circle-fill text-success"></i>
                </div>
                <div className="flex-grow-1 row">
                  <div className="col-sm-5 ms-2">
                    {" "}
                    <b>Assignee Name :</b>
                  </div>
                  <div className="col-sm-6">
                    {recruitmentPerDataFeedBack?.length === 0
                      ? "  No Data Found"
                      : recruitmentPerDataFeedBack.map((res, index) => {
                          return ` ${index + 1}.  ${
                            res?.authUser?.firstName
                          }   ${res?.authUser?.lastName}   `;
                        })}
                    <br />
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Education :</b>
                </div>
                <div className="col-sm-6">
                  {qualification || "No Data Found"}
                  <br />
                </div>
              </div>
            </div>

            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Technology :</b>
                </div>
                <div className="col-sm-6">
                  {technologies || "N/A"}
                  <br />
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>HR Feedback :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.review
                    ? "No Data Found"
                    : recruitmentPerData?.review}
                </div>
              </div>
            </div>

            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>HR Status :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.status
                    ? "No Data Found"
                    : recruitmentPerData?.status}
                </div>
              </div>
            </div>
            <hr></hr>

            { (recruitmentPerDataFeedBack?.length > 0 && recruitmentPerData?.status !== "Rejected") && (
              <React.Fragment>
                <div className="d-flex mt-1">
                  <div className="flex-shrink-0">
                    <i className="ri-checkbox-circle-fill text-success"></i>
                  </div>
                  <div className="flex-grow-1 row">
                    <div className="col-sm-5 ms-2">
                      <b>Technical Status 1 :</b>
                    </div>
                    <div className="col-sm-6">
                      {!recruitmentPerDataFeedBack?.[0]?.status
                        ? "Pending"
                        : recruitmentPerDataFeedBack?.[0]?.status}
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-1">
                  <div className="flex-shrink-0">
                    <i className="ri-checkbox-circle-fill text-success"></i>
                  </div>
                  <div className="flex-grow-1 row">
                    <div className="col-sm-5 ms-2">
                      {" "}
                      <b>Technical Review 1:</b>
                    </div>
                    <div className="col-sm-6">
                      {!recruitmentPerDataFeedBack?.[0]?.review
                        ? "---"
                        : recruitmentPerDataFeedBack?.[0]?.review}
                    </div>
                  </div>
                </div>
                <hr></hr>
              </React.Fragment>
            )}

            {(recruitmentPerDataFeedBack?.length > 1 && recruitmentPerData?.status !== "Rejected") && (
              <React.Fragment>
                {recruitmentPerDataFeedBack?.[0]?.status !== "Rejected" && (
                  <React.Fragment>
                    <div className="d-flex mt-1">
                      <div className="flex-shrink-0">
                        <i className="ri-checkbox-circle-fill text-success"></i>
                      </div>
                      <div className="flex-grow-1 row">
                        <div className="col-sm-5 ms-2">
                          <b>Technical Status 2 :</b>
                        </div>
                        <div className="col-sm-6">
                          {!recruitmentPerDataFeedBack?.[1]?.status
                            ? "Pending"
                            : recruitmentPerDataFeedBack?.[1]?.status}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-1">
                      <div className="flex-shrink-0">
                        <i className="ri-checkbox-circle-fill text-success"></i>
                      </div>
                      <div className="flex-grow-1 row">
                        <div className="col-sm-5 ms-2">
                          <b>Technical Review 2 :</b>
                        </div>
                        <div className="col-sm-6">
                          {!recruitmentPerDataFeedBack?.[1]?.review
                            ? "---"
                            : recruitmentPerDataFeedBack?.[1]?.review}
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Final Status :</b>
                </div>
                <div className="col-sm-6">
                  {recruitmentPerData?.finalStatus}
                </div>
              </div>
            </div>
            {/* In Future we have to refector this code start  */}
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        id="techround"
        isOpen={modaltechround}
        toggle={techroundtoggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={techroundtoggle} className="p-3 bg-soft-success">
          {" "}
          {technicalRoundStatus?.status === "Selected"
            ? "Technical Round 2"
            : "Technical Round 1"}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validationRecruitment.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="">
                  <label htmlFor="task-title-input" className="form-label">
                    Technology{" "}
                  </label>
                  <select
                    className={`form-select ${
                      validationRecruitment.errors.technology && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    name="technology"
                    validate={{ required: { value: true } }}
                    onChange={validationRecruitment.handleChange}
                    onBlur={validationRecruitment.handleBlur}
                  >
                    <option value="" selected hidden>
                      Select Technology{" "}
                    </option>
                    {designation?.map((res) => {
                      return (
                        <>
                          <option key={res.id} value={res.id}>
                            {res?.techName}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {validationRecruitment.errors.technology}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="">
                  <label htmlFor="task-title-input" className="form-label">
                    Technical Round{" "}
                  </label>
                  <select
                    className={`form-select ${
                      validationRecruitment.errors.round && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    name="round"
                    validate={{ required: { value: true } }}
                    onChange={validationRecruitment.handleChange}
                    onBlur={validationRecruitment.handleBlur}
                  >
                    <option selected hidden>
                      Select Technical Round{" "}
                    </option>
                    {technicalRoundStatus?.status === "Selected" ? (
                      <option value="Technical2">Technical 2</option>
                    ) : (
                      <>
                        <option value="Technical1">Technical 1</option>
                      </>
                    )}
                  </select>
                  <div className="invalid-feedback">
                    {validationRecruitment.errors.round}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="">
                  <label htmlFor="task-title-input" className="form-label">
                    Mode{" "}
                  </label>
                  <select
                    className={`form-select ${
                      validationRecruitment.errors.mode && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    name="mode"
                    validate={{ required: { value: true } }}
                    onChange={validationRecruitment.handleChange}
                    onBlur={validationRecruitment.handleBlur}
                  >
                    <option value="" selected disabled hidden>
                      Select Mode
                    </option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </select>
                  <div className="invalid-feedback">
                    {validationRecruitment.errors.mode}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="">
                  <label htmlFor="task-title-input" className="form-label">
                    Assignee Name
                  </label>
                  <select
                    className={`form-select ${
                      validationRecruitment.errors.Assignee && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    name="Assignee"
                    validate={{ required: { value: true } }}
                    onChange={validationRecruitment.handleChange}
                    onBlur={validationRecruitment.handleBlur}
                  >
                    <option value="" selected hidden>
                      Select Assignee
                    </option>
                    {employeeData?.map((res) => {
                      return (
                        <>
                          <option key={res.id} value={res.id}>
                            {res.firstName} {res.lastName}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {validationRecruitment.errors.Assignee}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  Interview Date :
                </label>
                <Flatpickr
                  name="dateOfInterview"
                  id="date-field"
                  className={`form-select ${
                    validationRecruitment.errors.mode && "is-invalid"
                  }`}
                  placeholder="Enter interview date"
                  value={validationRecruitment.values.dateOfInterview}
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                    onChange: function (selectedDates, dateStr, instance) {
                      validationRecruitment.setFieldValue(
                        "dateOfInterview",
                        dateStr
                      );
                    },
                  }}
                />
                <div className="invalid-feedback">
                  {validationRecruitment.errors.dateOfInterview}
                </div>
              </Col>
              <Col lg={6}>
                <div className="">
                  <label htmlFor="task-title-input" className="form-label">
                    Interview Time :
                  </label>
                  <Flatpickr
                    name="timeOfInterview"
                    id="date-field"
                    className={`form-select ${
                      validationRecruitment.errors.mode && "is-invalid"
                    }`}
                    // className="form-control"
                    placeholder="Enter interview time"
                    value={validationRecruitment.values.timeOfInterview}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      onChange: function (selectedDates, dateStr, instance) {
                        validationRecruitment.setFieldValue(
                          "timeOfInterview",
                          dateStr
                        );
                      },
                    }}
                  />
                  <div className="invalid-feedback">
                    {validationRecruitment.errors.timeOfInterview}
                  </div>
                </div>
              </Col>
            </Row>
            {!isOfflineMode && (
              <Row className="g-4 mb-3">
                <Col lg={12}>
                  <label htmlFor="task-duedate-input" className="form-label">
                    Google Meet Link :
                  </label>
                  <Input
                    type="link"
                    className={`form-select ${
                      validationRecruitment.errors.googlemeetlink &&
                      "is-invalid"
                    }`}
                    placeholder="Enter Google title"
                    name="googlemeetlink"
                    onChange={validationRecruitment.handleChange}
                    onBlur={validationRecruitment.handleBlur}
                    value={validationRecruitment.values.googlemeetlink || ""}
                  />
                  <div className="invalid-feedback">
                    {validationRecruitment.errors.googlemeetlink}
                  </div>
                </Col>
              </Row>
            )}
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => {
                  techroundtoggle(false);
                  validationRecruitment.resetForm();
                  validation.resetForm();
                }}
              >
                <i className="ri-close-fill align-bottom"></i>{" "}
                {role === "Admin" ? "Reject" : "Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {"Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Recruitment;
