import React, { useEffect, useState } from "react";
import Loader from "../../components/common/loader";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import { listOfEmployee } from "../../api/employeeApi";
import { getRole } from '../../utils/helpers/custom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { verifyEmail } from "../../api/forgetPasswordApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import { RequiredField, } from "../../utils/helpers/validationMasseges";

const ResetPassword = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userRoleCookie = getRole();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (userRoleCookie === "Admin") {
      listOfEmployee({
        isDeleted: false,
        role: ["Employee", "HR"],
      })
        .then((resp) => {
          setEmployeeData(resp?.data?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => { });
    } else {
      listOfEmployee({
        isDeleted: false,
        role: ["Employee"],
      })
        .then((resp) => {
          setEmployeeData(resp?.data?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => { });
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedEmail: '', 
    },
    validationSchema: Yup.object({
      selectedEmail: Yup.string().required(RequiredField("Employee")),
    }),
    onSubmit: (values,{ setSubmitting, resetForm }) => {
      setLoading(true);

      verifyEmail({ email: values?.selectedEmail })
        .then((response) => {
          const responseBody = response?.data;
            if (responseBody?.statusCode === ResponseStatusEnum.SUCCESS || responseBody?.statusCode === ResponseStatusEnum.ACCEPTED) {
                toast.success(responseBody?.message);
                resetForm();
            } else {
                toast.error(responseBody?.message);
            }
        })
        .catch((err) => {
          toast.error(err?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  document.title = "Reset Password";

  return (
    <div className="page-content">
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Container fluid>
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Reset Password</h5>
              </div>
            </Row>
            <Card>
              <CardBody>
                <Form id="Submit-Email" 
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">Employees:</label>
                        <select
                          className={`form-select`}
                          aria-label="Default select example"
                          name="selectedEmail"
                          id="coursesDropdown"
                          value={formik.values.selectedEmail}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="">Select Employee</option>
                          {employeeData?.map((employee) => (
                            <option key={employee.id} value={employee.email}>
                              {employee.firstName} {employee.lastName}
                            </option>
                          ))}
                        </select>
                        {formik.touched.selectedEmail && formik.errors.selectedEmail ? (
                          <div className="text-danger">{formik.errors.selectedEmail}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="addNewMember"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </React.Fragment>
      )}
    </div>
  );
};

export default ResetPassword;