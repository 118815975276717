import {
  VERIFY_EMAIL,
  UPDATE_PASSWORD,
  VERIFY_OTP,
  CHANGE_PASSWORD,
} from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const verifyEmail = async (data) => {
  const response = await apiService2.post(`${VERIFY_EMAIL}`, data);
  return response;
};

export const verifyOtp = async (data) => {
  const response = await apiService2.post(`${VERIFY_OTP}`, data);
  return response;
};

export const updatePassword = async (data) => {
  const response = await apiService2.post(`${UPDATE_PASSWORD}`, data);
  return response;
};

export const changePassword = async (id, data) => {
  const response = await apiService2.put(`${CHANGE_PASSWORD}/${id}`, data);
  return response;
};
