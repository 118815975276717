import { APPROVE_JUNIOR_TASK, APPROVE_JUNIOR_TIMESHEET, JUNIOR_TASKSHEET_LIST, APPROVE_REJECT_TIMESHEET, LIST_OF_JUNIOR, VIEW_JUNIOR, LIST_OF_REVIEW_DETAILS } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const viewJunior = async (props) => {
    const response = await apiService2.get(`${VIEW_JUNIOR}/${props}`);
    return response;
};

export const approveJuniorTimesheet = async(props)=>{
    const response = await apiService2.put(`${APPROVE_JUNIOR_TIMESHEET}/${props.id}`,props)
    return response
}

export const approvetasksheet = async (id,props) => {
    const response = await apiService2.put(`${APPROVE_JUNIOR_TASK}/${id}`, props);
    return response;
};

export const listOfJunior = async (props) => {
    const id = props;
    const response = await apiService2.get(`${LIST_OF_JUNIOR}/${id}`, props);
    return response;
};

export const approveRejectTimesheet = async (id,data) => {
    const response = await apiService2.put(`${APPROVE_REJECT_TIMESHEET}/${id}`, data);
    return response;
};

export const listOfJuniorTaskSheet = async (data) => {
    const response = await apiService2.post(`${JUNIOR_TASKSHEET_LIST}`, data);
    return response;
};

export const listOfReviewDetails = async (data) => {
    const response = await apiService2.post(`${LIST_OF_REVIEW_DETAILS}`, data);
    return response;
};
