import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import TableContainer from "../../components/common/tableContainer";
import DeleteModal from "../../components/common/deleteModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Loader from "../../components/common/loader";
import { listOfData } from "../../api/commonApi";
import {
  addCompanyDetails,
  deleteCompanyDetails,
  editCompanyDetails,
} from "../../api/companyApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import NoResultsMessage from "../../components/noResultMessage";
import { Link, useNavigate } from "react-router-dom";
import { getId } from "../../utils/helpers/custom";

const CompanyManagement = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfCompanyData, setListOfCompanyData] = useState();
  const [editCompanyData, setEditCompanyData] = useState();
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const authId = getId();
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    listOfData({
      modelName: "companyModel",
      condition: { isDeleted: 0 },
      selectionCriteria: ["id", "companyName"],
    })
      .then((res) => {
        setListOfCompanyData(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const projectColumns = useMemo(() => [
    {
      Header: "Sr No.",
      Cell: ({ row }) => {
        const indexFromStart = row?.index + 1;
        return <div>{indexFromStart}</div>;
      },
    },
    {
      Header: "Company Name",
      accessor: "companyName",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                id={`editMode-${cellProps?.cell?.row?.index}`}
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() =>
                  editCompanyDetailsClick(cellProps?.cell?.row?.original)
                }>
                <i className="ri-pencil-fill align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="Edit"
                  anchorId={`editMode-${cellProps?.cell?.row?.index}`}
                />
              </button>
              <button
                id={`deleteMode-${cellProps.cell.row.index}`}
                className="btn btn-sm btn-soft-danger remove-list"
                onClick={() =>
                  companyDelete(cellProps?.cell?.row?.original?.id)
                }>
                <i className="ri-delete-bin-5-fill align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="error"
                  content="Delete"
                  anchorId={`deleteMode-${cellProps?.cell?.row?.index}`}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const editCompanyDetailsClick = (cellProps) => {
    setEditCompanyData(cellProps);
    setIsEdit(true);
    navigate("/company-management/");
  };

  const companyDelete = (companyId) => {
    setSelectedCompanyId(companyId);
    setDeleteModal(true);
  };

  const companyDeleteTodo = () => {
    setLoading(true);
    setDeleteModal(false);
    deleteCompanyDetails(selectedCompanyId, { isDeleted: true })
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          fetchData();
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      companyName: editCompanyData?.companyName || "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required(RequiredField("Company name")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        setLoading(true);
        setIsEdit(false);
        setEditCompanyData("");
        editCompanyDetails(editCompanyData?.id, { ...values })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              fetchData();
              validation.resetForm();
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        addCompanyDetails({ ...values, userId: authId })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              fetchData();
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
            validation.resetForm();
          });
      }
    },
  });

  let data = listOfCompanyData?.sort((a, b) => a?.id - b?.id);

  document.title = "Company Information";

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => companyDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Company Information" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">
                  {isEdit === false ? "Add" : "Edit"} {"Company Information"}
                </h5>
              </div>
              {isEdit && (
                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setIsEdit(false);
                        setEditCompanyData("");
                      }}>
                      Back
                    </button>
                  </div>
                </div>
              )}
              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <Link to="/project-management">
                    <button
                      className="btn btn-primary createTask"
                      type="button">
                      + Client
                    </button>
                  </Link>
                </div>
              </div>
            </Row>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label
                          htmlFor="task-title-input"
                          className="form-label">
                          Company Name
                        </label>
                        <Input
                          type="text"
                          id="task-title-input"
                          className="form-control"
                          placeholder="Enter Company Name"
                          name="companyName"
                          validate={{ required: { value: true } }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ""}
                          invalid={
                            !!(
                              validation.touched.companyName &&
                              validation.errors.companyName
                            )
                          }
                        />
                        {validation.touched.companyName &&
                        validation.errors.companyName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.companyName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="addNewMember">
                          {isEdit === false ? "Submit" : "Edit"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>

            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Company</h4>
              </CardHeader>
              <CardBody>
                {data?.length ? (
                  <TableContainer
                    columns={projectColumns}
                    data={data}
                    isAddUserList={false}
                    customPageSize={5}
                    isGlobalFilter={true}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  />
                ) : (
                  <NoResultsMessage />
                )}
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default CompanyManagement;
