import { ALLOCATED_REPORT, DAMAGE_RESOURCE_REPORT, EMP_TASKSHEET_REPORT, LIST_OF_DATA, RECRUITMENT_REPORT, RESOURCE_REPORT } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const resourceReoprt = async (props) => {
    const response = await apiService2.post(`${RESOURCE_REPORT}`, props);
    return response;
};

export const allocatedReport = async (props) => {
    const response = await apiService2.post(`${ALLOCATED_REPORT}`, props);
    return response;
};

export const recruitmentReport = async (props) => {
    const response = await apiService2.post(`${RECRUITMENT_REPORT}`, props);
    return response;
};

export const employeeTasksheetReport = async (props) => {
    const response = await apiService2.post(`${EMP_TASKSHEET_REPORT}`, props);
    return response;
};

export const damageResourceReport = async (props) => {
    const response = await apiService2.post(`${DAMAGE_RESOURCE_REPORT}`, props);
    return response;
};

export const listOfDesignation = async (props) => {
    const response = await apiService2.post(`${LIST_OF_DATA}`, props);
    return response;
};