import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  removeToken,
  removeRole,
  getRole,
  getId,
  removeId,
  setName,
} from "../../utils/helpers/custom";
import { adminProfile, profile } from "../../api/profileApi";
import userDummyImg from "../../../src/assets/image/auth-one-bg.jpg";
import { baseURL } from "../../api/baseURL";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("Admin");
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const role = getRole();
  const id = getId();
  useEffect(() => {
    if (role === "Admin") {
      adminProfile(id).then((resp) => {
        setUserName(resp?.data);
        setName(`${resp?.data?.firstName} ${resp?.data?.lastName}`);

      });
    } else {
      profile(id).then((resp) => {
        setUserName(resp?.data);
        setName(`${resp?.data?.firstName} ${resp?.data?.lastName}`);
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle overflow-hidden header-profile-user"
              src={ `${baseURL}/uploads/${userName?.userDetails?.profileImage}`
              }
              onError={(e) => {
                e.target.src = userDummyImg; // Set a fallback image when an error occurs
              }}
            alt="Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName?.firstName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {role}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {userName?.firstName} {userName?.lastName}!
          </h6>
          <h6 className="dropdown-header">
            Last Logged In {userName?.loggedIn}
          </h6>
          <Link to="/profile">
            <DropdownItem>
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </DropdownItem>
          </Link>
          {role !== "Admin " && (
            <Link to="/change-password">
              <DropdownItem>
                <i className="mdi mdi-lock-reset text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">Change Password</span>
              </DropdownItem>
            </Link>
          )}
          <DropdownItem
            onClick={() => {
              removeToken();
              removeRole();
              removeId();
              localStorage.removeItem("Name");

              navigate("/login");
            }}
          >
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
