import React, { useMemo, useState, useEffect } from "react";
import Loader from "../../components/common/loader";
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import TableContainer from "../../components/common/tableContainer";
import { getId } from "../../utils/helpers/custom";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { listOfSeniorTaskSheet } from "../../api/tasksheetApi";
import { dateFormat } from "../../utils/helpers/common";

const TaskSheet = () => {
  document.title = "Tasksheet";
  const id = getId();
  const [loading, setloading] = useState(false);
  const [listOfTaskSheet, setListOfTaskSheet] = useState();

  useEffect(() => {
    setloading(true);
    listOfSeniorTaskSheet({
        addedBy: id,
    })
      .then((res) => {
        setListOfTaskSheet(res?.data?.data?.listOfSeniorTaskSheet);
        setloading(false);
      })
      .catch((err) => setloading(false));
  }, []);

  const QueryColumns = useMemo(() => [
    {
      Header: "Serial No",
      filterable: false,
      Cell: ({ row }) => row?.index + 1,
    },
    {
      Header: "Project Name",
      accessor: "projectName",
      filterable: false,
    },
    {
      Header: "Task Name",
      accessor: "taskName",
      filterable: false,
    },
    {
      Header: "Task Details",
      accessor: "taskDetails",
      filterable: false,
    },
    {
      Header: "Developer Name",
      accessor: `authUser.developerName`,
      filterable: false,
    },
    {
      Header: "Estimate Time",
      accessor: "estimateTime",
      Cell: (items) => {
        return (
          <div>{`${items.row.original.hours}:${items.row.original.minutes}:00`}</div>
        );
      },
      filterable: false,
    },
    {
      Header: " Start Date",
      accessor: "startDate",
      filterable: false,
      Cell: (items) => {
        return <div>{dateFormat(items.row.original.startDate)}</div>;
      },
    },
    {
      Header: "End Date",
      accessor: "endDate",
      filterable: false,
      Cell: (items) => {
        return <div>{dateFormat(items.row.original.endDate)}</div>;
      },
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <ReactTooltip
                place="bottom"
                variant="info"
                content="Edit Tasksheet"
                anchorId={`editAction-${cellProps.row.index}`}
              />
              <Link
                to={`/tasksheet/edit-tasksheet/${cellProps?.row?.original?.id}`}
                className="btn btn-sm btn-soft-info edit-list"
                id={`editAction-${cellProps.row.index}`}>
                <i className="ri-pencil-fill align-bottom" />
              </Link>
              <ReactTooltip
                place="bottom"
                variant="info"
                content="View Tasksheet"
                anchorId={`viewAction-${cellProps.row.index}`}
              />
            </div>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="page-content">
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <BreadCrumb title="Tasksheet" pageTitle="HRMS" />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">Tasksheet</h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <Link
                  to={"/tasksheet/add-tasksheet"}
                  className="btn btn-primary">
                  {" "}
                  + Add Tasksheet
                </Link>
              </div>
            </div>
          </Row>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">List of Tasksheet</h4>
            </CardHeader>

            <CardBody>
              <TableContainer
                columns={QueryColumns}
                data={listOfTaskSheet || []}
                isAddUserList={false}
                customPageSize={5}
                divClass="table-responsive table-card mb-1 "
                tableClass="align-middle table-wrap"
                theadClass="table-light text-muted"
              />
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default TaskSheet;
