import React, { useEffect, useState } from "react";
import Loader from "../../components/common/loader";
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import { addTasksheet, editTaskSheet, listOfSeniorTaskSheet, listOfTimesheet } from "../../api/tasksheetApi";
import { getId, getRole } from "../../utils/helpers/custom";
import Select from 'react-select';

const DailyUpdateAddEdit = () => {
  const { timesheet_id } = useParams();
  const [loading, setloading] = useState();
  const [pageTitle, setpageTitle] = useState();
  const [isedit, setisedit] = useState();
  const [editTimesheetData, seteditTimesheetData] = useState();
  const [listOfProject, setlistOfProject] = useState();
  const [listOfTask, setlistOfTask] = useState();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);

  const isEdit = true;

  const id = getId();
  const role = getRole();
  const navigate = useNavigate();

    const taskStatus = [
      {
        "value": 'Completed',
        "label": 'Complete'
      },
      {
        "value": 'Pending',
        "label": 'Pending'
      }
  ]

  document.title = pageTitle;

  useEffect(() => {
    if (!isNaN(timesheet_id)) {
      setisedit(true);
      setpageTitle("Edit Daily Update");
      setloading(true)
      listOfTimesheet({
        "modelName": "taskSheetModel",
        "condition": {
          "id": timesheet_id
        }
      }
      )
      .then((res) => { seteditTimesheetData(res?.data?.data[0]); 
         setSelectedProject({value: res?.data?.data[0]?.projectTask , label: res?.data?.data[0]?.projectTask})
         setSelectedTask({value: res?.data?.data[0]?.taskSheet?.taskName , label: res?.data?.data[0]?.taskSheet?.taskName})
         setSelectedTaskStatus({value: res?.data?.data[0]?.taskStatus , label:  res?.data?.data[0]?.taskStatus})
        setloading(false) })
        .catch((err) => setloading(false))
        
    } else {
      setisedit(false);
      setpageTitle("Add Daily Update");
    }

    listOfSeniorTaskSheet({
      modelName: "taskSheetOfSeniorModel",
      condition: {
        assignTo: parseInt(id),
      },
      selectionCriteria: ["id", "projectName"],
    }).then((res) => setlistOfProject(res?.data?.data)).catch((err) => setloading(false));


    listOfSeniorTaskSheet({
      modelName: "taskSheetOfSeniorModel",
      condition: {
        assignTo: parseInt(id),
      },
      selectionCriteria: ["id", "taskName"],
    }).then((res) => setlistOfTask(res?.data?.data)).catch((err) => setloading(false));
  }, [timesheet_id]);

  const validation = useFormik({

    enableReinitialize: true,

    initialValues: {
      projectTask: editTimesheetData?.projectTask || "",
      taskSheetId: editTimesheetData?.taskSheetId || "",
      date: editTimesheetData?.date || "",
      takenTime: editTimesheetData?.takenTime || "",
      detailsOfTask: editTimesheetData?.detailsOfTask || "",
      taskStatus: editTimesheetData?.taskStatus || "",
    },
    validationSchema: Yup.object({
      projectTask: Yup.string().required(RequiredField("Project name")),
      taskSheetId: Yup.string().required(RequiredField("Task")),
      date: Yup.string().required(RequiredField("Date")),
      takenTime: Yup.string().required(RequiredField("Taken Time")),
      detailsOfTask: Yup.string().required(RequiredField("Detail Task")),
      taskStatus: Yup.string().required(RequiredField("status")),
    }),
    onSubmit: (value) => {
      if (isedit) {
        setloading(true);
        editTaskSheet({
          userId: id,
          id: parseInt(timesheet_id),
          projectTask: value?.projectTask,
          taskSheetId: value?.taskSheetId,
          detailsOfTask: value?.detailsOfTask,
          date: value?.date,
          takenTime: value?.takenTime,
          taskStatus: value?.taskStatus,
        })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              validation.resetForm();
              navigate("/daily-update");
              setloading(false);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => setloading(false))
          .finally();
      } else {
        setloading(true);
        addTasksheet({
          userId: id,
          projectTask: value?.projectTask,
          taskSheetId: value?.taskSheetId,
          detailsOfTask: value?.detailsOfTask,
          date: value?.date,
          takenTime: value?.takenTime,
          taskStatus: value?.taskStatus,
        })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              validation.resetForm();
              navigate("/daily-update");
              setloading(false);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => setloading(false))
      }
    },
  });


  const selectedprojectObj = Array.isArray(listOfProject)
    ? listOfProject?.map((res) => {
      return {
        value: `${res?.projectName}`,
        label: `${res?.projectName}`,
      };
    })
    : null;

  const selectedtaskObj = Array.isArray(listOfTask)
    ? listOfTask?.map((res) => {
      return {
        value: `${res?.id}`,
        label: `${res?.taskName}`,
      };
    })
    : null;

    const selectedtaskStatusObj = Array.isArray(taskStatus)
    ? taskStatus.map((status) => ({
        value: status.value,
        label: status.label,
      }))
    : null;

  return (
    <div className="page-content">
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <Container fluid>
            <BreadCrumb title={pageTitle} pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">{pageTitle}</h5>
              </div>
              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <Link to={"/daily-update"} className="btn btn-primary">Back</Link>
                </div>
              </div>
            </Row>
            <Card>
              <CardBody>
                <Form onSubmit={validation.handleSubmit}>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">
                          Select Project :
                        </label>
                       
                        <Select
                          className={` ${validation.errors.projectTask && "is-invalid red-border"
                            }`}
                          placeholder="Select Project Name"
                          value={selectedProject || validation?.values?.projectTask}
                          isMulti={false}
                          onChange={(selectedOption) => {
                            setSelectedProject(selectedOption);
                            validation.setFieldValue('projectTask', selectedOption ? selectedOption.value : null);
                          }}
                          options={selectedprojectObj}
                          onBlur={validation.handleBlur}
                          name="projectTask"
                        />
                        {validation.touched.projectTask &&
                          validation.errors.projectTask ? (
                          <div className="invalid-feedback">
                            {validation.errors.projectTask}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">
                          Select Task :
                        </label>

                        <Select
                          className={` ${validation.errors.taskSheetId && "is-invalid red-border"
                            }`}
                          placeholder="Select Task Name"
                          value={selectedTask || validation?.values?.taskSheetId}
                          isMulti={false}
                          onChange={(selectedOption) => {
                            setSelectedTask(selectedOption);
                            validation.setFieldValue('taskSheetId', selectedOption ? selectedOption.value : null);  
                          }}
                          options={selectedtaskObj}
                          onBlur={validation.handleBlur}
                          name="taskSheetId"
                        />
                        {validation.touched.taskSheetId &&
                          validation.errors.taskSheetId ? (
                          <div className="invalid-feedback">
                            {validation.errors.taskSheetId}
                          </div>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <label htmlFor="task-duedate-input" className="form-label">
                          Date :
                        </label>
                        <Flatpickr
                          name="date"
                          id="date-field"
                          onBlur={validation.handleBlur}
                          className={`form-select  ${validation.errors.date && validation.touched.date ? "is-invalid" : null}`}
                          placeholder="Enter Date Time"
                          value={validation.values.date}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                            // maxDate: "today",
                            onChange: function (selectedDates, dateStr, instance) {
                              validation.setFieldValue('date', dateStr)
                            },
                          }}
                        />
                        {validation.touched.date &&
                          validation.errors.date ? (
                          <div className="invalid-feedback">
                            {validation.errors.date}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label htmlFor="task-duedate-input" className="form-label">
                        Taken Time :
                      </label>
                      <Flatpickr
                        name="takentime"
                        id="date-field"
                        className={`form-select  ${validation.errors.takenTime && validation.touched.takenTime ? "is-invalid" : null}`}
                        placeholder="Enter Taken Time"
                        value={validation.values.takenTime}
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          onChange: function (selectedDates, dateStr, instance) {
                            validation
                              .setFieldValue("takenTime", dateStr)
                              .then((res) => res)
                              .catch((err) => err);
                          },
                        }}
                      />
                      {validation.touched.takenTime &&
                        validation.errors.takenTime ? (
                        <div className="invalid-feedback">
                          {validation.errors.takenTime}
                        </div>
                      ) : null}
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">
                          Details Of Task :
                        </label>
                        <Input
                          type="textarea"

                          className="form-control custom-css-input"
                          placeholder="Enter your Task Details"
                          name="detailsOfTask"
                          validate={{ required: { value: true } }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.detailsOfTask || ""}
                          invalid={
                            validation.touched.detailsOfTask &&
                              validation.errors.detailsOfTask
                              ? true
                              : false
                          }
                        />
                        {validation.touched.detailsOfTask &&
                          validation.errors.detailsOfTask ? (
                          <FormFeedback type="invalid">
                            {validation.errors.detailsOfTask}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <label className="form-label pt-2">
                              Task Status :
                            </label>

                            <Select
                              className={` ${validation.errors.taskStatus && "is-invalid red-border"
                                }`}
                              placeholder="Select Task Status"
                              value={selectedTaskStatus || validation?.values?.taskStatus }
                              isMulti={false}
                              onChange={(selectedOption) => {
                                  setSelectedTaskStatus(selectedOption);
                                  validation.setFieldValue('taskStatus', selectedOption ? selectedOption.value : null);
                                }}

                              options={selectedtaskStatusObj}
                              onBlur={validation.handleBlur}
                              name="taskStatus"
                            />
                            {validation.touched.taskStatus &&
                              validation.errors.taskStatus ? (
                              <div className="invalid-feedback">
                                {validation.errors.taskStatus}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          {isedit ? <p className="mt-3">Remain time : {`${editTimesheetData?.taskSheetOfSenior?.remainingTime}`}</p> : null}
                        </Col>
                      </Row>
                      <Row>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <button type="submit" className="btn btn-primary" id="addNewMember">{"Save "}</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Container>
        )}
      </React.Fragment>
    </div>
  );
};

export default DailyUpdateAddEdit;
