import { ADD_DAILY_UPDATE, DELETE_DAILY_UPDATE, EDIT_DAILY_UPDATE, LIST_OF_DAILY_UPDATE } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const addDailyUpdateApi = async (props) => {
    const response = await apiService2.post(`${ADD_DAILY_UPDATE}`, props);
    return response;
};

export const listOFDailyUpdateApi = async (data) => {
  const response = await apiService2.post(`${LIST_OF_DAILY_UPDATE}`, data);
  return response;
};

export const editDailyUpdateApi = async (id, props) => {
  const response = await apiService2.put(
    `${EDIT_DAILY_UPDATE}/${id}`,
    props
  );
  return response;
};

export const deleteDailyUpdateApi = async (id) => {
  const response = await apiService2.delete(
    `${DELETE_DAILY_UPDATE}/${id}`
  );
  return response;
};
