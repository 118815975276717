import React, { useEffect, useMemo, useState } from "react";
import { Row, CardHeader, Card, CardBody, Container } from "reactstrap";
import { deleteBdeInterview, listOfBde } from "../../api/bdeApi";
import { Link } from "react-router-dom";
import Loader from "../../components/common/loader";
import BreadCrumb from "../../components/common/breadCrumb";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/deleteModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import NoResultsMessage from "../../components/noResultMessage";
import TableContainer from "../../components/common/tableContainer";
import { dateFormat } from "../../utils/helpers/common";

const BDE = () => {
  const [loading, setLoading] = useState(false);
  const [listOfBdeData, setListOfBdeData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  useEffect(() => {
    setLoading(true);
    listOfBde()
      .then((response) => {
        const data = response?.data?.data || [];
        setListOfBdeData(data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const companyDelete = (companyId) => {
    setSelectedCompanyId(companyId);
    setDeleteModal(true);
  };

  const companyDeleteTodo = () => {
    setLoading(true);
    setDeleteModal(false);

    deleteBdeInterview(selectedCompanyId, { isDeleted: true })
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
          setLoading(true);
          listOfBde()
            .then((response) => {
              setListOfBdeData(response?.data?.data);
            })
            .catch((err) => {
              return err;
            })
            .finally(() => {
              setLoading(false);
            });
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useMemo(() => [
    {
      Header: "Sr.no",
      Cell: ({ row }) => {
        const index = row?.index + 1;
        return <div>{index}</div>;
      },
    },
    {
      Header: "Company Name",
      accessor: "company.companyName",
      filterable: false,
    },
    {
      Header: "Client Name",
      accessor: "client.clientName",
      filterable: false,
    },
    {
      Header: "Employee Name",
      accessor: "authUser.developerName",
      filterable: false,
    },
    {
      Header: "Alised Name",
      accessor: "alisedName.alisedName",
      filterable: false,
    },
    {
      Header: "Year's Of Experience",
      accessor: "yearOfExperience",
      Cell: ({ row }) => {
        const index = `${row?.original?.yearOfExperience}`;
        return <div>{index}+</div>;
      },
      filterable: false,
    },
    {
      Header: "Technology",
      accessor: "bdeTechnology[0].technology.techName",
      filterable: false,
    },
    {
      Header: "Date Of Interview",
      accessor: "dateOfInterview",
      Cell: (cell) => {
        const date = cell?.row?.original?.dateOfInterview;
        return (
          <div>
              {dateFormat(date) !== "Invalid date"? dateFormat(date) : "---"}
          </div>
        )
      }
    },
    {
      Header: "Schedule By",
      accessor: "schedule.developerName",
      filterable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
    },
    {
      Header: "Round",
      accessor:"round",
      filterable: false,
    },
    {
      Header: "Remark",
      accessor: "remark",
      Cell:(cell) => {
        let remark = cell?.row?.original?.remark;
        return remark || '---';
      },
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <ReactTooltip
                place="bottom"
                variant="info"
                content="Edit BDE"
                anchorId={`editBde-${cellProps?.row?.original?.id}`}
              />
              <Link
                to={`/bde/edit-bde/${cellProps?.row?.original?.id}`}
                className="btn btn-sm btn-soft-info edit-list"
                id={`editBde-${cellProps?.row?.original?.id}`}>
                <i className="ri-pencil-fill align-bottom" />
              </Link>
              <ReactTooltip
                place="bottom"
                variant="error"
                content="Delete BDE"
                anchorId={`deleteBde-${cellProps?.row?.original?.id}`}
              />
              <button
                className="btn btn-sm btn-soft-danger remove-list"
                onClick={() => companyDelete(cellProps?.row?.original?.id)}
                id={`deleteBde-${cellProps?.row?.original?.id}`}>
                <i className="ri-delete-bin-5-fill align-bottom" />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  document.title = "BDE";

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => companyDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      {loading === false ? (
        <Container fluid>
          <BreadCrumb title="BDE" pageTitle="HRMS" />
          <Row className="mb-4">
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <Link to={"/bde/add-bde"} className="btn btn-primary">
                  + ADD BDE
                </Link>
              </div>
            </div>
          </Row>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">BDE</h4>
            </CardHeader>
            <CardBody>
              {listOfBdeData?.length ? (
                <TableContainer
                  columns={columns}
                  data={listOfBdeData}
                  isAddUserList={false}
                  isGlobalFilter={true}
                  customPageSize={5}
                  divClass="table-responsive table-card mb-1"
                  tableClass="align-middle table-wrap"
                  theadClass="table-light text-muted"
                />
              ) : (
                <NoResultsMessage />
              )}
            </CardBody>
          </Card>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default BDE;
