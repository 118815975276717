
import { TOTAL_EMPLOYEE, ACTIVE_EMPLOYEE, SOLVED_QUERIES, TOTAL_QUERIES, LEAVE_APPLICATION, TOTAL_EMPLOYEE_CHART, RECENTLY_REGISTRATION, ADMIN_LIST_OF_SUPPORTS, DASHBOARD_MULTI_CARD, LIST_OF_DAILY_UPDATES, LIST_OF_BIRTHDAY, LIST_OF_CURRENT_UPDATES } from "./apiRoutes"
import { apiService2 } from "./baseURL"

export const totalEmployee = async (data) => {
    const response = await apiService2.post(`${TOTAL_EMPLOYEE}`, data);
    return response.data;
};

export const activeEmployee = async (data) => {
    const response = await apiService2.post(`${ACTIVE_EMPLOYEE}`, data);
    return response.data;
};

export const solvedQueries = async (data) => {
    const response = await apiService2.post(`${SOLVED_QUERIES}`, data);
    return response.data;
};

export const totalQueries = async (data) => {
    const response = await apiService2.post(`${TOTAL_QUERIES}`, data);
    return response.data;
};

export const leaveApplication = async (data) => {
    const response = await apiService2.post(`${LEAVE_APPLICATION}`, data);
    return response.data;
};

export const totalEmployeechart = async () => {
    const response = await apiService2.get(`${TOTAL_EMPLOYEE_CHART}`);
    return response.data;
};

export const recentlyRegistration = async () => {
    const response = await apiService2.get(`${RECENTLY_REGISTRATION}`);
    return response.data;
};

export const listSupportAdmin = async (data) => {
    const response = await apiService2.post(`${ADMIN_LIST_OF_SUPPORTS}`, data);
    return response.data;
};

export const listSupportHR = async (data) => {
    const response = await apiService2.post(`${ADMIN_LIST_OF_SUPPORTS}`, data);
    return response.data;
};

export const dashboardMultiCard = async (data) => {
    const response = await apiService2.post(`${DASHBOARD_MULTI_CARD}`, data);
    return response;
};

export const listOfDailyUpdate = async (data) => {
    const response = await apiService2.post(`${LIST_OF_DAILY_UPDATES}`, data);
    return response;
};

export const listOfBirthday = async (data) => {
    const response = await apiService2.get(`${LIST_OF_BIRTHDAY}`, data);
    return response;
};

export const listOfCurrentUpdate = async (data) => {
    const response = await apiService2.get(`${LIST_OF_CURRENT_UPDATES}`, data);
    return response;
};
