import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { baseURL } from "../../api/baseURL";
import userDummyImg from "../../../src/assets/image/auth-one-bg.jpg";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import Loader from "../../components/common/loader";
import { listOfBirthday, listOfCurrentUpdate } from "../../api/dashboardApi";
import { getName } from "../../utils/helpers/custom";

const Section = () => {
  const Name = getName();

  const myDate = new Date();
  const hrs = myDate.getHours();
  let greeting;
  if (hrs >= 12) {
    greeting = hrs >= 16 ? "Good Evening" : "Good Afternoon";
  } else {
    greeting = "Good Morning";
  }

  return (
    <Row className="mb-3 pb-1">
      <Col xs={12}>
        <div className="d-flex align-items-lg-center flex-lg-row flex-column">
          <div className="flex-grow-1">
            <h4 className="fs-16 mb-1">
              {greeting}, {Name}!
            </h4>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const Section1 = () => {
  const [dailyUpdate, setDailyUpdate] = useState();
  const [birthdayList, setBirthdayList] = useState();
  const [loading, setLoading] = useState(false);

  const listOfUpdate = () => {
    listOfCurrentUpdate()
      .then((response) => {
        setLoading(false);
        setDailyUpdate(response?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const birthdays = () => {
    listOfBirthday()
      .then((response) => {
        setLoading(false);
        setBirthdayList(response?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    listOfUpdate();
    birthdays();
  }, []);

  const calculateColSize = () => {
    const availableCards = [
      birthdayList?.data?.employeeBirthday?.length > 0,
      birthdayList?.statusCode === ResponseStatusEnum.SUCCESS &&
        birthdayList?.data?.listOfBirthday?.length > 0,
      dailyUpdate?.statusCode === ResponseStatusEnum.SUCCESS,
    ];

    const numberOfAvailableCards = availableCards?.filter(Boolean)?.length;

    switch (numberOfAvailableCards) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      default:
        return 12; // Default to 12 columns if none of the cases match
    }
  };

  return (
    <Row className="mb-3 pb-1">
      <Card>
        <div className="mt-4 mx-3">
          <h5 className="fw-semibold mb-0">Daily Updates</h5>
        </div>
        {birthdayList?.statusCode === ResponseStatusEnum.NOT_FOUND &&
        dailyUpdate?.statusCode === ResponseStatusEnum.NOT_FOUND ? (
          <Card className="my-5 text-center">
            <h5>Sorry! no updates available</h5>
          </Card>
        ) : !loading ? (
          <Card>
            <Row>
              {birthdayList?.data?.employeeBirthday?.length > 0 && (
                <Col xl={calculateColSize()} lg={calculateColSize() / 2}>
                  <Card className="">
                    <CardHeader>
                      <h4 className="card-title text-black mb-0">Birthday</h4>
                    </CardHeader>
                    <CardBody>
                      <div className="mt-2">
                        <img
                          className="card-img img-fluid imageStyleBirthday"
                          src={`${baseURL}/uploads/${birthdayList?.data?.employeeBirthday[0]?.image}`}
                          onError={(e) => {
                            e.target.src = userDummyImg; // Set a fallback image when an error occurs
                          }}
                          alt="Avatar"
                        />
                      </div>
                      <p className="card-text text-black mt-4">
                        <h4 className="card-title text-black  mb-0">
                          Happy birthday{" "}
                          {birthdayList?.data?.employeeBirthday[0]?.fullName}
                        </h4>
                      </p>
                    </CardBody>
                  </Card>
                  <Card className="p-0">
                    <figure className="mb-0">
                      <blockquote className="blockquote">
                        <p className="lead">
                          “May your birthday be the start of a year filled with
                          good luck, good health and much happiness.” “Wishing
                          you a day full of laughter and happiness and a year
                          that brings you much success.”
                        </p>
                      </blockquote>
                      <figcaption className="blockquote-footer fs-13 text-end mb-0">
                        Nilesh Solanki
                        <cite
                          title="Source Title"
                          className="text-primary fw-semibold">
                          {" "}
                          © Shivinfotech
                        </cite>
                      </figcaption>
                    </figure>
                  </Card>
                </Col>
              )}

              {birthdayList?.statusCode === ResponseStatusEnum.SUCCESS &&
                birthdayList?.data?.listOfBirthday?.length > 0 && (
                  <Col xl={calculateColSize()} lg={calculateColSize() / 2}>
                    <Card className="">
                      <CardHeader>
                        <h4 className="card-title text-black mb-0">
                          Today's Birthday
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <div className="mx-n3">
                          <SimpleBar
                            autoHide={false}
                            className=" px-3 maxHeightOfSimpleBar">
                            {birthdayList?.data?.listOfBirthday?.map((item) => (
                              <div
                                className="d-flex align-items-center py-3"
                                key={item?.id}>
                                <div className="avatar-xs flex-shrink-0 me-3">
                                  <img
                                    className="img-fluid rounded-circle imageStyleOfList"
                                    src={`${baseURL}/uploads/${item?.userDetails?.profileImage}`}
                                    onError={(e) => {
                                      e.target.src = userDummyImg; // Set a fallback image when an error occurs
                                    }}
                                    alt="Avatar"
                                  />
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5 className="fs-14 mb-1">
                                      {item?.firstName} {item?.lastName}
                                    </h5>
                                    <p className="fs-13 text-muted mb-0">
                                      {item?.role || "Developer"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </SimpleBar>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}

              {dailyUpdate?.statusCode === ResponseStatusEnum.SUCCESS && (
                <Col xl={calculateColSize()} lg={calculateColSize() / 2}>
                  <Card className="">
                    <CardHeader>
                      <h4 className="card-title text-black mb-0">Notes</h4>
                    </CardHeader>
                    <CardBody>
                      <p className="text-muted">Update List</p>
                      <div id="contact-existing-list">
                        <SimpleBar className="mx-n3 maxHeightOfSimpleBar">
                          <ListGroup className="list mb-0" flush>
                            {dailyUpdate?.data?.map((item) => (
                              <ListGroupItem key={item?.id}>
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="contact-name fs-14 mb-2">
                                      {item?.title}
                                    </h5>
                                    <p className="contact-born text-muted mb-0">
                                      {item?.description}
                                    </p>
                                  </div>
                                </div>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </SimpleBar>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Card>
        ) : (
          <Loader />
        )}
      </Card>
    </Row>
  );
};

export { Section, Section1 };
