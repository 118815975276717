import { ADD_PAYMENT_DETAILS, DELETE_PAYMENT_DETAILS, EDIT_PAYMENT_DETAILS, FILTER_OF_PAYMENT, LIST_OF_PAYMENT_DETAILS, VIEW_RECEIVED_AMOUNT } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const listOfPaymentDetails = async (data) => {
    const response = await apiService2.post(`${LIST_OF_PAYMENT_DETAILS}`,data);
    return response?.data;
};

export const addPaymentDetails = async (data) => {
    const response = await apiService2.post(`${ADD_PAYMENT_DETAILS}`, data);
    return response;
};

export const viewReceivedAmount = async (id) => {
    const response = await apiService2.get(`${VIEW_RECEIVED_AMOUNT}/${id}`);
    return response;
};

export const editPaymentDetails = async (id, data) => {
    const response = await apiService2.put(`${EDIT_PAYMENT_DETAILS}/${id}`, data);
    return response;
};

export const deletePaymentDetails = async (id, data) => {
    const response = await apiService2.put(`${DELETE_PAYMENT_DETAILS}/${id}`, data);
    return response;
};

export const filterOfPayment = async (data) => {
    const response = await apiService2.post(`${FILTER_OF_PAYMENT}`, data);
    return response;
};