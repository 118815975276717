import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  FormFeedback,
  ModalHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import DeleteModal from "../../components/common/deleteModal";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import {
  addEmployee,
  countOfEmployee,
  activeDeActiveEmployee,
  editEmployee,
  filterOfEmployee,
  listOfEmployee,
  deleteEmployee,
} from "../../api/employeeApi";
import userDummyImg from "../../assets/image/auth-one-bg.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import Widgets from "../dashboard/widgets";
import Loader from "../../components/common/loader";
import { getId, getRole } from "../../utils/helpers/custom";
import { listOfAssigningCourse, listOfData } from "../../api/commonApi";
import { EMPLOYEE_PROFILE_IMAGE } from "../../api/apiRoutes";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { assignCourse, listTopics } from "../../api/itpApi";
import { dashboardMultiCard } from "../../api/dashboardApi";
import { baseURL } from "../../api/baseURL";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import { changeSenior } from "../../api/employeeApi";
import { employeeProfile } from "../../api/profileApi";
import { conditionallyRequired } from "../../utils/helpers/formUtils";
import Toggle from "./toggle";
import { listOfProject } from "../../api/itpProject";
import { MultiSelect } from "../../base/baseSelect";
import { listofjunior } from "../../api/tasksheetApi";
import { dateFormat } from "../../utils/helpers/common";

const Employee = () => {
  document.title = "Employee";
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalTodo, setModalTodo] = useState(false);
  const [modalTodoCourse, setModalTodoCourse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [employeeResId, setEmployeeResId] = useState([]);
  const [employeePerData, setEmployeePerData] = useState([]);
  const [designation, setdesignation] = useState([]);
  const [topic, setTopic] = useState([]);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const [selectedMultiProject, setSelectedMultiProject] = useState([]);
  const [assignCourseData, setAssignCourseData] = useState([]);
  const [dashboardCard, setdashboardMultiCard] = useState();
  const [passwordShow, setPasswordShow] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [gridList, setGridList] = useState("grid-view-filter");
  const [modalReallocation, setModalReallocation] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourse2, setSelectedCourse2] = useState("");
  const [editEmplyeeData, setEditEmployeeData] = useState();
  const [editId, setEditId] = useState();
  const [itpProject, setItpProject] = useState([]);
  const [responseMessage, setResponseMessage] = useState([]);
  const userIdCookie = getId();
  const userRoleCookie = getRole();
  const imageUrl = `${baseURL}/${EMPLOYEE_PROFILE_IMAGE}`;

  const topicObj = topic?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.courseName}`,
    };
  });

  const itpProjectSelect = itpProject?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.projectName}`,
    };
  });

  const employee = [
    {
      id: 1,
      cardColor: "primary",
      label: "Total Employee",
      badgeClass: "success",
      counter: dashboardCard?.totalUserData,
      bgcolor: "success",
      icon: "ri-account-box-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      cardColor: "primary",
      label: "Active Employee",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: dashboardCard?.activeEmployeeOfCurrentMonth,
      counter: dashboardCard?.countActiveUserData,
      bgcolor: "success",
      icon: "ri-team-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 3,
      cardColor: "primary",
      label: "In Active Employee",
      badgeClass: "success",
      counter: dashboardCard?.countInactiveUserData,
      bgcolor: "success",
      icon: "ri-team-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 4,
      cardColor: "primary",
      label: "Deleted Employee",
      badgeClass: "success",
      counter: dashboardCard?.countDeletedUserData,
      bgcolor: "success",
      icon: "ri-team-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];

  function handleMulti(selectedMulti) {
    setSelectedMulti(selectedMulti);
  }

  function handleMultiProject(selectedMulti) {
    const ids = selectedMulti?.map((item) => {
      return item.value;
    });
    setSelectedMultiProject(selectedMulti);
  }

  const handleEditClick = (resp) => {
    setLoading(true);
    setIsEdit(true);
    setModal(true);
    setEditId(resp?.id);
    employeeProfile(resp?.id)
      .then((res) => {
        setEditEmployeeData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTodoClicks = () => {
    setModalTodo(!modalTodo);
    setIsEdit(false);
    toggle();
  };

  const handleTodoClicksCourse = () => {
    setModalTodoCourse(!modalTodoCourse);
    toggleCourse();
  };
  //Modal
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalCourse, setModalCourse] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [filterEmployeeData, setFilterEmployeeData] = useState();

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setEditEmployeeData("");
    } else {
      setModal(true);
    }
  }, [modal]);

  const toggleCourse = useCallback(() => {
    if (modalCourse) {
      setModalCourse(false);
      setSelectedMulti("");
    } else {
      setModalCourse(true);
    }
  }, [modalCourse]);

  //delete
  const handleDeleteOrder = () => {
    setLoading(true);
    deleteEmployee(employeePerData?.id, {
      isDeleted: true,
    })
      .then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
        }
        fetchData();
        setDeleteModal(false);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = () => {
    setLoading(true);
      listOfEmployee({
        role: ["Employee", "HR", "BDE", "Admin"],
        isDeleted: false,
      })
        .then((resp) => {
          setEmployeeData(resp?.data?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {});

    Promise.all([
      dashboardMultiCard()
        .then((response) => {
          setdashboardMultiCard(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        }),

      countOfEmployee({
        modelName: "authUserModel",
        condition: {
          isDeleted: 0,
          role: ["Employee"],
        },
      })
        .then((resp) => {
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {}),

      countOfEmployee({
        modelName: "authUserModel",
        condition: {
          isDeleted: 1,
        },
      })
        .then((resp) => {
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {}),

      listOfData({
        modelName: "technologyModel",
        selectionCriteria: ["id", "techName"],
      })
        .then((resp) => {
          setdesignation(resp?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {}),

      listTopics({})
        .then((resp) => {
          setTopic(resp?.data?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        }),

        listOfProject({})
        .then((resp) => {
          setItpProject(resp?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        }),

        listofjunior() 
          .then((resp) => {
            setEmployeeDetails(resp?.data?.data);
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          }),
    ]);
  };

  const handleReallocationClick = () => {
    const payload = {
      assignerId: selectedCourse,
      assigneeId: selectedCourse2,
    };
    changeSenior(payload)
      .then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
        }
        setModalReallocation(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const list = document.querySelectorAll(".team-list");
    const buttonGroups = document.querySelectorAll(".filter-button");
    for (const element of buttonGroups) {
      element.addEventListener("click", onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
      if (
        event?.target?.id === "list-view-button" ||
        event?.target?.parentElement?.id === "list-view-button"
      ) {
        document.getElementById("list-view-button").classList.add("active");
        document.getElementById("grid-view-button").classList.remove("active");
        list?.forEach(function (el) {
          el?.classList.add("list-view-filter");
          el?.classList.remove("grid-view-filter");
        });
      } else {
        document.getElementById("grid-view-button").classList.add("active");
        document.getElementById("list-view-button").classList.remove("active");
        list?.forEach(function (el) {
          el?.classList.remove("list-view-filter");
          el?.classList.add("grid-view-filter");
        });
      }
    }
    
  }, []);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const emailformat =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // technologyId: checkbox === true ? null : (editEmplyeeData?.technology?.id),
      technologyId:
        checkbox === true ? null : editEmplyeeData?.technology?.id || "",
      firstName: editEmplyeeData?.firstName || "",
      remainLeave: editEmplyeeData?.reamingLeave || "",
      lastName: editEmplyeeData?.lastName || "",
      email: editEmplyeeData?.email || "",
      assignTo: editEmplyeeData?.assignTo || "",
      password: editEmplyeeData?.password || "",
      phoneNo: editEmplyeeData?.phoneNo || "",
      dateOfJoin: editEmplyeeData?.dateOfJoin || "",
      hrCheck: editEmplyeeData?.role === "HR",
      bdeCheck: editEmplyeeData?.role === "BDE",
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required(RequiredField("Last Name")),
      firstName: Yup.string().required(RequiredField("First Name")),
      remainLeave: Yup.string().required(RequiredField("Remaining Leave")),
      email: Yup.string()
        .required(RequiredField("Email"))
        .email("Email is not valid", emailformat),
      assignTo: conditionallyRequired(isEdit, RequiredField("Assign Senior")),
      password: conditionallyRequired(isEdit, RequiredField("Password")).when(
        "isEdit",
        {
          is: false,
          then: Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
        }
      ),
      phoneNo: Yup.string()
        .required(RequiredField("Phone number"))
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Too short")
        .max(10, "Too long"),
      dateOfJoin: Yup.string().required(RequiredField("Date Of Joining")),
      technologyId: Yup.string().when(["hrcheck", "bdeCheck"], {
        is: (hrCheck, bdeCheck) => !hrCheck && !bdeCheck,
        then: Yup.string().required(RequiredField("Role")),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        editEmployee(editId, {
          user: {
            technologyId:
              values.hrcheck === true || values.bdeCheck === true
                ? null
                : values.technologyId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            role:
              values.hrcheck === true
                ? "HR"
                : values.bdeCheck === true
                ? "BDE"
                : "Employee",
            phoneNo: `${values.phoneNo}`,
            dateOfJoin: values.dateOfJoin,
          },
          leave: {
            remainLeave: values.remainLeave,
          },
        })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              setEmployeeResId(response?.data?.data);
              fetchData();
            } else if (
              response?.data?.statusCode === ResponseStatusEnum.DB_SERVER_ERROR
            ) {
              toast.error(response?.data?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
        validation.resetForm();
      } else {
        addEmployee({
          userId: userIdCookie,
          addedBy: `${userRoleCookie}`,
          reportTo: values.assignTo,
          technologyId:
            values.hrcheck === true || values.bdeCheck === true
              ? null
              : values.technologyId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          role:
            values.hrcheck === true
              ? "HR"
              : values.bdeCheck === true
              ? "BDE"
              : "Employee",
          phoneNo: `${values.phoneNo}`,
          dateOfJoin: values.dateOfJoin,
          remainLeave: values.remainLeave,
        })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              setEmployeeResId(response?.data?.data);
              setAssignCourseData(values);
              fetchData();
              if (values?.hrcheck === false) {
                handleTodoClicksCourse();
              }
            } else if (
              response?.data?.statusCode === ResponseStatusEnum.DB_SERVER_ERROR
            ) {
              toast.error(response?.data?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
        validation.resetForm();
      }
      toggle();
    },
  });
  const assignParticularCourse = (data) => {
    setLoading(true);
    listOfAssigningCourse({ assignTo: data?.id, isDeleted: false })
      .then((res) => {
        setSelectedMulti(
          res?.data?.data?.map((data, key) => {
            return { value: data?.course?.id, label: data?.course?.courseName };
          })
        );
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
    setAssignCourseData(data);
    setEmployeeResId({ id: data?.id });
  };

  const onsubmitCourse = (e) => {
    setLoading(true);
    assignCourse({
      courseId: selectedMulti?.map((res) => parseInt(res?.value)),
      itpProjectId: selectedMultiProject?.map((res) => parseInt(res?.value)),
      assignTo: parseInt(employeeResId?.id),
      assigner: parseInt(userIdCookie),
    })
      .then((response) => {
        const responseBody = response?.data;
        if (
          responseBody?.statusCode === ResponseStatusEnum.SUCCESS ||
          responseBody?.statusCode === ResponseStatusEnum.ACCEPTED
        ) {
          toast.success(response?.data?.message);
          fetchData();
          toggleCourse();
        } else {
          toast.error(responseBody?.message);
          toggleCourse();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.data?.message);
        toggleCourse();
        return err;
      })
      .finally(() => {
        setLoading(false);
        setSelectedMulti("");
      });
  };

  const openModal = () => {
    setModalReallocation(true);
  };

  const handleHrCheckChange = () => {
    validation.setFieldValue("hrcheck", !validation.values.hrcheck);
    validation.setFieldValue("bdeCheck", false);
  };

  const handleBdeCheckChange = () => {
    validation.setFieldValue("bdeCheck", !validation.values.bdeCheck);
    validation.setFieldValue("hrcheck", false);
  };

  const validationFilter = useFormik({
    enableReinitialize: true,

    initialValues: {
      employeeName: "",
      employeeDesignation: "",
      employeeStatus: "",
      role: "",
    },
    validationSchema: Yup.object({
      employeeName: Yup.string("It Should be text"),
      employeeDesignation: Yup.string("It Should be text"),
      employeeStatus: Yup.string("It Should be text"),
      role: Yup.string("It Should be text"),
    }),
    onSubmit: (value) => {
      setLoading(true);

      let payload = {
        employeeName : value.employeeName,
        employeeDesignation : value.employeeDesignation,
        status : value.employeeStatus,
        role : value.role
      }
      filterOfEmployee(payload)
        .then((res) => {
          if (res?.status === 200) {
            setFilterEmployeeData(res?.data?.data);
            setLoading(false);

          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
          validationFilter.resetForm();
        });
    },
  });

  let dataList = filterEmployeeData ?? employeeData;

  const [toggleStatuses, setToggleStatuses] = useState([]);

  useEffect(() => {
    if (dataList && dataList?.length > 0) {
      const initialToggles = dataList?.map((item) => item?.isActive === false);
      setToggleStatuses(initialToggles);
    }
  }, [dataList]);

  const handleApprove = (row) => {
    setSelectedRow({ ...row, action: "InActive User" });

    if(!row.isActive === false) {
      setResponseMessage("you want to Active the Employee ?") 
    } else {
      setResponseMessage("you want to In Active the Employee ?")
    }
    
    setUpdateModal(true);
    const updatedRow = { ...row, isActive: !row.isActive };
    setSelectedId(updatedRow);

    const initialToggles = dataList?.map((item) => item?.isActive === false);
    setToggleStatuses(initialToggles);
  };

  const handleApproveSupport = () => {
    setUpdateModal(false);
    setLoading(true);
    const isDeletedValue = selectedRow.isActive ? false : true;

    activeDeActiveEmployee(selectedId?.id, { isActive: isDeletedValue })
      .then((response) => {
        setUpdateModal(false);
        if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          if (userRoleCookie === "Admin") {
            listOfEmployee({
              role: ["Employee", "HR", "BDE"],
              isDeleted: false,
            })
              .then((resp) => {
                setEmployeeData(resp?.data?.data);
              })
              .catch((err) => {
                return err;
              })
              .finally(() => {});
          } else {
            listOfEmployee({
              role: ["Employee", "BDE"],
              isDeleted: false,
            })
              .then((resp) => {
                setEmployeeData(resp?.data?.data);
              })
              .catch((err) => {
                return err;
              })
              .finally(() => {});
          }
        } else if (
          response?.data?.statusCode === ResponseStatusEnum?.DB_SERVER_ERROR
        ) {
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

   const handleToggleChange = (index, res) => {
    const newToggles = [...toggleStatuses];
    newToggles[index] = !newToggles[index];
    setToggleStatuses(newToggles);
    handleApprove(res);
  };

  const [selectedDesignationStatus, setSelectedDesignationStatus] = useState(null);
  const [selectedRoleStatus, setSelectedRoleStatus] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedAssignToDetails, setSelectedAssignToDetails] = useState(null);

  const designationDetails = [
    {
      label: "Select Designation",
      value: "",
    },
    {
      value: "Node Js",
      label: "Node Js",
    },
    {
      value: "Vue Js",
      label: "Vue Js",
    },
    {
      value: "React Js",
      label: "React Js",
    },
    {
      value: "Python",
      label: "Python",
    },
    {
      value: "MERN",
      label: "MERN",
    },
    {
      value: "MEAN",
      label: "MEAN",
    },
    {
      value: "Angular",
      label: "Angular",
    },
    {
      value: "BDE",
      label: "BDE",
    },
    {
      value: "HR",
      label: "HR",
    },
  ];

  const roleDetails = [
    {
      label: "Select Role",
      value: "",
    },
    {
      value: "HR",
      label: "HR",
    },
    {
      value: "Employee",
      label: "Employee",
    },
    {
      value: "BDE",
      label: "BDE",
    },
  ];

  const statusDetails = [
    {
      label: "Select Status",
      value: "",
    },
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "InActive",
      label: "InActive",
    },
    {
      value: "ALL",
      label: "ALL",
    },
  ];

  const selectedDesignationDetails = Array.isArray(designationDetails)
  ? designationDetails?.map((status) => ({
      value: status.value,
      label: status.label,
    }))
  : null;

  const roleData = Array.isArray(roleDetails)
  ? roleDetails?.map((status) => ({
      value: status.value,
      label: status.label,
    }))
  : null;

  const statusData = Array.isArray(statusDetails)
  ? statusDetails?.map((status) => ({
      value: status.value,
      label: status.label,
    }))
  : null;

  const developerData = Array.isArray(employeeDetails)
  ? employeeDetails?.map((status) => ({
      value: status?.developerName,
      label: status?.developerName,
    }))
  : null;

  const assignToData = Array.isArray(employeeData)
  ? employeeData?.map((status) => ({
      value: status?.id,
      label: `${status?.firstName} ${status?.lastName}`,
    }))
  : null;

  assignToData.unshift({
    "value": 1,
    "label": "Admin"
})
  return (
    <React.Fragment>
      <ApproveRejectModal
        show={updateModal}
        onDeleteClick={() => handleApproveSupport()}
        onCloseClick={() => {
          setUpdateModal(false)
        }}
        message={responseMessage}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Employees" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Employees</h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <button
                    className="btn btn-primary createTask"
                    type="button"
                    onClick={() => handleTodoClicks()}
                  >
                    + Employee
                  </button>
                </div>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <button
                    className="btn btn-primary createTask"
                    type="button"
                    onClick={openModal}
                  >
                    + Reallocation
                  </button>
                </div>
              </div>
            </Row>
            <Row>
              <Widgets data={employee} />
            </Row>
              <Card>
                <CardBody>
                  <Row className="g-2">
                    <Col xl={10}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg={3}>
                            <div className="p-2">
                            <Select
                            className={` ${
                              validationFilter.errors.employeeName &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Employee"
                            value={
                              selectedEmployee ||
                              validationFilter?.values?.employeeName
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedEmployee(selectedOption);
                              validationFilter.setFieldValue(
                                "employeeName",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={developerData}
                            onBlur={validationFilter.handleBlur}
                            name="employeeName"
                          />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="p-2">
                            <Select
                            className={` ${
                              validationFilter.errors.employeeDesignation &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Designation"
                            value={
                              selectedDesignationStatus ||
                              validationFilter?.values?.employeeDesignation
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedDesignationStatus(selectedOption);
                              validationFilter.setFieldValue(
                                "employeeDesignation",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={selectedDesignationDetails}
                            onBlur={validationFilter.handleBlur}
                            name="employeeDesignation"
                          />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="p-2">
                            <Select
                            className={` ${
                              validationFilter.errors.employeeStatus &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Status"
                            value={
                              selectedRoleStatus ||
                              validationFilter?.values?.employeeStatus
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedRoleStatus(selectedOption);
                              validationFilter.setFieldValue(
                                "employeeStatus",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={statusData}
                            onBlur={validationFilter.handleBlur}
                            name="employeeStatus"
                          />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="p-2">
                            <Select
                            className={` ${
                              validationFilter.errors.role &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Role"
                            value={
                              selectedStatus ||
                              validationFilter?.values?.role
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedStatus(selectedOption);
                              validationFilter.setFieldValue(
                                "role",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={roleData}
                            onBlur={validationFilter.handleBlur}
                            name="role"
                          />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="m-2">
                              <button
                                className="btn btn-primary createTask"
                                type="submit"
                                onClick={validationFilter.handleSubmit}
                              >
                                {!loading ? (
                                  "SEARCH"
                                ) : (
                                  <div className="d-inline-flex align-items-center">
                                    <Spinner size="sm" className="me-2" />
                                    Loading...
                                  </div>
                                )}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Col className="col-sm-auto ms-auto g-3" xl={2}>
                      <div className="list-grid-nav hstack gap-1">
                        <Button
                          color="info"
                          id="grid-view-button"
                          onClick={() => {
                            setGridList("grid-view-filter");
                          }}
                          className={`btn btn-soft-info nav-link btn-icon fs-15 ${
                            gridList === "grid-view-filter" && "active"
                          } filter-button`}
                        >
                          <i className="ri-grid-fill"></i>
                        </Button>
                        <Button
                          color="info"
                          id="list-view-button"
                          onClick={() => {
                            setGridList("list-view-filter");
                          }}
                          className={`btn btn-soft-info nav-link  btn-icon fs-15 ${
                            gridList === "list-view-filter" && "active"
                          } filter-button`}
                        >
                          <i className="ri-list-unordered"></i>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            <Col lg={12}>
              <Row className={`team-list ${gridList}`}>
                { dataList?.length > 0 ? dataList?.map((resp, index) => {
                  return (
                    <Col key={resp?.id}>
                      <Card className="team-box">
                        <div className="team-cover">
                          <img
                            src={
                              resp?.profileImage !== undefined
                                ? `${imageUrl}/${resp?.profileImage}`
                                : userDummyImg
                            }
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <CardBody className="p-4">
                          <Row className="align-items-center team-row">
                            <Col lg={4} className="col mt-4">
                              <div className="team-profile-img">
                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                  <img
                                    src={
                                      resp?.profileImage !== undefined
                                        ? `${imageUrl}/${resp?.profileImage}`
                                        : userDummyImg
                                    }
                                    alt=""
                                    className="img-fluid d-block rounded-circle h-100 w-100"
                                  />
                                </div>
                                <div className="team-content d-flex flex-column">
                                  <div className="d-flex" to="#">
                                    <h5 className="fs-16 mb-1 mx-2">
                                      {resp?.firstName}
                                    </h5>
                                    <h5 className="fs-16 mb-1">
                                      {resp?.lastName}
                                    </h5>
                                  </div>
                                  <p className="text-muted mb-0">
                                    {resp?.role === "BDE" || resp?.role === "HR"
                                      ? resp?.role
                                      : resp?.role +
                                        " " +
                                        resp?.technology?.techName}
                                  </p>
                                  <p className="text-muted mb-0">
                                  {dateFormat(resp?.dateOfJoin)}
                                  </p>
                                </div>
                                <div>
                                  <Toggle
                                    toggle={toggleStatuses[index]}
                                    handleToggleChange={() =>
                                      handleToggleChange(index, resp)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col lg={4} className="col">
                              <div className="text-end d-flex">
                                <Link
                                  to={`/employee-profile/${resp?.id}`}
                                  className="btn btn-light view-btn mx-1 custom_css_align_item_center"
                                >
                                  View Profile
                                </Link>

                                <button
                                  onClick={() => {
                                    setModalCourse(true);
                                    assignParticularCourse(resp);
                                  }}
                                  className="btn btn-light view-btn mx-1"
                                >
                                  Assign ITP
                                </button>
                                <div>
                                  <UncontrolledDropdown
                                    direction="start"
                                    className="btn btn-light view-btn mx-1"
                                  >
                                    <DropdownToggle
                                      tag="a"
                                      id="dropdownMenuLink2"
                                      role="button"
                                    >
                                      <i className="ri-more-fill fs-22"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        className="dropdown-item remove-list"
                                        // href="#removeMemberModal"
                                        onClick={() => {
                                          handleEditClick(resp);
                                        }}
                                      >
                                        <i className="ri-pencil-line me-2 align-bottom text-muted"></i>
                                        Edit
                                      </DropdownItem>
                                      <DropdownItem
                                        className="dropdown-item remove-list"
                                        // href="#removeMemberModal"
                                        onClick={() => {
                                          setDeleteModal(true);
                                          setEmployeePerData(resp);
                                        }}
                                      >
                                        <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                }): <div className="py-4 mt-4 text-center" id="noresult">
                <lord-icon
                  src="https://cdn.lordicon.com/msoeawqm.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "72px", height: "72px" }}
                ></lord-icon>
                <h5 className="mt-4">Sorry! No Result Found</h5>
              </div>
              }
              </Row>
            </Col>
          </Container>
        ) : (
          <Loader />
        )}

        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
            {" "}
            {isEdit ? "Edit" : "Add"} {"Employee Registration"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter First name"
                          name="firstName"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ""}
                          invalid={
                            !!(validation.touched.firstName &&
                            validation.errors.firstName)
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter Last name"
                          name="lastName"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ""}
                          invalid={
                            validation.touched.lastName &&
                            validation.errors.lastName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Official Email Id
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter Official Email Id"
                          name="email"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {!isEdit && (
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="teammembersName"
                            className="form-label"
                          >
                            Assign To
                          </Label>
                          <Select
                            className={` ${
                              validation.errors.assignTo &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Select Assign To"
                            value={
                              selectedAssignToDetails ||
                              validation?.values?.assignTo
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedAssignToDetails(selectedOption);
                              validation.setFieldValue(
                                "assignTo",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={assignToData}
                            onBlur={validation.handleBlur}
                            name="assignTo"
                          />
                          <div className="invalid-feedback">
                            {validation.errors.assignTo}
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Remaining Leave
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter Remaining Leave"
                          name="remainLeave"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.remainLeave || ""}
                          invalid={
                            !!(validation.touched.remainLeave &&
                            validation.errors.remainLeave)
                          }
                        />
                        {validation.touched.remainLeave &&
                        validation.errors.remainLeave ? (
                          <FormFeedback type="invalid">
                            {validation.errors.remainLeave}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {!isEdit && (
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                !!(validation.touched.password &&
                                validation.errors.password)
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className={`btn btn-link position-absolute ${
                                validation.errors.password ? "err" : "end-0"
                              } top-0 text-decoration-none text-muted`}
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Phone No.
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter Phone Number"
                          name="phoneNo"
                          validate={{
                            required: { value: true },
                          }}
                          maxLength={10}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneNo || ""}
                          invalid={
                            !!(validation.touched.phoneNo &&
                            validation.errors.phoneNo)
                          }
                        />
                        {validation.touched.phoneNo &&
                        validation.errors.phoneNo ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phoneNo}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Technology
                        </Label>
                        <select
                          className={`form-select ${
                            validation.values.hrcheck === false &&
                            validation.errors.technologyId &&
                            "is-invalid"
                          }`}
                          aria-label="Default select example"
                          id="selectreport"
                          name="technologyId"
                          disabled={
                            validation.values.hrcheck === true ||
                            validation.values.bdeCheck === true
                          }
                          validate={{ required: { value: true } }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.technologyId}
                        >
                          <option selected hidden>
                            Select Technology
                          </option>
                          {designation?.map((res) => {
                            return (
                              <>
                                <option value={res?.id}>{res?.techName}</option>
                              </>
                            );
                          })}
                        </select>
                        {validation.values.hrcheck === false && (
                          <div className="invalid-feedback">
                            {validation.errors?.technologyId}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Date of Joining
                        </Label>
                        <Flatpickr
                          className={`form-control ${
                            validation.errors.dateOfJoin && "is-invalid"
                          }`}
                          id="datepicker-publish-input"
                          placeholder="Select a date"
                          name="dateOfJoin"
                          validate={{ required: { value: true } }}
                          onBlur={validation.handleBlur} // this all comments are needed
                          value={validation.values.dateOfJoin}
                          invalid={
                            !!(validation.touched.dateOfJoin &&
                            validation.errors.dateOfJoin)
                          }
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                            onChange: function (
                              selectedDates,
                              dateStr,
                              instance
                            ) {
                              validation
                                .setFieldValue("dateOfJoin", dateStr)
                                .then((res) => res)
                                .catch((err) => err);
                            },
                          }}
                        />
                        <div className="invalid-feedback">
                          {validation.errors.dateOfJoin}
                        </div>
                      </div>
                    </Col>
                    {userRoleCookie === "Admin" && !isEdit && (
                      <Col lg={6}>
                        <div className="mb-3">
                          <div className="d-flex">
                            <Input
                              type="checkbox"
                              id="teammembersName"
                              name="hrcheck"
                              onChange={handleHrCheckChange}
                              checked={validation.values.hrcheck}
                            />
                            <Label
                              htmlFor="teammembersName"
                              className="form-label"
                              style={{ paddingLeft: 10, paddingTop: 3 }}
                            >
                              HR
                            </Label>
                          </div>
                          <span>* Designates that this user is HR.</span>
                        </div>
                      </Col>
                    )}
                    {!isEdit && (
                      <Col lg={6}>
                        <div className="mb-3">
                          <div className="d-flex">
                            <Input
                              type="checkbox"
                              id="teammembersName"
                              name="bdeCheck"
                              onChange={handleBdeCheckChange}
                              checked={validation.values.bdeCheck}
                            />
                            <Label
                              htmlFor="teammembersName"
                              className="form-label"
                              style={{ paddingLeft: 10, paddingTop: 3 }}
                            >
                              BDE
                            </Label>
                          </div>
                          <span>* Designates that this user is BDE.</span>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="button"
                      className="btn btn-ghost-success"
                      onClick={() => {
                        setModal(false);
                        validation.resetForm();
                      }}
                    >
                      <i className="ri-close-fill align-bottom"></i>Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="addNewMember"
                    >
                      {isEdit ? "Update" : "Register"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalCourse} toggle={toggleCourse} centered>
          <ModalHeader toggle={toggleCourse} className="p-3 bg-soft-success">
            {" "}
            {"Assign Course"}{" "}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onsubmitCourse(e);
              }}
            >
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter First name"
                          name="firstName"
                          disabled
                          value={assignCourseData?.firstName}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter Last name"
                          name="lastName"
                          disabled
                          value={assignCourseData?.lastName}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Official Email Id
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="teammembersName"
                          placeholder="Enter Official Email Id"
                          name="email"
                          disabled
                          value={assignCourseData?.email}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="choices-multiple-default"
                          className="form-label"
                        >
                          ITP Courses
                        </Label>
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={(e) => {
                            handleMulti(e);
                          }}
                          options={topicObj}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="choices-multiple-default"
                          className="form-label"
                        >
                          ITP Project
                        </Label>
                        <MultiSelect
                        value={selectedMultiProject}
                        isMulti={true}
                        onChange={handleMultiProject}
                        options={itpProjectSelect}
                      />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Role
                        </Label>
                        <select
                          className={`form-select`}
                          aria-label="Default select example"
                          id="selectreport"
                          name="technologyId"
                          disabled
                          value={assignCourseData?.technologyId}
                        >
                          {designation?.map((res) => {
                            return (
                              <>
                                <option hidden disabled selected>
                                  Select Role
                                </option>
                                <option value={res.id}>{res.techName}</option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="teammembersName" className="form-label">
                          Date of Joining
                        </Label>
                        <Flatpickr
                          disabled
                          className="form-control"
                          id="datepicker-publish-input"
                          placeholder="Select a date"
                          name="dateOfJoin"
                          value={assignCourseData?.dateOfJoin}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="button"
                      className="btn btn-ghost-success"
                      onClick={() => {
                        setModalCourse(false);
                        setSelectedMulti("");
                      }}
                    >
                      <i className="ri-close-fill align-bottom"></i>Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="addNewMember"
                    >
                      {"Submit "}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalReallocation}
          toggle={() => setModalReallocation(false)}
          centered
        >
          <ModalHeader
            toggle={() => setModalReallocation(false)}
            className="p-3 bg-soft-success"
          >
            Reallocation
          </ModalHeader>
          <ModalBody>
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="teammembersName" className="form-label">
                  Select Senior
                </Label>
                <select
                  className={`form-select`}
                  aria-label="Default select example"
                  name="technologyId"
                  id="coursesDropdown"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  <option value="">Select Senior</option>
                  {employeeData?.map((employee) => (
                    <option key={employee?.id} value={employee?.id}>
                      {employee?.firstName} {employee?.lastName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="teammembersName" className="form-label">
                  Select junior
                </Label>
                <select
                  className={`form-select`}
                  aria-label="Default select example"
                  name="technologyId"
                  id="coursesDropdowne"
                  value={selectedCourse2}
                  onChange={(e) => setSelectedCourse2(e.target.value)}
                >
                  <option value="">Select junior</option>
                  {employeeData?.map((employee) => (
                    <option key={employee?.id} value={employee?.id}>
                      {employee?.firstName} {employee?.lastName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-ghost-success"
                  onClick={() => {
                    setModalReallocation(false);
                  }}
                >
                  <i className="ri-close-fill align-bottom"></i>Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="addNewMember"
                  onClick={handleReallocationClick}
                >
                  {"Reallocation "}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default Employee;
