import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import { getId, getName, getRole } from "../../utils/helpers/custom";
import {
  addLeave,
  adminApproveRejectLeave,
  approveRejectLeave,
  countOfLeave,
  deleteLeave,
  filterOfLeaveDetails,
  listOfAcquireLeave,
  listOfLeave,
  updateStatusOfAcquireLeave,
  viewLeave,
} from "../../api/leaveApi";
import TableContainer from "../../components/common/tableContainer";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { dateFormat, dateFormatChange } from "../../utils/helpers/common";
import { ResponseStatusEnum, leaveStatus } from "../../utils/helpers/enums";
import { countOfData, listOfData } from "../../api/commonApi";
import Loader from "../../components/common/loader";
import FixedHeaderDatatables from "../../components/common/datatableCom";
import Select from "react-select";
import ApproveRejectModal from "../../components/common/approveRejectModal";

const Status = ({ status }) => {
  switch (status) {
    case "Pending":
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
    case "Rejected":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Approved":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
  }
};

const Leaves = () => {
  document.title = "Employee Leaves";
  const role = getRole();
  const name = getName();
  const id = getId();
  const [listLeave, setListLeave] = useState([]);
  const [datee, setDatee] = useState("");
  const [date1, setDate2] = useState("");
  const [leaveCardData, setLeaveCardDate] = useState();
  const [loading, setLoading] = useState(false);
  const [countOfCardData, setCountOfCardData] = useState();
  const [approveCardData, setApproveCardData] = useState();
  const [pedningCardData, setPendingCardData] = useState();
  const [leaveRejectData, setLeaveRejectData] = useState([]);
  const [listOfLeaveData, setlistOfLeaveData] = useState([]);
  const [listOfEmployeeLeaveData, setlistOfEmployeeLeaveData] = useState([]);
  const [listOfAquireleaveData, setListOfAquireLeaveData] = useState();
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedLeaveRow, setSelecteLeavedRow] = useState(null);
  const [modal, setModal] = useState();
  const [modalLeave, setModalLeave] = useState();
  const [cellData, setCellData] = useState();
  const [cellDataLeave, setCellDataLeave] = useState();
  const [action, setAction] = useState("");
  const [approvedId, setApprovedId] = useState()
  const [updateModalLeave, setUpdateModalLeave] = useState(false);
  const [stateValue, setStateValue] = useState(false)

  const toggleOFAquireLeave = () => setModal(!modal);
  const toggleRejectAdminLeave = () => setModalLeave(!modalLeave);
  const handleRejectAquireLeave = (cellData) => {
    aquireLeaveReject.handleSubmit();
    setModal(false);
    setCellData(cellData);
    toggleOFAquireLeave();
  };

  const handleApproveRejectLeaveFromAdmin = (cellDataLeave) => {
    setModalLeave(false);
    setCellDataLeave(cellDataLeave);
    toggleRejectAdminLeave();
  };

  function acquireLeaveDetails() {
    listOfAcquireLeave({
      condition: {
        isDeleted: false
      }
    })
    .then((response) => {
      setListOfAquireLeaveData(response?.data?.data);
    })
    .catch((err) => {
      setLoading(false);
      return err;
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const fetchData = () => {
    if (role === "Admin") {
      setLoading(true);
      listOfLeave()
        .then((response) => {
          setListLeave(response?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      viewLeave(id)
        .then((response) => {
          setListLeave(response?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
    countOfData({
      modelName: "leaveModel",
      condition: {
        status: "Pending",
        userId: id,
      },
    })
      .then((response) => {
        setPendingCardData(response?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });
    countOfData({
      modelName: "leaveModel",
      condition: {
        status: "Approve",
        userId: id,
      },
    })
      .then((response) => {
        setApproveCardData(response.data.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => { });
    if (role === "Admin") {
      countOfData({
        modelName: "leaveModel",
      })
        .then((response) => {
          setLeaveCardDate(response?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
        acquireLeaveDetails()
    } else {
      countOfData({
        modelName: "leaveModel",
        condition: {
          userId: id,
        },
      })
        .then((response) => {
          setLeaveCardDate(response?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
      countOfLeave(id)
        .then((response) => {
          setCountOfCardData(response?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setDatee("");
    setDate2("");
    filterOfLeaveDetails({
      userId: id,
    }).then((response) => setlistOfLeaveData(response?.data?.data))
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
        setStateValue(false);
      });
    filterOfLeaveDetails({}).then((response) => { setlistOfEmployeeLeaveData(response?.data?.data) }).catch((err) => { return err }).finally(() => { setLoading(false); setStateValue(false); });
  }, [stateValue]);

  const [developerName, setdeveloperName] = useState();
  useEffect(() => {
    if (role === "Admin" || role === "HR" || role === "BDE") {
      listOfData({
        modelName: "authUserModel",
        condition: {
          role : ["Employee", "HR","BDE"],
          isDeleted: false,
        },
        selectionCriteria: ["id", "firstName", "lastName"],
      })
        .then((response) => {
          setdeveloperName(response?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const developerObj = developerName?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.firstName} ${res?.lastName}`,
    };
  });

  const handleApprove = (row) => {
    setSelectedRow({ ...row, action: 'to approve this record' });
    setUpdateModal(true);
    setApprovedId(row?.cell?.row?.original?.id)
  };

  const handleApproveRejectLeave = (rowData) => {
    setSelectedRow({ ...rowData, action: 'to approve this record' });
    setUpdateModalLeave(true);
    setApprovedId(rowData?.cell?.row?.original?.id)
  };

  const handleApproveAquireLeave = (cell) => {
    setLoading(true);
    updateStatusOfAcquireLeave(approvedId, {
      status: "Approved",
    })
      .then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          setLoading(false);
          acquireLeaveDetails();
        } else {
          toast.error(response?.data?.message);
        }
      }).catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
    setUpdateModal(false);
    setSelectedRow(null);
    setAction("");
  };

  const handleApproveLeave = () => {
    setLoading(true);
    setUpdateModalLeave(false);
    approveRejectLeave(approvedId, { status: "Approved" })
      .then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          setModal(false);

          filterOfLeaveDetails({
          }).then((response) => setlistOfEmployeeLeaveData(response?.data?.data))
            .catch((err) => {
              setLoading(false);
              return err;
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          toast.error(response?.data?.message);
        }
      })
      .finally(() => {

        setLoading(false);
      });
    setUpdateModal(false);
    setSelecteLeavedRow(null);
    setAction('');
  };

  const [isApproveRejectModalOpen, setApproveRejectModalOpen] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const handleOpenApproveRejectModal = (leaveId) => {
    setSelectedLeaveId(leaveId);
    setApproveRejectModalOpen(true);
  };

  const leaveColumns = useMemo(() => [
    {
      Header: "Type of leave",
      accessor: "categoryLeave",
      filterable: false,
    },
    {
      Header: "From Date",
      accessor: "fromDate",
      filterable: false,
      Cell: (cell) => {
        const { fromDate } = cell.row.original;
        return fromDate !== null ? <div>{dateFormat(fromDate)}</div> : "-";
      },
    },
    {
      Header: "To Date",
      accessor: "toDate",
      filterable: false,
      Cell: (cell) => {
        const { toDate } = cell.row.original;
        return toDate !== null ? <div>{dateFormat(toDate)}</div> : "-";
      },
    },
    {
      Header: "Acquire Date",
      accessor: "acquireDate",
      filterable: false,
      Cell: (cell) => {
        const { acquireDate } = cell.row.original;
        return acquireDate !== null ? <div>{dateFormat(acquireDate)}</div> : "-";
      },
    },
    {
      Header: "Reason",
      accessor: "leaveReason",
      filterable: false,
    },

    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (item) => {
        if (role === "Admin") {
          return (
            <div>
              {item.row.original.status === "Approved" ||
                item.row.original.status === "Rejected" ? (
                <Status status={item.row.original.status} />
              ) : (
                <div className="hstack gap-2">
                  <button
                    id={`modalTodo-${item.cell.row.index}`}
                    className="btn btn-sm btn-soft-info edit-list"
                    onClick={() => {
                      // handleApproveRejectLeave(item)
                    }}
                  >
                    <i className="ri-check-line align-bottom" />
                    <ReactTooltip
                      place="bottom"
                      variant="info"
                      content="Approved"
                      anchorId={`modalTodo-${item.cell.row.index}`}
                    />
                  </button>
                  <button
                    id={`deleteTodo-${item.cell.row.index}`}
                    className="btn btn-sm btn-soft-danger remove-list"
                    onClick={() => {
                      handleTodoClicksReject();
                      setLeaveRejectData(item.cell.row.original);
                    }}
                  >
                    <i className="ri-close-line align-bottom" />
                    <ReactTooltip
                      place="bottom"
                      variant="error"
                      content="Reject"
                      anchorId={`deleteTodo-${item.cell.row.index}`}
                    />
                  </button>
                </div>
              )}
            </div>
          );
        } else {
          return <Status status={item.value} />;
        }
      },
    },
    {
      Header: "Leave Days",
      accessor: "numberOfDays",
      filterable: false,
    },
    {
      Header: "Leave Type",
      accessor: "leaveType",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (item) => (
        <div>
          {item?.row?.original?.status === "Approved" || item?.row?.original?.status === "Rejected" ? (
            "-"
          ) : (
            <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => handleOpenApproveRejectModal(item?.row?.original?.id)}>
              <i className="fa fa-times" aria-hidden="true">&times;</i>
            </button>
          )}
        </div>
      ),
    }
  ]);

  const handleCloseApproveRejectModal = () => {
    setApproveRejectModalOpen(false);
};

  const COPLeave = useMemo(() => [
    {
      Header: "Employee Name",
      accessor: "developerName",
      filterable: false,
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: (items) => {
        return <div>{dateFormat(items?.row?.original?.date)}</div>;
      },
    },
    {
      Header: "Reason",
      accessor: "reason",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            {cellProps.row.original.status === "Approved" ||
              cellProps.row.original.status === "Rejected" ? (
              <Status status={cellProps.row.original.status} />
            ) : (
              <div className="hstack gap-2">
                <div>
                  <ReactTooltip
                    className="bg-success"
                    place="bottom"
                    variant="info"
                    content="Approve"
                    anchorId={`approve-${cellProps?.row?.index}`}
                  />
                  <button
                    id={`approve-${cellProps?.row?.index}`}
                    className="btn btn-sm btn-soft-success edit-list"
                    onClick={() => {
                      handleApprove(cellProps);
                    }}
                  >
                    <i className="ri-check-line"></i>
                  </button>
                </div>
                <div>
                  <ReactTooltip
                    className="bg-danger"
                    place="bottom"
                    variant="info"
                    content="Reject"
                    anchorId={`reject-${cellProps?.row?.index}`}
                  />
                  <button
                    id={`reject-${cellProps?.row?.index}`}
                    className="btn btn-sm btn-soft-danger edit-list"
                    onClick={() => {
                      handleRejectAquireLeave(cellProps?.cell?.row?.original?.id);
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ]);

  const columns = useMemo(() => [
    {
      Header: "Employee Name",
      Cell: (items) => {
        return <div>{items?.row?.original?.authUser?.developerName}</div>;
      },
    },
    {
      Header: "Type of leave",
      accessor: "categoryLeave",
      filterable: false,
    },
    {
      Header: "Leave Type",
      accessor: "leaveType",
      filterable: false,
    },
    {
      Header: "Reason",
      accessor: "leaveReason",
      filterable: false,
    },
    {
      Header: "Leave Days",
      accessor: "numberOfDays",
      filterable: false,
    },
    {
      Header: "From Date",
      Cell: (cell) => {
        const { fromDate } = cell.row.original;
        return fromDate !== null ? <div>{dateFormat(fromDate)}</div> : "-";
      },
    },
    {
      Header: "To Date",
      Cell: (cell) => {
        const { toDate } = cell.row.original;
        return toDate !== null ? <div>{dateFormat(toDate)}</div> : "-";
      },
    },
    {
      Header: "Acquire Leave",
      Cell: (cell) => {
        const { acquireDate } = cell.row.original;
        return acquireDate !== null ? <div>{dateFormat(acquireDate)}</div> : "-";
      },
    },
    {
      Header: "Action",
      accessor: "status",
      Cell: (item) => {
        if (role === "Admin") {
          return (
            <div>
              {item.row.original.status === "Approved" ||
                item.row.original.status === "Rejected" ? (
                <Status status={item.row.original.status} />
              ) : (
                <div className="hstack gap-2">
                  <button
                    id={`modalTodo-${item?.cell?.row?.index}`}
                    className="btn btn-sm btn-soft-info edit-list"
                    onClick={() => { handleApproveRejectLeave(item)}}
                  >
                    <i className="ri-check-line align-bottom" />
                    <ReactTooltip
                      place="bottom"
                      variant="info"
                      content="Approve"
                      anchorId={`modalTodo-${item?.cell?.row?.index}`}
                    />
                  </button>
                  <button
                    id={`deleteTodo-${item?.cell?.row?.index}`}
                    className="btn btn-sm btn-soft-danger remove-list"
                    onClick={() => { handleApproveRejectLeaveFromAdmin(item) }}
                  >
                    <i className="ri-close-line align-bottom" />
                    <ReactTooltip
                      place="bottom"
                      variant="error"
                      content="Reject"
                      anchorId={`deleteTodo-${item?.cell?.row?.index}`}
                    />
                  </button>
                </div>
              )}
            </div>
          );
        } else {
          return <Status status={item?.value} />;
        }
      },
    },
  ]);
  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [modalTodoReject, setModalTodoReject] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditReject, setIsEditReject] = useState(false);

  const employeeLeave = [
    {
      id: 1,
      cardColor: "primary",
      label: "Pending Leave",
      badgeClass: "success",
      counter: countOfCardData?.pending_count,
      bgcolor: "success",
      icon: "ri-account-box-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      cardColor: "primary",
      label: "Approve Leave",
      badgeClass: "success",
      counter: countOfCardData?.approved_count,
      bgcolor: "success",
      icon: "ri-team-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 3,
      cardColor: "primary",
      label: "Rejected Leave",
      badgeClass: "success",
      counter: countOfCardData?.rejected_count,
      bgcolor: "success",
      icon: "ri-file-list-3-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 4,
      cardColor: "primary",
      label: "Total Leave in Bucket",
      badgeClass: "success",
      counter: countOfCardData?.total_leave_count,
      bgcolor: "success",
      icon: "ri-file-list-3-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];
  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  const toggleReject = useCallback(() => {
    if (modalTodoReject) {
      setModalTodoReject(false);
    } else {
      setModalTodoReject(true);
    }
  }, [modalTodoReject]);

  const handleTodoClicksReject = () => {
    setModalTodoReject(!modalTodo);
    setIsEditReject(false);
    toggleReject();
  };

  const RegEx = /^[A-Za-z\s]*$/;
  // To do Task List validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      leaveType: (todo?.leaveType) || "",
      leaveDays: (todo?.leaveDays) || "",
      reason: (todo?.reason) || "",
      fromDate: (todo?.fromDate) || "",
      toDate: (todo?.toDate) || "",
    },
    validationSchema: Yup.object({
      leaveType: Yup.string().required(RequiredField("select leave")),
      leaveDays: Yup.string().required(RequiredField("number of days")),
      reason: Yup.string()
        .required(RequiredField("Reason"))
        .matches(RegEx, "Enter Only Characters"),
      fromDate: Yup.string().required(RequiredField("number of days")),
      toDate: Yup.string().required(RequiredField("number of days")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        validation.resetForm();
      } else {
        setLoading(true);
        addLeave({
          userId: parseInt(id),
          fromDate: values.fromDate,
          toDate: values.toDate,
          leaveDays: values.leaveDays,
          reason: values.reason,
          status: "Pending",
          leaveType: values.leaveType,
        })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              viewLeave(id).then((response) => {
                setListLeave(response.data.data);
              });
            } else if (
              response?.data?.statusCode === ResponseStatusEnum.DUPLICATE
            ) {
              toast.warning(response?.data?.message);
            }
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
        setDate2("");
        setDatee("");
        validation.resetForm();
      }
      toggle();
    },
  });

  const validationReject = useFormik({

    enableReinitialize: true,

    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Please Enter Reason"),
    }),
    onSubmit: (values) => {
      updateStatusOfAcquireLeave(cellData, {
        reviewReason: values.reason,
        status: "Rejected",
      }).then((response) => {
        if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          listOfLeave().then((response) => {
            setListLeave(response?.data?.data);
          });
        }
      });
      validationReject.resetForm();
      toggleReject();
    },
  });

  const validationAdminApproveReject = useFormik({
    enableReinitialize: true,
    initialValues: {
      reviewReason: "",
    },
    validationSchema: Yup.object().shape({
      reviewReason: Yup.string().required(RequiredField("Reason")),
    }),
    onSubmit: (value) => {
      setLoading(true)
      adminApproveRejectLeave(cellDataLeave?.cell?.row?.original?.id, { status: "Rejected", ...value }).then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          filterOfLeaveDetails({
            userId: id,
          }).then((response) => setlistOfLeaveData(response?.data?.data))
            .catch((err) => {
              setLoading(false);
              return err;
            })
            .finally(() => {
              setLoading(false);
            });
          setLoading(false);
          setModalLeave(false);
        } else {
          toast.error(response?.data?.message);
          setModalLeave(false)
        }
        validationAdminApproveReject.resetForm();
      }).catch((err) => { setLoading(err); return err; }).finally(() => {
        setLoading(false);
      });
      setModalLeave(false);
    },
  });

  const aquireLeaveReject = useFormik({
    enableReinitialize: true,
    initialValues: {
      reviewReason: "",
    },
    validationSchema: Yup.object().shape({
      reviewReason: Yup.string().required(RequiredField("Reason")),
    }),
    onSubmit: (value) => {
      setLoading(true);
      updateStatusOfAcquireLeave(cellData, { ...value, status: "Rejected" })
        .then((response) => {
          if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(response?.data?.message);
            setLoading(true);
            acquireLeaveDetails();
            setModal(false);
          } else {
            toast.error(response?.data?.message);
            setModal(false);
          }
          aquireLeaveReject.resetForm();
        })
        .catch((err) => {
          setLoading(err);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
      setModal(false);
    },
  });


  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedUserName, setselectedUserName] = useState("Employee Name");

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    hrAdminleaveFilterValidation.setFieldValue(
      "developerName",
      selectedOption.value
    );
  };

  const empLeaveType = [
    {
      options: [
        { label: "All", value: "ALL" },
        { label: "Casual Leave", value: "CL" },
        { label: "Comp Of Leave", value: "COL" },
        { label: "LWP Leave", value: "LWP" },
      ],
    },
  ];

  const leaveFilterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryLeave: null,
      status: null,
      startDate: null,
      endDate: null,
    },
    onSubmit: (values) => {
      setLoading(true);
      filterOfLeaveDetails({
        userId: id,
        startDate: values?.startDate,
        endDate: values?.endDate,
        categoryLeave: values?.categoryLeave,
        status: values?.status,
      })
        .then((res) => setlistOfLeaveData(res?.data?.data))
        .catch((err) => { return err }).finally(() => { setLoading(false) });
    },
  });

  const hrAdminleaveFilterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      developerName: null,
      categoryLeave: null,
      status: null,
      startDate: null,
      endDate: null,
    },
    onSubmit: (values) => {
      setselectedUserName(selectedOption?.label);
      setLoading(true);

      const payload ={
        userId: values?.developerName,
        startDate: values?.startDate,
        endDate: values?.endDate,
        status: values?.status, 
      }
      if (values?.categoryLeave !== null) {
        payload.categoryLeave = values?.categoryLeave
      }
      filterOfLeaveDetails(payload)
        .then((res) => setlistOfEmployeeLeaveData(res?.data?.data))
        .catch((err) => { return err }).finally(() => { setLoading(false) });
    },
  });

  let message = "";
  if (selectedRow) {
    if (selectedRow.action === "approve") {
      message = `Are you sure you want to Approve the record with reason ${selectedRow.reason}?`;
    } else if (selectedRow.action === "reject") {
      message = `Are you sure you want to Reject the record with reason ${selectedRow.reason}?`;
    }
  }

  const downloadTableData = () => {
    const portionToPrint = document.getElementById('main-page');
    const download = document.getElementById('download-button');
    const downloadContent = document.getElementById('downloadContent');
    const filterId = document.getElementById('filterId');
    const paginationId = document.getElementById('paginationId');

    filterId.classList.add('nonPrintable');
    paginationId.classList.add('nonPrintable');
    portionToPrint.classList.add('nonPrintable');
    download.classList.add('nonPrintable');
    downloadContent.classList.add('printable');
   
    window.print()

    filterId.classList.remove('nonPrintable');
    paginationId.classList.remove('nonPrintable');
    portionToPrint.classList.remove('nonPrintable');
    download.classList.remove('nonPrintable');
    downloadContent.classList.remove('printable');
  }

  let data = listOfLeaveData?.sort((a, b) => b?.id - a?.id);
  let columnData = listOfEmployeeLeaveData?.sort((a, b) => b?.id - a?.id);

  const [response, setResponse] = useState(null);

  const handleDeleteLeave = (leaveId) => {
    setLoading(true);
    const payload = {
      id: leaveId,
      isDeleted: true,
    };
    setApproveRejectModalOpen(true);
    deleteLeave(payload)
        .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
                toast.success(response?.data?.message);
                handleCloseApproveRejectModal(false);
            }
            setResponse(response?.data);
            filterOfLeaveDetails({
              userId: id,
            }).then((response) => setlistOfLeaveData(response?.data?.data))
        })
        .catch((err) => {
            toast.error(err?.data?.message);
            return err;
        })
        .finally(() => {
            setLoading(false);
        });
};

  return (
    <React.Fragment>
      <ApproveRejectModal
        show={updateModal}
        onDeleteClick={() => handleApproveAquireLeave()}
        onCloseClick={() => setUpdateModal(false)}
        message={message}
        action={selectedRow ? selectedRow.action : ""}
      />

      <ApproveRejectModal
        show={updateModalLeave}
        onDeleteClick={() => handleApproveLeave()}
        onCloseClick={() => setUpdateModalLeave(false)}
        message={message}
        action={selectedRow ? selectedRow.action : ""}
      />
      <ApproveRejectModal
        show={isApproveRejectModalOpen}
        onCloseClick={handleCloseApproveRejectModal}
        onDeleteClick={() => handleDeleteLeave(selectedLeaveId)} 
        action="cancel leave" 
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid id="main-page">
            <BreadCrumb title="Leaves" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Leave Management</h5>
              </div>
            </Row>
            <Row>
              {role === "Admin" || role === "HR" ? (
                <></>
              ) : (
                <>
                  <Col lg={2}>
                    <Widgets data={[employeeLeave[0], employeeLeave[1]]} />
                  </Col>
                  <Col lg={2}>
                    <Widgets data={[employeeLeave[2], employeeLeave[3]]} />
                  </Col>
                  <Col lg={8}>
                    <CardHeader className="align-items-center d-flex">
                      <Row className="w-100">
                        <Card>
                          <CardHeader>
                            <h5 className="card-title mb-0">Your Leaves</h5>
                          </CardHeader>
                          <CardBody>
                            <FixedHeaderDatatables setStateValue={setStateValue} stateValue={stateValue}/>
                          </CardBody>
                        </Card>
                      </Row>
                    </CardHeader>
                  </Col>
                </>
              )}
            </Row>

            {role === "Admin" && (
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">Aquire Leaves</h4>
                    </CardHeader>
                    <CardBody>
                      {listOfAquireleaveData?.length ? (
                        <TableContainer
                          columns={COPLeave}
                          data={listOfAquireleaveData || []}
                          isAddUserList={false}
                          isGlobalFilter={true}
                          customPageSize={5}
                          divClass="table-responsive table-card mb-1"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light text-muted"
                        />
                      ) : (
                        <div className="py-4 mt-4 text-center" id="noresult">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{ width: "72px", height: "72px" }}
                          ></lord-icon>
                          <h5 className="mt-4">Sorry! No Result Found</h5>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            {(role === "Admin" || role === "HR") && (
              <Form onSubmit={hrAdminleaveFilterValidation.handleSubmit}>
                <CardBody>
                  <Row>
                    <Col>
                      <Select
                        name="developerName"
                        placeholder={"Select Developer Name"}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={developerObj}
                        classNamePrefix="custom-select"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "white",
                          }),
                        }}
                      />
                    </Col>
                    <Col>
                      <Input
                        name="categoryLeave"
                        type="select"
                        className={`form-select ${hrAdminleaveFilterValidation.errors
                          .categoryLeave &&
                          hrAdminleaveFilterValidation.values
                            .categoryLeave
                          ? "in-valid"
                          : null
                          }`}
                        id="choices-publish-status-input"
                        placeholder="Leave Type"
                        onChange={
                          hrAdminleaveFilterValidation.handleChange
                        }
                        onBlur={
                          hrAdminleaveFilterValidation.handleBlur
                        }
                        value={
                          hrAdminleaveFilterValidation.values
                            .categoryLeave
                        }
                      >
                        {empLeaveType?.map((item, key) => (
                          <React.Fragment key={key}>
                          <option selected hidden>Leave Type</option>
                            {item?.options?.map((item, key) => (
                              <option value={item?.value} key={key}>
                                {item?.label}
                              </option>
                            ))}
                          </React.Fragment>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        id="selectreport"
                        name="status"
                        onChange={
                          hrAdminleaveFilterValidation.handleChange
                        }
                        onBlur={
                          hrAdminleaveFilterValidation.handleBlur
                        }
                        value={
                          hrAdminleaveFilterValidation.values.status
                        }
                      >
                       <option selected hidden>Leave Status</option>
                        {leaveStatus?.map((res) => {
                          return (
                            <>
                              <option value={res}>{res}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                    <Col>
                      <Flatpickr
                        className="form-control"
                        id="datepicker-publish-input"
                        placeholder="Select date"
                        onBlur={
                          hrAdminleaveFilterValidation.handleBlur
                        }
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "d.m.y",
                          mode: "range",
                          onChange: function (
                            selectedDates,
                            dateStr,
                            instance
                          ) {
                            hrAdminleaveFilterValidation.setFieldValue(
                              "startDate",
                              dateFormatChange(selectedDates[0])
                            );
                            hrAdminleaveFilterValidation.setFieldValue(
                              "endDate",
                              dateFormatChange(selectedDates[1])
                            );
                          },
                        }}
                      />
                    </Col>
                    <Col className="col-lg-auto">
                      <button
                        className="btn btn-primary createTask"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? (
                          "SEARCH"
                        ) : (
                          <div className="d-inline-flex align-items-center">
                            <Spinner size="sm" className="me-2" />
                            Loading...
                          </div>
                        )}
                      </button>
                    </Col>
                  </Row>
                </CardBody>

                {listOfEmployeeLeaveData?.length ? (
                  <Col lg={12}>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">
                          {selectedUserName}
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <TableContainer
                          columns={columns} 
                          data={columnData || []}
                          isAddUserList={false}
                          customPageSize={5}
                          divClass="table-responsive table-card mb-1"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light text-muted"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                ) : (
                  <div className="py-4 mt-4 text-center" id="noresult">
                    <lord-icon
                      src="https://cdn.lordicon.com/msoeawqm.json"
                      trigger="loop"
                      colors="primary:#405189,secondary:#0ab39c"
                      style={{ width: "72px", height: "72px" }}
                    ></lord-icon>
                    <h5 className="mt-4">Sorry! No Result Found</h5>
                  </div>
                )}
              </Form>
            )}
          </Container>
        ) : (
          <Loader />
        )}


        {!["Admin", "HR"].includes(role) && (

          !stateValue ? (
            <Card id = "download">
            <CardHeader>
              <h5 className="card-title mb-0">Leave Details</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <CardBody>
                    <Form onSubmit={leaveFilterValidation.handleSubmit}>
                      <Row id = 'filterId'>
                        <Col>
                          <Input
                            name="categoryLeave"
                            type="select"
                            className={`form-select ${leaveFilterValidation.errors
                              .categoryLeave &&
                              leaveFilterValidation.values.categoryLeave
                              ? "in-valid"
                              : null
                              }`}
                            id="choices-publish-status-input"
                            placeholder="Leave Type"
                            onChange={leaveFilterValidation.handleChange}
                            onBlur={leaveFilterValidation.handleBlur}
                            value={
                              leaveFilterValidation.values.categoryLeave
                            }
                          >
                            {empLeaveType.map((item, key) => (
                              <React.Fragment key={key}>
                                {item.options.map((item, key) => (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </Input>
                          {leaveFilterValidation.errors.categoryLeave &&
                            leaveFilterValidation.values.categoryLeave ? (
                            <div className="invalid-feedback">
                              {leaveFilterValidation.errors.categoryLeave}
                            </div>
                          ) : null}
                        </Col>
                        <Col>
                          <select
                            className="form-select mb-3"
                            aria-label="Default select example"
                            id="selectreport"
                            name="status"
                            onChange={leaveFilterValidation.handleChange}
                            onBlur={leaveFilterValidation.handleBlur}
                            value={leaveFilterValidation.values.status}
                          >
                            <option selected hidden>Leave Status</option>
                            <option value="ALL">ALL</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                          </select>
                        </Col>
                        <Col>
                          <Flatpickr
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select date"
                            onBlur={leaveFilterValidation.handleBlur}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "d.m.y",
                              mode: "range",
                              onChange: function (
                                selectedDates,
                                dateStr,
                                instance
                              ) {
                                leaveFilterValidation.setFieldValue(
                                  "startDate",
                                  dateFormatChange(selectedDates[0])
                                );
                                leaveFilterValidation.setFieldValue(
                                  "endDate",
                                  dateFormatChange(selectedDates[1])
                                );
                              },
                            }}
                          />
                        </Col>
                        <Col className="col-lg-auto">
                          <button
                            className="btn btn-primary createTask"
                            type="submit"
                          >
                            {!loading ? (
                              "SEARCH"
                            ) : (
                              <div className="d-inline-flex align-items-center">
                                <Spinner size="sm" className="me-2" />
                                Loading...
                              </div>
                            )}
                          </button>
                        </Col>
                        </Row>
                        <Row>
                        {listOfLeaveData?.length ? (
                          <TableContainer
                            id='downloadContent'
                            columns={leaveColumns}
                            data={data || []}
                            isAddUserList={false}
                            customPageSize={10}
                            divClass="table-responsive table-card mb-1"
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                          />
                        ) : (
                          <div
                            className="py-4 mt-4 text-center"
                            id="noresult"
                          >
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{
                                width: "72px",
                                height: "72px",
                              }}
                            ></lord-icon>
                            <h5 className="mt-4">
                              Sorry! No Result Found
                            </h5>
                          </div>
                        )}
                          </Row>
                    </Form>
                    <div className="col-md-4" id="download-button">
                      <button
                        type="button"
                        class="custom-toggle active btn btn-success mt-3 align-right"
                        fdprocessedid="amod4n"
                        onClick={downloadTableData}
                      >
                        <span class="icon-off">
                          <i class="ri-check-fill align-bottom me-1"></i>
                          Download Leave Details
                        </span>
                      </button>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </CardBody>
          </Card>
          ) : (<Loader/>)

        )}
      </div>

      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {!!isEdit ? "Edit Leave" : "Add Leave"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                {" "}
                Full Name :
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Your Name"
                name="name"
                disabled
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={name}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  From :
                </label>
                <Flatpickr
                  name="fromDate"
                  id="date-field"
                  validate={{ required: { value: true } }}
                  onBlur={validation.handleBlur}
                  disabled={role === "Admin"}
                  className={`form-control ${validation.errors.fromDate && "is-invalid"
                    }`}
                  placeholder="From date"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    onChange: function (selectedDates, dateStr, instance) {
                      validation.setFieldValue("fromDate", dateStr);
                    },
                  }}
                  value={validation.values.fromDate}
                />
                <div className="invalid-feedback">
                  {validation.errors.fromDate}
                </div>
              </Col>
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  To :
                </label>
                <Flatpickr
                  name="toDate"
                  id="date-field"
                  validate={{ required: { value: true } }}
                  onBlur={validation.handleBlur}
                  disabled={role === "Admin"}
                  className={`form-control ${validation.errors.toDate && "is-invalid"
                    }`}
                  placeholder="To date"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    onChange: function (selectedDates, dateStr, instance) {
                      validation.setFieldValue("toDate", dateStr);
                    },
                  }}
                  value={validation.values.toDate}
                />
                <div className="invalid-feedback">
                  {validation.errors.toDate}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Number of Days :
                  </label>
                  <Input
                    type="number"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter number of days"
                    name="leaveDays"
                    disabled={role === "Admin"}
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.leaveDays || ""}
                    invalid={
                      validation.touched.leaveDays &&
                        validation.errors.leaveDays
                        ? true
                        : false
                    }
                  />
                  {validation.touched.leaveDays &&
                    validation.errors.leaveDays ? (
                    <FormFeedback type="invalid">
                      {validation.errors.leaveDays}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Leave Reason :
              </label>
              <Input
                type="textarea"
                id="task-title-input"
                className="form-control"
                placeholder="Enter leave reason"
                name="reason"
                disabled={role === "Admin"}
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  validation.touched.reason && validation.errors.reason
                    ? true
                    : false
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Leave Type :
              </label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                id="selectreport"
                value={validation.values.leaveType || ""}
                name="leaveType"
                disabled={role === "Admin"}
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              >
                <option selected hidden>
                  Select Leave Type 
                </option>
                <option value="Full"> Full</option>
                <option value="Half">Half</option>
                <option value="WFH">WFH</option>
              </select>
              {validation.touched.leaveType && validation.errors.leaveType ? (
                <FormFeedback type="invalid">
                  {validation.errors.leaveType}
                </FormFeedback>
              ) : null}
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodo(false)}
              >
                <i className="ri-close-fill align-bottom"></i>{" "}
                {role === "Admin" ? "Reject" : "Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {role === "Admin" ? "Approve" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        id="createTask"
        isOpen={modalTodoReject}
        toggle={toggleReject}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggleReject} className="p-3 bg-soft-success">
          {" "}
          {!!isEditReject ? "Edit Leave" : "Reject Leave"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validationReject.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                {" "}
                Full Name :
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Your Name"
                name="name"
                disabled
              />

            </div>
            <Row className="g-4 mb-3">
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  From :
                </label>
                <Flatpickr
                  name="fromDate"
                  id="date-field"
                  disabled={role === "Admin"}
                  className="form-control"
                  placeholder="From date"
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  value={leaveRejectData.fromDate}
                />
              </Col>
              <Col lg={6}>
                <label htmlFor="task-duedate-input" className="form-label">
                  To :
                </label>
                <Flatpickr
                  name="toDate"
                  id="date-field"
                  disabled={role === "Admin"}
                  className="form-control"
                  placeholder="To date"
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  value={leaveRejectData.toDate}
                />
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Number of Days :
                  </label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter task title"
                    name="leaveDays"
                    disabled={role === "Admin"}
                    value={leaveRejectData.leaveDays}
                  />
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Reject Leave Reason :
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Reject Reason"
                name="reason"
                validate={{ required: { value: true } }}
                onChange={validationReject.handleChange}
                onBlur={validationReject.handleBlur}
                value={validationReject.values.reason || ""}
                invalid={
                  validationReject.touched.reason &&
                    validationReject.errors.reason
                    ? true
                    : false
                }
              />
              {validationReject.touched.reason &&
                validationReject.errors.reason ? (
                <FormFeedback type="invalid">
                  {validationReject.errors.reason}
                </FormFeedback>
              ) : null}
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodoReject(false)}
              >
                <i className="ri-close-fill align-bottom"></i> {"Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {"Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggleOFAquireLeave}
        centered
        modalClassName="zoomIn"
      >
        <Form onSubmit={aquireLeaveReject.handleSubmit}>
          <ModalHeader
            toggle={toggleOFAquireLeave}
            className="p-3 bg-soft-success"
          >
            {" "}
            Reason for rejecting the Aquire Leave
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Label>Reason</Label>
                <Input
                  type="textarea"
                  title="reviewReason"
                  name="reviewReason"
                  placeholder={"Enter reviewReason for rejecting the leave"}
                  value={aquireLeaveReject.values.reviewReason}
                  onBlur={aquireLeaveReject.handleBlur}
                  onChange={aquireLeaveReject.handleChange}
                  invalid={
                    aquireLeaveReject.touched.reviewReason &&
                      aquireLeaveReject.errors.reviewReason
                      ? true
                      : false
                  }
                ></Input>
                {aquireLeaveReject.touched.reviewReason &&
                  aquireLeaveReject.errors.reviewReason ? (
                  <FormFeedback type="invalid">
                    {aquireLeaveReject.errors.reviewReason}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="none"
              className="btn btn-ghost-success"
              onClick={toggleOFAquireLeave}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={modalLeave}
        toggle={toggleRejectAdminLeave}
        centered
        modalClassName="zoomIn"
      >
        <Form onSubmit={validationAdminApproveReject.handleSubmit}>
          <ModalHeader
            toggle={toggleRejectAdminLeave}
            className="p-3 bg-soft-success"
          >
            {" "}
            Reason for rejecting the leave
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Label>Reason</Label>
                <Input
                  type="textarea"
                  title="reviewReason"
                  name="reviewReason"
                  placeholder={"Enter reviewReason for rejecting the leave"}
                  value={validationAdminApproveReject.values.reviewReason}
                  onBlur={validationAdminApproveReject.handleBlur}
                  onChange={validationAdminApproveReject.handleChange}
                  invalid={validationAdminApproveReject.touched.reviewReason &&
                    validationAdminApproveReject.errors.reviewReason
                    ? true
                    : false}
                ></Input>
                {validationAdminApproveReject.touched.reviewReason &&
                  validationAdminApproveReject.errors.reviewReason ? (
                  <FormFeedback type="invalid">
                    {validationAdminApproveReject.errors.reviewReason}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="none"
              className="btn btn-ghost-success"
              onClick={toggleRejectAdminLeave}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Leaves;
