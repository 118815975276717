import moment from "moment"

export const dateFormat = (date) => {
    return moment(date).format("DD-MM-YYYY");
}

export const getFormData = (object) => {
    const formData = new FormData(object);
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export const dateFormatChange = (date) => {
    return moment(date).format("YYYY-MM-DD");
}
export const timeFormat = (date) => {
    return moment(date).format("H:mm");
};

export const dateMonthFormat = (date) => {
    return moment(date).format("DD MMM Y");
}