import React, { useEffect, useState } from "react";
import Loader from "../../components/common/loader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import {
  addTasksheet,
  editTaskSheet,
  listOfTimesheet,
  listOfAssignProjects,
  listOfTasks,
} from "../../api/tasksheetApi";
import { getId } from "../../utils/helpers/custom";
import Select from "react-select";
import { TIME_ZERO } from "../../utils/helpers/enums";

const TimesheetAddEdit = () => {
  const { timesheet_id } = useParams();
  const [loading, setLoading] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [isEdited, setIsEdit] = useState();
  const [editTimeSheetData, setEditTimeSheetData] = useState();
  const [listOfProject, setListOfProject] = useState();
  const [listOfTask, setlistOfTask] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);
  const [selectedDailyTaskStatus, setSelectedDailyTaskStatus] = useState(null);
  const [selectedRemainTime, setSelectedRemainTime] = useState(null);
  let storeRemainTime;

  const isEdit = null;

  const id = getId();
  const navigate = useNavigate();

  const taskStatus = [
    {
      value: "Completed",
      label: "Complete",
    },
    {
      value: "Pending",
      label: "Pending",
    },
  ];

  const dailyTakStatus = [
    {
      value: "Completed",
      label: "Complete",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "On Process",
      label: "On Process",
    },
  ];

  document.title = pageTitle;
  const [projectSelected, setProjectSelected] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);

  useEffect(() => {
    if (!isNaN(timesheet_id)) {
      setIsEdit(true);
      setPageTitle("Edit Timesheet");
      setLoading(true);
      listOfTimesheet({
          timeSheetId: timesheet_id,
      })
        .then((res) => {
          setEditTimeSheetData(res?.data?.data?.listOfTaskSheet[0]);
          setSelectedProject({
            value: res?.data?.data?.listOfTaskSheet[0]?.taskSheet?.projectName,
            label: res?.data?.data?.listOfTaskSheet[0]?.taskSheet?.projectName,
          });
          setSelectedTask({
            value: res?.data?.data?.listOfTaskSheet[0]?.taskSheet?.taskName,
            label: res?.data?.data?.listOfTaskSheet[0]?.taskSheet?.taskName,
          });
          setSelectedTaskStatus({
            value: res?.data?.data?.listOfTaskSheet[0]?.taskStatus,
            label: res?.data?.data?.listOfTaskSheet[0]?.taskStatus,
          });
          setSelectedDailyTaskStatus({
            value: res?.data?.data?.listOfTaskSheet[0]?.dailyStatus,
            label: res?.data?.data?.listOfTaskSheet[0]?.dailyStatus,
          });
          setSelectedRemainTime(res?.data?.data?.listOfTaskSheet[0]?.taskSheet?.remainingTime);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    } else {
      setIsEdit(false);
      setPageTitle("Add Timesheet");
    }

    listOfAssignProjects()
      .then((res) => setListOfProject(res?.data?.data))
      .catch((err) => setLoading(false));

    listOfTimesheet({
      modelName: "taskSheetModel",
      condition: {
        id: timesheet_id,
      },
    });
  }, [timesheet_id]);

  useEffect(() => {
    if (selectedProject) {
      setProjectSelected(true);
    } else {
      setProjectSelected(false);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (projectSelected) {
      let projectName = selectedProject?.value;
      setLoadingTasks(true);
      listOfTasks({
        assignTo: parseInt(id),
        projectName,
        taskStatus: "Pending",
      })
        .then((res) => setlistOfTask(res?.data?.data))
        .catch((err) => {
          setlistOfTask([]);
          return err;
        })
        .finally(() => setLoadingTasks(false));
    }
  }, [projectSelected, selectedProject, id]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      projectTask: editTimeSheetData?.taskSheet?.projectName || "",
      taskSheetId: editTimeSheetData?.taskSheetId || "",
      date: editTimeSheetData?.date || "",
      takenHours: editTimeSheetData?.takenHours || "",
      takenMinutes: editTimeSheetData?.takenMinutes || "",
      detailsOfTask: editTimeSheetData?.detailsOfTask || "",
      taskStatus: editTimeSheetData?.taskStatus || "",
      dailyStatus: editTimeSheetData?.dailyStatus || "",
    },
    validationSchema: Yup.object({
      projectTask: Yup.string().required(RequiredField("Project name")),
      taskSheetId: Yup.string().required(RequiredField("Task")),
      date: Yup.string().required(RequiredField("Date")),
      takenMinutes: Yup.string().optional(),
      takenHours: Yup.string().optional(),
      detailsOfTask: Yup.string().required(RequiredField("Detail Task")),
      taskStatus: Yup.string().required(RequiredField("Status")),
      dailyStatus: Yup.string().required(RequiredField("Daily status")),
    }),
    onSubmit: (value) => {
      if (isEdited) {
        setLoading(true);
        editTaskSheet({
          userId: id,
          id: parseInt(timesheet_id),
          projectTask: value?.projectTask,
          taskSheetId: value?.taskSheetId,
          detailsOfTask: value?.detailsOfTask,
          date: value?.date,
          taskStatus: value?.taskStatus,
          takenHours: +value?.takenHours,
          takenMinutes: +value?.takenMinutes,
          dailyStatus: value?.dailyStatus,
        })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              validation.resetForm();
              navigate("/timesheet");
              setLoading(false);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => setLoading(false))
          .finally();
      } else {
        setLoading(true);
        addTasksheet({
          userId: id,
          projectTask: value?.projectTask,
          taskSheetId: value?.taskSheetId,
          detailsOfTask: value?.detailsOfTask,
          date: value?.date,
          takenMinutes: +value?.takenMinutes,
          takenHours: +value?.takenHours,
          taskStatus: value?.taskStatus,
          dailyStatus: value?.dailyStatus,
          remainingTime: storeRemainTime
        })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              validation.resetForm();
              navigate("/timesheet");
              setLoading(false);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => setLoading(false));
      }
    },
  });

  let selectedHours = validation?.values?.takenHours;
  let selectedTakenMinutes = validation?.values?.takenMinutes;

  let totalSeconds = timeStringToSeconds(isEdited? selectedRemainTime : selectedTask?.remainingTime);
  let inputSeconds = selectedHours * 3600 + selectedTakenMinutes * 60;
  let remainingSeconds = totalSeconds - inputSeconds;

  let remainingHour = Math.floor(remainingSeconds / 3600);
  let remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
  let remainingSecondsOutput = remainingSeconds % 60;

  function timeStringToSeconds(timeString) {
    if (isEdited? selectedRemainTime : selectedTask?.remainingTime !== undefined) {
      let [hours, minutes, seconds] = timeString.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }
  }

  const finalTime = (hr, min, sec) => {
    if (hr < 0 || min < 0) {
      return TIME_ZERO;
    } else {
      let finalHr = (hr === 0) ? "00" : (String(hr).length === 1) ? `0${hr}`: hr;
      let finalMin = (min === 0) ? "00" : min;
      const reamingTime = `${finalHr}:${finalMin}:${sec}0`;
      return reamingTime;
    }
  };

  storeRemainTime = finalTime(
    remainingHour,
    remainingMinutes,
    remainingSecondsOutput
  );

  const selectedProjectObj = Array?.isArray(listOfProject)
    ? listOfProject?.map((res) => {
        return {
          value: `${res?.project?.projectName}`,
          label: `${res?.project?.projectName}`,
        };
      })
    : null;

  const selectedTaskObj = Array.isArray(listOfTask)
    ? listOfTask?.map((res) => {
        return {
          value: `${res?.id}`,
          label: `${res?.taskName}`,
          estimateTime: `${res?.estimateTime}`,
          remainingTime: `${res?.remainingTime}`,
        };
      })
    : null;

  const selectedTaskStatusObj = Array.isArray(taskStatus)
    ? taskStatus.map((status) => ({
        value: status.value,
        label: status.label,
      }))
    : null;

  const selectedDailyTaskStatusObj = Array.isArray(dailyTakStatus)
    ? dailyTakStatus.map((status) => ({
        value: status.value,
        label: status.label,
      }))
    : null;

  return (
    <div className="page-content">
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <BreadCrumb title={pageTitle} pageTitle="HRMS" />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">{pageTitle}</h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <Link to={"/timesheet"} className="btn btn-primary">
                  Back
                </Link>
              </div>
            </div>
          </Row>
          <Card>
            <CardBody>
              <Form onSubmit={validation.handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label className="form-label">Select Project :</label>

                      <Select
                        className={` ${
                          validation.errors.projectTask &&
                          "is-invalid red-border"
                        }`}
                        placeholder="Select Project Name"
                        value={
                          selectedProject || validation?.values?.projectTask
                        }
                        isMulti={false}
                        onChange={(selectedOption) => {
                          setSelectedProject(selectedOption);
                          validation.setFieldValue(
                            "projectTask",
                            selectedOption ? selectedOption?.value : null
                          );
                          setSelectedTask("");
                        }}
                        options={selectedProjectObj}
                        onBlur={validation.handleBlur}
                        name="projectTask"
                      />
                      {validation.touched.projectTask &&
                      validation.errors.projectTask ? (
                        <div className="invalid-feedback">
                          {validation.errors.projectTask}
                        </div>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <label className="form-label">Select Task :</label>
                      <Select
                        className={` ${
                          validation.errors.taskSheetId &&
                          "is-invalid red-border"
                        }`}
                        placeholder={
                          listOfTask && listOfTask.length === 0
                            ? "No options available"
                            : "Select Task Name"
                        }
                        value={selectedTask || validation?.values?.taskSheetId}
                        isMulti={false}
                        onChange={(selectedOption) => {
                          setSelectedTask(selectedOption);
                          validation.setFieldValue(
                            "taskSheetId",
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        options={selectedTaskObj || []}
                        onBlur={validation.handleBlur}
                        name="taskSheetId"
                      />
                      {validation.touched.taskSheetId &&
                      validation.errors.taskSheetId ? (
                        <div className="invalid-feedback">
                          {validation.errors.taskSheetId}
                        </div>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <label
                        htmlFor="task-duedate-input"
                        className="form-label">
                        Date :
                      </label>
                      <Flatpickr
                        name="date"
                        id="date-field"
                        onBlur={validation.handleBlur}
                        className={`form-select  ${
                          validation.errors.date && validation.touched.date
                            ? "is-invalid"
                            : null
                        }`}
                        placeholder="Enter Date Time"
                        value={validation.values.date}
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          onChange: function (
                            selectedDates,
                            dateStr,
                            instance
                          ) {
                            validation.setFieldValue("date", dateStr);
                          },
                        }}
                      />
                      {validation.touched.date && validation.errors.date ? (
                        <div className="invalid-feedback">
                          {validation.errors.date}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-hour-input" className="form-label">
                        Taken Time :
                      </label>
                      <div className="input-group">
                        <select
                          name="takenHours"
                          id="task-hour-input"
                          className={`form-control ${
                            validation.errors.takenHours &&
                            validation.touched.takenHours
                              ? "is-invalid"
                              : ""
                          }`}
                          value={validation?.values?.takenHours || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            !!(
                              validation.touched.takenHours &&
                              validation.errors.takenHours
                            )
                          }>
                          <option value="00">Select Hours</option>
                          <option value="01">1 Hr</option>
                          <option value="02">2 Hr</option>
                          <option value="03">3 Hr</option>
                          <option value="04">4 Hr</option>
                          <option value="05">5 Hr</option>
                          <option value="06">6 Hr</option>
                          <option value="07">7 Hr</option>
                          <option value="08">8 Hr</option>
                          <option value="09">9 Hr</option>
                          <option value="10">10 Hr</option>
                        </select>
                        {validation.touched.takenHours &&
                        validation.errors.takenHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.takenHours}
                          </FormFeedback>
                        ) : null}
                        <span className="input-group-text">:</span>

                        <select
                          name="takenMinutes"
                          id="task-minute-input"
                          className={`form-control ${
                            validation.errors.takenMinutes &&
                            validation.touched.takenMinutes
                              ? "is-invalid"
                              : ""
                          }`}
                          value={validation?.values?.takenMinutes || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}>
                          <option value="00">Minutes</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                          <option value="60">60</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label className="form-label">Details Of Task :</label>
                      <Input
                        type="textarea"
                        className="form-control custom-css-input"
                        placeholder="Enter your Task Details"
                        name="detailsOfTask"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.detailsOfTask || ""}
                        invalid={
                          !!(
                            validation.touched.detailsOfTask &&
                            validation.errors.detailsOfTask
                          )
                        }
                      />
                      {validation.touched.detailsOfTask &&
                      validation.errors.detailsOfTask ? (
                        <FormFeedback type="invalid">
                          {validation.errors.detailsOfTask}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label pt-2">
                            Task Status :
                          </Label>

                          <Select
                            className={` ${
                              validation.errors.taskStatus &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Select Task Status"
                            value={
                              selectedTaskStatus ||
                              validation?.values?.taskStatus
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedTaskStatus(selectedOption);
                              validation.setFieldValue(
                                "taskStatus",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={selectedTaskStatusObj}
                            onBlur={validation.handleBlur}
                            name="taskStatus"
                          />
                          {validation.touched.taskStatus &&
                          validation.errors.taskStatus ? (
                            <div className="invalid-feedback">
                              {validation.errors.taskStatus}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label pt-2">
                            Daily Status :
                          </Label>

                          <Select
                            className={` ${
                              validation.errors.dailyStatus &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Select Daily Status"
                            value={
                              selectedDailyTaskStatus ||
                              validation?.values?.dailyStatus
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedDailyTaskStatus(selectedOption);
                              validation.setFieldValue(
                                "dailyStatus",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={selectedDailyTaskStatusObj}
                            onBlur={validation.handleBlur}
                            name="dailyStatus"
                          />
                          {validation.touched.dailyStatus &&
                          validation.errors.dailyStatus ? (
                            <div className="invalid-feedback">
                              {validation.errors.dailyStatus}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <p className="mt-3">
                          {selectedTask &&
                            (isEdited ? (
                              <>
                                Estimated time:{" "}
                                {selectedRemainTime !== undefined
                                  ? selectedRemainTime
                                  : TIME_ZERO}
                                <br />
                                Remaining time:{" "}
                                {finalTime(
                                  remainingHour,
                                  remainingMinutes,
                                  remainingSecondsOutput
                                )}
                              </>
                            ) : (
                              <>
                                Estimated time:{" "}
                                {selectedTask?.estimateTime !== undefined
                                  ? selectedTask?.remainingTime
                                  : TIME_ZERO}
                                <br />
                                Remaining time:{" "}
                                {finalTime(
                                  remainingHour,
                                  remainingMinutes,
                                  remainingSecondsOutput
                                )}
                              </>
                            ))}
                        </p>
                      </Col>
                    </Row>
                    <Row></Row>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="addNewMember">
                        {"Save "}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default TimesheetAddEdit;
