import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { getId } from "../../utils/helpers/custom";
import { changePassword } from "../../api/forgetPasswordApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const navigate = useNavigate();
  const id = getId();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required(RequiredField("Old password")),
      newPassword: Yup.string()
        .required(RequiredField("New password"))
        .test(
          "password-match",
          "Old Password & New Password should not match.",
          function (value) {
            return value !== this.parent.oldPassword;
          }
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirmPassword: Yup.string()
        .test(
          "password-match",
          "Password & Confirm Password dose not match.",
          function (value) {
            return value === this.parent.newPassword;
          }
        )
        .required(RequiredField("Confirm password")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const payload = {
        currentPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      };
      changePassword(id, payload)
        .then((response) => {
          const responseBody = response?.data;
          if (
            responseBody?.statusCode === ResponseStatusEnum.SUCCESS ||
            responseBody?.statusCode === ResponseStatusEnum.ACCEPTED
          ) {
            toast.success(responseBody?.message);
            navigate("/");
          } else {
            toast.error(responseBody?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.data?.message);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Change Password" pageTitle="HRMS" />
        <Row className="mb-4">
          <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
            <h5 className="fw-semibold mb-0">Change Password</h5>
          </div>
        </Row>
        <Row className="g-2">
          <Col lg={6}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                  <Row className="g-2">
                    <Col lg={12}>
                      <div>
                        <Label
                          htmlFor="oldpasswordInput"
                          className="form-label">
                          Old Password*
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            className="form-control pe-5"
                            placeholder="Enter old password"
                            name="oldPassword"
                            type={passwordShow ? "text" : "password"}
                            value={validation.values.oldPassword || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.oldPassword &&
                              validation.errors.oldPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.oldPassword &&
                          validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.oldPassword}
                            </FormFeedback>
                          ) : null}
                          <button
                            className={`btn btn-link position-absolute ${
                              validation.errors.oldPassword ? "err" : "end-0"
                            } top-0 text-decoration-none text-muted`}
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div>
                        <Label
                          htmlFor="newpasswordInput"
                          className="form-label">
                          New Password*
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={newPasswordShow ? "text" : "password"}
                            className="form-control"
                            id="newpasswordInput"
                            placeholder="Enter new password"
                            name="newPassword"
                            value={validation.values.newPassword || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.newPassword &&
                              validation.errors.newPassword
                            }
                          />
                          {validation.touched.newPassword &&
                            validation.errors.newPassword && (
                              <FormFeedback type="invalid">
                                {validation.errors.newPassword}
                              </FormFeedback>
                            )}
                          <button
                            className={`btn btn-link position-absolute ${
                              validation.errors.newPassword ? "err" : "end-0"
                            } top-0 text-decoration-none text-muted`}
                            type="button"
                            id="password-addon"
                            onClick={() =>
                              setNewPasswordShow(!newPasswordShow)
                            }>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div>
                        <Label
                          htmlFor="confirmpasswordInput"
                          className="form-label">
                          Confirm Password*
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={confirmPasswordShow ? "text" : "password"}
                            className="form-control"
                            id="confirmpasswordInput"
                            placeholder="Confirm password"
                            name="confirmPassword"
                            value={validation.values.confirmPassword || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                            }
                          />
                          {validation.touched.confirmPassword &&
                            validation.errors.confirmPassword && (
                              <FormFeedback type="invalid">
                                {validation.errors.confirmPassword}
                              </FormFeedback>
                            )}

                          <button
                            className={`btn btn-link position-absolute ${
                              validation.errors.confirmPassword
                                ? "err"
                                : "end-0"
                            } top-0 text-decoration-none text-muted`}
                            type="button"
                            id="password-addon"
                            onClick={() =>
                              setConfirmPasswordShow(!confirmPasswordShow)
                            }>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="text-end pt-3">
                        <button type="submit" className="btn btn-success w-50 ">
                          {loading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : (
                            "Change Password"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChangePassword;
