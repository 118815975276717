import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import TableContainer from "../../components/common/tableContainer";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/common/deleteModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Loader from "../../components/common/loader";
import { listOfData } from "../../api/commonApi";
import Select from "react-select";
import {
  addClientDetails,
  deleteClientDetails,
  editClientDetails, listOfClientDetails
} from "../../api/companyApi";
import {
  LIST_OF_CLIENT_DESIGNATION,
  ResponseStatusEnum,
} from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import NoResultsMessage from "../../components/noResultMessage";

const ClientManagement = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfCompanyData, setListOfCompanyData] = useState();
  const [editCompanyData, setEditCompanyData] = useState();
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const [selectedOsType, setSelectedOsType] = useState();
  const [selectedClientDetails, setSelectedClientDetails] = useState();
  const [selectedCompanyData, setSelectedCompanyData] = useState();
  const [isClientId, setIsClientId] = useState();
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    listOfData({
      modelName: "companyModel",
      condition: { isDeleted: 0 },
      selectionCriteria: ["id", "companyName"],
    })
      .then((res) => {
        setListOfCompanyData(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });

      listOfClientDetails().then((res) => {
        if (res?.data?.data) {
          setSelectedClientDetails(res?.data?.data)
          const data = res?.data?.data?.map((index) => {
            return {
              label: index.company.companyName,
                 value: index.companyId
            }
          })
          setSelectedCompanyData(data)
        } else {
          toast.error(res?.data?.message);
        }
      }).catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const projectColumns = useMemo(() => [
    {
      Header: "Sr No.",
      Cell: ({ row }) => {
        const indexFromStart = row?.index + 1;
        return <div>{indexFromStart}</div>;
      },
    },
    {
      Header: "Company Name",
      accessor: "company.companyName",
      filterable: false,
    },
    {
      Header: "Client Name",
      accessor: "clientName",
      filterable: false,
    },
    {
      Header: "Client Designation",
      accessor: "clientDesignation",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                id={`editMode-${cellProps?.cell?.row?.index}`}
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() =>
                  editCompanyDetailsClick(cellProps?.cell?.row?.original)
                }
              >
                <i className="ri-pencil-fill align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="Edit"
                  anchorId={`editMode-${cellProps?.cell?.row?.index}`}
                />
              </button>
              <button
                id={`deleteMode-${cellProps.cell.row.index}`}
                className="btn btn-sm btn-soft-danger remove-list"
                onClick={() =>
                  companyDelete(cellProps?.cell?.row?.original?.id)
                }>
                <i className="ri-delete-bin-5-fill align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="error"
                  content="Delete"
                  anchorId={`deleteMode-${cellProps?.cell?.row?.index}`}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

    const editCompanyDetailsClick = (cellProps) => {
      setEditCompanyData(cellProps);
      setIsClientId(cellProps?.companyId);
      setIsEdit(true);
      navigate("/project-management/");
    };

  const companyDelete = (companyId) => {
    setSelectedCompanyId(companyId);
    setDeleteModal(true);
  };

  const companyDeleteTodo = () => {
    setLoading(true);
    setDeleteModal(false);
    deleteClientDetails(selectedCompanyId, { isDeleted: true })
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          fetchData();
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      companyId: editCompanyData?.companyId || "",
      clientName: editCompanyData?.clientName || "",
      clientDesignation: editCompanyData?.clientDesignation || "",
      email: editCompanyData?.email || "",
      phoneNo: editCompanyData?.phoneNo || "",
    },
    validationSchema: Yup.object({
      companyId: Yup.string().required(RequiredField("Company name")),
      clientName: Yup.string().required(RequiredField("Client name")),
      clientDesignation: Yup.string().required(
        RequiredField("Client designation")
      ),
      email: Yup.string().optional(),
      phoneNo: Yup.string().optional(),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        setLoading(true);
        setIsEdit(false);
        setEditCompanyData("");
        editClientDetails(editCompanyData?.id, { ...values })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              fetchData();
              validation.resetForm();
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        addClientDetails({...values})
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              fetchData();
              validation.resetForm();
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      }
      validation.resetForm();
    },
  });

  const companyData = Array.isArray(listOfCompanyData)
  ? listOfCompanyData?.map((status) => ({
      value: status.id,
      label: status.companyName,
    }))
  : null;
  
  let filtered;
  if (isClientId !== undefined) {
    filtered = selectedCompanyData?.filter((item) => {
      return isClientId === item.value
    })
  }

  document.title = "Client Information";
  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => companyDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Clint Information" pageTitle="Company information" navigation="/company-management"/>
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Client Information</h5>
              </div>
              
              {!isEdit && ( <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <Link to="/company-management">
                    <button
                      className="btn btn-primary createTask"
                      type="button">
                      Back
                    </button>
                  </Link>
                </div>
              </div>)}
            </Row>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={4} md={4} sm={6}>
                        <div className="mb-3">
                      <Label
                            htmlFor="task-title-input"
                            className="form-label">
                            Company Name{" "}
                          </Label>
                          <Select
                            className={` ${
                              validation.errors.companyId &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Enter company name"
                            value={
                                selectedOsType || filtered
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                                setSelectedOsType(selectedOption);
                              validation.setFieldValue(
                                "companyId",
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            options={companyData}
                            onBlur={validation.handleBlur}
                            name="companyId"
                          />
                           {validation.touched.companyId &&
                        validation.errors.companyId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.companyId}
                          </FormFeedback>
                        ) : null}
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={6}>
                      <div className="mb-3">
                        <label
                          htmlFor="task-title-input"
                          className="form-label">
                          Client Name
                        </label>
                        <Input
                          type="text"
                          id="task-title-input"
                          className="form-control"
                          placeholder="Enter Client Name"
                          name="clientName"
                          validate={{ required: { value: true } }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.clientName || ""}
                          invalid={
                            !!(
                              validation.touched.clientName &&
                              validation.errors.clientName
                            )
                          }
                        />
                        {validation.touched.clientName &&
                        validation.errors.clientName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.clientName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={6}>
                      <div className="mb-3">
                        <label
                          htmlFor="task-title-input"
                          className="form-label">
                          Client Designation
                        </label>
                        <select
                          className={`form-select  ${
                            validation.errors.clientDesignation &&
                            validation.touched.clientDesignation
                              ? "is-invalid"
                              : null
                          }`}
                          aria-label="Default select example"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="clientDesignation"
                          value={validation.values.clientDesignation}>
                          <option selected hidden>
                            Select Client Designation
                          </option>
                          {LIST_OF_CLIENT_DESIGNATION?.map((data) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </select>
                        {validation.touched.clientDesignation &&
                        validation.errors.clientDesignation ? (
                          <div className="invalid-feedback">
                            {validation.errors.clientDesignation}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="designationInput"
                          className="form-label">
                          Phone No
                        </Label>
                        <Input
                          type="tel"
                          className="form-control"
                          id="designationInput"
                          placeholder="Enter primary phone number"
                          name="phoneNo"
                          validate={{ required: { value: true } }}
                          onChange={(e) => {
                            const input = e?.target?.value?.replace(/\D/g, "");
                            e.target.value = input;
                            validation.handleChange(e);
                          }}
                          onBlur={validation.handleBlur}
                          maxLength={10}
                          value={validation.values.phoneNo || ""}
                          invalid={
                            !!(
                              validation.touched.phoneNo &&
                              validation.errors.phoneNo
                            )
                          }
                        />
                        {validation.touched.phoneNo &&
                        validation.errors.phoneNo ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phoneNo}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            !!(
                              validation.touched.email &&
                              validation.errors.email
                            )
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="addNewMember">
                          {isEdit === false ? "Submit" : "Edit"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>

            {isEdit === false && (
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Client Information
                  </h4>
                </CardHeader>
                <CardBody>
                  {selectedClientDetails?.length ? (
                    <TableContainer
                      columns={projectColumns}
                      data={selectedClientDetails}
                      isAddUserList={false}
                      customPageSize={5}
                      isGlobalFilter={true}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <NoResultsMessage />
                  )}
                </CardBody>
              </Card>
            )}
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default ClientManagement;
