import React, { useState, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/deleteModal";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import {
  deviceOfNumber,
  listOfAllocation,
  resourcesAllocation,
  resourcesHistory,
  resourcesReturn,
} from "../../api/resourcesApi";
import TableContainer from "../../components/common/tableContainer";
import {
  countOfData,
  findDevicesNumber,
} from "../../api/commonApi";
import { listOfEmployee } from "../../api/employeeApi";
import { getId } from "../../utils/helpers/custom";
import { LIST_OF_DEVICES, ResponseStatusEnum } from "../../utils/helpers/enums";
import Loader from "../../components/common/loader";
import { dateFormat } from "../../utils/helpers/common";
import NoResultsMessage from "../../components/noResultMessage";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from "react-select";

const AllocationDevice = () => {
  const [resourceName, setResourceName] = useState("");
  const [isResource, setIsResource] = useState(true);
  const [device, setDevice] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalTodo, setModalTodo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [todo, setTodo] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [allocationList, setAllocationList] = useState([]);
  const [damageResourcesCardData, setDamageResourcesCardData] = useState("");
  const [historyData, setHistoryData] = useState();
  const [modelName, setModelName] = useState();
  const [returnModel, setReturnModel] = useState(false);
  const [submitDeviceDetails, setSubmitDeviceDetails] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [selectedDeviceName, setSelectedDeviceName] = useState();
  const [selectedModelDevice, setSelectedModelDevice] = useState("");
  const [selectedResourceId, setSelectedResourceId] = useState(null);

  const eComWidgets = [
    {
      id: 1,
      cardColor: "primary",
      label: "Allocated Resources",
      badgeClass: "success",
      counter: damageResourcesCardData,
      bgcolor: "success",
      icon: "bx bx-user-plus",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];

  const fetchData = () => {
    setLoading(true);
    listOfAllocation()
      .then((resp) => {
        setAllocationList(resp?.data?.data);
      })
      .catch((err) => err);
    listOfEmployee({
      isDeleted: false,
    })
      .then((resp) => setEmployeeList(resp?.data?.data))
      .catch((err) => err)
      .finally(() => {});
    countOfData({
      modelName: "resourcesDetailsModel",
      condition: {
        status: "Allocation",
      },
    })
      .then((res) => {
        setDamageResourcesCardData(res?.data?.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteTodo = () => {
    if (todo) {
      setDeleteModal(false);
    }
  };

  const AddAllocation = () => {
    setTodo("");
    setModalTodo(!modalTodo);
    toggle();
  };

  const toggle = useCallback(() => {
    if (modalTodo) {
      setResourceName("");
      setSelectedModelDevice("");
      setIsResource(true);
      setReturnModel(false);
      validation.resetForm();
      setModalTodo(false);
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      assignTo: todo?.assignTo || "",
      resourceId: todo?.resourceId || "",
      reason: todo?.reason || "",
    },
    validationSchema: Yup.object({
      assignTo:
      !returnModel && Yup.string().required(RequiredField("Employee name")),
      resourceId: Yup.string().when("resourceName", {
        is: (resourceName) => ["Laptop", "Mouse"].includes(resourceName),
        then: Yup.string().required(RequiredField("Resource Id")),
        otherwise: Yup.string().notRequired(),
      }),
      reason: Yup.string().required(RequiredField("Reason")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (!returnModel) {
        const allocationPayload = {
          resourceDetailsId: selectedResourceId,
          assignTo: parseInt(values.assignTo, 10),
          addedBy: parseInt(getId(), 10),
          reason: values.reason,
          status: "Allocation",
        };
        resourcesAllocation(allocationPayload)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              fetchData();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => toast.error(err))
          .finally(() => {
            setLoading(false);
          });
        validation.resetForm();
        setResourceName("");
        toggle();
      } else {
        const returnPayload = {
          resourceAssignId: submitDeviceDetails?.id,
          resourceDetailsId: submitDeviceDetails?.resourcesDetails?.id,
          remark: values.reason,
          status: "Return",
        };
        resourcesReturn(returnPayload)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              fetchData();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => toast.error(err))
          .finally(() => {
            setLoading(false);
          });
        validation.resetForm();
        setResourceName("");
        toggle();
      }
    },
  });

  const leaveFilterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      resourceName: "",
      resourceNo: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setLoading(true);
      resourcesHistory(values)
        .then((response) => {
          setHistoryData(response?.data?.data);
          setSelectedDevice("");
          setSelectedDeviceName("");
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const historyColumns = useMemo(() => [
    {
      Header: "Resource Name",
      accessor: "resourcesDetails.resourceName",
      filterable: false,
    },
    {
      Header: "Resource No",
      accessor: "resourcesDetails.resourceNo",
      filterable: false,
    },
    {
      Header: "Employee Name",
      accessor: "firstName",
      filterable: false,
      Cell: (cell) => {
        return `${cell?.row?.original?.authUser?.firstName} ${cell?.row?.original?.authUser?.lastName}`;
      },
    },
    {
      Header: "Allocated Date",
      accessor: "resourceAllocationDate",
      filterable: false,
      Cell: (cell) => {
        const date = cell?.row?.original?.resourceAllocationDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
    {
      Header: "Submitted Date",
      accessor: "returnDate",
      filterable: false,
      Cell: (cell) => {
        const date = cell?.row?.original?.returnDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
  ]);

  const allocationColumns = useMemo(() => [
    {
      Header: "Employee Name",
      accessor: "name",
      filterable: false,
      Cell: (cell) => {
        return `${cell?.row?.original?.authUser?.firstName} ${cell?.row?.original?.authUser?.lastName}`;
      },
    },
    {
      Header: "Resource Name",
      accessor: "resourceName",
      filterable: false,
      Cell: (cell) => {
        return cell?.row?.original?.resourcesDetails?.resourceName;
      },
    },
    {
      Header: "Resource Number",
      accessor: "resourceNo",
      filterable: false,
      Cell: (cell) => {
        return cell?.row?.original?.resourcesDetails?.resourceNo;
      },
    },
    {
      Header: "Allocated Date",
      accessor: "createdAt",
      Cell: (cell) => {
        const date = cell?.row?.original?.resourceAllocationDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
    {
      Header: "Submit Devise",
      accessor: "",
      Cell: (cell) => {
        return (
          <div>
            <button
              className="techround btn btn-sm btn-soft-info remove-list"
              onClick={() => {
                toggle();
                setModelName("Submit Device");
                setReturnModel(true);
                setSubmitDeviceDetails(cell?.row?.original);
              }}
              disabled={false}>
              <i className="ri-macbook-line"></i>
              <ReactTooltip
                place="bottom"
                variant="info"
                className="zindex1"
                content="Technical Round"
                anchorId={`Submit`}
              />
            </button>
          </div>
        );
      },
    },
  ]);

  let deviceDetails = Array.isArray(device)
    ? device?.map((status) => ({
        value: status?.id,
        label: status?.resourceNo,
      }))
    : null;

    let condition = !returnModel ?  { status: ["Available", "Return", "Success"] } : {status : ["Allocation"]};
  if (deviceDetails === null || deviceDetails === undefined) {
    deviceDetails = [];
  }

  document.title = "Allocation";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Allocate Resources" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Allocate Resources</h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <button
                    className="btn btn-primary createTask"
                    type="button"
                    onClick={() => {
                      AddAllocation();
                      setModelName("Add Allocation Form");
                      setReturnModel(false);
                    }}>
                    + Allocation
                  </button>
                </div>
              </div>
            </Row>
            <Row>
              <Widgets data={eComWidgets} />
            </Row>
            <Card>
              <CardBody>
                {allocationList?.length ? (
                  <TableContainer
                    columns={allocationColumns}
                    data={allocationList || []}
                    isAddUserList={false}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  />
                ) : (
                  <NoResultsMessage />
                )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Resources History</h5>
              </CardHeader>
              <CardBody className="mt-4">
                <div className="p-3 bg-light rounded mb-4">
                  <Form onSubmit={leaveFilterValidation.handleSubmit}>
                    <Row className="g-2">
                      <Col lg={4}>
                        <div className="mb-3">
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Resource Name
                          </label>
                          <Select
                            className={` ${
                              leaveFilterValidation.errors.resourceName &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Enter company name"
                            value={
                              selectedDeviceName ||
                              leaveFilterValidation?.values?.resourceName
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedDeviceName(selectedOption);
                              leaveFilterValidation.setFieldValue(
                                "resourceName",
                                selectedOption ? selectedOption.value : null
                              );
                              findDevicesNumber({
                                resourceName: selectedOption.value,
                                status: ["Allocation", "Return"],
                              }).then((res) => {
                                setDevice(res?.data?.data);
                              });
                            }}
                            options={LIST_OF_DEVICES}
                            onBlur={leaveFilterValidation.handleBlur}
                            name="resourceName"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            htmlFor="task-title-input"
                            className="form-label">
                            Resource No
                          </Label>
                          <Select
                            className={` ${
                              leaveFilterValidation.errors.resourceNo &&
                              "is-invalid red-border"
                            }`}
                            placeholder="Enter company name"
                            value={
                              selectedDevice ||
                              leaveFilterValidation?.values?.resourceNo
                            }
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedDevice(selectedOption);
                              leaveFilterValidation.setFieldValue(
                                "resourceNo",
                                selectedOption ? selectedOption.label : null
                              );
                            }}
                            options={deviceDetails}
                            onBlur={leaveFilterValidation.handleBlur}
                            name="resourceNo"
                          />
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3 p-4 ml-3">
                          <button
                            className="btn btn-primary createTask"
                            type="submit">
                            {!loading ? (
                              "SEARCH"
                            ) : (
                              <div className="d-inline-flex align-items-center">
                                <Spinner size="sm" className="me-2" />
                                Loading...
                              </div>
                            )}
                          </button>
                        </div>
                      </Col>
                      {historyData?.length ? (
                        <TableContainer
                          columns={historyColumns}
                          data={historyData || []}
                          isAddUserList={false}
                          customPageSize={5}
                          divClass="table-responsive table-card mb-1"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light text-muted"
                        />
                      ) : (
                        <NoResultsMessage />
                      )}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        id="damageDevice"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {modelName}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
            <input type="hidden" id="taskid-input" className="form-control" />

            {!returnModel && (
              <div className="mb-3">
                <label htmlFor="task-title-input" className="form-label">
                  Employee Name{" "}
                </label>
                <select
                  className={`form-select ${
                    validation.errors.assignTo && "is-invalid"
                  }`}
                  aria-label="Default select example"
                  id="selectreport"
                  name="assignTo"
                  validate={{ required: { value: true } }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}>
                  <option>Select Employee</option>
                  {employeeList?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    );
                  })}
                </select>
                <div className="invalid-feedback">
                  {validation.errors.assignTo}
                </div>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Resource Name
              </label>
              <select
                className={`form-select && "is-invalid"}`}
                aria-label="Default select example"
                id="selectreport"
                name="resourceName"
                validate={{ required: { value: true } }}
                onChange={(e) => {
                  setResourceName(e.target.value);
                  setIsResource(false);
                  deviceOfNumber({
                    resourceName: e.target.value,
                    ...condition
                  }).then((res) => {
                    setDevice(res?.data?.data);
                  });
                }}
                onBlur={validation.handleBlur}>
                <option>Select Resource </option>
                <option value="Laptop">Laptop</option>
                <option value="Mouse">Mouse</option>
                <option value="Bag">Bag</option>
                <option value="Mouse Pad">Mouse Pad</option>
                <option value="KeyBoard">KeyBoard</option>
                <option value="Cover">Cover</option>
              </select>
              {resourceName !== "" && (
                <div className="invalid-feedback">{`${RequiredField(
                  "Resource Name"
                )}`}</div>
              )}
            </div>

            <div className="mb-3">
              <Label htmlFor="task-title-input" className="form-label">
                Resource No
              </Label>
              <Select
                className={` ${
                  validation.errors.resourceNo && "is-invalid red-border"
                }`}
                placeholder="Enter company name"
                value={selectedModelDevice}
                isMulti={false}
                onChange={(selectedOption) => {
                  setSelectedModelDevice(selectedOption);
                  setSelectedResourceId(selectedOption.value);
                }}
                options={deviceDetails}
                onBlur={validation.handleBlur}
                name="resourceNo"
                isDisabled={isResource}
              />
              {selectedResourceId === null && (
                <div className="invalid-feedback">{`${RequiredField(
                  "Resource No"
                )}`}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Reason{" "}
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Reason"
                name="reason"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  !!(validation.touched.reason && validation.errors.reason)
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>

            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => {
                  setResourceName("");
                  setModalTodo(false);
                  validation.resetForm();
                }}>
                <i className="ri-close-fill align-bottom"></i> {"Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {"Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AllocationDevice;
