import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/deleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../components/common/breadCrumb";
import {
  addHrRaiseQuery,
  viewSupport,
} from "../../api/supportApi";
import TableContainer from "../../components/common/tableContainer";
import { ResponseStatusEnum, NO_DATA_FOUND } from "../../utils/helpers/enums";
import Loader from "../../components/common/loader";
import { getId, getRole } from "../../utils/helpers/custom";
import { dateFormat } from "../../utils/helpers/common";
import { findDevicesNumber, listOfData } from "../../api/commonApi"
import Select from "react-select";
import NoResultsMessage from "../../components/noResultMessage";
import { assignDeviceOfNumber } from "../../api/resourcesApi";

const Status = ({ status }) => {
  switch (status) {
    case "InProgress":
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
    case "Pending":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Completed":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
  }
};

const Support = () => {
  document.title = "Employee Support";
  const id = getId("id");
  const role = getRole();
  const [viewSupportList, setViewSupportList] = useState([]);
  const [support, setSupport] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [state, newState] = useState();
  const [selectedIssue, setSelectedIssue] = useState()
  const [selectedResourseNo, setSelectedResourseNo] = useState()
  const [selectedDevice, setSelectedDevice] = useState()
  const [selectedHR, setSelectedHR] = useState()
  const [listOfDataHR, setListOfDataHR] = useState();
  const [findDeviceData, setFindDeviceData] = useState();
  const [findDevicesResourceNoData, setFindDevicesResourceNoData] = useState()
  
  const fetchData = () => {
    setLoading(true);
    viewSupport(id)
      .then((response) => {
        setViewSupportList(response?.data?.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  useEffect(() => {
    viewSupport(id).then((response) => { newState(response.data.data) })
    const data = viewSupportList?.map((listData) => {
      return {
        typeOfIssue: listData?.supportIssues?.issue,
        reason: listData?.reason,
        dateOfRequest: listData?.dateOfRequest,
        resourceId: listData?.resource?.resourceId,
        resourceName: listData?.resources?.resourceName,
      };
    });
    setSupport(data);
  }, [viewSupportList]);

  useEffect(() => {
    setLoading(true);
    let condition = role === "HR" ? {role: "Admin"}:{
      role: "HR"
    }
    listOfData({
      modelName: "authUserModel",
      condition,
      selectionCriteria: ["id","firstName","lastName"]
    }).then((response) => {
      setListOfDataHR(response?.data)
    }).catch((err) => { return (err); }).finally(() => { setLoading(false) }).finally(() => { setLoading(false) });
  }, [])

  const [deleteModal, setDeleteModal] = useState(false);

  const mapData = viewSupportList?.map((res) => { return res?.support });

  const columns = useMemo(() => [
    {
      Header: "Device Name",
      accessor: "resourcesDetails.resourceName",
      filterable: false,
      Cell: (items) => {
        return <div>{items?.row?.original?.resource === null ?  '---' : items?.row?.original?.resourcesDetails?.resourceName}</div>;
      },
    },
    {
      Header: "Issue",
      accessor: "reason",
      filterable: false,
    },
    {
      Header: "Date of request",
      accessor: "dateOfRequest",
      filterable: false,
      Cell: (items) => {
        return <div>{dateFormat(items?.row?.original?.dateOfRequest)}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell?.value} />;
      },
    },
  ]);

  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = useCallback(() => {
    if (modalTodo) {
      findDevicesNumber().then((response) => { setFindDeviceData(response?.data?.data) }).catch((err) => { return err }).finally(() => { setLoading(false) });
      findDevicesNumber().then((response) => { setFindDevicesResourceNoData(response?.data?.data) }).catch((err) => { return err }).finally(() => { setLoading(false) });
      viewSupport(id).then((response) => { setViewSupportList(response?.data?.data) })
      setModalTodo(false);
      setSelectedIssue(null); // Reset the selected issue
      setSelectedDevice(null); // Reset the selected device
      setSelectedResourseNo(null); // Reset the selected resource number
      setSelectedHR(null); // Reset the selected HR
      validation.resetForm({ values: validation.initialValues }); // Reset the form values when closing the modal
      setTodo("");
      setTodo(null);
    } else {
      setModalTodo(true);
      setSelectedIssue(null); // Reset the selected issue
      setSelectedDevice(null); // Reset the selected device
      setSelectedResourseNo(null); // Reset the selected resource number
      setSelectedHR(null); // Reset the selected HR
      validation.resetForm({ values: validation.initialValues }); // Reset the form values when closing the modal
    }
  }, [modalTodo]);

  const handleTodoClicks = () => {
    setTodo("");
    setModalTodo(!modalTodo);
    toggle();
  };
  const handleDeleteTodo = () => {
    if (todo) {
      setDeleteModal(false);
    }
  };

  // To do Task List validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      device: (todo && todo.device) || "",
      resourceId: (todo && todo.id) || "",
      reason: (todo && todo.reason) || "",
      issueId: (todo && todo.id) || "",
      assignToHR: (todo && todo.assignToHR) || ""
    },
    validationSchema: Yup.object({
      // device: Yup.string().required("Please select Device"),
      // resourceId: Yup.string().required("Please select Device"),
      reason: Yup.string().required("Please Enter Issue"),
      issueId: Yup.string().required("Please Select Type Of Issue"),
      assignToHR: Yup.string().required("Please Select HR"),
      // resourceId: Yup.string().required("Please Select Resource No")
      
      resourceId: Yup.string().when(['issueId', 'resourceName'], (issueId, resourceName, schema) => {
        if ((issueId === 'Device issue' || issueId === 'Other') && (resourceName === 'Laptop' || resourceName === 'Mouse')) {
          return schema.required("Please Select Resource No");
        } else {
          return schema.notRequired();
        }
      }),
      
    }),
    onSubmit: (values) => {
      setLoading(true);
      setModalTodo(false);
      addHrRaiseQuery({
        resourceNo: values.resourceId,
        resourceName: values.resourceName,
        supportIssue: values.issueId,
        reason: values.reason,
        userId: parseInt(id),
        assignTo: parseInt(values.assignToHR)
      })
        .then((response) => {
          if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(response?.data?.message);
            setModalTodo(false);
            fetchData();
          }
        })
        .catch((err) => {
          toast.error(err.data.message);
          setModalTodo(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
          setModalTodo(false);
        });
        setTodo([]);
        setTodo("");
        validation.resetForm();
      },
  });

  const typeOfIssue =[{
    value: "Device issue",
    label: "Device issue",
  },
  {
    value: "Forgot password",
    label: "Forgot password",
  },
  {
    value: "Other",
    label: "Other",
  },
] 
     
  const selectedIssueObj = Array.isArray(selectedIssue)
    ? selectedIssue?.map((res) => {
      return {
        value: `${res?.value}`,
        label: `${res?.label}`,
      };
    })
    : null;

  function handleMultipleIssue(selectedIssue) {
    validation.setFieldValue("issueId", selectedIssue?.value);
    setSelectedIssue(selectedIssue)
  }

  const deviceOptions = [{
    value : "Laptop",
    label : "Laptop"
  },
  {
    value : "Mouse",
    label : "Mouse"
  },
  {
    value : "Bag",
    label : "Bag"
  },
  {
    value : "Mouse Pad",
    label : "Mouse Pad"
  },
  {
    value : "KeyBoard",
    label : "KeyBoard"
  }
]

  const selectedDeviceObj = Array.isArray(selectedDevice)
    ? selectedDevice?.map((res) => {
      return {
        value: `${res?.value}`,
        label: `${res?.label}`,
      };
    })
    : null;

  function handleMultipleDevice(selectedDevice) {
    assignDeviceOfNumber({
      resourceName: selectedDevice?.value,
      status: ["Allocation"],
      assignTo: +id
    })
      .then((resp) => {
        if (selectedDevice) {
          setDesignation(resp?.data);
          setSelectedResourseNo([]);
        } else {
          setDesignation([]);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
    validation.setFieldValue("resourceName", selectedDevice?.value);
    setSelectedDevice(selectedDevice)
  }
  
  
  const recourseNumber = designation?.data?.map((res) => {
    return {
      value: `${res?.resourcesDetails?.resourceNo}`,
      label: `${res?.resourcesDetails?.resourceNo}`,
    };
  });


  const selectedResourseNoObj = Array.isArray(selectedResourseNo)
    ? selectedResourseNo?.map((res) => {
      return {
        value: `${res?.value}`,
        label: `${res?.label}`,
      };
    })
    : null;

  function handleMultipleResourseNo(selectedResourseNo) {
    validation.setFieldValue("resourceId", selectedResourseNo?.value);
    setSelectedResourseNo(selectedResourseNo)
  }
  //  Assign to HR Options

  const listOfHR = listOfDataHR?.map((res) => {
    return {
      value: `${res?.id}`,
      label: `${res?.firstName} ${res?.lastName}`,
    };
  });

  const selectedAssignToHRObj = Array.isArray(selectedHR)
    ? selectedHR?.map((index) => {
      return {
        value: `${index}HR`,
        label: `${index}HR`,
      };
    })
    : null;

  function handleMultipleHR(selectedHR) {
    validation.setFieldValue("assignToHR", selectedHR?.value);
    setSelectedHR(selectedHR)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Support" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Raise Query</h5>
              </div>
              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <button
                    className="btn btn-primary createTask"
                    type="button"
                    onClick={() => handleTodoClicks()}
                  >
                    Raise a Query
                  </button>
                </div>
              </div>
            </Row>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Support Request</h4>
              </CardHeader>
              <CardBody>
                {mapData?.[0]?.length ? (
                  <TableContainer
                    columns={columns}
                    data={(mapData?.[0] || [])}
                    isAddUserList={false}
                    isGlobalFilter={true}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1"
                    tableClass="text-center table-wrap"
                    theadClass="table-light text-muted"
                  />
                ) : (
                  <NoResultsMessage/>
                )}
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {"Raise a Query"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />
            <div className="mb-3">
              <div className="mb-3">
                <Label htmlFor="skillsInput" className="form-label">
                  Type of Issue
                </Label>
                <Select
                  placeholder="Select Issue"
                  className={` ${validation.errors.issueId && "is-invalid red-border"
                    }`}
                  value={selectedIssueObj || selectedIssue}
                  isMulti={false}
                  onChange={(e) => {
                    handleMultipleIssue(e);
                  }}
                  options={typeOfIssue}
                  onBlur={validation.handleBlur}
                  name="issueId"
                />
                <div className="invalid-feedback">
                  {validation.errors.issueId}
                </div>
              </div>
            </div>
 
              {selectedIssue?.value === "Device issue" ? <><div className="mb-3">
                  <div className="mb-3">
                    <Label htmlFor="skillsInput" className="form-label">
                      Device
                    </Label>
                    <Select
                      placeholder=" Select Resourse Name"
                      className={` ${validation.errors.resourceName && "is-invalid red-border"
                        }`}
                      value={selectedDeviceObj || selectedDevice || validation?.values?.resourceName}
                      isMulti={false}
                      onChange={(e) => {
                        handleMultipleDevice(e);
                      }}
                      options={deviceOptions}
                      onBlur={validation.handleBlur}
                      name="resourceName"
                    />
                    {validation.touched.resourceName &&
                      validation.errors.resourceName ? (
                      <div className="invalid-feedback">
                        {validation.errors.resourceName}
                      </div>
                    ) : null}
                  </div>
                </div>

                {(selectedDevice?.value === 'Laptop' || selectedDevice?.value === 'Mouse') &&
                 <div className="mb-3">
                    <Label htmlFor="empDatabersName" className="form-label">
                      Select Resourse No
                    </Label>
                    <Select
                      placeholder=" Select Resourse Number"
                      className={` ${validation.errors.resourceId && "is-invalid"
                        }`}
                      value={selectedResourseNoObj || selectedResourseNo}
                      isMulti={false}
                      onChange={(e) => {
                        handleMultipleResourseNo(e);
                      }}
                      options={recourseNumber}
                      onBlur={validation.handleBlur}
                      name="resourceId"
                    />
                    {validation.touched.resourceId &&
                      validation.errors.resourceId ? (
                      <div className="invalid-feedback">
                        {validation.errors.resourceId}
                      </div>
                    ) : null}
                 </div>}
              </>
                : ''}

            <div className="mb-3">
              <Label htmlFor="assignTOHR" className="form-label">
                Assign to HR
              </Label>
              <Select
                placeholder="Select HR"
                className={` ${validation.errors.assignToHR && "is-invalid"
                  }`}
                value={selectedAssignToHRObj || selectedHR}
                isMulti={false}
                onChange={(e) => {
                  handleMultipleHR(e);
                }}
                options={listOfHR}
                onBlur={validation.handleBlur}
                name="assignToHR"
              />
              {validation.touched.assignToHR &&
                  validation.errors.assignToHR ? (
                  <div className="invalid-feedback">
                    {validation.errors.assignToHR}
                  </div>
                ) : null}
            </div>

          
            <div className="mb-3">
              <label htmlFor="task-title-input" className="form-label">
                Issue
              </label>
              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Issue"
                name="reason"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  validation.touched.reason && validation.errors.reason
                    ? true
                    : false
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>

            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodo(false)}
              >
                <i className="ri-close-fill align-bottom"></i> Close
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {"Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Support;
