import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Widget from "./widgets";
import { Section, Section1 } from "./section";
import StoreVisits from "./storeVisits";
import { getRole } from "../../utils/helpers/custom";
import RecentlyJoinEmployee from "./RecentlyJoinEmployee";
import * as Yup from "yup";
import { useFormik } from "formik";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import Confetti from "react-confetti";

import {
  totalEmployeechart,
  recentlyRegistration,
  dashboardMultiCard,
  listSupportAdmin,
  listSupportHR,
  listOfBirthday,
} from "../../api/dashboardApi";
import { getId } from "../../utils/helpers/custom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import {
  editTechnicalInterviews,
  listofTechnicalInterviews,
  listOfTechRound2,
} from "../../api/recruitmentApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import Rating from "react-rating";
import { toast } from "react-toastify";
import { dateFormat } from "../../utils/helpers/common";
import NoResultsMessage from "../../components/noResultMessage";

const Status = ({ status }) => {
  switch (status) {
    case "Pending":
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
    case "On Process":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Completed":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
  }
};

const DashboardEcommerce = () => {
  document.title = "Dashboard";
  const role = getRole();

  const [showConfetti, setShowConfetti] = useState(false);
  const [totalChart, setTotaleChart] = useState();
  const [recentlyRegistrationData, setrecentlyRegistrationdata] = useState();
  const [recruitmentId, setRecruitmentId] = useState();
  const [modalTodo, setModalTodo] = useState(false);
  const [recruitmentData, setRecruitmentData] = useState([]);
  const [recruitmentTableId, setRecruitmentTableId] = useState([]);
  const [techRound, setTechRound] = useState([]);
  const profileId = getId();
  const [recruitmentPerData, setRecruitmentPerData] = useState([]);

  const [loading, setLoading] = useState(false);

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      validation.resetForm();
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  // Add To do
  const handleTodoClicks = () => {
    validation.resetForm();
    setModalTodo(!modalTodo);
    toggle();
  };

  const [dashboardCard, setdashboardMultiCard] = useState();
  const [listSupport, setListSupport] = useState();
  const [listSupportHRDesc, setListSupportHR] = useState();
  const [birthdayDate, setBirthdayDate] = useState();

  // details modal
  const [detail, setDetail] = useState(null);
  const [modalDetail, setModalDetail] = useState(false);

  const detailToggle = useCallback(() => {
    if (modalDetail) {
      setModalDetail(false);
      setDetail(null);
    } else {
      setModalDetail(true);
    }
  }, [detail]);

  const candidateDetails = () => {
    setDetail("");
    setModalDetail(!modalDetail);
    detailToggle();
  };

  useEffect(() => {
    setLoading(true);
    if (role !== "Employee") {
      totalEmployeechart()
        .then((resp) => {
          setTotaleChart(resp.data);
        })
        .catch((err) => err)
        .finally(() => {});
      dashboardMultiCard()
        .then((response) => {
          setdashboardMultiCard(response.data.data);
        })
        .catch((err) => err)
        .finally(() => {});
      recentlyRegistration()
        .then((resp) => {
          setrecentlyRegistrationdata(resp.data);
        })
        .catch((err) => err)
        .finally(() => {});
      if (role === "Admin") {
        listSupportAdmin({ order: ["id", "DESC"] })
          .then((response) => {
            setListSupport(response.data);
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
      }
      if (role === "HR") {
        listSupportHR({
          order: ["id", "DESC"],
        })
          .then((response) => {
            setListSupportHR(response.data);
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
      }
    }
    if (role === "HR") {
      listOfTechRound2({ round: "Technical2" })
        .then((res) => {
          if (res.data.statusCode === ResponseStatusEnum.SUCCESS) {
            setTechRound(res.data.data);
          } else {
            setTechRound([]);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    }
    if (role === "Employee") {
      listofTechnicalInterviews(profileId)
        .then((res) => {
          if (res.data.statusCode === ResponseStatusEnum.SUCCESS) {
            setRecruitmentData(res.data.data);
          } else {
            setRecruitmentData([]);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    }
    listOfBirthday()
      .then((res) => {
        setBirthdayDate(res?.data?.data.employeeBirthday?.[0]?.dob);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Define a dynamic celebration date (e.g., from your data or user input)
  const dynamicCelebrationDate = new Date(birthdayDate); // Example: August 30, 2023

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Check if the current date matches the celebration date
    if (
      currentDate.getDate() === dynamicCelebrationDate.getDate() &&
      currentDate.getMonth() === dynamicCelebrationDate.getMonth()
      // currentDate.getFullYear() === dynamicCelebrationDate.getFullYear()
    ) {
      setShowConfetti(true);
    }
  }, [dynamicCelebrationDate]);

  const e_comWidgets = [
    {
      id: 1,
      cardColor: "primary",
      label: "Total Employee",
      badgeClass: "success",
      counter: dashboardCard?.totalUserData,
      bgcolor: "success",
      icon: "ri-account-box-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      cardColor: "primary",
      label: "Active Employee",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: dashboardCard?.activeEmployeeOfCurrentMonth,
      counter: dashboardCard?.countActiveUserData,
      bgcolor: "success",
      icon: "ri-team-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 3,
      cardColor: "primary",
      label: "Solved Queries",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: dashboardCard?.solvedQueriesOfCurrentMonthData,
      counter: dashboardCard?.solvedQueriesData,
      bgcolor: "success",
      icon: "ri-check-double-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 4,
      cardColor: "primary",
      label: "Total Queries",
      badgeClass: "success",
      counter: dashboardCard?.totalQueriesData,
      bgcolor: "success",
      icon: "ri-menu-2-line",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 5,
      cardColor: "primary",
      label: "Leave Application",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: dashboardCard?.leaveApplicationOfCurrentMonthData,
      counter: dashboardCard?.leaveApplicationData,
      bgcolor: "success",
      icon: "ri-file-list-3-fill",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];

  const QueryColumns1 = useMemo(() => [
    {
      Header: "Date",
      accessor: "dateOfInterview",
      filterable: false,
    },
    {
      Header: "Candidate Name",
      accessor: "recruitment.candidateName",
      filterable: false,
    },
    {
      Header: "Link",
      accessor: "recruitment.link",
      filterable: false,
      Cell: (cell) =>
        cell?.row?.original?.link !== "" ? (
          <a
            href={cell?.row?.original?.link}
            target="_blank"
            rel="noopener noreferrer">
            {cell?.row?.original?.link}
          </a>
        ) : (
          "---"
        ),
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell?.value} />;
      },
    },
    {
      Header: "Action",
      Cell: (cellProps, key) => {
        return (
          <div className="hstack gap-2 custom-css-action">
            <button
              id={`techround-${key + 1}`}
              className="techround btn btn-sm btn-soft-info remove-list"
              onClick={() => {
                handleTodoClicks();
                setRecruitmentId(cellProps?.row?.original?.recruitmentId);
                setRecruitmentTableId(cellProps?.row?.original?.id);
              }}>
              review
              <ReactTooltip
                place="bottom"
                variant="info"
                className="zindex1"
                content="Technical Round"
                anchorId={`techround-${key + 1}`}
              />
            </button>
          </div>
        );
      },
    },
  ]);
  const SupportColumns = useMemo(() => [
    {
      Header: "Employee Name",
      accessor: "employeeName",
      filterable: false,
    },
    {
      Header: "Issue",
      accessor: "issue",
      filterable: false,
    },
    {
      Header: "Date Of Request",
      Cell: (items) => {
        return <div>{dateFormat(items?.row?.original?.dateOfRequest)}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell?.value} />;
      },
    },
  ]);

  const techColumns = useMemo(() => [
    {
      Header: "Employee Name",
      Cell: (cell) => {
        return (
          <div className="hstack gap-2">
            {cell?.row?.original?.recruitment?.candidateName !== null
              ? cell?.row?.original?.recruitment?.candidateName
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell?.value} />;
      },
    },
    {
      Header: "Action",
      Cell: (cellProps, key) => {
        return (
          <div className="hstack gap-2">
            <button
              id={`fullreview-${key + 1}`}
              className="detailTask btn btn-sm btn-soft-info remove-list"
              onClick={() => {
                setRecruitmentPerData(cellProps.row.original);
                candidateDetails();
              }}>
              <i className="ri-eye-line"></i>
              <ReactTooltip
                place="bottom"
                variant="info"
                content="View Full Feedbacks"
                anchorId={`fullreview-${key + 1}`}
              />
            </button>
          </div>
        );
      },
    },
  ]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: "",
      hrfeedback: "",
      english: "",
      communication: "",
      confidence: "",
      technical: "",
      logical: "",
      review: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required(RequiredField("Status")),
      review: Yup.string().required(RequiredField("Review")),
      english: Yup.string().required(RequiredField("English")),
      technical: Yup.string().required(RequiredField("Technical")),
      communication: Yup.string().required(RequiredField("Communication")),
      logical: Yup.string().required(RequiredField("Logical")),
      confidence: Yup.string().required(RequiredField("Confidence")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      editTechnicalInterviews(parseInt(recruitmentId), {
        id: recruitmentTableId,
        english: String(values.english),
        communication: String(values.communication),
        confidence: String(values.confidence),
        technical: String(values.technical),
        logical: String(values.logical),
        review: values.review,
        status: values.status,
      })
        .then((resp) => {
          if (resp?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
            toast.success(resp?.data?.message);
            listofTechnicalInterviews(profileId)
              .then((res) => {
                if (res?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
                  setRecruitmentData(res?.data?.data);
                } else {
                  setRecruitmentData([]);
                }
              })
              .catch((err) => err)
              .finally(() => {});
          } else {
            toast.error(resp?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      toggle();
      validation.resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            {showConfetti && (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={2000}
                recycle={false}
                tweenDuration={20000}
              />
            )}
            <Row>
              <Col>
                <div className="h-100">
                  <Section />
                  {role !== "Employee" && (
                    <>
                      <Row>
                        <Widget data={e_comWidgets} />
                        <Section1 />
                      </Row>
                      <Row>
                        <StoreVisits data={totalChart} />
                        <Col xl={8}>
                          <RecentlyJoinEmployee
                            data={recentlyRegistrationData}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {role !== "Admin" && role !== "HR" && (
                    <>
                      {role === "Employee" && <Section1 />}
                      <Container fluid>
                        <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                          <div className="file-manager-content w-100 p-4 pb-0">
                            <Row className="mb-4">
                              <div className="col-auto order-1 d-block d-lg-none">
                                <button
                                  type="button"
                                  className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                                  <i className="ri-menu-2-fill align-bottom"></i>
                                </button>
                              </div>
                              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                <h5 className="fw-semibold mb-0">
                                  Technical Interview List
                                </h5>
                              </div>
                            </Row>
                            <Row></Row>
                            <CardBody>
                              {recruitmentData?.length ? (
                                <TableContainer
                                  columns={QueryColumns1}
                                  data={recruitmentData || []}
                                  isAddUserList={false}
                                  customPageSize={5}
                                  divClass="table-responsive table-card mb-1"
                                  tableClass="text-center table-wrap"
                                  theadClass="table-light text-muted"
                                />
                              ) : (
                                <NoResultsMessage />
                              )}
                            </CardBody>
                          </div>
                        </div>
                      </Container>
                    </>
                  )}
                  {role === "HR" && (
                    <Col>
                      <Card>
                        <CardHeader className="align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Support Request
                          </h4>
                        </CardHeader>

                        <CardBody>
                          {listSupportHRDesc?.length ? (
                            <TableContainer
                              columns={SupportColumns}
                              data={listSupportHRDesc || []}
                              isAddUserList={false}
                              customPageSize={5}
                              divClass="table-responsive table-card mb-1"
                              tableClass="align-middle table-nowrap"
                              theadClass="table-light text-muted"
                              // handleOrderClick={handleOrderClicks}
                            />
                          ) : (
                            <NoResultsMessage />
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader className="align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Selected for Final Round
                          </h4>
                        </CardHeader>

                        <CardBody>
                          {techRound?.length ? (
                            <TableContainer
                              columns={techColumns}
                              data={techRound || []}
                              isAddUserList={false}
                              customPageSize={5}
                              divClass="table-responsive table-card mb-1"
                              tableClass="align-middle table-nowrap"
                              theadClass="table-light text-muted"
                              // handleOrderClick={handleOrderClicks}
                            />
                          ) : (
                            <NoResultsMessage />
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {role === "Admin" && (
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              Support Request
                            </h4>
                          </CardHeader>

                          <CardBody>
                            {listSupport?.length ? (
                              <TableContainer
                                columns={SupportColumns}
                                data={listSupport || []}
                                isAddUserList={false}
                                customPageSize={5}
                                divClass="table-responsive table-card mb-1"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                                // handleOrderClick={handleOrderClicks}
                              />
                            ) : (
                              <NoResultsMessage />
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        id="detailTask"
        isOpen={modalDetail}
        toggle={detailToggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={detailToggle} className="p-3 bg-soft-success">
          {" "}
          {"Candidate Full Details"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
            <input type="hidden" id="taskid-input" className="form-control" />

            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Candidate Name :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.recruitment?.candidateName
                    ? "No Data Found"
                    : recruitmentPerData?.recruitment?.candidateName}
                </div>
              </div>
            </div>

            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Assignee Name :</b>
                </div>
                <div className="col-sm-6">
                  {recruitmentPerData?.authUser === undefined
                    ? "No Data Found"
                    : `${recruitmentPerData?.authUser?.firstName} ${recruitmentPerData?.authUser?.lastName}`}
                  <br />
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  <b>Recruitment Date :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.recruitment?.dateOfInterview
                    ? "No Data Found"
                    : recruitmentPerData?.recruitment?.dateOfInterview}
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  <b>Date :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.dateOfInterview
                    ? "No Data Found"
                    : recruitmentPerData?.dateOfInterview}
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  <b>English :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.english
                    ? "0"
                    : recruitmentPerData?.english}
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  <b>Communication :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.communication
                    ? "0"
                    : recruitmentPerData?.communication}
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  <b>Confidence :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.confidence
                    ? "0"
                    : recruitmentPerData?.confidence}
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  <b>Technical :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.technical
                    ? "0"
                    : recruitmentPerData?.technical}
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  <b>Logical :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.logical
                    ? "0"
                    : recruitmentPerData?.logical}
                </div>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Technical Feedback 2 :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.status
                    ? "Pending"
                    : recruitmentPerData?.status}
                </div>
              </div>
            </div>

            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Review :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.review
                    ? "No Data Found"
                    : recruitmentPerData?.review}
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex mt-1">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 row">
                <div className="col-sm-5 ms-2">
                  {" "}
                  <b>Status :</b>
                </div>
                <div className="col-sm-6">
                  {!recruitmentPerData?.status
                    ? "No Data Found"
                    : recruitmentPerData?.status}
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {"Add Candidate"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
            <input type="hidden" id="taskid-input" className="form-control" />

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Status
                  </label>
                  <select
                    className={`form-select ${
                      validation.errors.status && "is-invalid"
                    }`}
                    aria-label="Default select example"
                    id="selectreport"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="status"
                    value={validation.values.status}>
                    <option selected hidden>
                      Select Status
                    </option>
                    <option value="Selected"> Selected</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                  <div className="invalid-feedback">
                    {validation.errors.status}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Review
                  </label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter Feedback"
                    name="review"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.review || ""}
                    invalid={
                      !!(validation.touched.review && validation.errors.review)
                    }
                  />
                  {validation.touched.review && validation.errors.review ? (
                    <FormFeedback type="invalid">
                      {validation.errors.review}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    English
                  </label>
                  <Rating
                    name="english"
                    initialRating={validation.values.english || 0}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("english", value);
                    }}
                  />
                  {validation.touched.english && validation.errors.english ? (
                    <div className="error validateError">
                      {validation.errors.english}
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Communication
                  </label>
                  <Rating
                    name="english"
                    initialRating={validation.values.communication || 0}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("communication", value);
                    }}
                  />
                  {validation.touched.communication &&
                  validation.errors.communication ? (
                    <div className="error validateError">
                      {validation.errors.communication}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Technical
                  </label>
                  <Rating
                    name="technical"
                    initialRating={validation.values.technical || 0}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("technical", value);
                    }}
                  />
                  {validation.touched.technical &&
                  validation.errors.technical ? (
                    <div className="error validateError">
                      {validation.errors.technical}
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Logical
                  </label>
                  <Rating
                    name="logical"
                    initialRating={validation.values.logical || 0}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("logical", value);
                    }}
                  />
                  {validation.touched.logical && validation.errors.logical ? (
                    <div className="error validateError">
                      {validation.errors.logical}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row className="g-4 mb-3">
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Confidence
                  </label>
                  <Rating
                    name="confidence"
                    initialRating={validation.values.confidence || 0}
                    fractions={2}
                    emptySymbol="mdi mdi-star-outline text-muted"
                    fullSymbol="mdi mdi-star text-warning"
                    onChange={(value) => {
                      validation.setFieldValue("confidence", value);
                    }}
                  />
                  {validation.touched.confidence &&
                  validation.errors.confidence ? (
                    <div className="error validateError">
                      {validation.errors.confidence}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>

            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => setModalTodo(false)}>
                <i className="ri-close-fill align-bottom"></i>{" "}
                {role === "Admin" ? "Reject" : "Cancel"}
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {role === "Admin" ? "Approve" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
