import { LOGIN } from "./apiRoutes"
import { apiService } from "./baseURL"
import { setToken, setRole, setId } from '../utils/helpers/custom'
import jwt_decode from "jwt-decode"
import { ResponseStatusEnum } from "../utils/helpers/enums";

export const login = async (data) => {
    const response = await apiService.post(`${LOGIN}`, data);
    if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
        setToken(response.data.data.token)
        setId(response.data.data.id)
        const role = jwt_decode(response.data.data.token).role
        setRole(role)
    }
    return response;
};