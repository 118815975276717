import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import SwiperCore, { Autoplay } from "swiper";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import Loader from "../../components/common/loader";
import {
  addBankDetails,
  editBankDetails,
} from "../../api/bankDetailsApi";
import { employeeProfile } from "../../api/profileApi";
import userDummyImg from "../../../src/assets/image/auth-one-bg.jpg";
import { EMPLOYEE_PROFILE_IMAGE } from "../../api/apiRoutes";
import TableContainer from "../../components/common/tableContainer";
import { getRole, getId } from "../../utils/helpers/custom";
import moment from "moment";
import { baseURL } from "../../api/baseURL";
import NoResultsMessage from "../../components/noResultMessage";

//Images

const EmployeeProfile = () => {
  SwiperCore.use([Autoplay]);
  const [activityTab, setActivityTab] = useState("1");
  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [empData , setEmpData ] = useState([])
  const [resBankDetails, setResBankDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeAddressData, setEmployeeAddressData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [getAddress, setAddress] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [emergencyContactData, setEmergencyContactNameData] = useState([]);
  const [employeeReportToData, setEmployeeReportToData] = useState([]);
  const { emp_id } = useParams();
  const role = getRole();
  const imageUrl = `${baseURL}/${EMPLOYEE_PROFILE_IMAGE}`;
  const userId = getId();

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      bankName: resBankDetails === null ? "" : resBankDetails?.bankName || "",
      accountNo:
        resBankDetails === null ? "" : resBankDetails?.accountNo || "",
      holderName:
        resBankDetails === null ? "" : resBankDetails?.holderName || "",
      ifscCode: resBankDetails === null ? "" : resBankDetails?.ifscCode || "",
    },
    validationSchema: Yup.object({
      bankName: Yup.string().required(RequiredField("Bank Name")),
      accountNo: Yup.string().required(
        RequiredField("Bank Account Number")
      ),
      holderName: Yup.string().required(
        RequiredField("Account Holder Name")
      ),
      ifscCode: Yup.string().required(RequiredField("IFSC Code")),
    }),
    onSubmit: (values) => {
      if (resBankDetails === null) {
        setLoading(true);
        addBankDetails({
          addedBy: userId,
          assignTo: emp_id,
          bankName: values.bankName,
          accountNo: values.accountNo,
          holderName: values.holderName,
          ifscCode: values.ifscCode,
        })
          .then((resp) => {
            if (resp.data.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(resp.data.message);
              viewEmployeeProfileData();
            } else {
              toast.error(resp.data.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        editBankDetails(emp_id, {
          bankName: values.bankName,
          accountNo: values.accountNo,
          holderName: values.holderName,
          ifscCode: values.ifscCode,
        })
          .then((resp) => {
            if (resp.data.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(resp.data.message);
              viewEmployeeProfileData();
            } else {
              toast.error(resp.data.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      }
      validation.resetForm();
      toggle();
    },
  });

  const viewEmployeeProfileData = () => {
    setLoading(true);
    employeeProfile(emp_id)
      .then((resp) => {
        setEmpData(resp?.data?.data)
        setEmployeeData(resp?.data?.data);
        if (resp?.data?.data?.bankDetails) {
          setResBankDetails(resp?.data?.data?.bankDetails);
        } else {
          setResBankDetails(null);
        }
        if (resp?.data?.data?.userDetails) {
          setUserDetails(resp?.data?.data?.userDetails);
        } else {
          setUserDetails(null);
        }
        if (resp?.data?.data?.address) {
          setAddress(resp?.data?.data?.address);
        } else {
          setAddress(null);
        }
        if (resp?.data?.data?.educationDetails?.length === 0) {
          setEducationData([]);
        } else {
          setEducationData(resp?.data?.data?.educationDetails);
        }
        if (resp?.data?.data?.emergencyContact?.length === 0) {
          setEmergencyContactNameData(null);
        } else {
          setEmergencyContactNameData(resp?.data?.data?.emergencyContact);
        }
        if (resp?.data?.data?.address?.length === 0) {
          setEmployeeAddressData(null);
        } else {
          setEmployeeAddressData(resp?.data?.data?.reportTo);
        }
        if (resp?.data?.data?.reportTo?.length === 0) {
          setEmployeeReportToData(null);
        } else {
          setEmployeeReportToData(resp?.data?.data?.reportTo);
        }
       
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns2 = useMemo(() => [
    {
      Header: "Collage Name",
      accessor: "collegeName",
      filterable: false,
    },
    {
      Header: "Qualification",
      accessor: "qualification.qualification",
      filterable: false,
    },
    {
      Header: "Year",
      accessor: "passingYear",
      filterable: false,
    },
  ]);

  useEffect(() => {
    viewEmployeeProfileData();
  }, []);

  const toggleActivityTab = (tab) => {
    if (activityTab !== tab) {
      setActivityTab(tab);
    }
  };
  document.title = "Employee Profile";

  return (
    <React.Fragment>
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg">
                <img
                  src={
                    userDetails?.profileImage !== null
                      ? `${imageUrl}/${userDetails?.profileImage}`
                      : userDummyImg
                  }
                  alt=""
                  className="profile-wid-img"
                />
              </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
              <Row className="g-4">
                <div className="col-auto">
                  <div className="avatar-lg">
                    <img
                      src={
                        userDetails?.profileImage !== null
                          ? `${imageUrl}/${userDetails?.profileImage}`
                          : userDummyImg
                      }
                      alt="user-img"
                      className="img-thumbnail rounded-circle h-100 w-100"
                    />
                  </div>
                </div>

                <Col>
                  <div className="p-2">
                    <p className="text-white">
                      Employee Name : {employeeData?.firstName}
                    </p>
                    <p className="text-white">
                      Employee Id : {employeeData?.id}
                    </p>
                    <p className="text-white">Role : {employeeData?.role}</p>
                    <p className="text-white">
                      Date of Joining :{" "}
                      {moment(employeeData?.dateOfJoin).format("DD-MM-YYYY")}
                    </p>
                    <p className="text-white">
                      Phone No. : {employeeData?.phoneNo}
                    </p>
                    <p className="text-white">
                      Email ID : {employeeData?.email}
                    </p>
                    <p className="text-white">
                      Report to : {employeeReportToData?.firstName}{" "}
                      {employeeReportToData?.lastName}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg={12}>
                {/* <div> */}
                <Row>
                  <Col xxl={12}>
                    {/* Personal Details */}
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb-3">Personal Detail</h5>
                        <div className="table-responsive">
                          <Table className="table-borderless mb-0">
                            <tbody>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Skype Email Id :
                                </th>
                                <td className="text-muted">
                                  {!userDetails?.skypeId
                                    ? "No Data Found"
                                    : userDetails?.skypeId}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Address :
                                </th>
                                <td className="text-muted">
                                  {!getAddress?.addressLine1
                                    ? "No Data Found"
                                    : getAddress?.addressLine1}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Gender :
                                </th>
                                <td className="text-muted">
                                  {!userDetails?.gender
                                    ? "No Data Found"
                                    : userDetails?.gender}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Date of Birth :
                                </th>
                                <td className="text-muted">
                                  {!userDetails?.dob
                                    ? "No Data Found"
                                    : userDetails?.dob}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Nationality :
                                </th>
                                <td className="text-muted">
                                  {!userDetails?.nationality
                                    ? "No Data Found"
                                    : userDetails?.nationality}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Religious :
                                </th>
                                <td className="text-muted">
                                  {!userDetails?.religion
                                    ? "No Data Found"
                                    : userDetails?.religion}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={12}>
                    {/* Emergency Conatact */}
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb-3">Emergency Contact</h5>
                        <div className="table-responsive">
                          <Table className="table-borderless mb-0">
                            <tbody>
                              {emergencyContactData === null ? (
                                <NoResultsMessage/>
                              ) : (
                                emergencyContactData?.map((data, index) => {
                                  return (
                                    <>
                                      {index === 1 ? (
                                        <hr className="fullwidth"></hr>
                                      ) : (
                                        <></>
                                      )}
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Name :
                                        </th>
                                        <td className="text-muted">
                                          {emergencyContactData === null
                                            ? "No Data Found"
                                            : data?.name}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Relation :
                                        </th>
                                        <td className="text-muted">
                                          {emergencyContactData === null
                                            ? "No Data Found"
                                            : data?.relation}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Phone No :
                                        </th>
                                        <td className="text-muted">
                                          {emergencyContactData === null
                                            ? "No Data Found"
                                            : data?.phoneNo}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={12}>
                    {/* Bank Information */}
                    <Card>
                      <CardBody>
                        <div className="d-flex align-items-center mb-4">
                          <div className="flex-grow-1">
                            <h5 className="card-title mb-0">
                              Bank Information
                            </h5>
                          </div>
                          {role === "Admin" && (
                            <div className="flex-shrink-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-success"
                                onClick={() => {
                                  setModalTodo(true);
                                }}
                              >
                                <i
                                  className={`${
                                    resBankDetails === null
                                      ? "ri-add-line"
                                      : "ri-pencil-line"
                                  } align-middle`}
                                ></i>
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="table-responsive">
                          <Table className="table-borderless mb-0">
                            <tbody>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Bank Name :
                                </th>
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails?.bankName}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Bank Account No. :
                                </th>
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails?.accountNo}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Account Holder Name :
                                </th>
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails?.holderName}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  IFSC Code :
                                </th>
                                <td className="text-muted">
                                  {resBankDetails === null
                                    ? "No Data Found"
                                    : resBankDetails?.ifscCode}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <td className="text-muted">{educationData === null ? "" : educationData.qualification.qualification}</td> */}
                  <Col xxl={12}>
                    {/* Education Information */}
                    {/* <Card>
                                            <CardBody>
                                                <h5 className="card-title mb-4">Education Information</h5>
                                                <div className="table-responsive">
                                                    <Table className="table-borderless mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th className="ps-0" scope="row">College Name :</th>
                                                                <td className="text-muted">{educationData === null ? "" : educationData.collegeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="ps-0" scope="row">Education :</th>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th className="ps-0" scope="row">Year :</th>
                                                                <td className="text-muted">{educationData === null ? "" : educationData.passingYear}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>EducationData
                                                </div>
                                            </CardBody>
                                        </Card> */}
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb-3">
                          Education Information
                        </h5>
                        {educationData?.length ? (
                          <TableContainer
                            columns={columns2}
                            data={educationData || []}
                            customPageSize={5}
                            divClass="table-responsive table-card mb-1"
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            // handleOrderClick={handleOrderClicks}
                          />
                        ) : (
                          <NoResultsMessage/>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        ) : (
          <Loader />
        )}
        <Modal
          id="createTask"
          isOpen={modalTodo}
          toggle={toggle}
          modalClassName="zoomIn"
          centered
          tabIndex="-1"
        >
          <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
            Bank Details
          </ModalHeader>
          <ModalBody>
            <div id="task-error-msg" className="alert alert-danger py-2"></div>
            <Form
              id="creattask-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <input type="hidden" id="taskid-input" className="form-control" />
              <div className="mb-3">
                <label htmlFor="task-title-input" className="form-label">
                  Bank Name
                </label>
                <Input
                  type="text"
                  id="task-title-input"
                  className="form-control"
                  placeholder="Enter Your Name"
                  name="bankName"
                  validate={{ required: { value: true } }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.bankName || ""}
                  invalid={
                    validation.touched.bankName && validation.errors.bankName
                      ? true
                      : false
                  }
                />
                {validation.touched.bankName && validation.errors.bankName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.bankName}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="task-title-input" className="form-label">
                  Account Holder Name
                </label>
                <Input
                  type="text"
                  id="task-title-input"
                  className="form-control"
                  placeholder="Enter Your Name"
                  name="holderName"
                  validate={{ required: { value: true } }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.holderName || ""}
                  invalid={
                    validation.touched.holderName &&
                    validation.errors.holderName
                      ? true
                      : false
                  }
                />
                {validation.touched.holderName &&
                validation.errors.holderName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.holderName}
                  </FormFeedback>
                ) : null}
              </div>
              <Row className="g-4 mb-3">
                <Col lg={6}>
                  <label htmlFor="task-title-input" className="form-label">
                    Account Number
                  </label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="accountNo"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.accountNo || ""}
                    invalid={
                      validation.touched.accountNo &&
                      validation.errors.accountNo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.accountNo &&
                  validation.errors.accountNo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.accountNo}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg={6}>
                  <label htmlFor="task-title-input" className="form-label">
                    IFSC Code
                  </label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="ifscCode"
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.ifscCode || ""}
                    invalid={
                      validation.touched.ifscCode && validation.errors.ifscCode
                        ? true
                        : false
                    }
                  />
                  {validation.touched.ifscCode && validation.errors.ifscCode ? (
                    <FormFeedback type="invalid">
                      {validation.errors.ifscCode}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-ghost-success"
                  onClick={() => setModalTodo(false)}
                >
                  <i className="ri-close-fill align-bottom"></i>Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="addNewTodo"
                >
                  {resBankDetails ? "Update" : "Add"}
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default EmployeeProfile;
