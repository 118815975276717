import { ADD_CLIENT_PROJECT_DETAILS, DELETE_CLIENT_PROJECT_DETAILS, EDIT_CLIENT_PROJECT_DETAILS } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const addProjectDetails = async (data) => {
    const response = await apiService2.post(`${ADD_CLIENT_PROJECT_DETAILS}`, data);
    return response;
};

export const editProjectDetails = async (id, data) => {
    const response = await apiService2.put(`${EDIT_CLIENT_PROJECT_DETAILS}/${id}`, data);
    return response;
};

export const deleteProjectDetails = async (id, data) => {
    const response = await apiService2.put(`${DELETE_CLIENT_PROJECT_DETAILS}/${id}`, data);
    return response;
};
