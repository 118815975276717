import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../components/common/tableContainer";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/common/deleteModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { listOfData } from "../../api/commonApi";
import Loader from "../../components/common/loader";
import {
  addProjectDetails,
  deleteProjectDetails,
  editProjectDetails,
} from "../../api/projectDetailsApi";
import { ProjectTypes, ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import { listOfPaymentDetails } from "../../api/paymentApi";
import NoResultsMessage from "../../components/noResultMessage";
import Select from "react-select";

const Status = ({ status }) => {
  switch (status) {
    case "On Process":
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
    case "Completed":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    case "Maintain":
      return (
        <span className="badge badge-soft-info text-uppercase">{status}</span>
      );
    default:
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
  }
};

const ProjectDetails = () => {
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [editProjectDetailsData, setEditProjectDetailsData] = useState();
  const [projectState, setProjectState] = useState(undefined);
  const [projectDetails, setProjectDetails] = useState("");
  const [selectedClientProjectId, setSelectedClientProjectId] = useState();
  const [listOfCompanyData, setListOfClientName] = useState([]);
  const [listOfCompanyDetails, setListOfCompanyDetails] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCompanyData, setSelectedCompanyData] = useState();
  const [selectedClientData, setSelectedClientData] = useState();
  const [selectedProjectType, setSelectedProjectType] = useState();
  const [clientDetails, setClientDetails] = useState();
  const [projectType, setProjectType] = useState();
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const fetchDataClient = () => {
    setLoading(true);
    listOfData({
      condition: {
        isDeleted: false,
      },
      modelName: "companyModel",
      selectionCriteria: ["id", "companyName"],
    })
      .then((res) => {
        setListOfCompanyDetails(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCompanyId) {
      listOfData({
        modelName: "clientModel",
        selectionCriteria: ["id", "clientName"],
        condition: {
          companyId: selectedCompanyId,
          isDeleted: false,
        },
      })
        .then((response) => {
          setListOfClientName(response?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedCompanyId]);

  const fetchDataClientProject = () => {
    setLoading(true);
    listOfPaymentDetails()
      .then((res) => {
        setProjectDetails(res?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDataClient();
    fetchDataClientProject();
  }, []);

  const projectDetailsColumn = useMemo(() => [
    {
      Header: "Sr No.",
      Cell: ({ row }) => {
        const indexFromStart = row.index + 1;
        return <div>{indexFromStart}</div>;
      },
    },
    {
      Header: "Company Name",
      accessor: "company.companyName",
      filterable: false,
    },
    {
      Header: "Client Name",
      accessor: "client.clientName",
      filterable: false,
    },
    {
      Header: "Project Name",
      accessor: "projectName",
      filterable: false,
    },
    {
      Header: "Amount",
      accessor: "amount",
      filterable: false,
    },
    {
      Header: "From Date",
      accessor: "fromDate",
      filterable: false,
    },
    {
      Header: "To Date",
      accessor: "tillDate",
      filterable: false,
    },
    {
      Header: "Estimated Hours",
      accessor: "estimateHours",
      Cell: (cell) => {
        const estimatedHours = cell?.row?.original?.estimateHours;
        return (
          <div className="text-center">
            {estimatedHours === "00:00:00" ? "-" : estimatedHours}
          </div>
        );
      },
    },
    {
      Header: "Contact Duration",
      accessor: "durationInMonth",
      Cell: (cell) => {
        const contactDuration = cell?.row?.original?.durationInMonth;
        return <div className="text-center">{contactDuration || "---"}</div>;
      },
    },
    {
      Header: "Delivery Status",
      accessor: "deliveryStatus",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell?.value} />;
      },
    },
    {
      Header: "Action",
      Cell: (cellProps, key) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                id={`modalTodo-${cellProps?.cell?.row?.index}`}
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() =>
                  editProjectDetailsFunc(cellProps?.cell?.row?.original)
                }>
                <i className="ri-pencil-fill align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="Edit"
                  anchorId={`modalTodo-${cellProps?.cell?.row?.index}`}
                />
              </button>
              <button
                id={`deleteTodo-${cellProps.cell.row.index}`}
                className="btn btn-sm btn-soft-danger remove-list"
                onClick={() =>
                  projectDelete(cellProps?.cell?.row?.original?.id)
                }>
                <i className="ri-delete-bin-5-fill align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="error"
                  content="Delete"
                  anchorId={`deleteTodo-${cellProps?.cell?.row?.index}`}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const editProjectDetailsFunc = (cellProps) => {
    setIsEdit(true);
    setEditProjectDetailsData(cellProps);
    setProjectState(cellProps?.projectType);
    setClientDetails({
      label: cellProps?.client?.clientName,
      value: cellProps?.client?.id,
    });
    setProjectType({
      label: cellProps?.projectType,
      value: cellProps?.projectType,
    });
    navigate("/project-details");
  };

  const projectDelete = (cellProps) => {
    setSelectedClientProjectId(cellProps);
    setDeleteModal(true);
  };

  const projectDeleteTodo = () => {
    setDeleteModal(false);
    setLoading(true);
    deleteProjectDetails(selectedClientProjectId, { isDeleted: true })
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
          fetchDataClientProject();
          validation.resetForm();
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      amount: editProjectDetailsData?.amount || "",
      fromDate: editProjectDetailsData?.fromDate || "",
      tillDate: editProjectDetailsData?.tillDate || "",
      estimateHours: editProjectDetailsData?.estimateHours || "",
      durationInMonth: editProjectDetailsData?.durationInMonth || "",
      deliveryStatus: editProjectDetailsData?.deliveryStatus || "",
      projectName: editProjectDetailsData?.projectName || "",
      // clientName: editProjectDetailsData?.client?.id || "",
      projectType: editProjectDetailsData?.projectType || "",
      clientId: editProjectDetailsData?.client?.id || "",
      companyId: editProjectDetailsData?.company?.id || "",
    },
    validationSchema: Yup.object({
      amount: Yup.number().required(RequiredField("Amount")),
      fromDate: Yup.string().required(RequiredField("From date")),
      tillDate: Yup.string().required(RequiredField("To date")),
      deliveryStatus: Yup.string().required(RequiredField("Delivery status")),
      projectName: Yup.string().required(RequiredField("Project name")),
      estimateHours:
        projectState === "Fixed Project" &&
        Yup.string().required(RequiredField("Estimated hours")),
      durationInMonth:
        projectState !== "Fixed Project" &&
        Yup.string().required(RequiredField("Duration")),
      companyId: Yup.string().required(RequiredField("Company name")),
      clientId: Yup.string().required(RequiredField("Client name")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (isEdit) {
        editProjectDetails(editProjectDetailsData?.id, { ...values })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              setEditProjectDetailsData("");
              setProjectState();
              validation.resetForm();
              fetchDataClientProject();
              setIsEdit(false);
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const payload = {
          projectType: projectState,
          amount: values.amount,
          deliveryStatus: values?.deliveryStatus,
          estimateHours: values?.estimateHours,
          fromDate: values?.fromDate,
          tillDate: values?.tillDate,
          projectName: values?.projectName,
          durationInMonth: values?.durationInMonth.toString(),
          clientId: values?.clientId,
          companyId: values?.companyId,
        };
        addProjectDetails(payload)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              fetchDataClientProject();
              setProjectState("");
              validation.resetForm();
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const companyData = Array.isArray(listOfCompanyDetails)
    ? listOfCompanyDetails?.map((status) => ({
        value: status?.id,
        label: status?.companyName,
      }))
    : null;

  const clientData = Array.isArray(listOfCompanyData)
    ? listOfCompanyData?.map((status) => ({
        value: status?.id,
        label: status?.clientName,
      }))
    : null;

  const enable = isEdit === false ? selectedCompanyId === undefined : false;

  document.title = "Project";

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => projectDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb
              title={
                isEdit === false ? "Project Details" : "Edit Project Details"
              }
              pageTitle="HRMS"
            />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">
                  {isEdit === false
                    ? "Project Details"
                    : "Edit Project Details"}
                </h5>
              </div>
              {isEdit && (
                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setIsEdit(false);
                        setEditProjectDetailsData("");
                        setProjectState();
                      }}>
                      Back
                    </button>
                  </div>
                </div>
              )}
            </Row>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>
              <Card>
                <CardBody>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="task-title-input"
                              className="form-label">
                              Company Name{" "}
                            </Label>
                            <Select
                              className={` ${
                                validation.errors.companyId &&
                                "is-invalid red-border"
                              }`}
                              placeholder="Enter company name"
                              value={selectedCompanyData}
                              isMulti={false}
                              onChange={(selectedOption) => {
                                setSelectedCompanyId(selectedOption.value);
                                setSelectedCompanyData(selectedOption);
                                validation.setFieldValue(
                                  "companyId",
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              options={companyData}
                              onBlur={validation.handleBlur}
                              name="companyId"
                            />
                            {validation.touched.companyId &&
                            validation.errors.companyId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.companyId}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="task-title-input"
                              className="form-label">
                              Client Name{" "}
                            </Label>
                            <Select
                              className={` ${
                                validation.errors.clientId &&
                                "is-invalid red-border"
                              }`}
                              placeholder="Enter client name"
                              value={selectedClientData || clientDetails}
                              isMulti={false}
                              onChange={(selectedOption) => {
                                setSelectedClientData(selectedOption);
                                validation.setFieldValue(
                                  "clientId",
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              isDisabled={enable}
                              options={clientData}
                              onBlur={validation.handleBlur}
                              name="clientId"
                            />
                            {validation.touched.clientId &&
                            validation.errors.clientId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.clientId}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="task-title-input2"
                              className="form-label">
                              Project Type{" "}
                            </Label>
                            <Select
                              className={` ${
                                validation.errors.projectType &&
                                "is-invalid red-border"
                              }`}
                              placeholder="Enter project type"
                              value={selectedProjectType || projectType}
                              isMulti={false}
                              onChange={(selectedOption) => {
                                setSelectedProjectType(selectedOption);
                                setProjectState(selectedOption.value);
                                validation.setFieldValue(
                                  "projectType",
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              options={ProjectTypes}
                              onBlur={validation.handleBlur}
                              name="projectType"
                            />
                            {validation.touched.projectType &&
                            validation.errors.projectType ? (
                              <FormFeedback type="invalid">
                                {validation.errors.projectType}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      {projectState !== undefined && (
                        <Row>
                          <Col lg={6}>
                            <div className="mb-4">
                              <label
                                htmlFor="task-title-input"
                                className="form-label">
                                Project Name
                              </label>
                              <Input
                                type="text"
                                id="task-title-input"
                                className="form-control"
                                placeholder="Enter Your Project Name"
                                name="projectName"
                                validate={{ required: { value: true } }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation?.values?.projectName || ""}
                                invalid={
                                  validation.touched.projectName &&
                                  validation.errors.projectName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.projectName &&
                              validation.errors.projectName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.projectName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-4">
                              <label
                                htmlFor="task-title-input"
                                className="form-label">
                                Amount
                              </label>
                              <Input
                                type="number"
                                id="task-title-input"
                                className="form-control"
                                placeholder="Enter Your Amount"
                                name="amount"
                                validate={{ required: { value: true } }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation?.values?.amount || ""}
                                invalid={
                                  validation.touched.amount &&
                                  validation.errors.amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.amount &&
                              validation.errors.amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-4">
                              <label
                                htmlFor="task-fromdate-input"
                                className="form-label">
                                From :
                              </label>
                              <Flatpickr
                                name="fromDate"
                                id="date-field"
                                validate={{ required: { value: true } }}
                                onBlur={validation.handleBlur}
                                className={`form-control ${validation.errors.fromDate}`}
                                placeholder="Enter From date"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  onChange: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    validation.setFieldValue(
                                      "fromDate",
                                      dateStr
                                    );
                                  },
                                }}
                                value={validation.values.fromDate}
                              />
                              {validation.touched.fromDate &&
                              validation.errors.fromDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fromDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-4">
                              <label
                                htmlFor="task-duedate-input"
                                className="form-label">
                                To :
                              </label>
                              <Flatpickr
                                name="tillDate"
                                id="date-field"
                                validate={{ required: { value: true } }}
                                onBlur={validation.handleBlur}
                                className={`form-control ${validation.errors.tillDate}`}
                                placeholder="Enter To date"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  onChange: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    validation.setFieldValue(
                                      "tillDate",
                                      dateStr
                                    );
                                  },
                                }}
                                value={validation.values.tillDate}
                              />
                              {validation.touched.tillDate &&
                              validation.errors.tillDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tillDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {projectState === "Fixed Project" ? (
                            <Col lg={6}>
                              <div className="mb-4">
                                <label
                                  htmlFor="task-title-input"
                                  className="form-label">
                                  Estimated Hours
                                </label>
                                <Flatpickr
                                  name="estimateHours"
                                  id="date-field"
                                  className={`form-select  ${
                                    validation.errors.estimateHours &&
                                    validation.touched.estimateHours
                                      ? "is-invalid"
                                      : null
                                  }`}
                                  placeholder="Enter Estimate Time"
                                  value={validation?.values?.estimateHours}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "H:i",
                                    time_24hr: true,
                                    onChange: function (
                                      selectedDates,
                                      dateStr,
                                      instance
                                    ) {
                                      validation
                                        .setFieldValue("estimateHours", dateStr)
                                        .then((res) => res)
                                        .catch((err) => err);
                                    },
                                  }}
                                />
                                {validation.touched.estimateHours &&
                                validation.errors.estimateHours ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.estimateHours}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          ) : (
                            <Col lg={6}>
                              <div className="mb-4">
                                <label
                                  htmlFor="task-title-input"
                                  className="form-label">
                                  Contract Duration in Month
                                </label>
                                <Input
                                  type="number"
                                  id="task-title-input"
                                  className="form-control"
                                  placeholder="Enter Contract Duration"
                                  name="durationInMonth"
                                  validate={{ required: { value: true } }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.durationInMonth || ""
                                  }
                                  invalid={
                                    validation.touched.durationInMonth &&
                                    validation.errors.durationInMonth
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.durationInMonth &&
                                validation.errors.durationInMonth ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.durationInMonth}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          )}
                          <Col lg={6}>
                            <div className="mb-3">
                              <label
                                htmlFor="task-title-input"
                                className="form-label">
                                Delivery Status
                              </label>
                              <select
                                className={`form-select  ${
                                  validation.errors.deliveryStatus &&
                                  validation.touched.deliveryStatus
                                    ? "is-invalid"
                                    : null
                                }`}
                                aria-label="Default select example"
                                id="selectreport"
                                name="deliveryStatus"
                                validate={{ required: { value: true } }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.deliveryStatus}
                                invalid={
                                  validation.touched.deliveryStatus &&
                                  validation.errors.deliveryStatus
                                    ? true
                                    : false
                                }>
                                <option value="" selected disabled hidden>
                                  Select Mode
                                </option>
                                <option value="On Process">On Process</option>
                                <option value="Completed">Completed</option>
                                <option value="Maintain">Maintain</option>
                              </select>
                              {validation.touched.deliveryStatus &&
                              validation.errors.deliveryStatus ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.deliveryStatus}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                id="addNewMember">
                                {isEdit === false ? "Save" : "Edit"}{" "}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Form>

            {/* {projectState.value !== undefined && <>  ----need for future*/}
            {isEdit === false && (
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Projects</h4>
                </CardHeader>
                <CardBody>
                  {projectDetails?.length ? (
                    <TableContainer
                      columns={projectDetailsColumn}
                      data={projectDetails}
                      isAddUserList={false}
                      isGlobalFilter={true}
                      customPageSize={5}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <NoResultsMessage />
                  )}
                </CardBody>
              </Card>
            )}
            {/* </>} */}
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default ProjectDetails;
