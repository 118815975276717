import React, { useState, useEffect, useMemo } from "react";
import { Card, CardBody, Col, Container, Form, Input, Row, Spinner } from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { getId } from "../../utils/helpers/custom";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { listOfJuniorTaskSheet } from "../../api/reviewApi";
import NoResultsMessage from "../../components/noResultMessage";
import { dateFormat, dateFormatChange } from "../../utils/helpers/common";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";

const Status = ({ status }) => {
  switch (status) {
    case "Completed":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    case "Rejected":
      return (
        <span className="badge badge-soft-danger text-uppercase">{status}</span>
      );
    case "Approved":
      return (
        <span className="badge badge-soft-success text-uppercase">
          {status}
        </span>
      );
    default:
      return (
        <span className="badge badge-soft-warning text-uppercase">
          {status}
        </span>
      );
  }
};

const ReviewTaskSheet = () => {
  document.title = "Review TimeSheet";
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [filetedReviewList, setFiletedReviewList] = useState([]);

  const toComponentB = (id, date) => {
    navigate("/time-sheet/review-details", { state: { userId: id, date: date} });
  };

  const id = getId();

  useEffect(() => {
    setLoading(true);
    listOfJuniorTaskSheet({
      assignerId: parseInt(id)
    })
      .then((response) => {
        setReviewList(response?.data?.data?.timeSheetData);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      date: "",
      developerName: "",
    },
    validationSchema: Yup.object({
      date: Yup.string("It Should be text").optional(),
      developerName: Yup.string("It Should be text").optional(),
    }),
    onSubmit: (value) => {
      let payload = {
        ...value,
        assignerId : +id 
      }
      setLoading(true);
      listOfJuniorTaskSheet(payload)
        .then((response) => {
          setFiletedReviewList(response?.data?.data?.timeSheetData);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
       validation.resetForm();
    },
  });

  const columns = useMemo(() => [
    {
      Header: "Sr.no",
      filterable: false,
      Cell: ({ row }) => row?.index + 1,
    },
    {
      Header: "Date",
      accessor: "createdAt",
      filterable: false,
      Cell: (items) => {
        return <div>{dateFormat(items.row.original.date)}</div>;
      },
    },
    {
      Header: "Developer Name",
      accessor: "authUser.developerName",
      filterable: false,
    },
    {
      Header: "Total hour",
      accessor: "totalHours",
      filterable: false,
    },
    {
      Header: "Task Status",
      accessor: "taskStatus",
      filterable: false,
      Cell: (cell) => {
        return <Status status={cell.value} />;
      },
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                id={`viewMode-${cellProps?.cell?.row?.index}`}
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() => toComponentB(
                  cellProps?.cell?.row?.original?.authUser?.id,
                  cellProps?.cell?.row?.original?.date
                )
                }>
                <i className="ri-eye-line align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="View"
                  anchorId={`viewMode-${cellProps?.cell?.row?.original?.id}`}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  let dataList = null;
  filetedReviewList?.length > 0 ? dataList = filetedReviewList : dataList = reviewList;

  return (
    <div className="page-content">
      {loading === false ? (
        <Container fluid>
          <BreadCrumb title="Review Timesheet" pageTitle="HRMS" />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">Review Timesheet</h5>
            </div>
          </Row>
          <Card>
              <CardBody className="mt-0">
                <Formik
                  initialValues={validation.initialValues}
                  validationSchema={validation.validationSchema}
                  onSubmit={validation.handleSubmit}
                >
                  <Form onSubmit={validation.handleSubmit}>
                      <Row>
                        <Col lg={5} md = {5}>
                        <div className="p-2">
                        <Flatpickr
                        className="form-control"
                        id="datepicker-publish-input"
                        placeholder="Select date"
                        onBlur={
                          validation.handleBlur
                        }
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "d.m.y",
                          onChange: function (
                            selectedDates,
                          ) {
                            validation.setFieldValue(
                              "date",
                              dateFormatChange(selectedDates[0])
                            );
                          },
                        }}
                      />
                      </div>
                        </Col>
                        <Col lg={5} md = {5}>
                          <div className="p-2">
                          <Input
                            className="form-control"
                            id="developerName"
                            placeholder="Enter developer name"
                            validate={{ required: { value: false } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.developerName}
                            invalid={
                              validation.touched.developerName &&
                              validation.errors.developerName ? (
                                <div className="error">
                                  {" "}
                                  {validation.errors.developerName}
                                </div>
                              ) : null
                            }
                          />
                          </div>
                          <div className="invalid-feedback">
                            {validation.errors.developerName}
                          </div>
                        </Col>
                        <Col lg={2} md = {2}>
                          <div className="m-2">
                          <button
                            className="btn btn-primary createTask"
                            type="submit"
                          >
                            {!loading ? (
                              "SEARCH"
                            ) : (
                              <div className="d-inline-flex align-items-center">
                                <Spinner size="sm" className="me-2" />
                                Loading...
                              </div>
                            )}
                          </button>
                          </div>
                        </Col>
                      </Row>
                  </Form>
                </Formik>
              </CardBody>
            </Card>
          <div className="file-manager-content w-100 p-4 pb-0">
            <Card>
              <CardBody>
                {dataList?.length ? (
                  <TableContainer
                    columns={columns}
                    data={dataList || []}
                    isAddUserList={false}
                    isGlobalFilter={false}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  />
                ) : (
                  <NoResultsMessage />
                )}
              </CardBody>
            </Card>
          </div>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ReviewTaskSheet;
