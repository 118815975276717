import { ASSIGNING_COURSE, COUNT_OF_DATA, FIND_DEVICES_NUMBER, LIST_OF_DATA } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const listOfData = async (data) => {
    const response = await apiService2.post(`${LIST_OF_DATA}`,data);
    return response.data;
};

export const findDevicesNumber = async (props) => {
    const response = await apiService2.post(`${FIND_DEVICES_NUMBER}`, props)
    return response
}

export const countOfData = async (props) => {
    const response = await apiService2.post(`${COUNT_OF_DATA}`, props)
    return response
}

export const listOfAssigningCourse = async (props) => {
    const response = await apiService2.post(`${ASSIGNING_COURSE}`, props)
    return response;
}