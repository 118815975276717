import { ACQUIRE_COMP_OF_LEAVE, ADD_LEAVE, DELETE_LEAVE, FILTER_OF_LEAVE_DETAILS, LEAVE_APPROVE_REJECT, LEAVE_HISTORY_OF_EMPLOYEES, LIST_OF_APPLICANTS, VIEW_LEAVE, APPROVE_REJECT_LEAVE_ADMIN, COUNT_OF_LEAVE,  LIST_OF_AQUIRE_LEAVE, UPDATE_STATUS_OF_AQUIRE_LEAVE, APPLY_ACQUIRE, COUNT_OF_TOTAL_LEAVE ,EDIT_DELETE_ACQUIRELEAVE, COUNT_OF_TOTAL_CASUAL_LEAVE, COUNT_OF_TOTAL_COM_OF_LEAVE} from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const listOfLeave = async () => {
    const response = await apiService2.get(`${LIST_OF_APPLICANTS}`);
    return response;
};

export const viewLeave = async (id) => {
    const response = await apiService2.get(`${VIEW_LEAVE}/${id}`);
    return response;
};

export const viewLeaveOfEmployee = async (i) => {
    const response = await apiService2.get(`${LEAVE_HISTORY_OF_EMPLOYEES}`);
    return response;
};

export const approveRejectLeave = async (id,data) => {
    const response = await apiService2.put(`${LEAVE_APPROVE_REJECT}/${id}`, data);
    return response;
};

export const addLeave = async (data) => {
    const response = await apiService2.post(`${ADD_LEAVE}`, data);
    return response;
};
export const addAcquireCompOfLeave = async (data) => {
    const response = await apiService2.post(`${ACQUIRE_COMP_OF_LEAVE}`, data);
    return response;
};

export const deleteLeave = async (data) => {
    const response = await apiService2.put(`${DELETE_LEAVE}`, data);
    return response;
};

export const filterOfLeaveDetails = async (data) => {
    const response = await apiService2.post(`${FILTER_OF_LEAVE_DETAILS}`, data);
    return response;
};

export const countOfLeave = async (id) => {
    const response = await apiService2.get(`${COUNT_OF_LEAVE}/${id}`);
    return response;
};

export const adminApproveRejectLeave = async (id, data) => {
    const response = await apiService2.put(`${APPROVE_REJECT_LEAVE_ADMIN}/${id}`, data);
    return response;
};

export const listOfAcquireLeave = async (data) => {
    const response = await apiService2.post(`${LIST_OF_AQUIRE_LEAVE}`, data);
    return response;
};

export const updateStatusOfAcquireLeave = async (id, data) => {
    const response = await apiService2.put(`${UPDATE_STATUS_OF_AQUIRE_LEAVE}/${id}`, data);
    return response;
};

export const applyCompOffLeave = async (data) => {
    const response = await apiService2.post(`${APPLY_ACQUIRE}`, data);
    return response;
};

export const countOfTotalCasualLeave = async (id) => {
    const response = await apiService2.get(`${COUNT_OF_TOTAL_CASUAL_LEAVE}/${id}`);
    return response;
};

export const countOfTotalComOfLeave = async (id) => {
    const response = await apiService2.get(`${COUNT_OF_TOTAL_COM_OF_LEAVE}/${id}`);
    return response;
};

export const editDeleteAquireLeave = async (id,data) => {
    const response = await apiService2.post(`${EDIT_DELETE_ACQUIRELEAVE}/${id}`, data);
    return response;
};