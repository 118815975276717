import React, { useState, useMemo, useEffect } from "react";
import { Card, Container, CardBody, CardHeader, Row } from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { getId, getRole } from "../../utils/helpers/custom";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { Link, useNavigate } from "react-router-dom";
import NoResultsMessage from "../../components/noResultMessage";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { deleteProject, listOfAssignProject, listOfProject } from "../../api/itpProject";
import { toast } from "react-toastify";
import { ResponseStatusEnum } from "../../utils/helpers/enums";

const ITPProject = (props) => {
  const navigate = useNavigate();
  const role = getRole();
  const id = getId();
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedAssignRow, setSelectedAssignRow] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState();

  const listOfProjectFun = () => {
    setLoading(true);
    listOfProject()
      .then((res) => {
        if (res?.statusCode === ResponseStatusEnum.SUCCESS) {
          setSelectedRow(res?.data);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  const listOfEmployeeAssignProject = () => {
    setLoading(true);
    listOfAssignProject({
      assignTo : +id
    })
      .then((res) => {
        if (res?.statusCode === ResponseStatusEnum.SUCCESS) {
          setSelectedAssignRow(res?.data);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listOfProjectFun();
    if(role === "Employee") {
      listOfEmployeeAssignProject();
    } else {
      listOfProjectFun();
    }
  }, []);

  const QueryColumns = useMemo(() => [
    {
      Header: "Project Name",
      accessor: "projectName",
      filterable: false,
    },
    {
      Header: "Day",
      accessor: "totalDays",
      filterable: false,
    },
    {
      Header: "Api",
      accessor: "totalApis",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              {/* Edit */}
              {(role !== "Employee" || role !== "BDE") && (
                <button
                  id={`editMode-${cellProps?.cell?.row?.index}`}
                  className="btn btn-sm btn-soft-info remove-list"
                  onClick={() =>
                    navigate(
                      `/itp-project/create/${cellProps?.cell?.row?.original?.id}`
                    )
                  }>
                  <i className="ri-pencil-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="info"
                    content="Edit"
                    anchorId={`editMode-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              )}
              {/* Delete */}
              {(role !== "Employee" || role !== "BDE") && (
                <button
                  id={`deleteTodo-${cellProps?.cell?.row?.original?.id}`}
                  className="btn btn-sm btn-soft-danger remove-list"
                  onClick={() =>
                    handleDelete(cellProps?.cell?.row?.original?.id)
                  }>
                  <i className="ri-delete-bin-5-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="error"
                    content="Delete"
                    anchorId={`deleteTodo-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              )}
              <button
                id={`viewMode-${cellProps?.cell?.row?.index}`}
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() =>
                  navigate('/itp-project/modules',
                  { state: { id: cellProps?.cell?.row?.original?.id} }
                  )
                }
              >
                <i className="ri-eye-line align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="View"
                  anchorId={`viewMode-${cellProps?.cell?.row?.index}`}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const EmployeeQueryColumns = useMemo(() => [
    {
      Header: "Project Name",
      accessor: "itpProject.projectName",
      filterable: false,
    },
    {
      Header: "Day",
      accessor: "itpProject.totalDays",
      filterable: false,
    },
    {
      Header: "Api",
      accessor: "itpProject.totalApis",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                id={`viewMode-${cellProps?.cell?.row?.index}`}
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() =>
                  navigate('/itp-project/modules',
                  { state: { id: cellProps?.cell?.row?.original?.itpProject?.id} }
                  )
                }
              >
                <i className="ri-eye-line align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="View"
                  anchorId={`viewMode-${cellProps?.cell?.row?.index}`}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);


  // Delete
  const handleDelete = (id) => {
    setSelectedDeleteId(id);
    setDeleteModal(true);
  };

  const handleDeleteTodo = () => {
    setLoading(true);
    deleteProject({id: selectedDeleteId})
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(res?.data?.message);
          listOfProjectFun();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setDeleteModal(false);
        setLoading(false);
        listOfProjectFun();
      });
  };

  document.title = "ITP Project";

  return (
    <React.Fragment>
      <ApproveRejectModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
        action={selectedRow ? selectedRow.action : ""}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="ITPProject" pageTitle="ITP" navigation="/itp" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">
                  ITP(Interns Training Program) Project
                </h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  {role !== "Employee" && (
                    <Link to="/itp-project/create">
                      <button
                        className="btn btn-primary createTask"
                        type="button">
                        + New Project
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </Row>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">ITP Project</h4>
              </CardHeader>

              <CardBody>
                {role !== "Employee" && role !== "BDE" ? (
                  <>
                    {selectedRow?.length ? (
                      <TableContainer
                        columns={QueryColumns}
                        data={selectedRow || []}
                        isAddUserList={false}
                        customPageSize={5}
                        isGlobalFilter={true}
                        divClass="table-responsive table-card mb-1 "
                        tableClass="align-middle table-nowrap "
                        theadClass="table-light text-muted"
                        SearchPlaceholder="Search..."
                      />
                    ) : (
                      <NoResultsMessage />
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {selectedAssignRow?.length ? (
                      <TableContainer
                        columns={EmployeeQueryColumns}
                        data={selectedAssignRow || []}
                        isAddUserList={false}
                        customPageSize={5}
                        isGlobalFilter={true}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                        SearchPlaceholder="Search..."
                      />
                    ) : (
                      <NoResultsMessage />
                    )}{" "}
                  </>
                )}
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </React.Fragment>
  );
};

export default ITPProject;
