import { ADD_DOCUMENT_DETAILS, ADD_EDUCATION_DETAILS, DELETE_DOCUMENT_DETAILS, DELETE_EDUCATION_DETAILS, VIEW_DOCUMENT_DETAILS, VIEW_EDUCATION_DETAILS } from "./apiRoutes";
import { apiService2, apiService3 } from "./baseURL";

// Education
export const viewEducationDetails = async (data) => {
    const response = await apiService2.post(`${VIEW_EDUCATION_DETAILS}`, data);
    return response;
};

export const addEducationDetails = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    const response = await apiService3.post(`${ADD_EDUCATION_DETAILS}`, formData);
    return response;
};

export const deleteEducationDetails = async (data) => {
    const response = await apiService2.put(`${DELETE_EDUCATION_DETAILS}`, data);
    return response;
};

// document
export const viewDocumentDetails = async (id) => {
    const response = await apiService2.get(`${VIEW_DOCUMENT_DETAILS}/${id}`);
    return response;
};

export const addDocumentDetails = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    const response = await apiService3.post(`${ADD_DOCUMENT_DETAILS}`, formData);
    return response;
};

export const deleteDocumentDetails = async (data, id) => {
    const response = await apiService2.put(
      `${DELETE_DOCUMENT_DETAILS}/${id}`,
      data
    );
    return response;
};

