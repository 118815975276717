import * as Yup from "yup";

export const conditionallyRequired = (isEdit, message) => {
    return isEdit ? Yup.string() : Yup.string().required(message);
};

export const conditionallyRequiredGoogle = (isEdit, message) => {
  return Yup.string().when('url', {
    is: (value) => !isEdit || (isEdit && value && value.trim() !== ''), // Check if it's not in edit mode or if the value is not empty
    then: Yup.string()
      .url('Invalid URL format')
      .required(message), // Apply URL and required validation
    otherwise: Yup.string(), // No validation when not in edit mode or value is empty
  });
};