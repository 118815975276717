import React from 'react';

const NoResultsMessage = () => {
  return (
    <div className="py-4 mt-4 text-center" id="noresult">
      <lord-icon
        className="masterEmployeeWidth"
        src="https://cdn.lordicon.com/msoeawqm.json"
        trigger="loop"
        colors="primary:#405189,secondary:#0ab39c"
      ></lord-icon>
      <h5 className="mt-4">Sorry! No Result Found</h5>
    </div>
  );
};

export default NoResultsMessage;
