import { ADD_ALIASED, EDIT_ALIASED, DELETE_ALIASED, LIST_OF_DATA } from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const addAliased = async (data) => {
    const response = await apiService2.post(`${ADD_ALIASED}`,data);
    return response;
};

export const editAliased = async (id,data) => {
    const response = await apiService2.put(`${EDIT_ALIASED}/${id}`,data);
    return response;
};

export const deleteAliased = async (data, id) => {
    const response = await apiService2.put(`${DELETE_ALIASED}/${id}`, data);
    return response;
};

export const listOfAliasedData = async (data) => {
    const response = await apiService2.post(`${LIST_OF_DATA}`,data);
    return response.data;
};