import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../components/common/withRouter";

//import Components
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer";

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState("");

  document.documentElement.setAttribute("data-layout", "vertical");
  document.documentElement.setAttribute("data-sidebar-size", "lg");

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    let scrollup = document.documentElement.scrollTop;
    // if (scrollup > 0) {
    //   setHeaderClass("topbar-shadow");
    // } else {
    //   setHeaderClass("");
    // }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header headerClass={headerClass} />
        <Sidebar layoutType="vertical" />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      {/* <RightSidebar /> */}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Layout);
