import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import {
  Input,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  ModalFooter,
  Button
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { getId, getName } from "../../utils/helpers/custom";
import { addAcquireCompOfLeave, addLeave, applyCompOffLeave, countOfTotalCasualLeave, countOfTotalComOfLeave } from "../../api/leaveApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import { dateFormatChange } from "../../utils/helpers/common";
import { listOfData } from "../../api/commonApi";
import Loader from "./loader";
import HolidayList from '../../documents/holidatList.pdf'
import { useNavigate } from "react-router-dom";

const FixedHeaderDatatables = (props) => {
  const name = getName()
  const id = getId()
  const [modalTodo, setModalTodo] = useState(false);
  const [typeOfLeaveModal, setTypeOfLeaveModal] = useState(false);
  const [leaveModalTitle, setLeaveModalTitle] = useState(false);
  const [listOfCompOffData, setListOfCompOffData] = useState();
  const [countOfCasualLeaveData, setCountOfCasualLeaveData] = useState();
  const [countOfComOfLeaveData, setCountOfComOfLeaveData] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    listOfData({
      modelName: "compOfLeaveModel",
      condition: {
        userId: id,
        status : "Approved"
      },
      selectionCriteria: [
        "id",
        "date"
      ]
    }).then((response) => { setListOfCompOffData(response?.data); setLoading(false) }).catch((err) => { setLoading(false); return err; }).finally(() => { setLoading(false) })
    countOfTotalCasualLeave(id).then((response) => { setCountOfCasualLeaveData(response?.data?.data) }).catch((err) => { setLoading(false); return err; }).finally(() => { setLoading(false) });
    countOfTotalComOfLeave(id).then((response) => { setCountOfComOfLeaveData(response?.data?.data) }).catch((err) => { setLoading(false); return err; }).finally(() => { setLoading(false) });
  }, [])

  const handleCasualLeaveClicks = () => {
    setLeaveModalTitle("Casual Leave");
    setTypeOfLeaveModal("CL");
    setModalTodo(!modalTodo);
    toggle();
  };

  const handleAcquireModal = () => {
    navigate("/aquireleaves");
  };

  const handleLeaveWithoutPaidClicks = () => {
    setLeaveModalTitle("Leave Without Paid");
    setTypeOfLeaveModal("LWP");
    setModalTodo(!modalTodo);
    toggle();
  };
  const leaveTypeMapping = {
    CL: 'Casual Leave',
    COL: 'Comp Off Leave',
    LWP: 'LWP Leave',
  };

  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>SR No.</span>,
      selector: row => row.srNo,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Leave Type</span>,
      selector: row => row.type,
      sortable: true,
      cell: (row) => (
        <span title={leaveTypeMapping[row.type]}>{row.type}</span>
      ),
    },
    {
      name: <span className='font-weight-bold fs-13'>Total Leaves</span>,
      selector: row => row.totalLeave,
      sortable: true,
      cell: (row) => {
        if (row.type === "COL") {
          return (
            <div>{countOfComOfLeaveData?.totalLeave === undefined ? "-" : countOfComOfLeaveData?.totalLeave}</div>
          )
        } else if (row.type === "CL") {
          return (
            <div>{countOfCasualLeaveData?.totalLeave === undefined ? "-" : countOfCasualLeaveData?.totalLeave}</div>
          )
        } else {
          return (
            <div>{"-"}</div>
          )
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Used Leaves</span>,
      selector: row => row.totalUseLeave,
      sortable: true,
      cell: (row) => {
        if (row.type === "COL") {
          return (
            <div>{countOfComOfLeaveData?.totalUseLeave === undefined ? "-" : countOfComOfLeaveData?.totalUseLeave}</div>
          )
        } else if (row.type === "CL") {
          return (
            <div>{countOfCasualLeaveData?.totalUseLeave === undefined ? "-" : countOfCasualLeaveData?.totalUseLeave}</div>
          )
        } else {
          return (
            <div>{"-"}</div>
          )
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Remaining Leaves</span>,
      selector: row => row.remainingLeave,
      sortable: true,
      cell: (row) => {
        if (row.type === "COL") {
          return (
            <div>{countOfComOfLeaveData?.remainingLeave === undefined ? "-" : countOfComOfLeaveData?.remainingLeave}</div>
          )
        } else if (row.type === "CL") {
          return (
            <div>{countOfCasualLeaveData?.remainingLeave === undefined ? "-" : countOfCasualLeaveData?.remainingLeave}</div>
          )
        } else {
          return (
            <div>{"-"}</div>
          )
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Extra Taken Leaves</span>,
      selector: row => row.extraLeave,
      sortable: true,
      cell: (row) => {
        if (row.type === "COL") {
          return (
            <div>{countOfComOfLeaveData?.extraLeave === undefined ? "-" : countOfComOfLeaveData?.extraLeave}</div>
          )
        } else if (row.type === "CL") {
          return (
            <div>{countOfCasualLeaveData?.extraLeave === undefined ? "-" : countOfCasualLeaveData?.extraLeave}</div>
          )
        } else {
          return (
            <div>{"-"}</div>
          )
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13' >Action</span>,
      sortable: true,
      cell: row => {
        if (row.type === "COL") {
          return (
            <div className='row'>
              <div className="col-md-6 w-100 mt-1">
                <div>
                  <div className="d-flex flex-column">
                    <div className="col-md-6 w-100 pt-1">
                      <span
                        className="btn btn-link fs-13 fs-13 text-success"
                        onClick={handleAcquireModal}
                      >
                        Acquire
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        if (row.type == "CL") {
          return (
            <span
              className="btn btn-link fs-13 text-success"
              onClick={() => handleCasualLeaveClicks()}
            >
              Apply
            </span>
          );
        }
        if (row.type == "LWP") {
          return (
            <span
              className="btn btn-link fs-13 text-success"
              onClick={() => handleLeaveWithoutPaidClicks()}
            >
              Apply
            </span>
          );
        }
      },
    },
  ];

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setLeaveModalTitle("")
      leaveValidation.resetForm()
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  const data = [
    {
      srNo: "01",
      type: "CL",
      totalLeaves: "10",
      remainLeaves: "5",
      status: "Re-open",
    },
    {
      srNo: "02",
      type: "COL",
      totalLeaves: "5",
      remainLeaves: "-",
      status: "On-Hold",
    },
    {
      srNo: "03",
      type: "LWP",
      totalLeaves: "7",
      remainLeaves: "-",
      status: "Closed",
    },
  ];

  const leaveValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryLeave: typeOfLeaveModal, // Assuming you have 'typeOfLeaveModal' available in the scope
      fromDate: "",
      toDate: "",
      aquiredDate: "",
      date: "",
      numberOfDays: "",
      leaveReason: "",
      leaveType: "",
    },
    validationSchema: Yup.lazy(values => {
      const { categoryLeave } = values;

      return Yup.object().shape({
        leaveType: Yup.string().required(RequiredField('Type Of Leave')),
        date: Yup.string().required(RequiredField('Date')),
        leaveReason: Yup.string().required(RequiredField('Reason')),
        numberOfDays: categoryLeave === 'COL'
          ? Yup.string().notRequired()
          : Yup.number("Must Be A Number").required(RequiredField('Number Of Days')),
      });
    }),
    onSubmit: (values) => {
      props.setStateValue(true);
      setLoading(true);
      if (leaveModalTitle == "Casual Leave" || leaveModalTitle == "Leave Without Paid") {
        const payloadLeave = {
          userId: id,
          fromDate: values?.fromDate,
          toDate: values?.toDate,
          numberOfDays: values?.numberOfDays,
          leaveReason: values?.leaveReason,
          leaveType: values?.leaveType,
          categoryLeave: values?.categoryLeave,
          status: "Pending"
        }
        addLeave(payloadLeave).then((response) => {
          if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            leaveValidation.resetForm()
            setModalTodo(false);
            toast.success(response?.data?.message);
            // window.location.reload();
          } else {
            toast.error(response?.data?.message)
          }
        }).catch((err) => err).finally(() => { setLoading(false) })
      }
      const selectedOption = JSON.parse(values.aquiredDate);
      const payload = {
        userId: id,
        toDate: values.date,
        acquireDate: selectedOption.date,
        compOfLeaveId: selectedOption.id,
        leaveType: values.leaveType,
        leaveReason: values.leaveReason
      }

      applyCompOffLeave(payload).then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          leaveValidation.resetForm()
          setModalTodo(false);
          toast.success(response?.data?.message);
          // window.location.reload();
        } else {
          toast.error(response?.data?.message);
          setLoading(false);
        }
      }).catch((err) => { setLoading(false); return err }).finally(() => { setLoading(false); });
    }
  });

  const AcquireLeaveValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: "",
      reason: "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required(RequiredField("Date")),
      reason: Yup.string().required(RequiredField("Reason")),
    }),
    onSubmit: (values) => {
      addAcquireCompOfLeave({ userId: parseInt(id), ...values }).then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(res?.data?.message);
          // window.location.reload();
          AcquireLeaveValidation.resetForm()
          setModalTodo("")
        } else {
          toast.error(res?.data?.message);
        }
      }).catch((err) => err)
    }
  });

  return (
    <div>
      {loading === false ? (<>
        <DataTable
          columns={columns}
          data={data}
        />

        <div className='col-md-4'>
          <button type="button" class="custom-toggle active btn btn-success mt-3 align-right" fdprocessedid="amod4n">
            <span class="icon-off"><i class="ri-check-fill align-bottom me-1"></i>
              <a className="clr-white" href={HolidayList} target="_blank" rel="noopener noreferrer">
                Holiday List
              </a>
            </span>
          </button>
        </div>

        {typeOfLeaveModal == "CL" || typeOfLeaveModal == "COL" || typeOfLeaveModal == "LWP" ? (
          <Modal
            id="createTask"
            isOpen={modalTodo}
            toggle={toggle}
            modalClassName="zoomIn"
            centered
            tabIndex="-1"
          >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
              {" "}
              {leaveModalTitle}
            </ModalHeader>
            <ModalBody>
              <div id="task-error-msg" className="alert alert-danger py-2"></div>
              <Form
                id="creattask-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  leaveValidation.handleSubmit();
                  return false;
                }}
              >
                <input type="hidden" id="taskid-input" className="form-control" />
                <div className="mb-3">
                  <label htmlFor="task-full-name-input" className="form-label">
                    {" "}
                    Full Name :
                  </label>
                  <Input
                    type="text"
                    id="task-full-name-input"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="name"
                    value={name}
                    disabled
                  />
                </div>
                <Row className="g-4">
                  {
                    typeOfLeaveModal === "COL" && (
                      <Col lg={6}>
                        <div className="">
                          <label htmlFor="task-title-input" className="form-label">
                            Acquire Comp Off Leave :
                          </label>
                          <select
                            className={`form-select ${leaveValidation.errors.aquiredDate && leaveValidation.touched.aquiredDate ? "is-invalid" : null}`}
                            aria-label="Default select example"
                            id="selectedLeaveType"
                            name="aquiredDate"
                            onChange={leaveValidation.handleChange}
                            onBlur={leaveValidation.handleBlur}
                            value={leaveValidation.values.aquiredDate}
                          >
                            <option selected hidden>
                              Select Acquire Date
                            </option>
                            {listOfCompOffData?.map((res) => {
                              return (
                                <option key={res.id} value={JSON.stringify({ id: res.id, date: res.date })}>
                                  {res?.date}
                                </option>
                              );
                            })}
                          </select>
                          {leaveValidation.errors.aquiredDate && leaveValidation.touched.date ? (
                            <div className="invalid-feedback">
                              {leaveValidation.errors.aquiredDate}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    )
                  }
                  {typeOfLeaveModal === "COL" ?
                    <Col lg={6}>
                      <label htmlFor="task-duedate-input" className="form-label">
                        Date :
                      </label>

                      <Flatpickr
                        className={leaveValidation.errors.date && leaveValidation.touched.date ? "is-invalid" : null}
                        name="date"
                        id="date-field"
                        onBlur={leaveValidation.handleBlur}
                        placeholder="Select Date"
                        value={leaveValidation.values.date}
                        invalid={
                          leaveValidation.touched.date &&
                            leaveValidation.errors.date
                            ? true
                            : false
                        }
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          onChange: function (selectedDates, dateStr, instance) {
                            leaveValidation
                              .setFieldValue("date", dateStr)
                              .then((res) => res)
                              .catch((err) => err);
                          },
                        }}
                      />
                      {leaveValidation.errors.date && leaveValidation.touched.date ? (
                        <div className="invalid-feedback">
                          {leaveValidation.errors.date}
                        </div>
                      ) : null}
                    </Col>
                    :
                    <Col lg={6}>
                      <label htmlFor="task-duedate-input" className="form-label">
                        Date :
                      </label>
                      <Flatpickr
                        className={leaveValidation.errors.date && leaveValidation.touched.date ? "is-invalid" : null}
                        name="date"
                        id="date-field"
                        onBlur={leaveValidation.handleBlur}
                        placeholder="Select Date"
                        options={{
                          mode: "range",
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          onChange: function (selectedDates, dateStr, instance) {
                            leaveValidation.setFieldValue("date", dateStr);
                            leaveValidation.setFieldValue("fromDate", dateFormatChange(selectedDates[0]));
                            leaveValidation.setFieldValue("toDate", dateFormatChange(selectedDates[1]));
                          },
                        }}
                      />
                      {leaveValidation.errors.date && leaveValidation.touched.date ? (
                        <div className="invalid-feedback">
                          {leaveValidation.errors.date}
                        </div>
                      ) : null}
                    </Col>
                  }

                  <Col lg={6}>
                    {
                      (typeOfLeaveModal === "COL") ? null : (<div className="mb-3">
                        <label htmlFor="task-number-input" className="form-label">
                          Number of Days :
                        </label>
                        <Input
                          type="number"
                          id="task-number-input"
                          className={`form-control ${leaveValidation.errors.numberOfDays && leaveValidation.touched.numberOfDays ? "is-invalid" : null} `}
                          placeholder="Enter number of days"
                          name="numberOfDays"
                          onChange={leaveValidation.handleChange}
                          onBlur={leaveValidation.handleBlur}
                          value={leaveValidation.values.numberOfDays}
                        />
                        {leaveValidation.errors.numberOfDays && leaveValidation.touched.numberOfDays ? (
                          <div className="invalid-feedback">
                            {leaveValidation.errors.numberOfDays}
                          </div>
                        ) : null}
                      </div>)
                    }

                  </Col>
                </Row>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Leave Reason :
                  </label>
                  <Input
                    type="textarea"
                    id="task-title-input"
                    className={`form-control ${leaveValidation.errors.leaveReason && leaveValidation.touched.leaveReason ? "is-invalid" : null}`}
                    placeholder="Enter leave reason"
                    name="leaveReason"
                    onChange={leaveValidation.handleChange}
                    onBlur={leaveValidation.handleBlur}
                    value={leaveValidation.values.leaveReason}
                  />
                  {leaveValidation.errors.leaveReason && leaveValidation.touched.leaveReason ? (
                    <div className="invalid-feedback">
                      {leaveValidation.errors.leaveReason}
                    </div>
                  ) : null}
                </div>


                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Leave Type :
                  </label>
                  <select
                    className={`form-select ${leaveValidation.errors.leaveType && leaveValidation.touched.leaveType ? "is-invalid" : null}`}
                    aria-label="Default select example"
                    id="leaveType"
                    name="leaveType"
                    onChange={leaveValidation.handleChange}
                    onBlur={leaveValidation.handleBlur}
                    value={leaveValidation.values.leaveType}
                  >
                    <option selected hidden>
                      Select Leave Type
                    </option>
                    <option value="Full"> Full</option>
                    <option value="Half">Half</option>
                    {typeOfLeaveModal === "COL" ? null : <option value="WFH">WFH</option>}
                  </select>
                  {leaveValidation.errors.leaveType && leaveValidation.touched.leaveType ? (
                    <div className="invalid-feedback">
                      {leaveValidation.errors.leaveType}
                    </div>
                  ) : null}
                </div>


                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-ghost-success"
                    onClick={() => { setModalTodo(false); setLeaveModalTitle(""); leaveValidation.resetForm() }}
                  >
                    <i className="ri-close-fill align-bottom"></i>{" "}
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary" id="addNewTodo">
                    Submit
                  </button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        ) : null}

        {typeOfLeaveModal == "AcquireModal" ? (
          <Modal
            id="createTask"
            isOpen={modalTodo}
            toggle={toggle}
            modalClassName="zoomIn"
            centered
            tabIndex="-1"
          >
            <Form onSubmit={(e) => {
              e.preventDefault();
              AcquireLeaveValidation.handleSubmit();
              return false;
            }}>
              <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
                {" "}
                Acquire Comp Off Leave{" "}
              </ModalHeader>
              <ModalBody>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <label>Select Date :</label>
                      <Flatpickr
                        name="date"
                        id="date-field"
                        placeholder="Select Date"
                        className={`from-control ${AcquireLeaveValidation.errors.date && AcquireLeaveValidation.touched.date ? "is-invalid" : null}`}
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          onChange: function (selectedDates, dateStr, instance) {
                            AcquireLeaveValidation.setFieldValue("date", dateStr);
                          },
                        }}
                      />
                      {AcquireLeaveValidation.errors.date && AcquireLeaveValidation.touched.date ? (
                        <div className="invalid-feedback">
                          {AcquireLeaveValidation.errors.date}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Col lg={12}>
                      <div>
                        <label>Enter your reason :</label>
                        <Input
                          type="textarea"
                          className={`form-control ${AcquireLeaveValidation.errors.date && AcquireLeaveValidation.touched.date ? "is-invalid" : null}`}
                          placeholder="Enter your reason"
                          name="reason"
                          onChange={AcquireLeaveValidation.handleChange}
                          onBlur={AcquireLeaveValidation.handleBlur}
                          value={AcquireLeaveValidation.values.reason}
                        />
                        {AcquireLeaveValidation.errors.reason && AcquireLeaveValidation.touched.reason ? (
                          <div className="invalid-feedback">
                            {AcquireLeaveValidation.errors.reason}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </div>
                </Row>

              </ModalBody>
              <ModalFooter>
                <Button color="btn btn-ghost-success" onClick={toggle}>
                  <i className="ri-close-fill align-bottom"></i>
                  Cancel
                </Button>{" "}
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        ) : null}
      </>) : (<Loader />)}
    </div>
  );
};

export default FixedHeaderDatatables;