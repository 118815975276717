import { LIST_OF_COURCE, LIST_OF_TOPICS, ADD_TOPIC, ADD_COURCE, DELETE_COURCE, UPDATE_COURCE, LIST_ITP_TOPICS, ADD_COURSE, UPDATE_COURSE, DELETE_COURSE, DELETE_TOPICS, FIND_ASSIGN_COURSE, ASSIGN_COURSE, LIST_OF_TRAINEE_COURSE, LIST_OF_ASSIGN_COURSE ,DRAG_DROP} from "./apiRoutes"
import { apiService2 } from "./baseURL"

export const listOfCource = async (data) => {
    const response = await apiService2.post(`${LIST_OF_COURCE}`, data);
    return response.data;
};

export const addCource = async (data) => {
    const response = await apiService2.post(`${ADD_COURCE}`, data);
    return response;
};

export const deleteCource = async (data) => {
    const response = await apiService2.put(`${DELETE_COURCE}`, data);
    return response;
};

export const updateCource = async (data) => {
    const response = await apiService2.put(`${UPDATE_COURCE}`, data);
    return response;
};

export const listOfTopics = async (data) => {
    const response = await apiService2.post(`${LIST_OF_TOPICS}`, data);
    return response.data;
};

export const addTopic = async (data) => {
    const response = await apiService2.post(`${ADD_TOPIC}`, data);
    return response;
};

export const listTopics = async (data) => {
    const response = await apiService2.post(`${LIST_ITP_TOPICS}`, data);
    return response;
};

export const addCourse = async (data) => {
    const response = await apiService2.post(`${ADD_COURSE}`, data);
    return response;
};

export const updateCourse = async (data) => {
    const response = await apiService2.put(`${UPDATE_COURSE}`, data);
    return response;
};

export const deleteCourse = async (data) => {
    const response = await apiService2.put(`${DELETE_COURSE}`, data);
    return response;
};

export const deleteTopics = async (data) => {
    const response = await apiService2.put(`${DELETE_TOPICS}`, data);
    return response;
};

export const findAssignCourse = async (data) => {
    const response = await apiService2.post(`${FIND_ASSIGN_COURSE}`, data);
    return response;
};

export const assignCourse = async (data) => {
    const response = await apiService2.post(`${ASSIGN_COURSE}`, data);
    return response;
};

export const listOfAssignCourse = async (data) => {
    const response = await apiService2.post(`${LIST_OF_ASSIGN_COURSE}`, data);
    return response;
};

export const dragAndDrop = async (data) => {
    const response = await apiService2.put(`${DRAG_DROP}`, data);
    return response;
};

