//Login
export const LOGIN = "api/login";

//Profile 
export const PROFILE = "api/viewProfile";
export const EDIT_PROFILE = "api/editProfile";
export const EDIT_PROFILE_IMAGE = "api/editProfileImage";
export const EDIT_EMP_HR_PROFILE = "api/editProfile";
export const EMPLOYEE_PROFILE = "api/viewProfile";
export const REMOVE_PROFILE_IMAGE = "/api/removeProfileImage";

//Add Employee
export const LIST_OF_DATA = "api/listOfData";
export const ADD_EMPLOYEE = "api/addEmployee";
export const EDIT_EMPLOYEE = "/api/editEmployee";
export const LIST_OF_EMPLOYEE = "api/listOfEmployee";
export const COUNT_OF_EMPLOYEE = "api/countData";
export const EMPLOYEE_PROFILE_IMAGE = "uploads";
export const DELETE_EMPLOYEE = "api/deleteEmployee";
export const CHANGE_SENIOR = "/api/changeSenior";
export const UPDATE_STATUS_OF_EMPLOYEE = "api/employeeDelete";

//Bank Details
export const BANK_DETAILS = "api/viewBankDetails";
export const EDIT_BANK_DETAILS = "api/admin/editBankDetails";
export const ADD_BANK_DETAILS = "api/admin/addBankDetails";

//Education Details
export const VIEW_EDUCATION_DETAILS = "api/viewEducation";
export const ADD_EDUCATION_DETAILS = "api/addDocument";
export const DELETE_EDUCATION_DETAILS = "api/deleteDocument";

//Document
export const ADD_DOCUMENT_DETAILS = "api/addDocument";
export const VIEW_DOCUMENT_DETAILS = "api/viewDocument";
export const DELETE_DOCUMENT_DETAILS = "api/deleteDocument";

//ITP 
export const LIST_OF_COURCE = "api/admin/itp/listOfCourse";
export const ADD_COURCE = "api/admin/itp/addCourse";
export const DELETE_COURCE = "api/admin/itp/deleteCourse";
export const UPDATE_COURCE = "api/admin/itp/updateCourse";
export const LIST_OF_TOPICS = "api/itp/listOfTopics";
export const ADD_TOPIC = "api/admin/itp/addTopic";
export const DELETE_TOPIC = "/api/admin/itp/deleteTopic";
export const ASSIGN_COURSE = "api/itpProject/assignItp";

//dashboard api 
export const ACTIVE_EMPLOYEE = "api/countData";
export const TOTAL_EMPLOYEE = "api/countData";
export const LEAVE_APPLICATION = "api/countData";
export const TOTAL_QUERIES = "api/countData";
export const SOLVED_QUERIES = "api/countData";
export const RECENTLY_REGISTRATION = "api/recentlyRegistration";
export const TOTAL_EMPLOYEE_CHART = "api/pieChart"
export const ADMIN_LIST_OF_SUPPORTS = "/api/listOfSupports";
export const DASHBOARD_MULTI_CARD = "/api/countOfMultipleCardData";
export const LIST_OF_DAILY_UPDATES = "/api/dailyUpdate/listOfDailyUpdate";
export const LIST_OF_CURRENT_UPDATES = "/api/dailyUpdate/showDailyUpdate";
export const LIST_OF_BIRTHDAY = "/api/listOfTodayBirthday";
export const FILTER_OF_EMPLOYEE = "/api/filterOfEmployee";

//Report
export const RESOURCE_REPORT = "/api/admin/report/resourceReport";
export const ALLOCATED_REPORT = "/api/admin/report/resourceAllocatedReport";
export const RECRUITMENT_REPORT = "api/admin/report/recruitmentReport";
export const EMP_TASKSHEET_REPORT = "/api/admin/report/employeeTimeSheet";
export const DAMAGE_RESOURCE_REPORT = "/api/admin/report/damageResourceReport";

//Support
export const HR_LIST_SUPPORT_EMP_DATA = "/api/hr/listSupportDataOfEmployee";
export const ADD_RESOURCES = "/api/addSupport";
export const VIEW_SUPPORT = "api/viewSupport";
export const COUNT_OF_SUPPORT = "/api/countData";
export const UPDATE_SUPPORT = "/api/hr/updateSupportStatus";
export const MULTI_COUNT = "/api/hr/countOfMultipleSupportData";
export const UPDATE_STATUS_OF_SUPPORT = "/api/hr/updateSupportStatus";

//Resources
export const ADD_RESOURCE = "api/hr/resources/addResources"
export const ADD_CONFIGURATION = "api/hr/addConfiguration"
export const EDIT_CONFIGURATION = "api/hr/editResources"
export const RESOURCE_ALLOCATION = "api/hr/resources/resourcesAllocation"
export const RESOURCE_RETURN = "/api/hr/resources/resourcesReturn"
export const LIST_OF_RESOURCE = "api/listOfResources"
export const COUNT_OF_DEVICE = "api/hr/countOfDevice"
export const FIND_DEVICES_NUMBER = "/api/hr/resources/findDevicesNumber"
export const LIST_OF_ALLOCATION = "api/hr/resources/listOfAllocation"
export const LIST_OF_DAMAGED_DEVICES = "api/hr/resources/listOfDamagedResources"
export const LIST_OF_CONFIGURATION = "api/hr/listOfConfiguration"
export const MULIT_COUNT_CONFIG = "/api/hr/countOfMultipleConfigurationData";
export const VENDOR_LIST = "/api/hr/vendorList"
export const RESOURCES_HISTORY = "/api/hr/resources/resourcesHistory";
export const RESOURCES_EDIT_DAMAGED_RESOURCE = "/api/hr/resources/editDamagedResources";
export const RESOURCES_EDIT_DAMAGED_RESOURCE_FILTER = "/api/hr/resources/listOfResourcesManageFilter";
export const RESOURCES_RESOURCE_FILTER = "/api/hr/resources/listOfAvailableResources";
export const RESOURCES_FIND_ASSIGN_DEVICES = "/api/hr/resources/findAssignDevices";

//Itp
export const LIST_ITP_TOPICS = "api/itp/listOfTopics"
export const ADD_COURSE = "/api/itp/addCourse";
export const UPDATE_COURSE = "/api/itp/updateTopic";
export const DELETE_COURSE = "/api/itp/deleteCourse";
export const DELETE_TOPICS = "/api/itp/deleteTopic";
export const LIST_OF_ASSIGN_COURSE   ="/api/itp/listOfAssignCourse";
export const DRAG_DROP = "/api/itp/updateDrag";

//ITP Project 
export const LIST_OF_ITP_PROJECT = "/api/itpProject/listOfItpProject";
export const ADD_ITP_PROJECT = "/api/itpProject/addItpProject";
export const UPDATE_ITP_PROJECT = "/api/itpProject/updateItpProject";
export const DELETE_ITP_PROJECT = "/api/itpProject/deleteItpProject";
export const DELETE_ITP_PROJECT_DETAILS = "/api/itpProject/deleteItpProjectDetails";
export const LIST_OF_ASSIGN_ITP_PROJECTS ="/api/itpProject/listOfAssignItpProjects";
export const FIND_ITP_PROJECT ="/api/itpProject/findItpProject";

//Leave
export const FILTER_OF_LEAVE_DETAILS="/api/leave/filterOfLeaveDetails"
export const LEAVE_APPROVE_REJECT = "/api/admin/leave/updateStatusOfLeave"
export const LIST_OF_APPLICANTS = "/api/admin/listOfApplicants";
export const ADD_LEAVE = "api/leave/addLeave";
export const ACQUIRE_COMP_OF_LEAVE="/api/leave/acquireCompOffLeave"
export const APPLY_COMP_OF_LEAVE = "/api/employee/leave/applyCompOffLeave";
export const LEAVE_HISTORY_OF_EMPLOYEES = "api/hr/leaveHistoryOfEmployees";
export const VIEW_LEAVE = "/api/viewLeaveHistory";
export const DELETE_LEAVE = "/api/deleteLeave";
export const COUNT_OF_LEAVE = "/api/leave/countOfLeave";
export const APPROVE_REJECT_LEAVE_ADMIN = "/api/admin/leave/updateStatusOfLeave";
export const LIST_OF_AQUIRE_LEAVE = "/api/admin/leave/listOfAcquireLeave";
export const UPDATE_STATUS_OF_AQUIRE_LEAVE = "/api/admin/leave/updateStatusOfAcquireLeave";
export const APPLY_ACQUIRE = "/api/leave/applyCompOffLeave";
export const COUNT_OF_TOTAL_CASUAL_LEAVE = "api/leave/calculateLeave";
export const COUNT_OF_TOTAL_COM_OF_LEAVE = "api/leave/calculateColLeave";
export const EDIT_DELETE_ACQUIRELEAVE = "/api/leave/editDeleteAcquireLeave"
export const LIST_OF_TASK = "/api/listOfTask"

//RecruitMent
export const LIST_OF_TECHROUND2 = "api/hr/listOfTechnicalRoundOne"
export const LIST_OF_RECRUITMENT = "api/hr/listOfRecruitment";
export const ADD_RECRUITMENT = "api/hr/addRecruitment";
export const EDIT_RECRUITMENT = "/api/hr/editRecruitment";
export const LIST_OF_TECHNICAL_INTERVIEWS = "api/employee/listofTechnicalInterviews";
export const ADD_OF_TECHNICAL_INTERVIEWS = "api/hr/addTechnicalRecruitment";
export const EDIT_OF_TECHNICAL_INTERVIEWS = "api/employee/editTechnicalInterviews";

// Widgets
export const COUNT_OF_DATA = "api/countData";

//TaskSheet
export const VIEW_TASKSHEET = "api/viewTasksheet";
export const ADD_TASKSHEET = "api/addTaskSheet";
export const ADD_PROJECT = "/api/hr/project/addProject";
export const LIST_OF_PROJECT = "api/hr/project/listOfUserProjectCount";
export const EDIT_PROJECT = "api/hr/project/editProject";
export const LIST_OF_SENIOR_PROJECT = "api/hr/project/listOfProject";
export const LIST_OF_TASKSHEET = "/api/listOfTaskSheet";
export const EDIT_TASKSHEET = "api/editTaskSheet";
export const LIST_OF_TIMESHEET = "api/listOfTaskSheet";
export const LIST_OF_SENIOR_TASKSHEET = "api/listOfSeniorTaskSheet";
export const ADD_SENIOR_TASKSHEET = "api/employee/taskSheet/seniorAddTask";
export const EDIT_SENIOR_TASKSHEET = "api/taskSheet/seniorEditTask";
export const LIST_OF_JUNIOR_DEVELOPER = "api/listOfJunior";
export const LIST_OF_PROJECTS = "/api/listOfProject";

export const LIST_OF_ASSIGN_PROJECT = "/api/listOfAssignProject";

//Review 
export const APPROVE_JUNIOR_TIMESHEET = "/api/employee/approveTimeSheetStatus/";
export const VIEW_JUNIOR = "api/viewTasksheet";
export const VIEW_JUNIOR_Tasksheet = "api/viewJuniorTasksheet";
export const APPROVE_JUNIOR_TASK = "api/approveTimesheet";
export const LIST_OF_JUNIOR = "api/listOfJuniorTaskSheet";
export const JUNIOR_TASKSHEET_LIST = "api/listOfReviewTaskSheet";
export const LIST_OF_REVIEW_DETAILS = "/api/listOfTaskSheet";

//Find
export const FIND_ASSIGN_COURSE = "api/itp/findAssignCourse";

// Approve/Reject TimeSheet
export const APPROVE_REJECT_TIMESHEET = "api/approveTimeSheetStatus";

//InterviewFeedBack
export const ADD_INTERVIEW_FEEDBACK = "/api/interviewFeedBack/addInterviewFeedBack";
export const EDIT_INTERVIEW_FEEDBACK = "/api/interviewFeedBack/editInterviewFeedBack";
export const VIEW_INTERVIEW_FEEDBACK = "/api/interviewFeedBack/viewInterviewFeedBackDetails";
export const LIST_OF_INTERVIEW_FEEDBACK = "/api/interviewFeedBack/listOfInterviewFeedBack";
export const DELETE_INTERVIEW_FEEDBACK = "/api/interviewFeedBack/deleteInterviewFeedBack";
// Company
export const ADD_COMPANY = "/api/admin/clientInformation/addCompany";
export const UPDATE_COMPANY = "/api/admin/clientInformation/editCompany";
export const DELETE_COMPANY = "/api/admin/clientInformation/deleteCompany";

//Client 
export const ADD_CLIENT = "/api/admin/clientInformation/addClientInformation";
export const EDIT_CLIENT = "/api/admin/clientInformation/editClientInformation";
export const DELETE_CLIENT = "/api/admin/clientInformation/deleteClientInformation";
export const LIST_OF_CLIENT = "/api/admin/clientInformation/listOfClientInformation";

// ProjectDetails
export const ADD_CLIENT_PROJECT_DETAILS = "/api/admin/addClientProject";
export const EDIT_CLIENT_PROJECT_DETAILS = "/api/admin/editClientProject";
export const DELETE_CLIENT_PROJECT_DETAILS = "/api/admin/deleteClientProject"; 

// Payment
export const LIST_OF_PAYMENT_DETAILS = "/api/admin/projectPayment/listOfPaymentDetails";
export const ADD_PAYMENT_DETAILS = "/api/admin/projectPayment/addProjectPayment";
export const VIEW_RECEIVED_AMOUNT = "/api/admin/projectPayment/viewReceivedAmount:";
export const EDIT_PAYMENT_DETAILS = "/api/admin/projectPayment/editProjectPayment";
export const DELETE_PAYMENT_DETAILS = "/api/admin/projectPayment/deleteProjectPayment";
export const FILTER_OF_PAYMENT = "/api/admin/projectPayment/filterOfPayment";

//Aliased
export const ADD_ALIASED = "/api/admin/alised/addAlisedName";
export const EDIT_ALIASED = "/api/admin/alised/editAlisedName";
export const DELETE_ALIASED = "/api/admin/alised/deleteAlisedName";

//BDE
export const ADD_BDE = '/api/bde/addBdeInterview'
export const LIST_OF_EMPLOYEE_BDE_NAME = "/api/listOfEmployeeBdeName";
export const LIST_OF_BDE = "/api/bde/listOfBdeInterview";
export const UPDATE_BDE = "/api/bde/editBdeInterview";
export const DELETE_BDE = "/api/bde/deleteBdeInterview";


//Forget Password
export const VERIFY_EMAIL = "/api/verifyEmail";
export const VERIFY_OTP = "/api/verifyOtp";
export const UPDATE_PASSWORD = "/api/forgotPassword";
export const CHANGE_PASSWORD = "/api/resetPassword";

// Assign Course
export const ASSIGNING_COURSE = "/api/listOfAssignCourses";

//Daily Update
export const ADD_DAILY_UPDATE = "/api/dailyUpdate/addDailyUpdate";
export const LIST_OF_DAILY_UPDATE = "/api/dailyUpdate/listOfDailyUpdate";
export const EDIT_DAILY_UPDATE = "/api/dailyUpdate/editDailyUpdate";
export const DELETE_DAILY_UPDATE = "/api/dailyUpdate/deleteDailyUpdate";
