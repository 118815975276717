export const RequiredField = (field) => {
    return `${field} is required.`
}
export const BasicPlaceholderFormat=(fieldName) =>{
    return ` Please enter your ${fieldName}`
}


export const numberRegex = /^(?=.*\d)[\d\s]+$/;
export const aadharRegex = /^(?=.*\d)[\d\s]+$/ ||"^[2-9]{1}[0-9]{11}$";
// ^ represents the starting of the string.
// [2-9]{1} represents the first digit should be any from 2-9.
// [0-9]{3} represents the next 3 digits after the first digit should be any digit from 0-9.
// \\s represents white space.
// [0-9]{4} represents the next 4 digits should be any from 0-9.
// \\s represents white space.
// [0-9]{4} represents the next 4 digits should be any from 0-9.
// $ represents the ending of the string.