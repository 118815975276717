import React, { useState, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/common/deleteModal";
import {
  Card,
  CardBody,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import Widgets from "../dashboard/widgets";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  addResource,
  configurationCountData,
  listOfConfiguration,
} from "../../api/resourcesApi";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { getId } from "../../utils/helpers/custom";
import { ResponseStatusEnum, NO_DATA_FOUND } from "../../utils/helpers/enums";
import NoResultsMessage from "../../components/noResultMessage";
import { dateFormat } from "../../utils/helpers/common";

const DamageDevice = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalTodo, setModalTodo] = useState(false);
  const [todo, setTodo] = useState(null);
  const [configurationData, setConfigurationData] = useState([]);
  const navigate = useNavigate();

  const handleDeleteTodo = () => {
    if (todo) {
      setDeleteModal(false);
    }
  };

  const amountRegex = /^\d+$/;

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  //detailed view
  const [detailView, setDetailView] = useState(null);
  const [modalDetailedView, setModalDetailedView] = useState(false);

  const detailViewToggle = useCallback(() => {
    if (modalDetailedView) {
      setModalDetailedView(false);
      setDetailView(null);
    } else {
      setModalDetailedView(true);
    }
  }, [detailView]);

  const detailedView = () => {
    setDetailView("");
    setModalDetailedView(!modalDetailedView);
    detailViewToggle();
  };

  // add details for laptop
  const [detail, setDetail] = useState(null);
  const [modalDetail, setModalDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [configCardData, setConfigCardData] = useState();
  const laptopDetailToggle = useCallback(() => {
    if (modalDetail) {
      setModalDetail(false);
      setDetail(null);
    } else {
      setModalDetail(true);
    }
  }, [detail]);

  const fetchData = () => {
    setLoading(true);
    listOfConfiguration({})
      .then((resp) => {
        setConfigurationData(resp?.data?.data);
      })
      .catch((err) => err)
      .finally(() => {
        // setLoading(false)
      });
    configurationCountData()
      .then((response) => {
        setConfigCardData(response?.data?.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const QueryColumns = useMemo(() => [
    {
      Header: "Sr.no",
      Cell: ({ row }) => {
        const index = row?.index + 1;
        return <div>{index}</div>;
      },
    },
    {
      Header: "Brand Name",
      Cell: (item) => {
        return (
          <div>
            {item?.cell?.row?.original?.brand ||
              NO_DATA_FOUND}
          </div>
        );
      },
    },
    {
      Header: "Resource",
      Cell: (item) => {
        return (
          <div>{item?.cell?.row?.original?.resourceName || NO_DATA_FOUND}</div>
        );
      },
    },
    {
      Header: "Resource Number",
      Cell: (item) => {
        return (
          <div>
            {item?.cell?.row?.original?.resourceNo ||
              NO_DATA_FOUND}
          </div>
        );
      },
    },
    {
      Header: "Vendor",
      Cell: (item) => {
        return (
          <div>
            {item?.cell?.row?.original?.vendor?.business ||
              NO_DATA_FOUND}
          </div>
        );
      },
    },
    {
      Header: "Purchase Date",
      Cell: (cell) => {
        const date = cell?.row?.original?.purchaseDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
    {
      Header: "Warranty End Date",
      Cell: (cell) => {
        const date = cell?.row?.original?.warrantyEndDate;
        return (
          <div>
            {dateFormat(date) !== "Invalid date" ? dateFormat(date) : "---"}
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: (cellProps, key, items) => {
        return (
          <div className="hstack gap-2">
            <button
              id={`editAction-${cellProps.row.index}`}
              className="btn btn-sm btn-soft-info edit-list"
              onClick={() => {
                navigate(
                  `/configuration/editConfiguration/${cellProps.row.original.id}`
                );
              }}>
              <i className="ri-pencil-fill align-bottom" />
              <ReactTooltip
                place="bottom"
                variant="info"
                content="Edit Configuration"
                anchorId={`editAction-${cellProps.row.index}`}
              />
            </button>
            {cellProps?.cell?.row?.original?.resourceName === "Laptop" && (
              <div className="hstack gap-2">
                {" "}
                <button
                  id={`detailedView-${cellProps.row.index}`}
                  className="btn btn-sm btn-soft-info "
                  onClick={() => {
                    detailedView();
                  }}>
                  <i className="ri-booklet-line align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="info"
                    content="Detailed View"
                    anchorId={`detailedView-${cellProps.row.index}`}
                  />
                </button>
              </div>
            )}
          </div>
        );
      },
    },
  ]);

  const profileId = getId();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      resource: "",
      brandName: "",
      allocatedNumber: "",
      amount: "",
      paidby: "",
      vendor: "",
      purchaseDate: "",
    },
    validationSchema: Yup.object({
      resource: Yup.string().required(RequiredField("Resource")),
      brandName: Yup.string().required(RequiredField("Brand name")),
      allocatedNumber: Yup.string().required(RequiredField("Allocated number")),
      amount: Yup.string()
        .required(RequiredField("Amount"))
        .matches(amountRegex, "Please enter valid amount."),
      paidby: Yup.string().required(RequiredField("Paid by")),
      vendor: Yup.string().required(RequiredField("Vendor")),
      purchaseDate: Yup.string().required(RequiredField("Purchase date")),
    }),
    onSubmit: (values) => {
      if (values.resource === "Laptop") {
        setDetail("");
        setModalDetail(!modalDetail);
        laptopDetailToggle();
      }
      addResource({
        addedBy: profileId,
        resourceName: values.resource,
        resourceNo: values.allocatedNumber,
        amount: parseInt(values.amount),
        paidBy: values.paidby,
        brand: values.brandName,
        vendorId: values.vendor,
        purchaseDate: values.purchaseDate,
      })
        .then((resp) => {
          if (resp.data.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(resp.data.message);
          } else {
            toast.error(resp.data.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      validation.resetForm();
      toggle();
    },
  });

  const eComWidgets = [
    {
      id: 1,
      cardColor: "primary",
      label: "Available Devices",
      badgeClass: "success",
      badge: "ri-arrow-right-up-line",
      percentage: configCardData?.availableResourcesData,
      counter: configCardData?.availableResourcesData,
      bgcolor: "success",
      icon: "bx bx-devices",
      decimals: 0,
      prefix: "",
      suffix: "",
      link: "/filter-resource",
    },
    {
      id: 1,
      cardColor: "primary",
      label: "Allocated Devices",
      badgeClass: "success",
      badge: "ri-arrow-right-up-line",
      percentage: configCardData?.totalAllocatedDevicesOfCurrentMonthData,
      counter: configCardData?.totalAllocatedDevicesData,
      bgcolor: "success",
      icon: "bx bx-devices",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 1,
      cardColor: "primary",
      label: "Damaged Devices",
      badgeClass: "success",
      badge: "ri-arrow-right-up-line",
      percentage: configCardData?.totalDamageDevicesData,
      counter: configCardData?.totalDamageDevicesData,
      bgcolor: "success",
      icon: "bx bx-devices",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 1,
      cardColor: "primary",
      label: "Total Devices",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: configCardData?.totalDevicesOfCurrentMonthData,
      counter: configCardData?.totalDevicesData,
      bgcolor: "success",
      icon: " bx bx-navigation",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];

  document.title = "Configuration";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Configuration" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Configuration</h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <button
                    className="btn btn-primary createTask"
                    type="button"
                    onClick={() => navigate("/configuration/addConfiguration")}>
                    + Configuration
                  </button>
                </div>
              </div>
            </Row>
            <Row>
              <Widgets data={eComWidgets} />
            </Row>
            <Card>
              <CardBody>
                {configurationData?.length ? (
                  <TableContainer
                    columns={QueryColumns}
                    data={configurationData || []}
                    isAddUserList={false}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  />
                ) : (
                  <NoResultsMessage />
                )}
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </React.Fragment>
  );
};

export default DamageDevice;
