import React, { useState, useCallback, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { getId, getRole } from "../../utils/helpers/custom";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import NoResultsMessage from "../../components/noResultMessage";
import DeleteModal from "../../components/common/deleteModal";
import {
  addDailyUpdateApi,
  deleteDailyUpdateApi,
  editDailyUpdateApi,
  listOFDailyUpdateApi,
} from "../../api/dailyUpdateApi";
import { dateFormat } from "../../utils/helpers/common";

const DailyUpdate = () => {
  const id = getId();
  const role = getRole();

  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfDailyUpdateData, setListOfDailyUpdateData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editDailyUpdateCellData, setEditDailyUpdateCellData] = useState();

  useEffect(() => {
    setLoading(true)
    listOFDailyUpdateApi({})
      .then((response) => {
        const reversedData = response?.data?.data;
        setListOfDailyUpdateData(reversedData);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //Add Daily Update
  const addDailyUpdate = () => {
    newAddDailyUpdate.resetForm();
    setEditDailyUpdateCellData("");
    setModalTodo("");
    setModalTodo(!modalTodo);

    setModalTodo([]);
    toggle();
    setIsEdit(false);
  };

  const editDailyUpdateHandle = (data) => {
    newAddDailyUpdate.resetForm();
    setEditDailyUpdateCellData(data);

    setModalTodo("");
    setIsEdit(true);
    setModalTodo(!modalTodo);
    setModalTodo([]);
    setTodo([]);
    toggle();
  };

  const toggle = useCallback(() => {
    newAddDailyUpdate.resetForm();
    if (modalTodo) {
      setModalTodo(false);
      newAddDailyUpdate.resetForm();

      setModalTodo(null);
    } else {
      newAddDailyUpdate.resetForm();
      setModalTodo(true);
    }
  }, [modalTodo]);

  //meomo
  const QueryColumnsAdmin = useMemo(() => [
    {
      Header: "Serial No",
      filterable: false,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>; // Add 1 to start the serial number from 1
      },
    },
    {
      Header: "Title",
      accessor: "title",
      filterable: false,
    },
    {
      Header: "Description",
      accessor: "description",
      filterable: false,
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      Cell: (cell) => {
        const date = cell?.row?.original?.startDate;
        return (
          <div>
              {dateFormat(date) !== "Invalid date"? dateFormat(date) : "---"}
          </div>
        )
      }
    },
    {
      Header: "End Date",
      accessor: "endDate",
      Cell: (cell) => {
        const date = cell?.row?.original?.endDate;
        return (
          <div>
              {dateFormat(date) !== "Invalid date"? dateFormat(date) : "---"}
          </div>
        )
      }
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="hstack gap-2">
            <button
              className="btn btn-sm btn-soft-info edit-list"
              onClick={() => editDailyUpdateHandle(cellProps?.row?.original)}
            >
              <i className="ri-pencil-fill align-bottom" />
            </button>
            <button className="btn btn-sm btn-soft-danger remove-list">
              <i
                className="ri-delete-bin-5-fill align-bottom"
                onClick={() => dailyUpdateDelete(cellProps?.row?.original?.id)}
              />
            </button>
          </div>
        );
      },
    },
  ]);

  //Daily Update Data
  const columns = useMemo(() => [
    {
      Header: "Sr.no",
      Cell: ({ row }) => {
        const index = row?.index + 1;
        return <div>{index}</div>;
      },
    }, 
    {
      Header: "Title",
      accessor: "title",
      filterable: false,
    },
    {
      Header: "Description",
      accessor: "description",
      filterable: false,
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      Cell: (cell) => {
        const date = cell?.row?.original?.startDate;
        return (
          <div>
              {dateFormat(date) !== "Invalid date"? dateFormat(date) : "---"}
          </div>
        )
      }
    },
    {
      Header: "End Date",
      accessor: "endDate",
      Cell: (cell) => {
        const date = cell?.row?.original?.endDate;
        return (
          <div>
              {dateFormat(date) !== "Invalid date"? dateFormat(date) : "---"}
          </div>
        )
      }
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() => editDailyUpdateHandle(cellProps?.row?.original)}
              >
                <i className="ri-pencil-fill align-bottom" />
              </button>
              <button className="btn btn-sm btn-soft-danger remove-list">
                <i
                  className="ri-delete-bin-5-fill align-bottom"
                  onClick={() => dailyUpdateDelete(cellProps?.row?.original?.id)}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const newAddDailyUpdate = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editDailyUpdateCellData?.title || "",
      description: editDailyUpdateCellData?.description || "",
      startDate: editDailyUpdateCellData?.startDate || "",
      endDate: editDailyUpdateCellData?.endDate || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(RequiredField("Title ")),
      description: Yup.string().required(RequiredField("Description ")),
      startDate: Yup.date().required(RequiredField("Start Date")),
      endDate: Yup.date().required(RequiredField("End Date")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (isEdit) {
        editDailyUpdateApi(editDailyUpdateCellData?.id, { ...values })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              newAddDailyUpdate.resetForm();
              toast.success(response?.data?.message);
              setLoading(true);
              listOFDailyUpdateApi({})
                .then((response) => {
                  const reversedData = response?.data?.data;
                  setListOfDailyUpdateData(reversedData);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                });
              setModalTodo(false);
              setModalTodo([]);
            } else {
              toast.error(response?.data?.message);
              setLoading(false);
              setModalTodo(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setModalTodo(false);
            return err;
          })
          .finally(() => {
            setLoading(false);
            setModalTodo(false);
          });
        setModalTodo(false);
        newAddDailyUpdate.resetForm();
      } else {
        addDailyUpdateApi({ userId: id, ...values })
          .then((response) => {
            if (response?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              setLoading(true);
              listOFDailyUpdateApi({})
                .then((response) => {
                  const reversedData = response?.data?.data;
                  setListOfDailyUpdateData(reversedData);
                })
                .catch((err) => {
                  setLoading(false);
                  return err;
                })
                .finally(() => {
                  setLoading(false);
                });
              setModalTodo(false);
            } else {
              toast.error(response?.data?.message);
              setLoading(false);
              setModalTodo(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setModalTodo(false);
            return err;
          })
          .finally(() => {
            setModalTodo(false);
            setLoading(false);
          });
        setModalTodo(false);
        newAddDailyUpdate.resetForm();
      }
    },
  });
 
  // Delete To do
  const [deleteid, setdeleteid] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const dailyUpdateDelete = (id) => {
    setdeleteid(id);
    setTodo(todo);
    setDeleteModal(true);
  };

  const handleDeleteTodo = () => {
    deleteDailyUpdateApi(deleteid).then((resp) => {
      setLoading(true);
      if (resp?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
        setLoading(false);
        listOFDailyUpdateApi({})
          .then((response) => {
            const reversedData = response?.data?.data;
            setListOfDailyUpdateData(reversedData);
          })
          .catch((err) => {
            setLoading(false);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
        setDeleteModal(false);
        toast.success(resp?.data?.message);
      } else {
        setLoading(false);
        toast.error(resp?.data?.message);
      }
    });
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Daily Update" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Daily Update</h5>
              </div>

              {role === "HR" || role === "Admin" ? (
                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <button
                      className="btn btn-primary createTask"
                      type="button"
                      onClick={() => addDailyUpdate()}
                    >
                      + Add Daily Update
                    </button>
                  </div>
                </div>
              ) : null}
            </Row>

            {role === "HR" ? (
              <>
                <Card>
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      List Of Daily Update
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {listOfDailyUpdateData?.length ? (
                      <TableContainer
                        columns={columns}
                        data={listOfDailyUpdateData}
                        isAddUserList={false}
                        isGlobalFilter={true}
                        customPageSize={5}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-wrap"
                        theadClass="table-light text-muted"
                      />
                    ) : (
                      <NoResultsMessage />
                    )}
                  </CardBody>
                </Card>
              </>
            ) : role === "Admin" ? (
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    List of Daily Update
                  </h4>
                </CardHeader>
                <CardBody>
                  <TableContainer
                    columns={QueryColumnsAdmin}
                    data={listOfDailyUpdateData}
                    isAddUserList={false}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1 "
                    tableClass="align-middle table-wrap "
                    theadClass="table-light text-muted"
                  />
                </CardBody>
              </Card>
            ) : null}
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {isEdit === true ? "Edit" : "Add"} {"Daily Update"}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>
          <Form
            id="creattask-form"
            onSubmit={(e) => {
              e.preventDefault();
              newAddDailyUpdate.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" id="taskid-input" className="form-control" />

            <Row className="mb-3">
              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="task-title-input" className="form-label">
                    Title :
                  </Label>
                  <Input
                    type="text"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter your Title "
                    name="title"
                    validate={{ required: { value: true } }}
                    onChange={newAddDailyUpdate.handleChange}
                    onBlur={newAddDailyUpdate.handleBlur}
                    value={newAddDailyUpdate.values.title || ""}
                    invalid={
                      newAddDailyUpdate.touched.title &&
                        newAddDailyUpdate.errors.title
                        ? true
                        : false
                    }
                  />

                  {newAddDailyUpdate.touched.title &&
                    newAddDailyUpdate.errors.title ? (
                    <FormFeedback type="invalid">
                      {newAddDailyUpdate.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <label htmlFor="task-title-input" className="form-label">
                    Description :
                  </label>
                  <Input
                    type="textarea"
                    id="task-title-input"
                    className="form-control"
                    placeholder="Enter description"
                    name="description"
                    rows="1"
                    validate={{ required: { value: true } }}
                    onChange={newAddDailyUpdate.handleChange}
                    onBlur={newAddDailyUpdate.handleBlur}
                    value={newAddDailyUpdate.values.description || ""}
                    invalid={
                      newAddDailyUpdate.touched.description &&
                        newAddDailyUpdate.errors.description
                        ? true
                        : false
                    }
                  />
                  {newAddDailyUpdate.touched.description &&
                    newAddDailyUpdate.errors.description ? (
                    <FormFeedback type="invalid">
                      {newAddDailyUpdate.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="startDateInput" className="form-label">
                    Start Date:
                  </Label>
                  <Flatpickr
                    className={`form-control ${newAddDailyUpdate.errors.startDate && "is-invalid"
                      }`}
                    id="startDateInput"
                    placeholder="Select a Start Date"
                    name="startDate"
                    onBlur={newAddDailyUpdate.handleBlur}
                    value={newAddDailyUpdate.values.startDate || ""}
                    invalid={
                      newAddDailyUpdate.touched.startDate &&
                        newAddDailyUpdate.errors.startDate
                        ? true
                        : false
                    }
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      onChange: function (selectedDates, dateStr, instance) {
                        newAddDailyUpdate
                          .setFieldValue("startDate", dateStr)
                          .then((res) => {
                            // Calculate the max end date (7 days from start date)
                            const maxEndDate = new Date(selectedDates[0]);
                            maxEndDate.setDate(maxEndDate.getDate() + 7);

                            // Get a reference to the end date picker
                            const endDatePicker = document.getElementById("endDateInput");

                            // Set the maxDate for the end date picker
                            endDatePicker._flatpickr.set("maxDate", maxEndDate);
                          })
                          .catch((err) => err);
                      },
                    }}
                  />
                  {newAddDailyUpdate.touched.startDate &&
                    newAddDailyUpdate.errors.startDate ? (
                    <FormFeedback type="invalid">
                      {newAddDailyUpdate.errors.startDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="endDateInput" className="form-label">
                    End Date:
                  </Label>
                 
                  <Flatpickr
                    className={`form-control ${newAddDailyUpdate.errors.endDate && "is-invalid"
                      }`}
                    id="endDateInput"
                    placeholder="Select a End Date"
                    name="endDate"
                    onBlur={newAddDailyUpdate.handleBlur}
                    value={newAddDailyUpdate.values.endDate || ""}
                    onChange={(selectedDates, dateStr) => {
                      newAddDailyUpdate.setFieldValue("endDate", dateStr);
                      // Calculate the min start date (7 days before the end date)
                      const minStartDate = new Date(selectedDates[0]);
                      minStartDate.setDate(minStartDate.getDate() - 7);

                      // Get a reference to the start date picker
                      const startDatePicker = document.getElementById("startDateInput");

                      // Set the minDate for the start date picker
                      startDatePicker._flatpickr.set("minDate", minStartDate);
                    }}
                    invalid={
                      newAddDailyUpdate.touched.endDate &&
                        newAddDailyUpdate.errors.endDate
                        ? true
                        : false
                    }
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      enable: [
                        // Function to enable dates only within 7 days from the start date
                        function (date) {
                          const selectedStartDate = new Date(
                            newAddDailyUpdate.values.startDate
                          );
                          const maxEndDate = new Date(selectedStartDate);
                          maxEndDate.setDate(maxEndDate.getDate() + 7);
                          return date >= selectedStartDate && date <= maxEndDate;
                        },
                      ],
                    }}
                  />
                  {newAddDailyUpdate.touched.endDate &&
                    newAddDailyUpdate.errors.endDate ? (
                    <FormFeedback type="invalid">
                      {newAddDailyUpdate.errors.endDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-success"
                onClick={() => {
                  setModalTodo(false);
                }}
              >
                <i className="ri-close-fill align-bottom"></i> Close
              </button>
              <button type="submit" className="btn btn-primary" id="addNewTodo">
                {isEdit === true ? "Edit Data" : "Submit"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DailyUpdate;
