import { Label } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";

const BaseSelect = ({
  label,
  name,
  className,
  options,
  placeholder,
  handleChange,
  handleBlur,
  value,
  touched,
  error,
  isDisabled,
}) => {
  const handleMenuOpen = () => {
    handleBlur({ target: { name } });
  };

  return (
    <>
      {label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )}
      <Select
        name={name}
        className={className || "select-border"}
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={!!(touched && error)}
        isDisabled={isDisabled}
        onMenuOpen={handleMenuOpen}
      />
      {touched && error ? (
        <div className="text-danger error-font">{error}</div>
      ) : null}
    </>
  );
};

const MultiSelect = ({ value, isMulti, onChange, options, styles }) => {
  return (
    <Select
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      options={options}
      styles={styles}
    />
  );
};

BaseSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  value: PropTypes.any,
  touched: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
};

MultiSelect.propTypes = {
  value: PropTypes.array,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  styles: PropTypes.object,
};

export { BaseSelect, MultiSelect };
