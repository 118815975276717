import React, { useEffect, useMemo, useState } from "react";
import BreadCrumb from "../../components/common/breadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { Formik, useFormik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "../../components/common/tableContainer";
import {
  listOfInterviewFeedBack,
  deleteInterviewFeedBack,
} from "../../api/interviewApi";
import Loader from "../../components/common/loader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import DeleteModal from "../../components/common/deleteModal";
import * as Yup from "yup";
import { getId, getRole } from "../../utils/helpers/custom";
import { listOfEmployee } from "../../api/employeeApi";
import Select from "react-select";
import { dateFormat } from "../../utils/helpers/common";

const InterviewFeedBack = () => {
  const [interviewOfEmployee, setInterviewOfEmployee] = useState();
  const [selectedDeleteId, setSelectedDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfInterview, setListOfInterview] = useState();
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedTakenInterview, setSelectedTakenInterview] = useState(null);
  const [selectedGivenInterview, setSelectedGiveInterview] = useState(null);

  let role = getRole();
  const id = getId();

  const interviewDeleteFunc = (id) => {
    setSelectedDeleteId(id);
    setDeleteModal(true);
  };

  const interviewDeleteTodo = () => {
    const data = { isDeleted: true };
    setDeleteModal(false);
    setLoading(true);
    deleteInterviewFeedBack(data, selectedDeleteId)
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          listOfInterviewFeedBack()
            .then((response) => {
              setListOfInterview(response?.data);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              return err;
            })
            .finally(() => {
              setLoading(false);
            });
          toast.success(res?.data?.message);
        } else if (res?.data?.statusCode === ResponseStatusEnum.ACCESS_DENIED)
          toast.error(res?.data?.message);
        else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const QueryColumns = useMemo(() => [
    {
      Header: "Given By",
      accessor: "assignUser",
      filterable: false,
    },
    {
      Header: "Taken By",
      accessor: "takenBy",
      filterable: false,
    },
    {
      Header: "Over All",
      accessor: "avg",
      filterable: false,
    },
    {
      Header: "Interview Level",
      accessor: "level",
      filterable: false,
      Cell: (cell) => {
        return (
          <div>
            {cell.row.original.level === null
              ? ResponseStatusEnum.NO_DATA_FOUND
              : cell.row.original.level}
          </div>
        );
      },
    },
    {
      Header: "Interview Date",
      accessor: "interviewDate",
      Cell: (cell) => {
        const date = cell?.row?.original?.interviewDate;
        return (
          <div>
              {dateFormat(date) !== "Invalid date"? dateFormat(date) : "---"}
          </div>
        )
      }
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              {/* Edit */}
              {parseInt(id) === cellProps?.cell?.row?.original?.addedBy ||
              role === "Admin" ? (
                <button
                  id={`editMode-${cellProps?.cell?.row?.index}`}
                  className="btn btn-sm btn-soft-info remove-list"
                  onClick={() =>
                    navigate(
                      `/interview_feedBackFrom/${cellProps?.cell?.row?.original?.id}`
                    )
                  }>
                  <i className="ri-pencil-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="info"
                    content="Edit"
                    anchorId={`editMode-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              ) : (
                <button
                  id={`editMode-${cellProps?.cell?.row?.index}`}
                  className="btn btn-sm btn-soft-info remove-list"
                  disabled
                  onClick={() =>
                    navigate(
                      `/interview_feedBackFrom/${cellProps?.cell?.row?.original?.id}`
                    )
                  }>
                  <i className="ri-pencil-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="info"
                    content="Edit"
                    anchorId={`editMode-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              )}
              {/* Delete */}
              {parseInt(id) === cellProps?.cell?.row?.original?.addedBy ||
              role === "Admin" ? (
                <button
                  id={`deleteTodo-${cellProps.cell.row.index}`}
                  className="btn btn-sm btn-soft-danger remove-list"
                  onClick={() =>
                    interviewDeleteFunc(cellProps?.cell?.row?.original?.id)
                  }>
                  <i className="ri-delete-bin-5-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="error"
                    content="Delete"
                    anchorId={`deleteTodo-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              ) : (
                <button
                  id={`deleteTodo-${cellProps.cell.row.index}`}
                  className="btn btn-sm btn-soft-danger remove-list"
                  disabled
                  onClick={() =>
                    interviewDeleteFunc(cellProps?.cell?.row?.original?.id)
                  }>
                  <i className="ri-delete-bin-5-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="error"
                    content="Delete"
                    anchorId={`deleteTodo-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              )}
              <button
                id={`viewMode-${cellProps?.cell?.row?.index}`}
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() =>
                  toComponentB(
                    cellProps?.cell?.row?.original?.id,
                    cellProps?.cell?.row?.original?.avg,
                    cellProps?.cell?.row?.original?.interviewDate
                  )
                }>
                <i className="ri-eye-line align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="View"
                  anchorId={`viewMode-${cellProps?.cell?.row?.index}`}
                />
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const navigate = useNavigate();
  const toComponentB = (data, avg, date) => {
    navigate("/interview_review_page", {
      state: { id: data, count: avg, date: date },
    });
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      takenBy: "",
      junior: "",
    },
    validationSchema: Yup.object({
      takenBy: Yup.string("It Should be text"),
      junior: Yup.string("It Should be text"),
    }),
    onSubmit: (value) => {
      setLoading(true);
      listOfInterviewFeedBack(value)
        .then((res) => {
          if (res?.statusCode === 200) {
            setInterviewOfEmployee(res?.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
      validation.resetForm();
    },
  });

  useEffect(() => {
    setLoading(true);
    listOfInterviewFeedBack()
      .then((res) => {
        if (res?.statusCode === 200) {
          setListOfInterview(res?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });

    listOfEmployee({
      role: ["Employee", "HR", "BDE", "Admin"],
      isDeleted: false,
    })
      .then((resp) => {
        setEmployeeData(resp?.data?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const assignToData = Array.isArray(employeeData)
    ? employeeData?.map((status) => ({
        value: `${status?.firstName} ${status?.lastName}`,
        label: `${status?.firstName} ${status?.lastName}`,
      }))
    : null;

  let dataList =
    interviewOfEmployee === undefined ? listOfInterview : interviewOfEmployee;

  document.title = "Interview";

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => interviewDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Interview Feed Back" pageTitle="HRMS" />

            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Interview Feedback</h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  <Link to="/interview_feedBackFrom">
                    <button
                      className="btn btn-primary createTask"
                      type="button">
                      + Add Interview Feedback
                    </button>
                  </Link>
                </div>
              </div>
            </Row>
            <Card>
              <CardBody className="mt-0">
                <Formik
                  initialValues={validation.initialValues}
                  validationSchema={validation.validationSchema}
                  onSubmit={validation.handleSubmit}>
                  <Form>
                    {role === "Admin" ? (
                      <Row>
                        <Col lg={5}>
                          <div className="p-2">
                            <Select
                              className={` ${
                                validation.errors.takenBy &&
                                "is-invalid red-border"
                              }`}
                              placeholder="Taken Interviews"
                              value={
                                selectedTakenInterview ||
                                validation?.values?.takenBy
                              }
                              isMulti={false}
                              onChange={(selectedOption) => {
                                setSelectedTakenInterview(selectedOption);
                                validation.setFieldValue(
                                  "takenBy",
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              options={assignToData}
                              onBlur={validation.handleBlur}
                              name="takenBy"
                            />
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="p-2">
                            <Select
                              className={` ${
                                validation.errors.junior &&
                                "is-invalid red-border"
                              }`}
                              placeholder="Give Interviews"
                              value={
                                selectedGivenInterview ||
                                validation?.values?.junior
                              }
                              isMulti={false}
                              onChange={(selectedOption) => {
                                setSelectedGiveInterview(selectedOption);
                                validation.setFieldValue(
                                  "junior",
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              options={assignToData}
                              onBlur={validation.handleBlur}
                              name="junior"
                            />
                          </div>
                          <div className="invalid-feedback">
                            {validation.errors.junior}
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="m-2">
                            <button
                              className="btn btn-primary createTask"
                              type="submit">
                              {!loading ? (
                                "SEARCH"
                              ) : (
                                <div className="d-inline-flex align-items-center">
                                  <Spinner size="sm" className="me-2" />
                                  Loading...
                                </div>
                              )}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="g-0">
                        <Col lg={6} xs={4}>
                          <div className="p-2">
                            <Select
                              className={` ${
                                validation.errors.takenBy &&
                                "is-invalid red-border"
                              }`}
                              placeholder="Taken Interviews"
                              value={
                                selectedTakenInterview ||
                                validation?.values?.takenBy
                              }
                              isMulti={false}
                              onChange={(selectedOption) => {
                                setSelectedTakenInterview(selectedOption);
                                validation.setFieldValue(
                                  "takenBy",
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              options={assignToData}
                              onBlur={validation.handleBlur}
                              name="takenBy"
                            />
                          </div>
                        </Col>
                        <Col lg={6} xs={4} className="interviewFrom">
                          <button
                            className="btn btn-primary createTask"
                            type="submit">
                            {!loading ? (
                              "SEARCH"
                            ) : (
                              <div className="d-inline-flex align-items-center">
                                <Spinner size="sm" className="me-2" />
                                Loading...
                              </div>
                            )}
                          </button>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </Formik>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  Interview Feedback
                </h4>
              </CardHeader>

              <CardBody>
                <TableContainer
                  columns={QueryColumns}
                  data={dataList || []}
                  isAddUserList={false}
                  customPageSize={10}
                  divClass="table-responsive table-card mb-1 "
                  tableClass="align-middle table-nowrap "
                  theadClass="table-light text-muted"
                />
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default InterviewFeedBack;
