import { ADD_BANK_DETAILS, BANK_DETAILS, EDIT_BANK_DETAILS } from "./apiRoutes";
import { apiService2 } from "./baseURL";


export const viewBankDetails = async (id) => {
    const response = await apiService2.get(`${BANK_DETAILS}/${id}`);
    return response;
};

export const editBankDetails = async (id,data) => {
    const response = await apiService2.put(`${EDIT_BANK_DETAILS}/${id}`,data);
    return response;
};
export const addBankDetails = async (data) => {
    const response = await apiService2.post(`${ADD_BANK_DETAILS}`,data);
    return response;
};


