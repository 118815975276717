import axios from "axios";

//Production Or Live
export const baseURL = "https://hrms-wnzh.onrender.com";

//Developer Testing 
// export const baseURL = "https://hrms-refector.onrender.com"; 
// export const baseURL = "http://localhost:3333";

export const apiService = axios.create({
  baseURL,
});

export const apiService2 = axios.create(
  {
    baseURL,
    headers: {
      Accept: "application/json",
    },
  })

export const apiService3 = axios.create(
  {
    baseURL,
    headers: {
      Accept: "multipart/form-data",
    },
  })
  
  apiService2.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
  
  apiService3.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });