import { EMPLOYEE_PROFILE, EDIT_EMP_HR_PROFILE, EDIT_PROFILE_IMAGE, REMOVE_PROFILE_IMAGE } from "./apiRoutes";
import { apiService2, apiService3 } from "./baseURL";

export const adminProfile = async (id) => {
  const response = await apiService2.get(`${EMPLOYEE_PROFILE}/${id}`);
  return response.data;
};

export const profile = async (id) => {
  const response = await apiService3.get(`${EMPLOYEE_PROFILE}/${id}`);
  return response.data;
};

export const editProfile = async (id, data) => {
  const response = await apiService3.put(`${EDIT_EMP_HR_PROFILE}/${id}`, data);
  return response;
}

export const editProfileImage = async (id, data) => {
  const formData = new FormData();
  formData.append("profileImage", data);
  const response = await apiService3({
    method: "put",
    url: `${EDIT_PROFILE_IMAGE}/${id}`,
    data: formData
  });
  return response
}

export const editEmpHrProfile = async (id, data) => {
  const response = await apiService2.put(`${EDIT_EMP_HR_PROFILE}/${id}`, data);
  return response;
}

export const employeeProfile = async (id) => {
  const response = await apiService3.get(`${EMPLOYEE_PROFILE}/${id}`);
  return response;
}

export const removeProfileImage = async (id, data) => {
  const response = await apiService2.put(`${REMOVE_PROFILE_IMAGE}/${id}`,data);
  return response;
};