import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { dateFormat, dateFormatChange } from "../../utils/helpers/common";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../components/common/deleteModal";
import BreadCrumb from "../../components/common/breadCrumb";
import TableContainer from "../../components/common/tableContainer";
import ExportCSVModal from "../../components/common/exportCSVModal";
import {
  allocatedReport,
  damageResourceReport,
  employeeTasksheetReport,
  recruitmentReport,
  resourceReoprt,
} from "../../api/reportApi";
import Loader from "../../components/common/loader";
import { listOfData } from "../../api/commonApi";
import { listOfEmployee } from "../../api/employeeApi";
import NoResultsMessage from "../../components/noResultMessage";

const EmployeeReport = () => {
  const refComp = useRef(null);

  const [modal, setModal] = useState(false);
  const [validateReport, setValidateReport] = useState(true);
  const [btn, setBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [report, setReport] = useState("");

  const [data1, setData1] = useState({
    startDate: "",
    endDate: "",
  });

  const SingleOptions = [
    { value: "Resource Report", label: "Resource Report" },
    {
      value: "Resources Allocated Report",
      label: "Resources Allocated Report",
    },
    { value: "Damage Resource Report", label: "Damage Resource Report" },
    { value: "Recruitment Report", label: "Recruitment Report" },
    { value: "Employee Tasksheet Report", label: "Employee Tasksheet Report" },
  ];
  const [designation1, setDesignation] = useState([]);
  useEffect(() => {
    if (report?.value === "Recruitment Report") {
      listOfData({
        modelName: "technologyModel",
        selectionCriteria: ["id", "techName"],
      }).then((resp) => {
        setDesignation(resp?.data);
      });
      setData1({ startDate: "" });
    }
  }, [report?.value]);

  const [name, setName] = useState({
    employeeName: "",
  });
  useEffect(() => {
    if (report.value) {
      setData1({ startDate: "" });
    }
  }, [report.value]);
  const [empName, setEmpName] = useState([]);

  useEffect(() => {
    if (report.value === "Employee Tasksheet Report") {
      listOfEmployee({
        isDeleted: false,
        role: ["Employee", "HR"],
      })
        .then((resp) => {
          setEmpName(resp.data.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => { });
      setData1({ startDate: "" });
    }
  }, [report.value]);

  const [allocateStatus, setAllocateStatus] = useState("");
  const AllocatedStatus = [
    { value: "Approved", label: "Approved" },
    { value: "Reject", label: "Reject" },
  ];

  const [allocateItem, setAllocateItem] = useState("");
  const AllocatedItem = [
    { value: "Laptop", label: "Laptop" },
    { value: "Mouse", label: "Mouse" },
    { value: "Bag", label: "Bag" },
    { value: "Mousepad", label: "Mousepad" },
    { value: "Keyboard", label: "Keyboard" },
  ];
  const [recruitmentStatus, setRecruitmentStatus] = useState("");

  const RecruitmentStatus = [
    { value: "Selected", label: "Selected" },
    { value: "Rejected", label: "Rejected" },
  ];

  const [technology, setTechnology] = useState({
    techName: "",
  });
  const [resourcereport, setResourceReport] = useState();
  const [allocatedreport, setAllocatedeReport] = useState();
  const [recruitmentreport, setRecruitmentReport] = useState();
  const [empolyereport, setEmployReport] = useState();
  const [damageresourcereport, setDamageResourceReport] = useState();
  const [newVar, setnewVar] = useState("");

  const onSubmitClick = (Value_id, e) => {
    e.preventDefault();
    setBtn(true);
    setnewVar(Value_id);
    if (report?.value === "Resource Report") {
      setLoading(true);
      resourceReoprt({ ...data1 })
        .then((response) => {
          setResourceReport(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else if (report?.value === "Resources Allocated Report") {
      setLoading(true);
      allocatedReport({ ...data1, resourceName: allocateItem?.value })
        .then((response) => {
          setAllocatedeReport(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else if (report?.value === "Recruitment Report") {
      setLoading(true);
      recruitmentReport({
        ...data1,
        ...technology,
        status: recruitmentStatus?.value,
      })
        .then((response) => {
          setRecruitmentReport(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else if (report?.value === "Employee Tasksheet Report") {
      setLoading(true);
      employeeTasksheetReport({
        ...data1,
        ...name,
        status: allocateStatus?.value,
      })
        .then((response) => {
          setEmployReport(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else if (report?.value === "Damage Resource Report") {
      setLoading(true);
      damageResourceReport({ ...data1 })
        .then((response) => {
          setDamageResourceReport(response?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      return "error";
    }
    validation.resetForm();
  };

  const orderstatus = [
    {
      options: [
        { label: "Status", value: "Status" },
        { label: "All", value: "All" },
        { label: "Pending", value: "Pending" },
        { label: "Inprogress", value: "Inprogress" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "Pickups", value: "Pickups" },
        { label: "Returns", value: "Returns" },
        { label: "Delivered", value: "Delivered" },
      ],
    },
  ];

  const orderPayment = [
    {
      options: [
        { label: "Select Payment", value: "Select Payment" },
        { label: "All", value: "All" },
        { label: "Mastercard", value: "Mastercard" },
        { label: "Paypal", value: "Paypal" },
        { label: "Visa", value: "Visa" },
        { label: "COD", value: "COD" },
      ],
    },
  ];

  const productName = [
    {
      options: [
        { label: "Product", value: "Product" },
        { label: "Puma Tshirt", value: "Puma Tshirt" },
        { label: "Adidas Sneakers", value: "Adidas Sneakers" },
        {
          label: "350 ml Glass Grocery Container",
          value: "350 ml Glass Grocery Container",
        },
        {
          label: "American egale outfitters Shirt",
          value: "American egale outfitters Shirt",
        },
        { label: "Galaxy Watch4", value: "Galaxy Watch4" },
        { label: "Apple iPhone 12", value: "Apple iPhone 12" },
        { label: "Funky Prints T-shirt", value: "Funky Prints T-shirt" },
        {
          label: "USB Flash Drive Personalized with 3D Print",
          value: "USB Flash Drive Personalized with 3D Print",
        },
        {
          label: "Oxford Button-Down Shirt",
          value: "Oxford Button-Down Shirt",
        },
        {
          label: "Classic Short Sleeve Shirt",
          value: "Classic Short Sleeve Shirt",
        },
        {
          label: "Half Sleeve T-Shirts (Blue)",
          value: "Half Sleeve T-Shirts (Blue)",
        },
        { label: "Noise Evolve Smartwatch", value: "Noise Evolve Smartwatch" },
      ],
    },
  ];

  const [isEdit, setIsEdit] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteOrder = () => {
    if (order) {
      setDeleteModal(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customer: (order?.customer) || "",
      product: (order?.product) || "",
      orderDate: (order?.orderDate) || "",
      amount: (order?.amount) || "",
      payment: (order?.payment) || "",
      status: (order?.status) || "",
    },
    validationSchema: Yup.object({
      customer: Yup.string().required("Please Enter Customer Name"),
      product: Yup.string().required("Please Enter Product Name"),
      amount: Yup.string().required("Please Enter Total Amount"),
      payment: Yup.string().required("Please Enter Payment Method"),
      status: Yup.string().required("Please Enter Delivery Status"),
    }),
    onSubmit: (values) => {
      validation.resetForm();
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
      setDate(defaultDate());
    }
  }, [modal]);

  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const data_allocated = useMemo(() => [
    {
      Header: "Date",
      accessor: (row) => dateFormat(row?.resource?.resourcesAssign?.createdAt),
      filterable: false,
    },
    {
      Header: "Resource",
      accessor: (row) => row?.resourceName,
      filterable: false,
    },
    {
      Header: "Resource No",
      accessor: (row) => row?.resourceNo,
      filterable: false,
    },
    {
      Header: "Employee Name",
      accessor: (row) => `${row?.resourcesAssign?.authUser?.firstName} ${row?.resourcesAssign?.authUser?.lastName}`,
      filterable: false,
    }
  ]);

  const data_damage = useMemo(
    () => [
      {
        Header: "Resource Id",
        accessor: "resourceDetailsId",
        filterable: false,
      },
      {
        Header: "Device",
        accessor: (cell) => cell?.resourcesDetails?.resourceName,
        filterable: false,
      },
      {
        Header: "Brand",
        accessor: (cell) => cell?.resourcesDetails?.brand,
        filterable: false,
      },
      {
        Header: "Purchase Date",
        accessor: "resourcesDetails.purchaseDate",
        filterable: false,
      },
      {
        Header: "Warranty Start Date",
        accessor: "resourcesDetails.warrantyStartDate",
        filterable: false,
        Cell: (cell) => [<>{cell?.row?.original?.resourcesDetails.warrantyStartDate === null ? "---" : cell?.row?.original?.resourcesDetails.warrantyStartDate}</>]
      },
      {
        Header: "Warranty End Date",
        accessor: "resources.resourcesDetails.warrantyEndDate",
        filterable: false,
        Cell: (cell) => [<>{cell?.row?.original?.resourcesDetails.warrantyEndDate === null ? "---" : cell?.row?.original?.resourcesDetails.warrantyEndDate}</>]
      },
      {
        Header: "Delivery Status",
        accessor: "status",
        Cell: (cell) => {
          return (
            <span className="badge text-uppercase badge-soft-warning">
              {" "}
              {cell.value}{" "}
            </span>
          );
        },
      },
    ],
    [handleOrderClick]
  );

  const data_recruitment = useMemo(() => [
    {
      Header: "Date",
      accessor: "dateOfInterview",
      filterable: false,
      Cell: (cell) => cell.row.original.recruitment?.dateOfInterview
    },
    {
      Header: "Candidate Name",
      accessor: "candidateName",
      filterable: false,
      Cell: (cell) => cell.row.original.recruitment?.candidateName
    },
    {
      Header: "Technology",
      accessor: "techName",
      filterable: false,
      Cell: (cell) => cell.row.original.recruitment?.technology?.techName
    },
    {
      Header: "HR Feedback",
      accessor: "recruitment?.status",
      filterable: false,
      Cell: (cell) => [<>{cell.row.original?.review === null ? "---" : cell.row.original?.review}</>]
    },
    {
      Header: "status",
      accessor: "status",
      Cell: (cell) => {
        switch (cell?.value) {
          case "Rejected":
            return (
              <span className="badge text-uppercase badge-soft-danger">
                {" "}
                {cell?.value}{" "}
              </span>
            );
          case "Selected":
            return (
              <span className="badge text-uppercase badge-soft-success">
                {" "}
                {cell?.value}{" "}
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-warning">
                {" "}
                {cell?.value}{" "}
              </span>
            );
        }
      },
    },
  ]);

  const data_emp_taskSheet = useMemo(() => [
    {
      Header: "Date",
      accessor: "date",
      filterable: false,
    },
    {
      Header: "Employee Name",
      accessor: "authUser.firstName",
      filterable: false,
      Cell: (cell) => [<>{cell?.row.original.authUser !== null ? `${cell?.row?.original?.authUser?.firstName} ${cell?.row?.original?.authUser?.lastName}` : "---" }</>]
    },
    {
      Header: "Task Name",
      accessor: "nameOfTask",
      filterable: false,
      Cell: (cell) => [<>{cell?.row?.original?.taskSheet === null ? "---" : cell?.row?.original?.taskSheet?.taskName}</>]
    },
    {
      Header: "Detail Of Task",
      accessor: "detailsOfTask",
      filterable: false,
    },
    {
      Header: "Estimated Time",
      accessor: "estimateTime",
      filterable: false,
      Cell: (cell) => [<>{cell?.row?.original?.taskSheet === null ? "---" : cell?.row?.original?.taskSheet?.estimateTime}</>]
    },
    {
      Header: "Taken Time",
      accessor: "takenTime",
      filterable: false,
    },
    {
      Header: "status",
      accessor: "taskStatus",
      Cell: (cell) => {
        switch (cell.value) {
          case "Completed":
            return (
              <span className="badge text-uppercase badge-soft-success">
                {" "}
                {cell?.value}{" "}
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-warning">
                {" "}
                {cell?.value}{" "}
              </span>
            );
        }
      },
    },
  ]);

  const defaultDate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };

  const [date, setDate] = useState(defaultDate());

  const dateFormate = (e) => {
    const dateString = e.toString().split(" ");

    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = H % 12 || 12;
    h = h <= 9 ? (h = "0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const orderDate = (date + ", " + time).toString();
    setDate(orderDate);
  };

  document.title = "Report";
  return (
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={orderList}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="Employee Report" pageTitle="HRMS" />
            <Row>
              <Col lg={12}>
                <Card id="orderList">
                  <CardHeader className="card-header border-0">
                    <Row className="align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">Reports</h5>
                      </div>
                    </Row>
                    <br />
                    <Row className="align-items-center gy-3">
                      <Form
                        onSubmit={(e) => {
                          onSubmitClick(report?.value, e);
                        }}
                      >
                        <Row className="g-3">
                          <Col lg={12}>
                            <div className="form-floating">
                              <Row>
                                <Col lg={3}>
                                  <Label htmlFor="selectreport">
                                    Report Type
                                  </Label>
                                  <Select
                                    value={report}
                                    onChange={(value) => {
                                      setReport(value);
                                      setValidateReport(false);
                                    }}
                                    options={SingleOptions}
                                  />
                                  {validateReport && btn ? (
                                    <p style={{ color: "red" }}>
                                      Please Select Report Type.
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <Col lg={3}>
                                  <Label htmlFor="selectreport">
                                    Select Date
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    id="datepicker-publish-input"
                                    placeholder="Select a date"
                                    value={data1?.startDate && data1?.endDate}
                                    onChange={(event) => {
                                      setData1({
                                        ...data1,
                                        startDate: dateFormatChange(event[0]),
                                        endDate: dateFormatChange(event[1]),
                                      });
                                    }}
                                    options={{
                                      altInput: true,
                                      mode: "range",
                                    }}
                                    ref={refComp}
                                  />
                                </Col>
                                <Col lg={2}>
                                  {report?.value ===
                                    "Employee Tasksheet Report" && (
                                      <>
                                        <Label htmlFor="selectreport">
                                          Employee Name
                                        </Label>
                                        <div className="mb-3">
                                          <select
                                            className={`form-select ${validation.errors.designationId &&
                                              "is-invalid"
                                              }`}
                                            aria-label="Default select example"
                                            id="selectreport"
                                            name="designationId"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={(event) => {
                                              setName({
                                                ...name,
                                                employeeName:
                                                  event?.target?.value,
                                              });
                                            }}
                                            onBlur={validation.handleBlur}
                                          >
                                            {empName?.map((res) => {
                                              return (
                                                <>
                                                  <option
                                                    hidden
                                                    disabled
                                                    selected
                                                  >
                                                    Select Employe Name
                                                  </option>
                                                  <option
                                                    value={res?.employeeName}
                                                  >
                                                    {res?.firstName}
                                                  </option>
                                                </>
                                              );
                                            })}
                                          </select>
                                          <div className="invalid-feedback">
                                            {validation.errors.designationId}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  {report?.value === "Recruitment Report" && (
                                    <>
                                      <Label htmlFor="selectreport">
                                        Technology
                                      </Label>
                                      <div className="mb-3">
                                        <select
                                          className={`form-select ${validation.errors.designationId &&
                                            "is-invalid"
                                            }`}
                                          aria-label="Default select example"
                                          id="selectreport"
                                          name="designationId"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={(event) => {
                                            setTechnology({
                                              ...technology,
                                              techName:
                                                event?.target?.value,
                                            });
                                          }}
                                          onBlur={validation.handleBlur}
                                        >
                                          {designation1?.map((res) => {
                                            return (
                                              <>
                                                <option
                                                  hidden
                                                  disabled
                                                  selected
                                                >
                                                  Select Technology
                                                </option>
                                                <option
                                                  value={res?.techName}
                                                >
                                                  {res?.techName}
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                        <div className="invalid-feedback">
                                          {validation.errors.designationId}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {report?.value ===
                                    "Resources Allocated Report" && (
                                      <>
                                        <Label htmlFor="selectreport">
                                          Allocated Item
                                        </Label>
                                        <Select
                                          value={allocateItem}
                                          onChange={(value) =>
                                            setAllocateItem(value)
                                          }
                                          options={AllocatedItem}
                                        />
                                      </>
                                    )}
                                </Col>
                                <Col lg={2}>
                                  {report?.value ===
                                    "Employee Tasksheet Report" && (
                                      <>
                                        <Label htmlFor="selectreport">
                                          Status
                                        </Label>
                                        <Select
                                          value={allocateStatus}
                                          onChange={(value) =>
                                            setAllocateStatus(value)
                                          }
                                          options={AllocatedStatus}
                                        />
                                      </>
                                    )}
                                  {report?.value === "Recruitment Report" && (
                                    <>
                                      <Label htmlFor="selectreport">
                                        Status
                                      </Label>
                                      <Select
                                        value={recruitmentStatus}
                                        onChange={(value) =>
                                          setRecruitmentStatus(value)
                                        }
                                        options={RecruitmentStatus}
                                      />
                                    </>
                                  )}
                                </Col>
                                <Col md={2} className="g-4">
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </CardHeader>
                  {resourcereport !== undefined ? (
                    <CardBody className="pt-0">
                      {newVar === "Resource Report" && (
                        <div class="container shadow py-2">
                          <div class="table-responsive">
                            <table class="table accordion">
                              {resourcereport !== undefined && (
                                <thead>
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Paid By</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody>
                                {resourcereport?.map((res) => (
                                  <>
                                    <tr
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#r${res?.id}`}
                                      key={res?.id}
                                    >
                                      <th scope="row">
                                        {res?.id}
                                        <i class="bi bi-chevron-down"></i>
                                      </th>
                                      <td>{res?.resourceName}</td>
                                      <td>{res?.amount}</td>
                                      <td>{res?.paidBy}</td>
                                    </tr>
                                    {res?.configuration?.length !== 0 ? (
                                      <>
                                        <tr
                                          class="collapse accordion-collapse"
                                          id={`r${res?.id}`}
                                          data-bs-parent=".table"
                                        >
                                          <th>ID</th>
                                          <th>Company</th>
                                          <th>Purchase Date</th>
                                          <th>Warrenty Date</th>
                                        </tr>

                                        {res.configuration?.map((resp) => (
                                          <tr
                                            class="collapse accordion-collapse"
                                            id={`r${res?.id}`}
                                            data-bs-parent=".table"
                                            key={resp?.id}
                                          >
                                            <td>{resp?.id}</td>
                                            <td>{resp?.brand}</td>
                                            <td>{resp?.purchaseDate}</td>
                                            <td>{resp?.warrantyEndDate}</td>
                                          </tr>
                                        ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  ) : (
                    report.value === "Resource Report" && (
                      <NoResultsMessage />
                    )
                  )}

                  {newVar === "Resources Allocated Report" ? (
                    <CardBody>
                      {allocatedreport?.length ? (
                        <div class="container shadow  py-2">
                          <TableContainer
                            columns={data_allocated}
                            data={allocatedreport || []}
                            isAddUserList={false}
                            customPageSize={5}
                            divClass="table-responsive table-card mb-1"
                            tableClass="text-center table-wrap"
                            theadClass="table-light text-muted"
                          />
                          <Col lg={12} className="g-4">
                            <div className="text-end">
                              <button className="btn-md btn btn-primary mt-3">
                                <i className="ri-download-fill"></i> Download
                              </button>
                            </div>
                          </Col>
                        </div>
                      ) : (
                        <NoResultsMessage />
                      )}
                    </CardBody>
                  ) : (
                    ""
                  )}

                  {newVar === "Damage Resource Report" ? (
                    <CardBody>
                      {damageresourcereport?.length ? (
                        <div class="container shadow min-vh-100 py-2">
                          <TableContainer
                            columns={data_damage}
                            data={damageresourcereport || []}
                            isAddUserList={false}
                            customPageSize={5}
                            divClass="table-responsive table-card mb-1"
                            tableClass="text-center table-nowrap"
                            theadClass="table-light text-muted"
                          />
                        </div>
                      ) : (
                        <NoResultsMessage />
                      )}
                    </CardBody>
                  ) : (
                    ""
                  )}

                  {newVar === "Recruitment Report" ? (
                    <CardBody>
                      {recruitmentreport?.length ? (
                        <div class="container shadow  py-2">
                          <TableContainer
                            columns={data_recruitment}
                            data={recruitmentreport || []}
                            isAddUserList={false}
                            customPageSize={5}
                            divClass="table-responsive table-card mb-1"
                            tableClass="text-center table-nowrap"
                            theadClass="table-light text-muted"
                          />
                        </div>
                      ) : (
                        <NoResultsMessage />
                      )}
                    </CardBody>
                  ) : (
                    ""
                  )}

                  {newVar === "Employee Tasksheet Report" ? (
                    <CardBody>
                      {empolyereport?.length ? (
                        <div class="container shadow  py-2">
                          <TableContainer
                            columns={data_emp_taskSheet}
                            data={empolyereport || []}
                            isAddUserList={false}
                            customPageSize={5}
                            divClass="table-responsive table-card mb-1"
                            tableClass="text-center table-wrap"
                            theadClass="table-light text-muted"
                          />
                        </div>
                      ) : (
                        <NoResultsMessage />
                      )}
                    </CardBody>
                  ) : (
                    ""
                  )}

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Order" : "Add Order"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        validation.resetForm();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div className="mb-3">
                          <Label htmlFor="id-field" className="form-label">
                            Order Id
                          </Label>
                          <Input
                            name="orderId"
                            id="id-field"
                            className="form-control"
                            placeholder="Enter Order Id"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.orderId || ""}
                            invalid={
                              validation.touched.orderId &&
                                validation.errors.orderId
                                ? true
                                : false
                            }
                          />
                          {validation.touched.orderId &&
                            validation.errors.orderId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.orderId}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="customername-field"
                            className="form-label"
                          >
                            Customer Name
                          </Label>
                          <Input
                            name="customer"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.customer || ""}
                            invalid={
                              validation.touched.customer &&
                                validation.errors.customer
                                ? true
                                : false
                            }
                          />
                          {validation.touched.customer &&
                            validation.errors.customer ? (
                            <FormFeedback type="invalid">
                              {validation.errors.customer}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="productName-field"
                            className="form-label"
                          >
                            Product
                          </Label>

                          <Input
                            name="product"
                            type="select"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.product || ""}
                            required
                          >
                            {productName.map((item, key) => (
                              <React.Fragment key={key}>
                                {item.options.map((item, key) => (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </Input>
                          {validation.touched.product &&
                            validation.errors.product ? (
                            <FormFeedback type="invalid">
                              {validation.errors.product}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="date-field" className="form-label">
                            Order Date
                          </Label>

                          <Flatpickr
                            name="orderDate"
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            options={{
                              enableTime: true,
                              altInput: true,
                              altFormat: "d M, Y, G:i K",
                              dateFormat: "d M, Y, G:i K",
                            }}
                            onChange={(e) => dateFormate(e)}
                            value={validation.values.orderDate || ""}
                          />

                          {validation.touched.orderDate &&
                            validation.errors.orderDate ? (
                            <FormFeedback type="invalid">
                              {validation.errors.orderDate}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="row gy-4 mb-3">
                          <div className="col-md-6">
                            <div>
                              <Label
                                htmlFor="amount-field"
                                className="form-label"
                              >
                                Amount
                              </Label>
                              <Input
                                name="amount"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount || ""}
                                invalid={
                                  validation.touched.amount &&
                                    validation.errors.amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.amount &&
                                validation.errors.amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <Label
                                htmlFor="payment-field"
                                className="form-label"
                              >
                                Payment Method
                              </Label>

                              <Input
                                name="payment"
                                type="select"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.payment || ""}
                              >
                                {orderPayment?.map((item, key) => (
                                  <React.Fragment key={key}>
                                    {item?.options?.map((item, key) => (
                                      <option value={item?.value} key={key}>
                                        {item?.label}
                                      </option>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </Input>
                              {validation.touched.payment &&
                                validation.errors.payment ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.payment}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div>
                          <Label
                            htmlFor="delivered-status"
                            className="form-label"
                          >
                            Delivery Status
                          </Label>

                          <Input
                            name="status"
                            type="select"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || ""}
                          >
                            {orderstatus.map((item, key) => (
                              <React.Fragment key={key}>
                                {item?.options?.map((item, key) => (
                                  <option value={item?.value} key={key}>
                                    {item?.label}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </Input>
                          {validation.touched.status &&
                            validation.errors.status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Close
                          </button>

                          <button type="submit" className="btn btn-success">
                            {!!isEdit ? "Update" : "Add Customer"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Modal>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
  );
};

export default EmployeeReport;
