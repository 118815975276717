import React from "react";
import { Navigate } from "react-router-dom";
import DashboardEcommerce from "../pages/dashboard";
import Leaves from "../pages/leaves";
import Employee from "../pages/employees";
import EmployeeProfile from "../pages/employees/employeeProfile";
import Error404 from "../pages/authentication/errors/error";
import Error500 from "../pages/authentication/errors/error500";
import Login from "../pages/authentication/login";
import Report from "../pages/reports";
import Profile from "../pages/profile/profile";
import EditProfile from "../pages/profile/editProfile";
import TimeSheet from "../pages/timesheet";
import EmployeeSupport from "../pages/support";
import Support from "../pages/support/employeesSupport";
import ITP from "../pages/itp";
import Recruitment from "../pages/recruitment";
// import Review from "../pages/review";
import JuniorSheet from "../pages/review/juniorts";
import DamageDevice from "../pages/resources";
import Configuration from "../pages/resources/configuration";
import Allocation from "../pages/resources/allocation";
import ItpDetails from "../pages/itp/itpDetails";
import HRLeaves from "../pages/leaves/HRLeaves";
import ClientManagement from "../pages/projectmanagement/clientIndex.js";
import ProjectDetails from "../pages/projectmanagement/projectDetails";
import PaymentDetails from "../pages/projectmanagement/paymentDetails";
import TaskSheet from "../pages/tasksheet";
import TasksheetAddEdit from "../pages/tasksheet/tasksheetAddEdit";
import TimesheetAddEdit from "../pages/timesheet/timesheetAddEdit";
import BDE from "../pages/bde";
import MasterTable from "../pages/mastertable.js";
import AddEditConfiguration from "../pages/resources/addEditConfiguration";
import InterviewFeedBack from "../pages/interviewFeedBack";
import InterviewFeedBackForm from "../pages/interviewFeedBack/interviewFeedBack";
import InterviewReview from "../pages/interviewFeedBack/feedBackReview";
import ForgetPassword from "../pages/forgetpassword/forgetPassword";
import ResetPassword from "../pages/forgetpassword/resetPasword";
import BdeAddEdit from "../pages/bde/bdeAddEdit";
import DailyUpdate from "../pages/dailyupdate";
import DailyUpdateAddEdit from "../pages/dailyupdate/dailyUpdateAddEdit";
import AquireLeave from "../pages/leaves/acquireLeave";
import ChangePassword from "../pages/password/changePassword.js";
import ForgetPasswordPage from "../pages/authentication/ForgetPassword.js";
import ITPProject from "../pages/itp/project.js";
import ITPProjectDetails from "../pages/itp/projectDetails.js";
import ITPProjectModule from "../pages/itp/projectModule.js";
import ReviewDetails from "../pages/review/reviewDetails.js";
import ReviewTaskSheet from "../pages/review/reviewIndex.js";
import FilterOfResource from "../pages/resources/filterOfResource.js";
import CompanyManagement from "../pages/projectmanagement/companyIndex.js";

const authProtectedRoutes = [
  { path: "/report", component: <Report /> },
  { path: "/leaves", component: <Leaves /> },
  { path: "/hrleaves", component: <HRLeaves /> },
  { path: "/aquireleaves", component: <AquireLeave /> },
  {
    path: "/edit-aquireleaves/:id",
    component: <AquireLeave />,
  },
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/profile", component: <Profile /> },
  { path: "/update-profile", component: <EditProfile /> },
  { path: "/employee", component: <Employee /> },
  { path: "/employee-profile/:emp_id", component: <EmployeeProfile /> },
  { path: "/timesheet", component: <TimeSheet /> },
  { path: "/timesheet/add-timesheet", component: <TimesheetAddEdit /> },
  {
    path: "/timesheet/edit-timesheet/:timesheet_id",
    component: <TimesheetAddEdit />,
  },
  { path: "/tasksheet", component: <TaskSheet /> },
  { path: "/tasksheet/add-tasksheet", component: <TasksheetAddEdit /> },
  {
    path: "/tasksheet/edit-tasksheet/:tasksheet_id",
    component: <TasksheetAddEdit />,
  },
  {
    path: "/time-sheet/review-details",
    component: <ReviewDetails />,
  },
  { path: "/support", component: <Support /> },
  { path: "/employee-support", component: <EmployeeSupport /> },
  { path: "/itp", component: <ITP /> },
  { path: "/itp/:courseId", component: <ItpDetails /> },
  { path: "/recruitment", component: <Recruitment /> },
  { path: "/review", component: <ReviewTaskSheet /> },
  { path: "/junior-tasksheet/:idJuniour", component: <JuniorSheet /> },
  { path: "/damage-device", component: <DamageDevice /> },
  { path: "/configuration", component: <Configuration /> },
  { path: "/allocation", component: <Allocation /> },
  { path: "/project-management", component: <ClientManagement /> },
  { path: "/project-details", component: <ProjectDetails /> },
  { path: "/payment-details", component: <PaymentDetails /> },
  { path: "/bde", component: <BDE /> },
  { path: "/bde/add-bde", component: <BdeAddEdit /> },
  { path: "/bde/edit-bde/:bdeId", component: <BdeAddEdit /> },
  { path: "/master-table", component: <MasterTable /> },
  { path: "/filter-resource", component: <FilterOfResource /> },
  { path: "/company-management", component: <CompanyManagement /> },
  {
    path: "/configuration/addConfiguration",
    component: <AddEditConfiguration />,
  },
  {
    path: "/configuration/editConfiguration/:configurationId",
    component: <AddEditConfiguration />,
  },
  { path: "/interview_feedback", component: <InterviewFeedBack /> },
  { path: "/interview_feedBackFrom", component: <InterviewFeedBackForm /> },
  {
    path: "/interview_feedBackFrom/:interviewId",
    component: <InterviewFeedBackForm />,
  },
  { path: "/interview_review_page", component: <InterviewReview /> },
  { path: "/resetpassword", component: <ResetPassword /> },
  { path: "/employee-aliased-details", component: <MasterTable /> },
  { path: "/daily-update", component: <DailyUpdate /> },
  { path: "/daily-update/add-daily-update", component: <DailyUpdateAddEdit /> },
  {
    path: "/daily-update/edit-daily-update/:dailyUpdateId",
    component: <DailyUpdateAddEdit />,
  },
  { path: "/change-password", component: <ChangePassword /> },
  { path: "/itp-project", component: <ITPProject /> },
  { path: "/itp-project/create", component: <ITPProjectDetails /> },
  {
    path: "/itp-project/create/:itpProjectId",
    component: <ITPProjectDetails />,
  },
  { path: "/itp-project/modules", component: <ITPProjectModule /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
  { path: "/forgetpassword", component: <ForgetPassword /> },
  { path: "*", component: <Error404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
];

export { authProtectedRoutes, publicRoutes };
