import {
  ADD_CLIENT,
  ADD_COMPANY,
  DELETE_CLIENT,
  DELETE_COMPANY,
  EDIT_CLIENT,
  LIST_OF_CLIENT,
  UPDATE_COMPANY,
} from "./apiRoutes";
import { apiService2 } from "./baseURL";

export const addCompanyDetails = async (data) => {
  const response = await apiService2.post(`${ADD_COMPANY}`, data);
  return response;
};

export const editCompanyDetails = async (id, data) => {
  const response = await apiService2.put(`${UPDATE_COMPANY}/${id}`, data);
  return response;
};

export const deleteCompanyDetails = async (id, data) => {
  const response = await apiService2.put(`${DELETE_COMPANY}/${id}`, data);
  return response;
};

export const addClientDetails = async (data) => {
  const response = await apiService2.post(`${ADD_CLIENT}`, data);
  return response;
};

export const editClientDetails = async (id, data) => {
  const response = await apiService2.put(`${EDIT_CLIENT}/${id}`, data);
  return response;
};

export const deleteClientDetails = async (id, data) => {
  const response = await apiService2.put(`${DELETE_CLIENT}/${id}`, data);
  return response;
};

export const listOfClientDetails = async (data) => {
  const response = await apiService2.post(`${LIST_OF_CLIENT}`, data);
  return response;
};
