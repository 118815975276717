import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import logoLight from "../../assets/logo/shivlogo.png";
import ParticlesAuth from "./particlesAuth";
import withRouter from "../../components/common/withRouter";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import {
  updatePassword,
  verifyEmail,
  verifyOtp,
} from "../../api/forgetPasswordApi";

const ForgetPasswordPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showpasswordsfield, setShowPasswordField] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [buttonLabel, setbuttonLabel] = useState("Send OTP");
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      otp: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(RequiredField("Email"))
        .matches(emailRegex, "Please enter valid email address."),
      otp: Yup.string().when([], {
        is: () => showOtp,
        then: Yup.string()
          .required("OTP is required")
          .length(4, "OTP must be 4 characters"),
        otherwise: Yup.string().notRequired(),
      }),
      newPassword: Yup.string().when([], {
        is: () => showpasswordsfield,
        then: Yup.string()
          .required(RequiredField("New Password"))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
        otherwise: Yup.string().notRequired(),
      }),
      confirmPassword: Yup.string().when([], {
        is: () => showpasswordsfield && validation.values.confirmPassword,
        then: Yup.string()
          .oneOf(
            [Yup.ref("newPassword")],
            "New Password & Confirm Password do not match."
          )
          .required("Confirm Password is required"),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      if (values.email !== "" && !showOtp && showpasswordsfield === false) {
        const verifyEmailpayload = {
          email: values.email,
        };
        verifyEmail(verifyEmailpayload)
          .then((response) => {
            const responseBody = response?.data;
            if (
              responseBody?.statusCode === ResponseStatusEnum.SUCCESS ||
              responseBody?.statusCode === ResponseStatusEnum.ACCEPTED
            ) {
              setShowOtp(true);
              setbuttonLabel("Verify OTP");
              toast.success(responseBody?.message);
            } else {
              toast.error(responseBody?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      if (values.otp !== "" && showOtp) {
        const payload = {
          email: values.email,
          otp: values.otp,
        };

        verifyOtp(payload)
          .then((response) => {
            const responseBody = response?.data;
            if (
              responseBody?.statusCode === ResponseStatusEnum.SUCCESS ||
              responseBody?.statusCode === ResponseStatusEnum.ACCEPTED
            ) {
              setShowPasswordField(true);
              setShowOtp(false);
              setbuttonLabel("Submit");
              toast.success(responseBody?.message);
            } else {
              toast.error(responseBody?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      if (values.newPassword !== "" && setShowPasswordField && !showOtp) {
        const payload1 = {
          email: values.email,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        };
        updatePassword(payload1)
          .then((response) => {
            const responseBody = response?.data;
            if (
              responseBody?.statusCode === ResponseStatusEnum.SUCCESS ||
              responseBody?.statusCode === ResponseStatusEnum.ACCEPTED
            ) {
              toast.success(responseBody?.message);
              navigate("/login");
            } else {
              toast.error(responseBody?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message);
            return err;
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
  });

  document.title = "Forgot Password";
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="30" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Forgot Password?</h5>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}></lord-icon>
                  </div>

                  <Alert
                    className="alert-borderless alert-warning text-center mb-2 mx-2"
                    role="alert">
                    Enter your email and OTP will be sent to you!
                  </Alert>
                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      <div className="mb-2">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          disabled={showOtp || showpasswordsfield}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      {showOtp && (
                        <div className="mb-2">
                          <Label className="form-label">OTP</Label>
                          <Input
                            name="otp"
                            className="form-control"
                            placeholder="Enter otp"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.otp || ""}
                            invalid={
                              validation.touched.otp && validation.errors.otp
                                ? true
                                : false
                            }
                          />
                          {validation.touched.otp && validation.errors.otp ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.otp}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      )}

                      {showpasswordsfield && (
                        <>
                          {" "}
                          <div>
                            <Label
                              htmlFor="newpasswordInput"
                              className="form-label">
                              New Password*
                            </Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Input
                                type={newPasswordShow ? "text" : "password"}
                                className="form-control"
                                id="newpasswordInput"
                                placeholder="Enter new password"
                                name="newPassword"
                                value={validation.values.newPassword || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.newPassword &&
                                  validation.errors.newPassword
                                }
                              />
                              {validation.touched.newPassword &&
                                validation.errors.newPassword && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.newPassword}
                                  </FormFeedback>
                                )}
                              <button
                                className={`btn btn-link position-absolute ${
                                  validation.errors.newPassword
                                    ? "err"
                                    : "end-0"
                                } top-0 text-decoration-none text-muted`}
                                type="button"
                                id="password-addon"
                                onClick={() =>
                                  setNewPasswordShow(!newPasswordShow)
                                }>
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                            </div>
                          </div>
                          <div>
                            <Label
                              htmlFor="confirmpasswordInput"
                              className="form-label">
                              Confirm Password*
                            </Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Input
                                type={confirmPasswordShow ? "text" : "password"}
                                className="form-control"
                                id="confirmpasswordInput"
                                placeholder="Enter confirm password"
                                name="confirmPassword"
                                value={validation.values.confirmPassword || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.confirmPassword &&
                                  validation.errors.confirmPassword
                                }
                              />
                              {validation.touched.confirmPassword &&
                                validation.errors.confirmPassword && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.confirmPassword}
                                  </FormFeedback>
                                )}

                              <button
                                className={`btn btn-link position-absolute ${
                                  validation.errors.confirmPassword
                                    ? "err"
                                    : "end-0"
                                } top-0 text-decoration-none text-muted`}
                                type="button"
                                id="password-addon"
                                onClick={() =>
                                  setConfirmPasswordShow(!confirmPasswordShow)
                                }>
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          {isLoading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : (
                            <>{buttonLabel}</>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline">
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
