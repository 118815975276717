import React from 'react'
import { Col, Container, Input, InputGroup, Row } from 'reactstrap'
import ParticlesAuth from "../particlesAuth";
import { Link } from 'react-router-dom';
import Countdown from "react-countdown"

// //import images
// import comingsoon from '../../../assets/image/comingsoon.png';
import error from '../../../assets/image/error400-cover.png';

const Error = () => {
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Sorry, Page not Found 😭</span>
        }
    }

document.title ="Page Not Found";

    return (
        <React.Fragment>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 pt-4 mb-4">
                                    <div className="mb-5">
                                        <img src={error} alt="" height="300"/>
                                        {/* <h1 className="display-2 coming-soon-text">Error 404</h1> */}
                                    </div>
                                    <div>
                                        <Row className="justify-content-center mt-5">
                                            <Col lg={8}>
                                                <Countdown date="2022/12/31" renderer={renderer} />
                                            </Col>
                                        </Row>

                                        <div className="mt-5">
                                
                                        </div>
                                        <Link to="/dashboard" className="btn btn-success"><i className="mdi mdi-home me-1"></i>Back to home</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </div>
        </React.Fragment>
    )
}

export default Error