import React, { useState, useMemo, useEffect } from "react";
import { Card, Container, CardBody, CardHeader, Row } from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { getRole } from "../../utils/helpers/custom";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import { Link, useLocation } from "react-router-dom";
import NoResultsMessage from "../../components/noResultMessage";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { deleteProjectDetails, listOfAssignProjectModule, listOfProject } from "../../api/itpProject";
import { toast } from "react-toastify";
import { ResponseStatusEnum } from "../../utils/helpers/enums";

const ITPProjectModule = (props) => {
  document.title = "ITP Project Module";
  const location = useLocation();
  const id = location.state.id;
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState();

  const listOfProjectFun = () => {
    setLoading(true);
    listOfProject({
        itpProjectId: id
    })
      .then((res) => {
        if (res?.statusCode === ResponseStatusEnum.SUCCESS) {
          setSelectedRow(res?.data[0]?.itpProjectDetails);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  const listOfProjectModules = () => {
    setLoading(true);
    listOfAssignProjectModule(id)
      .then((res) => {
        if (res?.statusCode === ResponseStatusEnum.SUCCESS) {
          setSelectedRow(res?.data[0]?.itpProjectDetails);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listOfProjectFun();
    if (role  === 'Employee') {
      listOfProjectModules();
    }
  }, []);

  const QueryColumns = useMemo(() => [
    {
      Header: "Module",
      accessor: "module",
      filterable: false,
    },
    {
      Header: "Day",
      accessor: "days",
      filterable: false,
    },
    {
      Header: "Api",
      accessor: "apis",
      filterable: false,
    },
    {
        Header: "Screen",
        accessor: "screen",
        filterable: false,
    },
    {
        Header: "Api End Point",
        Cell: (cellProps) => {
          const Celldata = cellProps.row.original.apiEndPoints;
          return (
            <div>
              <div className="hstack gap-2">
              {HtmlToJsx(Celldata)}
              </div>
            </div>
          );
        },
        accessor: "apiEndPoints",
        filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              {/* Delete */}
              {(role !== "Employee") && (
                <button
                  id={`deleteTodo-${cellProps?.cell?.row?.original?.id}`}
                  className="btn btn-sm btn-soft-danger remove-list"
                  onClick={() =>
                    handleDelete(cellProps?.cell?.row?.original?.id)
                  }>
                  <i className="ri-delete-bin-5-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="error"
                    content="Delete"
                    anchorId={`deleteTodo-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              )}
              {(role === "Employee" ) && (
                <button
                  className="btn btn-sm btn-soft-danger remove-list"
                  disabled
                  >
                  <i className="ri-delete-bin-5-fill align-bottom" />
                  <ReactTooltip
                    place="bottom"
                    variant="error"
                    content="Delete"
                    anchorId={`deleteTodo-${cellProps?.cell?.row?.index}`}
                  />
                </button>
              )}
            </div>
          </div>
        );
      },
    },
  ]);

  const role = getRole();

  // Delete
  const handleDelete = (id) => {
    setSelectedDeleteId(id);
    setDeleteModal(true);
  };

  const handleDeleteTodo = () => {
    setLoading(true);
    deleteProjectDetails({id: selectedDeleteId})
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(res?.data?.message);
          listOfProjectFun();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setDeleteModal(false);
        setLoading(false);
        listOfProjectFun();
      });
  };

  function HtmlToJsx(htmlString) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }

  return (
    <React.Fragment>
      <ApproveRejectModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
        action={selectedRow ? selectedRow.action : ""}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="ITP Project Module" pageTitle="ITP Project" navigation="/itp-project" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">
                  ITP(Interns Training Program) Project Modules
                </h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                    <Link to="/itp-project">
                      <button
                        className="btn btn-primary createTask"
                        type="button">
                        Back
                      </button>
                    </Link>
                </div>
              </div>
            </Row>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">ITP Project Module</h4>
              </CardHeader>

              <CardBody>
                  <>
                    {selectedRow?.length ? (
                      <TableContainer
                        columns={QueryColumns}
                        data={selectedRow || []}
                        isAddUserList={false}
                        customPageSize={10}
                        isGlobalFilter={true}
                        divClass="table-responsive table-card mb-1 "
                        tableClass="align-middle table-nowrap "
                        theadClass="table-light text-muted"
                        SearchPlaceholder="Search..."
                      />
                    ) : (
                      <NoResultsMessage />
                    )}
                  </> 
                </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </React.Fragment>
  );
};

export default ITPProjectModule;
