import React, { useState, useCallback, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Card,
  Container,
  CardBody,
  CardHeader,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { getId, getRole } from "../../utils/helpers/custom";
import * as Yup from "yup";
import { useFormik, Formik, Field, ErrorMessage, FieldArray } from "formik";
import TableContainer from "../../components/common/tableContainer";
import Loader from "../../components/common/loader";
import {
  listTopics,
  addCourse,
  updateCourse,
  deleteCourse,
  deleteTopics,
  listOfAssignCourse,
} from "../../api/itpApi";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { Link } from "react-router-dom";
import NoResultsMessage from "../../components/noResultMessage";
import ApproveRejectModal from "../../components/common/approveRejectModal";

const ITP = (props) => {
  const id = getId();

  const [itplist, setitplist] = useState();
  const [loading, setLoading] = useState(false);
  const [assignCourse, setAssignCourse] = useState([]);
  const [payload, setPayload] = useState();
  const [selectedRow, setSelectedRow] = useState(null);

  const listOfTopicsFun = () => {
    setLoading(true);
    listTopics({ ...itplist })
      .then((resp) => {
        setitplist(resp.data.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  const listOfAssignTopicFun = () => {
    setLoading(true);
    listOfAssignCourse({ assignTo: parseInt(id) })
      .then((response) => {
        if (response?.data?.message === ResponseStatusEnum.DATA_NOT_FOUND) {
          setAssignCourse([]);
        } else {
          setAssignCourse(response?.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (role === "Admin" || role === "HR") {
      listOfTopicsFun();
    }

    if (role === "Employee" || role === "BDE") {
      listOfAssignTopicFun();
    }
  }, []);

  const QueryColumnsEmp = useMemo(() => [
    {
      Header: "Course Name",
      accessor: "course.courseName",
      Cell: (item) => {
        return <div>{item?.cell?.row?.original?.course?.courseName}</div>;
      },
    },
    {
      Header: "No of Topics",
      accessor: "course.noOfTopics",
      Cell: (item) => {
        return <div>{item?.cell?.row?.original?.course?.noOfTopics}</div>;
      },
    },
    {
      Header: "Duration(Hours)",
      accessor: "course.duration",
      Cell: (item) => {
        return <div>{item?.cell?.row?.original?.course?.duration}</div>;
      },
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <Link
                to={`/itp/${cellProps?.cell?.row?.original?.course?.id}`}
                className="btn btn-sm btn-soft-info edit-list">
                <i className="ri-eye-line align-bottom" />
              </Link>
            </div>
          </div>
        );
      },
    },
  ]);

  const QueryColumns = useMemo(() => [
    {
      Header: "Course Name",
      accessor: "courseName",
      filterable: false,
    },
    {
      Header: "No of Topics",
      accessor: "noOfTopics",
      filterable: false,
    },
    {
      Header: "Duration(Hours)",
      accessor: "duration",
      filterable: false,
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div>
            <div className="hstack gap-2">
              <button
                className="btn btn-sm btn-soft-info edit-list"
                onClick={() => itpEdit(cellProps.row.original)}>
                <i className="ri-pencil-fill align-bottom" />
              </button>
              <button
                className="btn btn-sm btn-soft-danger remove-list"
                onClick={() => handleDelete(cellProps.row.original?.id)}>
                <i className="ri-delete-bin-5-fill align-bottom" />
              </button>
              <Link
                to={`/itp/${cellProps?.row?.original?.id}`}
                className="btn btn-sm btn-soft-info edit-list">
                <i className="ri-eye-line align-bottom" />
              </Link>
            </div>
          </div>
        );
      },
    },
  ]);

  const [todo, setTodo] = useState(null);
  const [modalTodo, setModalTodo] = useState(false);
  const [topic, setRowsData] = useState([]);
  const role = getRole();
  const idUser = parseInt(getId());

  const additp = () => {
    setTodo("");
    setModalTodo(!modalTodo);
    setIsEdit(false);
    setRowsData([]);
    toggle();
  };

  const toggle = useCallback(() => {
    if (modalTodo) {
      setModalTodo(false);
      listTopics({ ...itplist })
        .then((resp) => {
          setitplist(resp?.data?.data);
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
      setTodo(null);
    } else {
      setModalTodo(true);
    }
  }, [modalTodo]);

  //Edit table
  const [isEdit, setIsEdit] = useState(false);
  const itpEdit = useCallback(
    (arg) => {
      const todo = arg;
      setPayload([...(todo?.topic || [])]);

      setTodo({
        courseName: todo.courseName,
        courseId: todo.id,
      });
      setRowsData(todo.topic);
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      courseName: (todo && todo.courseName) || "",
    },
    validationSchema: Yup.object({
      courseName: Yup.string().required(RequiredField("Course name")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const newTopic = topic.map((res) => {
          return {
            id: res.id,
            link: res.link,
            topicName: res.topicName,
            hour: res.hour,
          };
        });
        setLoading(true);
        updateCourse({ ...values, courseId: todo.courseId, topic: newTopic })
          .then((response) => {
            if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(response?.data?.message);
              listOfTopicsFun();
              setLoading(false);
            }
          })
          .catch((err) => err)
          .finally(() => {
            setLoading(false);
          });
        setModalTodo(false);
        validation.resetForm();
      } else {
        setLoading(true);
        addCourse({ userId: idUser, ...values, topic }).then((response) => {
          if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(response?.data?.message);
            listTopics({ ...itplist }).then((resp) => {
              setitplist(resp.data.data);
              setLoading(false);
            });
          }
        });
      }
      setModalTodo(false);
      validation.resetForm();
      toggle();
    },
  });

  const [deleteId, setDeleteId] = useState();
  const [deleteTopicId, setDeleteTopicId] = useState(undefined);

  // Delete To do
  const [deleteModal, setDeleteModal] = useState(false);
  const [arrayHelpersState, setArrayHelpersState] = useState();
  const [indexState, setIndexState] = useState();

  useEffect(() => {
    if (deleteTopicId !== undefined) {
      deleteTopics({
        courseId: todo?.courseId,
        topic: [{ id: deleteTopicId, isDeleted: true }],
      })
        .then((res) => {
          arrayHelpersState?.remove(indexState);
          setDeleteTopicId(undefined);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {});
    } else {
      arrayHelpersState?.remove(indexState);
    }
  }, [deleteTopicId, arrayHelpersState, todo?.courseId, indexState]);
  const itpDelete = (id, arrayHelpers, index) => {
    setDeleteTopicId(id);
    setArrayHelpersState(arrayHelpers);
    setIndexState(index);
  };

  const handleDelete = (row) => {
    setSelectedRow({ ...row, action: "to delete this record" });
    setDeleteId(row);
    setDeleteModal(true);
  };

  const handleDeleteTodo = () => {
    deleteCourse({ id: deleteId, isDeleted: true }).then((resp) => {
      setLoading(true);
      if (resp.data.statusCode === ResponseStatusEnum.SUCCESS) {
        setDeleteModal(false);
        setitplist(resp.data.data);
        toast.success(resp.data.message);
        listTopics().then((resp) => {
          setitplist(resp.data.data);
          setLoading(false);
        });
      } else {
        setLoading(false);
        toast.error(resp.data.message);
      }
    });
  };

  const handleSubmit = (values) => {
    if (isEdit) {
      updateCourse({ ...values, courseId: todo.courseId })
        .then((response) => {
          if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
            toast.success(response?.data?.message);
            listOfTopicsFun();
            setLoading(false);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      addCourse({ userId: idUser, ...values }).then((response) => {
        if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
          toast.success(response?.data?.message);
          listTopics({ ...itplist }).then((resp) => {
            setitplist(resp.data.data);
            setLoading(false);
          });
        }
      });
    }

    setModalTodo(false);
  };

  const validationSchema = Yup.object({
    courseName: Yup.string().required("Course name is required."),
    topic: Yup.array().of(
      Yup.object().shape({
        topicName: Yup.string().required("Topic name is required."),
        link: Yup.string().nullable().url("Invalid URL format"),
        hour: Yup.string().required("Hour is required"),
        remark: Yup.string().nullable(),
      })
    ),
  });

  const dataList = role === "Admin" || role === "HR" ? itplist : assignCourse;

  const column =
    role === "Admin" || role === "HR" ? QueryColumns : QueryColumnsEmp;

  document.title = "ITP";

  return (
    <React.Fragment>
      <ApproveRejectModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
        action={selectedRow ? selectedRow.action : ""}
      />
      <div className="page-content">
        {loading === false ? (
          <Container fluid>
            <BreadCrumb title="ITP" pageTitle="HRMS" />
            <Row className="mb-4">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">
                  ITP(Interns Training Program)
                </h5>
              </div>

              <div className="col-auto order-2 order-sm-3 ms-auto">
                <div className="hstack gap-2">
                  {role !== "Employee" && (
                    <button
                      className="btn btn-primary createTask"
                      type="button"
                      onClick={() => additp()}>
                      + New ITP
                    </button>
                  )}
                  {role !== "BDE" && (
                    <Link to="/itp-project">
                      <button
                        className="btn btn-primary createTask"
                        type="button">
                        Project
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </Row>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">ITP</h4>
              </CardHeader>
              <CardBody>
                {dataList?.length ? (
                  <TableContainer
                    columns={column}
                    data={dataList || []}
                    isAddUserList={false}
                    customPageSize={5}
                    isGlobalFilter={true}
                    divClass="table-responsive table-card mb-1 "
                    tableClass="align-middle table-nowrap "
                    theadClass="table-light text-muted"
                    SearchPlaceholder="Search..."
                  />
                ) : (
                  <NoResultsMessage />
                )}
              </CardBody>
            </Card>
          </Container>
        ) : (
          <Loader />
        )}
      </div>

      <Modal
        size="xl"
        id="createTask"
        isOpen={modalTodo}
        toggle={toggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1">
        <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
          {" "}
          {!!isEdit ? "Edit " : "Add "}
          {"ITP Form"}{" "}
        </ModalHeader>
        <ModalBody>
          <div id="task-error-msg" className="alert alert-danger py-2"></div>

          <Formik
            initialValues={{
              courseName: "" || todo?.courseName,
              topic: isEdit
                ? payload
                : [{ topicName: "", remark: "", link: "", hour: "" }],
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize>
            {({ values, handleSubmit, handleChange, handleBlur }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(values);
                }}>
                <input
                  type="hidden"
                  id="taskid-input"
                  className="form-control"
                />

                <Row className="g-4 mb-3">
                  <Col lg={12}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Course Name:
                      </label>

                      <Field
                        className="form-control"
                        placeholder="Enter Course Name"
                        name="courseName"
                        onChange={handleChange("courseName")}
                        onBlur={handleBlur("courseName")}
                        value={values.courseName}
                      />
                      <ErrorMessage
                        name="courseName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                </Row>

                <FieldArray
                  name="topic"
                  render={(arrayHelpers) => (
                    <div>
                      <div
                        className="todo-content position-relative px-4 mx-n4"
                        id="todo-content">
                        <div className="todo-task" id="todo-task">
                          <div className="table-responsive">
                            <table className="table align-middle position-relative table-nowrap">
                              <thead className="table-active">
                                <tr>
                                  <th scope="col">Topic Name</th>
                                  <th scope="col">Remark</th>
                                  <th scope="col">Link</th>
                                  <th scope="col">Hour</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody id="task-list">
                                <button
                                  type="button"
                                  className="btn btn-ghost-success mt-2 custom-css-input-size-button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      topicName: "",
                                      link: "",
                                      hour: "",
                                    })
                                  }>
                                  + New Topic
                                </button>
                                {values.topic.map((user, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Field
                                        placeholder="Enter Topic Name"
                                        name={`topic.${index}.topicName`}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`topic.${index}.topicName`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </td>

                                    <td className="custom-css-input-size">
                                      <Field
                                        placeholder="Enter Remark"
                                        name={`topic.${index}.remark`}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`topic.${index}.remark`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </td>

                                    <td className="custom-css-input-size-link">
                                      <Field
                                        placeholder="Enter Link"
                                        name={`topic.${index}.link`}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`topic.${index}.link`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </td>

                                    <td className="custom-css-input-size-hour">
                                      <Field
                                        type="text"
                                        placeholder="Enter Hour"
                                        name={`topic.${index}.hour`}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`topic.${index}.hour`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </td>

                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={() =>
                                          itpDelete(
                                            user.id,
                                            arrayHelpers,
                                            index
                                          )
                                        }>
                                        <i className="ri-delete-bin-2-fill"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />

                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-ghost-success"
                    onClick={() => {
                      setModalTodo(false);
                      listTopics({ ...itplist })
                        .then((resp) => {
                          setitplist(resp.data.data);
                        })
                        .catch((err) => err)
                        .finally(() => {
                          setLoading(false);
                        });
                    }}>
                    <i className="ri-close-fill align-bottom"></i> Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ITP;
