import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { findAssignCourse, listTopics, dragAndDrop } from "../../api/itpApi";
import Loader from "../../components/common/loader";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { getRole } from "../../utils/helpers/custom";
import DragndropTable from "../../components/common/dragndropTable";
import { toast } from "react-toastify";
import TableContainer from "../../components/common/tableContainer";

const ItpDetails = () => {
  const { courseId } = useParams();
  const role = getRole();
  const [loading, setLoading] = useState(false);
  const [topic, setTopic] = useState([]);
  const [courseName, setCourseName] = useState([]);
  const [assignCourse, setAssignCourse] = useState([]);

  const listAssignCourseFunAddBY = () => {
    listTopics({
      courseId: courseId,
    })
      .then((res) => {
        if (res?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
          setCourseName(res?.data?.data[0]?.courseName);
          setTopic(res?.data?.data[0]?.topic);
        } else {
          setTopic([]);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  const listAssignCourseFunAssignBy = () => {
    findAssignCourse({ courseId: parseInt(courseId) })
      .then((response) => {
        if (response?.data?.message === "Data not found.") {
          setAssignCourse([]);
        } else {
          setAssignCourse(response?.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (role === "Admin" || role === "HR") {
      listAssignCourseFunAddBY();
    }
    if (role === "BDE" || role === "Employee") {
      listAssignCourseFunAssignBy();
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result || !result?.destination) {
      return;
    }

    if (result?.source?.droppableId !== result?.destination?.droppableId) {
      return;
    }

    const fromIndex = result?.source?.index;
    const toIndex = result?.destination?.index;
    const draggedTopic = topic[fromIndex];

    const items = [...topic];
    items.splice(fromIndex, 1);
    items.splice(toIndex, 0, draggedTopic);

    setTopic(items);
    const insertdata = [];
    for (let key of items) {
      insertdata?.push({
        id: key?.id,
        courseId: key?.courseId,
        topicName: key?.topicName,
        link: key?.link,
        hour: key?.hour,
      });
    }

    let payload = {
      topic: insertdata,
    };

    dragAndDrop(payload)
      .then((response) => {
        if (response?.data?.statusCode === ResponseStatusEnum?.SUCCESS) {
          listAssignCourseFunAddBY();
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        return error;
      });
  };

  const QueryColumns = useMemo(() => {
    const columns = [
      {
        Header: "Topic Name",
        accessor: "topicName",
        filterable: false,
      },
      {
        Header: "Hour",
        accessor: "hour",
        filterable: false,
      },
      {
        Header: "Remark",
        accessor: "remark",
        filterable: false,
      },
      {
        Header: "Link",
        Cell: (cellProps) => {
          const Celldata = cellProps.row.original.link;
          return (
            <div>
              <div className="hstack gap-2">
                <a href={Celldata} target="_blank">
                  {Celldata}
                </a>
              </div>
            </div>
          );
        },
        accessor: "link",
        filterable: false,
      },
    ];

    // Conditionally add the "Action" column header if the role is not "Employee"
    if (role === "Admin" || role === "HR") {
      columns.unshift({
        Header: "Action",
        Cell: () => (
          <div>
            <i className="bx bx-menu"></i>
          </div>
        ),
      });
    }

    return columns;
  }, [role]);

  return (
    <div className="page-content">
      {loading === false ? (
        <Container fluid>
          <BreadCrumb title="itp-details" pageTitle="HRMS" />
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1 ">{courseName}</h4>
              <Link to={"/itp"} className="btn btn-primary">
                Back
              </Link>
            </CardHeader>

            {(role === "Admin" || role === "HR") && (
              <CardBody>
                {topic?.length ? (
                  <DragndropTable
                    columns={QueryColumns}
                    data={topic || []}
                    isAddUserList={false}
                    isGlobalFilter={true}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-wrap"
                    theadClass="table-light text-muted"
                    onDragEnd={onDragEnd}
                  />
                ) : (
                  <div className="py-4 mt-4 text-center" id="noresult">
                    <lord-icon
                      src="https://cdn.lordicon.com/msoeawqm.json"
                      trigger="loop"
                      colors="primary:#405189,secondary:#0ab39c"
                      style={{ width: "72px", height: "72px" }}></lord-icon>
                    <h5 className="mt-4">Sorry! Topics are not available.</h5>
                  </div>
                )}
              </CardBody>
            )}
            {(role === "Employee" || role === "BDE") && (
              <CardBody>
                {assignCourse?.length ? (
                  <TableContainer
                    columns={QueryColumns}
                    data={assignCourse || []}
                    isAddUserList={false}
                    customPageSize={5}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-wrap"
                    theadClass="table-light text-muted"
                    onDragEnd={onDragEnd}
                  />
                ) : (
                  <div className="py-4 mt-4 text-center" id="noresult">
                    <lord-icon
                      src="https://cdn.lordicon.com/msoeawqm.json"
                      trigger="loop"
                      colors="primary:#405189,secondary:#0ab39c"
                      style={{ width: "72px", height: "72px" }}></lord-icon>
                    <h5 className="mt-4">Sorry! Topics are not available.</h5>
                  </div>
                )}
              </CardBody>
            )}
          </Card>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ItpDetails;
